import React from 'react';
import PropTypes from 'prop-types';
import './RankedCompanyCardInfo.scss';

const RankedCompanyCardInfo = (props) => {
  const { title, text, icon, color = 'primary' } = props;
  return (
    <div className="ranked-company-card-info">
      <div className="ranked-company-card-info__title">
        <i className={`material-icons color-${color}`}> {icon} </i>
        {title}
      </div>
      {text}
    </div>
  );
};

RankedCompanyCardInfo.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default RankedCompanyCardInfo;
