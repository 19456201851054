import React, { Component } from 'react';
import { RankedCompanyList } from 'components/User/Reports/RankedCompanyList/RankedCompanyList';
import dummyInfoObjects from 'commons/DummyInfoObjects';


const sortbyScore = ({ score: scoreA }, { score: scoreB }) => {
  if (scoreA > scoreB) {
    return -1;
  }

  if (scoreA < scoreB) {
    return 1;
  }

  return 0;
};
export class RankedCompanyView extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getValueBySourceName = (data, sourceName) => {
    const { hiring = {}, painPointV2 = {}, competitors = {} } = data;
    const valueSwitcher = {
      'By All': () => data.avg || 0,
      'By Hiring': () => hiring.score || 0,
      'By Pain Points': () => painPointV2.score || 0,
      'By Competitors': () => competitors.score || 0,
    };
    const value = valueSwitcher[sourceName]();
    return value > 100 ? 100 : value;
  }

  getOverview = (data, sourceName) => {
    const { hiring = {}, painPointV2 = {}, competitors = {} } = data;
    const valueSwitcher = {
      'By All': this.getAllOverView(data, 3),
      'By Hiring': this.getOverViewByObject('hiring', hiring) || [],
      'By Pain Points': this.getOverViewByObject('painPointV2', painPointV2) || [],
      'By Competitors': this.getOverViewByObject('competitors', competitors) || [],
    };
    const value = valueSwitcher[sourceName];
    return value;
  }

  getOverViewByObject = (name, { overview = '' }) => (
    !overview.isNullOrEmpty() && [{ ...dummyInfoObjects[name], text: overview }]
  )

  getAllOverView = (data, limit) => {
    let filteredData = [];
    Object.keys(data).forEach((name) => {
      const { overview } = data[name];
      if (overview) {
        filteredData = [...filteredData, { type: name, ...data[name] }];
      }
    });
    filteredData = filteredData.sort(sortbyScore).slice(0, limit);
    return filteredData.map(({ type, overview }) => (
      { ...dummyInfoObjects[type], text: overview }
    ));
  }

  calculateRankedCompanies = () => {
    const { result } = this.props;
    const {
      scores,
      company_ids,
    } = result;

    const sourceNames = ['By All', 'By Hiring', 'By Pain Points'];

    let rankedData = [];
    sourceNames.forEach((sourceName) => {
      let dataList = [];
      Object.keys(scores).forEach((key) => {
        const data = scores[key];
        const companyIndex = company_ids.findIndex(({ companyName }) => companyName === key);
        const percent = this.getValueBySourceName(data, sourceName);
        if (percent > 0) {
          dataList = [...dataList, {
            name: key,
            reportId: company_ids[companyIndex].id,
            percent,
            infoList: this.getOverview(data, sourceName),
          }];
        }
      });
      rankedData = [
        ...rankedData,
        {
          sourceName,
          dataList,
        },
      ];
    });
    return rankedData;
  }

  render() {
    const { userType } = this.props;
    const rankedData = this.calculateRankedCompanies();

    return (
      <div className="company-reports__body__section">
        <h2> Ranked Companies </h2>
        <RankedCompanyList rankedDatas={rankedData} userType={userType} />
      </div>
    );
  }
}

export default RankedCompanyView;
