import React, { Component } from 'react';
import StringUtils from 'utils/StringUtils';
import Image from 'components/Image/Image';
import './CompanyTableDescriptionsItem.scss';

export class CompanyTableDescriptionsItem extends Component {
  render() {
    const { article = {} } = this.props;
    const { title, url: link, attributeIndexes } = article;
    return (
      <a className="company-table-insights-item" href={link} target="_blank" title={title}>
        <div className="company-table-insights-item__image">
          <Image src={new StringUtils().getCompanyLogo()} />
        </div>
        <div className="company-table-insights-item__title">
          {title}
        </div>
        <div className="company-table-insights-item__text">
          <ul>
            {attributeIndexes.map(i => {
              return (
                <li>
                  {i[1].map((index, x) => {
                    if (x == 0)
                      return [<span> {i[0].slice(0, index[0])} </span>, <span style={{color:"blue"}}> {i[0].slice(index[0], index[1])}</span>]
                    else
                      return [<span> {i[0].slice(i[1][x-1][1], index[0])} </span>, <span style={{color:"blue"}}> {i[0].slice(index[0], index[1])} </span>]
                    })
                  }
                  <span> {i[0].slice(i[1].at(-1)[1])}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </a>
    )
  }
}

export default CompanyTableDescriptionsItem;
