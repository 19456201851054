import React, { Component } from 'react'
import CompanyCheckBox from '../CompanyCheckBox/CompanyCheckBox'
import CompanyTableJobs from '../CompanyTableInsights/CompanyTableJobs';
import CompanyTableDescriptions from '../CompanyTableInsights/CompanyTableDescriptions';
import './CompanyTableItem.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export class CompanyTableItem extends Component {
  constructor(props) {
    super(props);
    this.tableJobsRef = null;
    this.tableAttributesRef = null;
    this.mouseEventTimeOut = null;
  }

  onSelected = (isChecked) => {
    const { name } = this.props;
    this.props.onCompanySelected(name, isChecked);
  }

  onMouseEnterJobs = () => {
    this.tableJobsRef.onLoadInsights()
  }

  onMouseLeaveJobs = () => {
    this.tableJobsRef.setDeActive();
  }

  onMouseEnterAttributes = () => {
    this.mouseEventTimeOut = setTimeout(() => {
      this.tableAttributesRef.onLoadInsights();
    }, 500);
  }

  onMouseLeaveAttributes = () => {
    this.tableAttributesRef.setDeActive();
    clearTimeout(this.mouseEventTimeOut);
  }

  render() {
    const { 
      name = '', 
      company_name, 
      locations = [], 
      description_text= '', 
      short_definition = '',
      long_definition = '',
      number_of_employees = '', 
      values = [],
      selected = false,
      website_url = '',
      revenue_range = '',
      keywords = '',
      attributes = '',
      contextualConcepts = '',
      company_type = '',
      jobs = [],
      score,
     } = this.props;
    let { categories = [] } = this.props;
    const revenue = values && values.find(({name}) => name == "Revenue") || '';
    categories = categories.filter(c => c !== '') 
    return (
      <div className={`company-table-item ${selected ? 'selected' :'' }` }>
        <CompanyCheckBox {...{ name, company_name: company_name || name, selected, location: locations.join(','), website_url, score }} onSelectChange={this.onSelected}  />
        <div className="company-table-item__description a-tooltip">
          <div className="company-table-item__description__text ">
            { short_definition || description_text || long_definition }
          </div>
          <span className="a-tooltip__content">
            <h4 className="a-tooltip__content__title">
              Company Description
            </h4>
            <div className="a-tooltip__content__text">
              { short_definition || description_text || long_definition }
            </div>
          </span>
        </div>
        { categories.length > 0 ?
          <div className="company-table-item__info a-tooltip inline-flex">
            <i className="material-icons blue">category</i>
            <div className="company-table-item__info__text">
              { categories.join(',') || ''}          
            </div>
            <span className="a-tooltip__content">
              <h4 className="a-tooltip__content__title">
                <i className="material-icons blue">category</i> Verticals
              </h4>
              <div className="a-tooltip__content__text">
                { categories.join(',') }
              </div>
            </span>
          </div>: <div className="company-table-item__info" />
        }
        { keywords.length > 0 ?
          <div className="company-table-item__info a-tooltip inline-flex" onMouseLeave={this.onMouseLeaveJobs}>
            <i className="material-icons blue">category</i>
            <div className="company-table-item__info__text" onMouseEnter={this.onMouseEnterJobs}>
              { keywords.join(', ') || ''}          
              <CompanyTableJobs jobs={jobs} name={name} ref={(ref) => { this.tableJobsRef = ref; }} />
            </div>
          </div>: <div className="company-table-item__info" />
        }
        { attributes.length > 0 ?
          <div className="company-table-item__info a-tooltip inline-flex" onMouseLeave={this.onMouseLeaveAttributes}>
          <i className="material-icons blue">category</i>
          <div className="company-table-item__info__text" onMouseEnter={this.onMouseEnterAttributes}>
            { attributes.join(', ') || ''}          
            <CompanyTableDescriptions jobs={jobs} name={name} ref={(ref) => { this.tableAttributesRef = ref; }} />
          </div>
        </div>: <div className="company-table-item__info" />
        }
        {/* { contextualConcepts.length > 0 ?
          <div className="company-table-item__info a-tooltip inline-flex">
            <i className="material-icons blue">category</i>
            <div className="company-table-item__info__text">
              { contextualConcepts.join(', ') || ''}          
            </div>
            <span className="a-tooltip__content">
              <h4 className="a-tooltip__content__title">
                <i className="material-icons blue">category</i> Contextual Concepts
              </h4>
              <div className="a-tooltip__content__text">
                { contextualConcepts.join(', ') }
              </div>
            </span>
          </div>: <div className="company-table-item__info" />
        } */}
        { company_type.length > 0 ?
          <div className="company-table-item__info a-tooltip inline-flex">
            <i className="material-icons blue">category</i>
            <div className="company-table-item__info__text">
              { company_type}          
            </div>
            <span className="a-tooltip__content">
              <h4 className="a-tooltip__content__title">
                <i className="material-icons blue">category</i> Company Type
              </h4>
              <div className="a-tooltip__content__text">
                { company_type }
              </div>
            </span>
          </div>: <div className="company-table-item__info" />
        }
        { number_of_employees !== '' ?
          <div className="company-table-item__info">
            <i className="material-icons orange">people</i> {number_of_employees}
          </div>: <div className="company-table-item__info" /> 
        }
        {
          <div className="company-table-item__info">
            {score > 0 && <div style={{ width: 25, height: 25 }}>
              <CircularProgressbar value={score} text={score} styles={buildStyles({
                textSize: '50px', textColor: '#1B57D1', pathColor: '#1B57D1',
              })} />
            </div>}
          </div>
        }
      </div>
    )
  }
}

export default CompanyTableItem;

