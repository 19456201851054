
export const SET_TOOLTIP_DATA = 'SET_TOOLTIP_DATA';
export const setTooltipData = tooltipData => ({
  type: SET_TOOLTIP_DATA,
  tooltipData: tooltipData
});

export const SET_TOOLTIP_SCROLL_POSITION = 'SET_TOOLTIP_SCROLL_POSITION';
export const setTooltipScrollPosition = scrollPosition => ({
  type: SET_TOOLTIP_SCROLL_POSITION,
  parentScroll: scrollPosition
});

export const SET_TOOLTIP_HAS_FOCUS = 'SET_TOOLTIP_HAS_FOCUS';
export const setTooltipHasFocus = tooltipHasFocus => ({
  type: SET_TOOLTIP_HAS_FOCUS,
  tooltipHasFocus: tooltipHasFocus 
});
