export class SelectorDataHelper {

  parseOptionsResponse = (response) => {
    return response.json().then((json) => {
      const { search_term, optional_names } = json;
      const maybeMean = json['did_you_maybe_mean'];

      if (maybeMean) {
        return this.getUniqObjects(maybeMean);
      }

      if (search_term) {
        return [{ value: search_term.toUpperCase(), label: search_term.toUpperCase() }]
      }

      return this.getUniqObjects(optional_names);

    });
  }

  getUniqObjects = (name_list) => {
    const uniqueResults = name_list.filter((e, i) => name_list.findIndex(a => a.search_term === e.search_term) === i);
    return uniqueResults.map(({ search_term }) => {
      return { value: search_term.toUpperCase(), label: search_term.toUpperCase() };
    });
  }
  
  parseSuggestionResult = (response) => {
    return response.json().then((json) => {
      const { results = [] } = json;
      const uniqueResults = results.filter((e, i) => results.findIndex(a => a.search_term === e.search_term) === i);
        
      let output;
      if (uniqueResults.length === 1) {
        output = uniqueResults[0].associated_attributes;
      } else {
        output = uniqueResults.map(o => o.search_term);
      }

      return output.map(search_term => ({ value: search_term.toUpperCase() , label: search_term.toUpperCase() }));
    });
  }
}

export default SelectorDataHelper;
