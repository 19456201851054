import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from 'assets/img/404_not_found.svg';
import './NotFound.scss';

export class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <div className="not-found__content">
          <h1 className="not-found__content__title">
            Page Not Found 
          </h1>
          <h1 className="not-found__content__sub-title">
            Looks Like You Got Lost
          </h1>
          <div className="not-found__content__button">
            <Link to="/">
              <i className="material-icons margin-r-5"> arrow_back </i>
              Go Back
            </Link>
          </div>
        </div>
        <div className="not-found__image">
          <img src={NotFoundImage} />
        </div>
      </div>
    )
  }
}

export default NotFound;
