import { HttpService } from "services/HttpService";

export class ReportCardService {

  deleteReport = (id) => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      addToken: true,
      companyId: id,
    };
    return new HttpService().sendRequest(`${host}/api/userCompany/delete`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  sendContactDataRequest = (id, pushNotification) => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      addToken: true,
      companyId: id,
    };
    return new Promise(resolve => {
      new HttpService().sendRequest(`${host}/api/contactDataRequest`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        const { data = {}, message } = response;
        const { contactDataRequested = false } = data;
        let notificationData = { 
          title: "Request Success", 
          text: "Contact info successfully requested and will be available shortly.",
          iconName: "send",
          iconColor: "green",
        };
        if (!contactDataRequested) {
          notificationData = { 
            title: "Request Error", 
            text: message,
            iconName: "error",
            iconColor: "red-light",
          };
        }
  
        pushNotification(notificationData);
        resolve(
          { isContactButtonLoading: false, contactDataRequested }
        );
      });
    });
  }

  exportData = ({ company, company_id }) => {
    return new Promise((resolve, reject) => {
      const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
      fetch(`${host}/api/exportReport/${company_id}`)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${company}_${company_id}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        resolve();
      })
      .catch(() => alert('There is an issue with the export, please try again!'));
    });
  }
}

export default ReportCardService;
