import React, { Component } from 'react';
import CardView from 'components/Views/CardView/CardView';
import listViewLoader from 'assets/img/placeholders/report_list_view.svg';

export class ReportListViewLoader extends Component {
  render() {
    return (
      <div>
        <CardView childComponent={
          <img className="responsive-image" src={listViewLoader} />
        } />
      </div>
    )
  }
}

export default ReportListViewLoader;
