import { 
  RECEIVE_DEMO_REPORTS, DEMO_REPORTS_REQUEST, RECEIVE_FREEMIUM_REPORTS, FREEMIUM_REPORTS_REQUEST,
  DEMO_COMPANY_LIST
  } from "store/actions/demo/report";

const initialState = {
  demoReports: [],
  freemiumReportsRequest: false,
  demoReportsRequest: false,
  freemiumReports: [],
  demoCompanyList: []
};

const reportReducers = (state = initialState, action) => {
  switch (action.type) {
    case DEMO_REPORTS_REQUEST:
      return Object.assign({}, state, {
        type: action.type,
        demoReportsRequest: action.demoReportsRequest,
      });
    case DEMO_COMPANY_LIST:
      return Object.assign({}, state, {
        type: action.type,
        demoCompanyList: action.demoCompanyList,
      });
    case RECEIVE_DEMO_REPORTS:
      return Object.assign({}, state, {
        type: action.type,
        demoReports: action.demoReports,
      });
    case FREEMIUM_REPORTS_REQUEST:
      return Object.assign({}, state, {
        type: action.type,
        freemiumReportsRequest: action.freemiumReportsRequest,
      });
    case RECEIVE_FREEMIUM_REPORTS:
      return Object.assign({}, state, {
        type: action.type,
        freemiumReports: action.freemiumReports,
      });
    default:
      return state
  }
}

export default reportReducers;
