import React from 'react';
import { AsyncCreatable } from 'react-select';
import SelectorDataHelper from './helpers/SelectorDataHelper';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
let controller = new AbortController();
let signal = controller.signal;

const customStyles = {
  placeholder: (provided) => {
    return {
      ...provided,
      paddingLeft: 0,
      fontWeight: 400,
    }
  },
  control: (provided, state) => {
    return {
      ...provided,
      borderWidth: 0,
      borderRadius: 20,
      opacity : state.isDisabled ? 0.5 : 1,
      backgroundColor: "#F5F5F5",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow: 0,
      ':hover,:focus': {
        borderColor: "#9753e1"
      }
    };
  }, 
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "#FFFFFF",
      borderRadius: 20,
      paddingLeft: 5,
      paddingRight: 5,
      borderColor: "#172b35",
      borderWidth: 1
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: "15px",
      fontWeight: "500",
    }
  }
}

const customTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral20: "#464646",
    }
  };
}

const getNewOptionData = (inputValue, optionLabel) => {
  return {
    value: inputValue.toUpperCase(),
    label: optionLabel.toUpperCase()
  }
}

const fetchAttributeJson = (inputValue) => {
  const body = JSON.stringify({ attributeName: inputValue });
  try {
    return fetch(`${host}/api/attributeSuggestion`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
      signal: signal
    });
  } catch (error) {
    return null;
  }
}

function abortFetching() {
  const { aborted } = signal;
  if (!aborted) {
    controller.abort()
    controller = new AbortController();
    signal = controller.signal;
  }
}

let requestTimeout = undefined;
const promiseOptions = inputValue =>
  new Promise(resolve => {
    abortFetching();
    clearTimeout(requestTimeout);
    requestTimeout = setTimeout(() => {
      if (inputValue.length > 1) {
        fetchAttributes(inputValue).then((data) => {
          resolve(data);
        });
      }
    }, 500);
});

const logCustomInput = (inputValue) => {
  const body = JSON.stringify(
    { 
      inputType: 'attribute',
      inputValue: inputValue 
    }
  );
  return fetch(`${host}/api/userInput`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
  .then((response) => response.json())
}

const fetchAttributes = (inputValue) => {
  return fetchAttributeJson(inputValue)
  .catch((err) => {
    console.log(err);
  })
  .then((response) => {
    if (response && response.ok) {
      return new SelectorDataHelper().parseSuggestionResult(response);
    }
  });
};

export default class AttributeSelector extends React.Component {

  constructor(props) {
    super(props)
    this.state = { inputValue: '', menuIsOpen: false };  
  }
  
  onInputChange = (query, { action }) => {
    if (action !== "set-value") {
      this.setState({ inputValue: query.toUpperCase(), menuIsOpen: (action !== 'menu-close') });
      return query.toUpperCase();
    }

    return this.state.inputValue;
  }

  handleChange = (newValue, { action }) => {
    this.props.onChange(newValue);
    this.runOnChanged(action, newValue);
    this.setState({ inputValue: '', onFocus: true, menuIsOpen: true  });
  };

  handleFocus = (inputValue) => {
    this.setState({ inputValue: '', onFocus: true, menuIsOpen: true });
  };

  handleBlur = (inputValue) => {
    this.setState({ inputValue: this.props.value, onFocus: false, menuIsOpen: false });
  };

  createOption = (inputValue) => {
    logCustomInput(inputValue).then((response) => {
      console.log(response);
    });
  }

  runOnChanged(type, values) {
    const options =  { 
      'select-option': () => {},
      'remove-value': () => {},
      'create-option': () => { 
        const valueLength = values.length;
        if(valueLength > 0) {
          this.createOption(values[valueLength - 1].value) 
        }
      }
    }[type] || (() => {})
    return options()
  }

  getNoOptionMessage = () => {
    const { inputValue } = this.state;

    if(!inputValue.isNullOrEmpty()) {
      this.setState({ inputValue: '', onFocus: false, menuIsOpen: false });
    }

    return "No Options"
  }
  
  render() {
    const { inputValue } = this.state;
    return (
      <AsyncCreatable
        isMulti
        inputValue={inputValue}
        placeholder="e.g. Digital Twin, Predictive Maintenance, or Blockchain"
        onChange={this.handleChange}
        styles={customStyles}
        theme={customTheme}
        loadOptions={promiseOptions}
        onInputChange={this.onInputChange} 
        getNewOptionData={getNewOptionData}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onSelectResetsInput={false}
        createOptionPosition="first"
        isDisabled={this.props.disabled}
        hideSelectedOptions={true}
        autoBlur={false}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        noOptionsMessage={() => this.getNoOptionMessage()}
        menuIsOpen={this.state.menuIsOpen}
        value={this.props.value}
      />
    );
  }
}
