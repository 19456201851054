import React, { Component } from 'react';
import { connect } from 'react-redux';
import EnkiDarkLogo from 'components/Image/EnkiDarkLogo';
import { SignUpForm } from 'components/SignUpForm/SignUpForm';
import { updateAuthModal } from 'store/actions/root/auth-modal';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import { receiveCurrentUserData } from 'store/actions/root/user';
import './AuthModal.scss';

export class AuthModal extends Component {

  constructor(props) {
    super(props)
    this.state = { modalClass: 'auth-modal' };
  }

  componentWillReceiveProps(props) {
    const { modalStatus } = props;

    if(modalStatus) {
      this.setState({ modalClass: 'auth-modal open' });
    } else {
      this.setState({ modalClass: 'auth-modal close' });
    }
  }

  onCloseButton = () => {
    this.props.updateAuthModal(false);
  }

  onSignupSubmit = (userData) => {
    this.props.receiveCurrentUserData(userData);
    this.props.updateAuthModal(false);
    this.props.updateMessagePopup({
      isOpen: true,
      title: 'Welcome to enki',
      text: 'Our AI solution provides forward looking intelligence about emerging markets and technologies to help you get ahead',
      icon: 'check', 
      iconColor: "accent"
    });
  }

  render() {
    return (
      <div className={this.state.modalClass}>
        <div className="auth-modal__close-button" onClick={this.onCloseButton}>
          <i className="material-icons"> clear </i>
        </div>
        <div className="auth-modal__content">
          <div className="auth-modal__content__head">
            <EnkiDarkLogo />
            <h5>
              Let's find you actionable insights about your target accounts
            </h5>
          </div>
          <div className="auth-modal__content__body">
            <SignUpForm onSubmit={this.onSignupSubmit} />
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { authModalReducers } = rootReducers;
  return {
    modalStatus: authModalReducers.modalStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAuthModal: (status) => dispatch(updateAuthModal(status)),
    receiveCurrentUserData: (data) => dispatch(receiveCurrentUserData(data)),
    updateMessagePopup: (data) => dispatch(updateMessagePopup(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
