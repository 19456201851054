export default {
    // "TECHNOLOGY": [
    //     "WEB APP",
    //     "MOBILE APP",
    //     "COMPUTER VISION",
    //     "INTERNET OF THINGS",
    //     "BLOCKCHAIN",
    //     "DATA MODELLING",
    //     "AUGMENTED REALITY",
    //     "VIRTUAL REALITY",
    //     "ARTIFICIAL INTELLIGENCE",
    //     "SAAS",
    //     "CLOUD COMPUTING",
    //     "DRONE"
    // ],
    // "IT": [
    //      "APPLICATION PERFORMANCE MONITORING",
    //      "BUSINESS PERFORMANCE",
    //      "IT OPERATION MONITORING",
    //      "IT ROOT CAUSE ANALYSIS",
    //      "SYSTEM MONITORING",
    //      "DATABASE MONITORING",
    //      "PREDICTIVE ANALYSIS",
    //      "NETWORK OUTAGES",
    //      "CLOUD MONITORING",
    //      "APPLICATION TECHNOLOGY MAPPING",
    //      "IT OBSERVABILITY"
    // ],
    // "IOT": [
    //     "IOT",
    //     "PREDICTIVE MAINTENANCE",
    //     "DIGITAL TWIN",
    //     "ANOMALY DETECTION",
    //     "EDGE ANALYTICS",
    //     "IOT NUMERICAL MODELLING",
    //     "IOT ASSET MANAGEMENT",
    //     "IOT FALSE POSITIVE",
    //     "IOT SYSTEM OPTIMIZATION",
    //     "IOT OPERATIONAL IMPROVEMENT"
    // ],
    // "FINANCE": [
    //     "FINANCIAL SERVICES", 
    //     "FINTECH",
    //     "DEBT COLLECTIONS",
    //     "FUNDING",
    //     "FRAUD DETECTION"
    // ],
    // "RETAIL": [
    //     "RETAIL", 
    //     "RETAIL TECHNOLOGY"
    // ]
    "IOT INSIGHT": [
        "IOT",
        "IIOT",
        "INTERNET OF THINGS",
        "PREVENTIVE MAINTENANCE",
        "PREDICTIVE MAINTENANCE",
        "DIGITAL TWIN",
        "EDGE COMPUTING",
        "EDGE ANALYTICS",
        "ASSET MANAGEMENT",
        "RELIABILITY",
        "OPERATING COST",
        "OPERATIONAL IMPROVEMENT",
        "MACHINE MONITORING",
        "CONNECTED OPERATIONS",
        "SENSORS",
        "ARTIFICIAL INTELLIGENCE",
        "AUTONOMOUS SYSTEMS",
        "INDUSTRIAL AUTOMATION",
        "SYSTEM OPTIMIZATION",
        "WEARABLES",
        "FAILURES",
        "BOTTLENECK",
        "DEVIATION",
        "EFFICIENCY",
        "UPTIME",
        "REDUCE DOWNTIME",
    ],
    "TECHNOLOGY INSIGHT": [
        "ARTIFICIAL INTELLIGENCE",
        "AI",
        "MACHINE LEARNING",
        "ML",
        "AUGMENTED REALITY",
        "AR",
        "VIRTUAL REALITY",
        "VR",
        "MIXED REALITY",
        "DEEP LEARNING",
        "CONVOLUTIONAL NEURAL NETWORKS",
        "RECURRENT NEURAL NETWORK",
        "NLP",
        "NATURAL LANGUAGE PROCESSING",
        "CRYPTOGRAPHY",
        "LSTM",
        "FCN",
        "GPU",
        "FGPA",
        "SOCS",
        "COMPUTER VISION",
        "OBJECT DETECTION",
        "TEXT TO SPEECH",
        "IOT",
        "FAILURES",
        "BOTTLENECK",
        "DEVIATION",
        "EFFICIENCY",
        "UPTIME",
        "REDUCE DOWNTIME",
        "OPERATIONAL IMPROVEMENT",
    ],
    "IT INSIGHT": [
        "INFORMATION TECHNOLOGY",
        "AUTONOMOUS",
        "AIOPS",
        "APM",
        "AUTONOMOUS IT OPERATIONS",
        "BUSINESS PERFORMANCE",
        "ANOMALY DETECTION",
        "INFRASTRUCTURE OPERATIONS",
        "INFRASTRUCTURE",
        "PREDICTIVE ANALYSIS",
        "PREVENTIVE ANALYSIS",
        "CLOUD",
        "EDGE CLOUD",
        "IT OPERATIONS MANAGEMENT",
        "IT SERVICE MONITORING",
        "APPLICATION PERFORMANCE MANAGEMENT",
        "DATA CENTER",
        "DEVOPS",
        "HYBRID",
        "DISTRIBUTED",
        "SRE",
        "DATA CENTER",
        "DOWNTIME",
        "BOTTLENECK",
        "OUTAGE",
    ],
    "MESSAGING PLATFORM INSIGHT": [
        "AI",
        "ML",
        "AUGMENTED REALITY",
        "VIRTUAL REALITY",
        "MIXED REALITY",
        "NLP",
        "DEEP LEARNING",
        "CONVOLUTIONAL NEURAL NETWORK",
        "RECURRENT NEURAL NETWORK",
        "CRYPTOGRAPHY",
        "TEXT TO SPEECH",
        "IOT",
        "WHATSAPP",
        "FAILURES",
        "BOTTLENECK",
        "DEVIATION",
        "EFFICIENCY",
        "UPTIME",
        "REDUCE DOWNTIME",
        "DIGITAL",
        "OPERATIONAL IMPROVEMENT",
    ],
    "PURPOSE DRIVEN MARKETING": [
        "REWARDS",
        "LOYALTY REWARDS",
        "CUSTOMER MARKETING",
        "MULTICHANNEL MARKETING",
        "ENGAGEMENT",
        "LOCAL PROGRAMS",
        "COMMUNITY PROGRAMS",
        "CHARITY",
        "DONATIONS",
        "SOCIAL RESPONSIBILITY",
        "SOCIAL IMPACT",
        "NONPROFIT",
        "SOCIAL GOOD",
        "SUPPORT CAUSE",
    ],
}