import React, { Component } from 'react';
import LoginFormComponent from 'components/LoginForm/LoginForm';
import SocialFooter from 'components/SocialFooter/SocialFooter';
import EnkiLogo from 'components/Image/EnkiLogo';
import CookieService from 'services/CookieService';
import { RoutePermissonService } from 'services/RoutePermissonService';
import LoginIllustration from 'components/Image/LoginIllustration'
import { UserDataProvider } from 'providers/user/UserDataProvider';
import { Link }from 'react-router-dom'
import './login-page.scss'


export class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.checkToken = this.checkToken.bind(this);
  }

  componentDidMount() {
    this.checkCookie();
  }

  checkCookie() {
    const token = new CookieService().getCookie('token');
    if (token != undefined && token.length > 0) {
      this.checkToken(token);
    }
  }

  checkToken(token) {
    this.setState({ loaderIsActive: true, showMessageBox: false })
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      'addToken': true
    };
    new UserDataProvider().getCurrentUsersData().then((result) => {
      if(result.hasOwnProperty('email') && result.userType !== 'demo') {
        this.onFormSubmit(result);
      }
    })
    .catch((err) => {
      console.log(err)
    });
  }

  onFormSubmit(result) {
    const { propsData } = this.props.location;
    if(!propsData) {
      this.props.history.push('/');
      return;
    }

    const { location } = propsData;
    const { userType } = result;
    const cookieService = new CookieService();
    cookieService.setCookie({ name: 'user_type', value: userType }, 10);
    const routeResult = new RoutePermissonService().checkRoutePermmissiom(location.pathname);
    if(!routeResult.result) {
      location.pathname = '/';
    }

    const Path = this.getPathForUser(location, userType);
    this.props.history.push(Path);
  }

  getPathForUser(location, userType) {
    const { pathname, search = '' } = location;
    return {
      'freemium': { pathname: pathname == '/'  && search.isNullOrEmpty() ? 'research':pathname, search: search },
      'premium': { pathname: pathname == '/' && search.isNullOrEmpty() ? 'dashboard/reports':pathname, search: search },
      'demo': { pathname: pathname == '/' && search.isNullOrEmpty() ? 'demo':pathname, search: search },
    }[userType];
  }

  render() {
    return (
      <div className="margin-t-0">
        <div className="login-page">
          <div className="login-page__content">
            <div className="login-page__content__logo">
              <Link to={`/`}>
                <EnkiLogo />
              </Link>
            </div>
            <div className="login-page__content__form">
              <LoginFormComponent onSubmit={this.onFormSubmit}></LoginFormComponent>
            </div>
            <SocialFooter />
          </div>
          <div className="login-page__slider">
            <div className="login-page__slider__head">
              <h2>
                Get a Deeper Understanding of Your Market With Enki
              </h2>
              <h5>
                Our AI solution provides forward looking intelligence about emerging markets and technologies to help you get ahead
              </h5>
              {/* <Link className="a-button a-button--primary a-button--is-small a-button--rounded" to="/demo">
                Try It Free
              </Link>
              <span className="login-page__slider__info margin-l-15">
                No login required
              </span> */}
            </div>
            <div className="login-page__slider__image">
              <LoginIllustration />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginPage;
