import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyTableDescriptionsItem from './CompanyTableDescriptionsItem';
import { HttpService } from 'services/HttpService';
import CompanyInsightsLoader from 'components/Loaders/CompanyInsightsLoader';
import StringUtils from 'utils/StringUtils';
import Image from 'components/Image/Image';
import desertImage from 'assets/img/desert.svg';
import './CompanyTableInsights.scss';

export class CompanyTableDescriptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      requestCompleted: false,
      isActive: false,
      articles: []
    };
  }

  onLoadInsights = () => {
    const { isLoading, articles = [] } = this.state;
    if (isLoading || articles.length > 0) {
      this.setState({ isActive: true });
      return;
    }

    this.setState({ isLoading: true, isActive: true });
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const { jobs, attributes } = this.props;
    const body = { data:jobs, attributes };
    new HttpService().sendRequest(`${host}/api/get/attribute_indexes`, { 
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response) => {
      let { data : articles } = response;
      if (articles.length > 0) {
        this.setState({ articles, isLoading: false, requestCompleted: true });
      }
    });
  } 

  setDeActive = () => {
    this.setState({ isActive: false });
  }

  getInsightsLoader = () => {
    return (
      <div className="company-table-insights__list">
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
      </div>
    )
  }

  getCompanyInsights = () => {
    const { jobs } = this.props;
    const { articles = [] } = this.state;

    return (
      <div className="company-table-insights__list">
        { 
          articles.map((article, i) => {
            if(article.attributeIndexes.length > 0)
              return <CompanyTableDescriptionsItem article={article} key={i} />
          })
        }
      </div>
    );
  }

  render() {
    const { name } = this.props;
    const { isLoading, isActive } = this.state;
    return (
      <div className={`company-table-insights ${isActive ? 'active' : ''}`}>
        <div className="company-table-insights__title">
          <div className="company-table-insights__title__image">
            <Image src={new StringUtils().getCompanyLogo(name)} />
          </div> 
          {name} Attribute Insights
        </div>
        <div className="company-table-insights__indicator"></div>
        { isLoading ? this.getInsightsLoader(): this.getCompanyInsights() }
      </div>
    )
  }
}

const mapStateToProps = function (store) {
  const { userReducers } = store;
  const { companiesReducers } = userReducers;
  return {
    attributes: companiesReducers.attributes
  };
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(CompanyTableDescriptions);