import React, { Component } from 'react';
import './CompanyNameContent.scss';


export class CompanyNameContent extends Component {
  render() {
    const { companyData = {} } = this.props;
    const { company_description = '', company_name = '', company_logo = '' } = companyData;
    return (
      <div className="company-name-content">
        <div className="company-name-content__logo">
          <img src={company_logo} />
        </div>
        <div className="company-name-content__title">
          <div className="company-name-content__title__name">
            { company_name }
          </div>
          <div className="company-name-content__title__subtext">
            { company_description }
          </div>
        </div>
      </div>
    )
  }
}

export default CompanyNameContent;
