import React, { Component } from 'react'
import { ReportListViewLoader } from 'components/Loaders/ReportListViewLoader';


const getContents = (size) => {
  const array = [];

  for (let index = 0; index < size; index++) {
    array[index] = index
  }

  return array.map((i) => {
    return (
      <div className="col-md-12 margin-t-0 margin-b-20" key={i}>
        <ReportListViewLoader />
      </div>
    )
  });
}

export class ReportListPlaceHolder extends Component {
  render() {
    const { size = 9 } = this.props;

    return (
      <div className="row margin-t-0">
        {
          getContents(size)
        }
      </div>
    )
  }
}

export default ReportListPlaceHolder;
