import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton } from 'components/form/IconButton/IconButton';
import { updateModalData } from 'store/actions/root/modal';
import { ClipboardService } from 'services/ClipboardService';
import { pushToastMessage } from 'store/actions/root/toast';
import monthNames from 'commons/DateValues';
import './InsightGroupItem.scss';
import InsightCardAttributes from './InsightCardAttributes';

export class InsightGroupItem extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      hasLiked: ''
    };
  }

  updateLikedState = (type) => {
    this.setState({ hasLiked: type });
  }

  getTemplate = () => {
    const { currentUserData, article, } = this.props;
    const { emailTemplates = [] } = article;
    const { firstName , lastName, companyName, userType } = currentUserData;
    let template = emailTemplates[Math.floor((Math.random() * emailTemplates.length))];
    if (userType !== 'demo') {
      template = template
        .replace('{{your name}}', `${firstName} ${lastName}`)
        .replace('{{company}}', companyName)
        .replace('{{your company}}', companyName)
        .replace('{{date}}', this.getDate());
    }

    return template;
  }

  getDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);

    return `${monthNames[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
  }

  openMailTemplates = () => {
    const template = this.getTemplate();
    this.props.updateModalData({
      isActive: true,
      title: 'Mail Template',
      text: (
        <div className="margin-t-20">
          <p dangerouslySetInnerHTML={{__html: template.replace(/\n/g, '<br/>')}} />
        </div>
      ),
      buttons: [
        {
          text: 'Copy Text',
          icon: 'content_copy',
          color: 'blue-2',
          onClick: () => { this.copyText(template); },
        },
        {
          text: 'Close',
          icon: 'cancel',
          color: 'blue-r',
          onClick: () => {},
        },
      ],
    });
  }

  getTagList = (props) => {
    const {
      matchedAttributes = [],
      contextualConcepts = [],
      companies = [],
      locations = [],
    } = props;

    const tagList = new Map();
    matchedAttributes.forEach((attribute) => {
      tagList.set(attribute, { value: attribute, type: 'key', color: 'blue' });
    });
    companies.forEach((company) => {
      tagList.set(company, { value: company, type: 'apartment', color: 'orange' });
    });
    locations.forEach((location) => {
      tagList.set(location, { value: location, type: 'place', color: 'green' });
    });
    contextualConcepts.forEach((concept) => {
      tagList.set(concept, { value: concept, type: 'category', color: 'blue-2' });
    });

    return Array.from(tagList.values());
  };

  getTags = () => {
    const { article } = this.props;
    const tagList = this.getTagList(article);
    return <InsightCardAttributes tagList={tagList} />;
  }

  copyText = (text) => {
    new ClipboardService(document).copyText(text);
    const toastData = {
      title: 'Text is copied to clipboard',
      iconName: 'content_copy',
      iconColor: 'green-light',
      duration: 3000,
    };
    this.props.pushToastMessage(toastData);
  }

  render() {
    const { article } = this.props;
    const {
      publishedDate = '',
      title = '',
      subtitle = '',
      content,
      onLikeButtonClick,
      onUnLikeButtonClick,
      url,
      isSubContent = false,
      emailTemplates = [],
      insightType
    } = article;

    const { hasLiked = '' } = this.state;

    return (
      <div className="insight-content">
        { !isSubContent ? 
          <div className="insight-content__title">
            {subtitle} 
          </div>: ''
        }
        { !subtitle.isNullOrEmpty() && !isSubContent ? 
          <div className="insight-content__date">
            {publishedDate}
          </div>: ''
        }
        { this.getTags() }
        <div className="insight-content__content">
          <div className="insight-content__content__text">
            { content }
          </div>
        </div>
        <div className="insight-content__foot">
          <div className="insight-content__foot__right">
            { emailTemplates.length > 0 && <IconButton color="blue-grey" iconName="mail" text="Write Email" title="Generate a personalized email" isLoading={false} handleClick={this.openMailTemplates} />}
            <IconButton color="blue-grey" iconName="visibility" title="Open source in a new tab" text="Read More" isLoading={false} handleClick={this.onReadMode} url={url} />
            { !insightType && <IconButton holded={hasLiked === 'unlike'} classes="cursor--pointer" title="Train Enki to ignore similar insights" color="red-light" iconName="remove" text="Irrelevant" isLoading={false} handleClick={() => { this.updateLikedState('unlike'); onLikeButtonClick(article, 'unlike') } } /> }
            { !insightType && <IconButton holded={hasLiked === 'like'} classes="cursor--pointer margin-l-10" title="Train Enki to find more insights like this one" color="blue-2" iconName="add" text="Relevant" isLoading={false} handleClick={() => { this.updateLikedState('like'); onUnLikeButtonClick(article, 'like') } } /> }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateModalData: (data) => dispatch(updateModalData(data)),
  pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
});

const mapStateToProps = (store) => {
  const { rootReducers, dashBoardReducers } = store;
  const { userDataReducers } = rootReducers;
  const { reportReducers } = dashBoardReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
    reportCompanyName: reportReducers.companyName,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightGroupItem);
