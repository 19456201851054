import React, { Component } from 'react';
import AttributeViews from 'components/Views/AttributeViews/AttributeViews';
import { CardView } from 'components/Views/CardView/CardView';
import { Image } from 'components/Image/Image';
import linkedinLogo from 'assets/img/icons/linkedin.svg';
import StringUtils from 'utils/StringUtils';
import './AttributePeople.scss';

export class AttributePeople extends Component {
  
  render() {
    const { people = [] } = this.props;

    return (
      <div className='row'>
      { 
        people.map((person, i) => {
          const { current_experience = {}, title, location = '', matchedAttributes = [], corporatePhone, email , user_company_name = '', emailStatus } = person;
          const { user_experience_title = '', user_experience_company = '' } = current_experience || {};

          return <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 padding-l-10 padding-r-10 margin-t-0 margin-b-20' key={i}>
              <CardView classes="cursor--default" childComponent={
                <div className='attribute-people-view' target="_blank" href={person.linkedin}>
                  <div className='attribute-people-view__head'>
                    <div className="attribute-people-view__head__image">
                      <Image src={person.profile_image} />
                    </div>
                    <div className='attribute-people-view__head__title'>
                      <h3 title={person.name}>{person.name}</h3>
                      <div className='attribute-people-view__head__sub-title'>
                        <Image src={new StringUtils().getCompanyLogo(user_experience_company || user_company_name)} alt={ user_experience_company || user_company_name } /> 
                        <span title={`${ user_experience_company || user_company_name },  ${user_experience_title || title}`}>
                          { user_experience_company || user_company_name },  {user_experience_title || title}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="attribute-people-view__body">
                    { 
                      location && location.trim().length > 0 && 
                      <span className="attribute-people-view__body__attribute margin-b-5">
                        <i className="material-icons color-red-light">place</i>
                        {location}
                      </span>
                    }
                    { 
                      corporatePhone && 
                      <span className="attribute-people-view__body__attribute margin-b-5">
                        <i className="material-icons color-blue-2" >phone</i>
                        <a href={`tel:${corporatePhone}`}>{corporatePhone}</a> 
                      </span>
                    }
                    { 
                      email && 
                      <span className="attribute-people-view__body__attribute mail margin-b-5">
                        { emailStatus=="verified" ? <i className="material-icons color-green" title="Verified">verified</i> : <i className="material-icons color-purple">mail</i> }
                        <a href={`mailto:${email}`} >{email}</a>
                      </span>
                    }
                    <AttributeViews list={ matchedAttributes.map(a => ({ text: a, color: "gray" })) } max={5} />
                  </div> 
                  <div className="attribute-people-view__footer">
                    <a className="attribute-people-view__footer__link" target="_blank" href={person.linkedin}>
                      <img src={linkedinLogo} alt="Linkedin Page" />
                      Visit Profile
                    </a>
                  </div>
                </div>
              } />
            </div>
          }
        )
        }
      </div>
    );
  }
}

export default AttributePeople;
