import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilterView from 'components/Views/FilterView/FilterView';
import FilterViewLoader from 'components/Loaders/FilterViewLoader';
import ReportsHeaderTabView from 'components/User/Reports//ReportsHeaderTabView/ReportsHeaderTabView';
import './ReportsViewHeader.scss';

export class ReportsViewHeader extends Component {
  render() {
    const { userCombinedDataRequest } = this.props;
    return (
      <div className="reports-view-header">
        <div className="reports-view-header__title">
          <ReportsHeaderTabView />
        </div>
        <div className="reports-view-header__middle"></div>
        <div className="reports-view-header__filters">
          { !userCombinedDataRequest? 
            <FilterView />:
            <FilterViewLoader />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  const { dashBoardReducers } = store;
  const { userNLPReducers } = dashBoardReducers;
  return {
    userCombinedDataRequest: userNLPReducers.userCombinedDataRequest,
  };
};

export default connect(mapStateToProps)(ReportsViewHeader);
