import React, { Component } from 'react';
import companyTableItemLoader from 'assets/img/placeholders/company_table_item.svg';

export class CompanyTableItemLoader extends Component {
  render() {
    return (
      <div className="margin-t-0">
        <img style={{ 'width': '100%' }} src={companyTableItemLoader} />
      </div>
    )
  }
}

export default CompanyTableItemLoader;
