import React, { Component } from 'react'
import enkiLogo from 'assets/img/icons/enki-logo-light.png';

export class EnkiLogo extends Component {
  render() {
    return (
      <div>
        <img src={enkiLogo} alt="Enki AI logo"/>
      </div>
    )
  }
}

export default EnkiLogo
