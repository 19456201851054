import React, { Component } from 'react'
import './FloatingInput.scss'

export class FloatingInputComponent extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onValueChange(e.target.value);
  }

  render() {
    const { title, type, iconName, value } = this.props
    return (
      <div className="a-floating-input__box">
        <input 
          className="a-floating-input" 
          value={value}
          type={type} required
          onChange={this.handleChange}/>
        <div className="a-floating-input__label">
          {title}
        </div>
        <div className="a-floating-input__highlighter"></div>
        <i className="material-icons"> {iconName} </i>
      </div>
    )
  }
}

export default FloatingInputComponent
