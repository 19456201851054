import { 
  RECEIVE_USER_NLP_DATA, 
  USER_NLP_DATA_REQUEST,
  RECEIVE_USER_COMBINED_DATA,
  USER_COMBINED_DATA_REQUEST,
  DELETE_COMBINED_DATA_ITEM,
  RESET_COMBINED_DATA,
  CLEAR_COMBINED_DATAS,
  DELETE_SINGLE_COMPANY_DATA_ITEM
 } from 'store/actions/dashboard/UserNlp';

const initialState = {
  userNLPData: [],
  combinedData:[],
  pageable: {},
  userNLPDataRequest: false,
  combinedCompanyRequest: false,
};

const deleteCombinedDataItem = (combinedData, item) => {
  const index = combinedData.findIndex(({ _id }) => _id === item);
  if (index > -1) {
    combinedData.splice(index, 1);
  }

  return combinedData;
};

const userNLPReducers = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_USER_NLP_DATA:
      const { userNLPData } = action;
      return Object.assign({}, state, {
        type: action.type,
        pageable: userNLPData.pageable,
        userNLPData: [...state.userNLPData, ...userNLPData.data],
      });
    case RECEIVE_USER_COMBINED_DATA:
      const { combinedData } = action;
      return Object.assign({}, state, {
        type: action.type,
        pageable: combinedData.pageable,
        combinedData: [...state.combinedData, ...combinedData.data],
      });
    case USER_COMBINED_DATA_REQUEST:
      return Object.assign({}, state, {
        type: action.type,
        combinedCompanyRequest: action.userCombinedDataRequest,
      });
    case USER_NLP_DATA_REQUEST:
      return Object.assign({}, state, {
        type: action.type,
        userNLPDataRequest: action.userNLPDataRequest,
      });
    case CLEAR_COMBINED_DATAS:
      state.combinedData = action.combinedData;
      state.userNLPData = action.userNLPData;
      return state;
    case RESET_COMBINED_DATA:
      state.combinedData = action.combinedData
      return state;
    case DELETE_COMBINED_DATA_ITEM:
      return { ...state, combinedData: deleteCombinedDataItem(state.combinedData, action.item) };
    case DELETE_SINGLE_COMPANY_DATA_ITEM:
      return { ...state, userNLPData: deleteCombinedDataItem(state.userNLPData, action.item) };
    default:
      return state;
  }
}

export default userNLPReducers;
