import React from 'react';
import CircleButton from 'components/form/CircleButton';
import Papa from 'papaparse';
import FileService from 'services/FileService';

const relevantFields = {
  executiveQuotes: "Executive Insight",
  externalQuotes: "Market Thought Leaders",
  quotes: "Key Decision Makers",
  partnership: "Partnerships", 
  painPoints: "Challenges and Solutions",
  painPointV2: "Challenges and Solutions",  
  investment: "Investments and Acquisitions", 
  initiative: "Strategic Initiatives", 
  win: "Recent Wins", 
  growth: "Growth", 
  promotion: "Executive Moves", 
  opportunity: "Opportunities", 
  hiring: "Hiring", 
  competitors: "Competitors"
};

const isQuotes = (type) => (
  type === 'quotes' || type === 'quotesV2' || type === 'executiveQuotes' || type === 'externalQuotes'
);

const pick = ({
  company, championKey, subtitle, title, url,
  published_date, matchedAttributes,
  emailTemplates = [], contextualConcepts,
  companies, relevance, locations,
  titleCompanyMatch, titleAttributeMatch, insightType,
  group,
}) => (
  {
    Company: company,
    Section: relevantFields[insightType] || insightType,
    Person: isQuotes(insightType) ? championKey : '',
    Headline: subtitle || group,
    Paragraph: title,
    Url: url,
    'Published Date': published_date,
    Attributes: matchedAttributes,
    'Email Template': emailTemplates[0] || '',
    'Contextual Concepts': contextualConcepts,
    Companies: companies,
    'Relevance Score': relevance,
    Locations: locations,
    'Company Title Relevance Match': titleCompanyMatch,
    'Attribute Title Relevance Match': titleAttributeMatch,
  }
);

function getCsvData(metadata, company) {
  let relevanceMap = [];
  const { relevance, area } = metadata;
  Object.keys(relevance).forEach((key) => {
    relevanceMap = [...relevanceMap, ...relevance[key].map((item) => ({ ...item, company, area }))];
  });

  const csv = Papa.unparse(relevanceMap.map(pick));

  if (csv) {
    return `\ufeff${csv}`;
  }

  return '';
}

export default class ExportFavoritesButton extends React.Component {
  handleButtonClick() {
    const { metadata, company } = this.props;
    const csvContent = getCsvData(metadata, company);
    new FileService().downloadFileContent(document, csvContent, 'favorites.csv');
  }

  render() {
    const { metadata } = this.props;
    if (!metadata) {
      return null;
    }

    return (
      <div className="margin-l-10">
        <CircleButton color="blue-2" icon="attachment" title="Export Favorites" handleClick={() => { this.handleButtonClick(); }} />
      </div>
    );
  }
}
