import { UPDATE_MESSAGE_POPUP } from 'store/actions/root/message-popup';

const initialState = {
  messagePopup: {
    isOpen: false
  }
};

const messagePopupReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MESSAGE_POPUP:
      return Object.assign({}, state, {
        type: action.type,
        messagePopup: action.messagePopup,
      });
    default:
      return state
  }
}

export default messagePopupReducers;
