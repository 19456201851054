import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardView } from 'components/Views/CardView/CardView';
import { CompanyInformationCard } from './CompanyInformationCard';
import { HttpService } from 'services/HttpService';
import CompanyInformationLoader from 'components/Loaders/CompanyInformationLoader';
import CompanyInformationValuesLoader from 'components/Loaders/CompanyInformationValuesLoader';
import { CompanyValuesCard } from './CompanyValues/CompanyValuesCard';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import './CompanyInformationView.scss';

export class CompanyInformationView extends Component {

  constructor(props) {
    super(props);
    this.state = { companyData: {}, isPendingRequest: false, contentNotFound: true }; 
  }

  componentWillReceiveProps(props) {
    const { name } = props;
    if(name && !window.tooltipDatas[name]) {
      this.loadCompanyData(name);
    } else {
      this.setState({ companyData: window.tooltipDatas[name] });
    }
  }

  componentWillMount() {
    const { name } = this.props;
    if(name && !window.tooltipDatas[name]) {
      this.loadCompanyData(name);
    } else {
      this.setState({ companyData: window.tooltipDatas[name] });
    }
  }

  loadCompanyData = (name) => {
    const body = {
    	"company_name": name.toLowerCase()
    };
    
    const { isPendingRequest } = this.state;
    if (!isPendingRequest) {
      this.setState({ isPendingRequest: true });
      const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
      new HttpService().sendRequest(`${host}/api/get/company_informations`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => {
        const { data, result } = response;
        if(result === 200 && this.checkPropsName(data.name)) {
          window.tooltipDatas[name] = data;
          this.setState({ companyData: data, isPendingRequest: false });
        } else {
          window.tooltipDatas[name] = { not_found: true };
          this.setState({ companyData: { not_found: true }, isPendingRequest: false, contentNotFound: true });
        }
      });
    }
  }

  checkPropsName = (name) => {
    return this.textClearAndLowerCase(name) == this.textClearAndLowerCase(this.props.name);
  }

  textClearAndLowerCase = (text = '') => {
    return text.replace(/[^a-zA-Z ]/g, "").toLowerCase();
  }

  onTooltipReport = (e) => {
    const { name } = this.props;
    this.resetCompanyInformations(name);
    setTimeout(() => {
      this.props.updateMessagePopup({
        isOpen: true,
        title: 'Thank you',
        text: 'Your feedback is very important to us. We will check this data as soon as possible.',
        icon: 'check', 
        iconColor: "accent"
      });
    }, 250);
  }

  resetCompanyInformations = (name) => {
    const body = {
      "use_cache": false,
      "search_text":  name.toLowerCase()
    };
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    new HttpService().sendRequest(`${host}/api/reset/company_informations`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      window.tooltipDatas[name] = null
    });
  }

  getValuesContent = () => {
    const { companyData } = this.state;
    const { company_name, values = [] } = companyData;
    if (company_name && (!values || values.length === 0)) {
      return ('')
    }

    return(
      <div className="col-md-12 col-lg-12 col-xl-3 margin-t-0 margin-b-20">
        {
          !company_name ?
          <CompanyInformationValuesLoader />:
          <CardView classes="cursor--default" childComponent={
            <CompanyValuesCard companyData={companyData} />
          } />
        }

      </div>
    )
  }

  render() {
    const { companyData } = this.state;
    const { not_found = false, company_name, values = [] } = companyData;
    
    if (not_found || company_name === "") {
      return (null)
    }

    return (
      <div className="company-information-view">
        <div className="company-information-view__title">
          Company Information
        </div>
        <div className="row">
          <div className={`col-md-12 col-lg-12 ${ (company_name && (!values || values.length === 0)) ? '': 'col-xl-9'} margin-t-0 margin-b-20`}>
            { 
              !company_name ?
              <CompanyInformationLoader />:
              <CardView classes="cursor--default" childComponent={
                <CompanyInformationCard companyData={companyData} onReportHandle={this.onTooltipReport} />
              } />
            }
          </div>
          { this.getValuesContent() }
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateMessagePopup: (data) => dispatch(updateMessagePopup(data))
  }
};

export default connect(null, mapDispatchToProps)(CompanyInformationView);
