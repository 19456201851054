import React, { Component } from 'react'
import { CircleLoader } from 'components/CircleLoader/CircleLoader';
import { Link } from 'react-router-dom';

export class CircleButton extends Component {
  onClick = (e) => {
    e.preventDefault();
    this.props.handleClick(this.props.value);
  }

  render() {
    const { classes = '', color, icon, value, isLoading = false, url, target="_blank", title } = this.props;

    if(isLoading) {
      return (
        <CircleLoader isActive={true} size="small" />
      )
    }

    if (url) {
      return (
        <Link className={`a-circle-button ${classes}`} color={color} to={url} value={value}>
          <i className="material-icons"> { icon } </i>
        </Link>
      )
    }

    return (
      <div className={`a-circle-button ${classes}`} color={color} value={value} onClick={this.onClick} data-tooltip={title}>
        <i className="material-icons"> { icon } </i>
      </div>
    )
  }
}

export default CircleButton
