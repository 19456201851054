import React, { Component } from 'react';
import TokenService from 'services/TokenService';
import CookieService from 'services/CookieService';

export class LogOutPage extends Component {

  componentWillMount() {
    const tokenService = new TokenService();
    const cookieService = new CookieService();
    tokenService.removeToken();
    tokenService.removeTokenByName('demo_token');
    cookieService.deleteCookie('user_type');
    localStorage.removeItem('userData');
    this.props.history.push('/');
  }

  render() {
    return null
  }
}

export default LogOutPage;
