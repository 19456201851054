import React, { Component } from 'react'
import { connect } from 'react-redux';
import PageHead from 'components/Demo/PageHead/PageHead';
import CookieService from 'services/CookieService';
import { ReportCardView } from 'components/User/Reports/ReportCardView/ReportCardView';
import { ItemHighLighter } from 'helpers/ItemHighLighter';
import { fetchCurrentUserData } from 'store/actions/root/user';
import { fetchDemoReports, fetchFreemiumReports } from 'store/actions/demo/report';
import NavBar from 'components/Navigations/NavBar/NavBar';
import FreemiumPageHead from 'components/Demo/FreemiumPageHead/FreemiumPageHead';
import FreemiumBanner from 'components/Demo/FreemiumBanner/FreemiumBanner';
import { ReportCardPlaceHolder } from 'components/User/Reports/ReportCardPlaceHolder/ReportCardPlaceHolder';
import NoResultContent from 'components/Demo/NoResultContent/NoResultContent';
import './TryDemo.scss';


const reportItems = (data, searchText) => {
  return data.map((item, id) => {
    const highlightedItem =  new ItemHighLighter().highlightReportItem(item, '');
    if(highlightedItem) {
      highlightedItem.paramsText = searchText
      return <div className="col-md-12 col-lg-6 col-xl-4 margin-t-0 margin-b-20" key={id}>
        <ReportCardView isDemo={true} {...highlightedItem} />
      </div>
    }

    return ''
  });
};

const getDemoContent = (reportCards, demoReportsRequest, paramsText) => {
  return (
    <div className="try-demo-layout__reports">
      <div className="try-demo-layout__reports__title">
        Sample Insights Reports
      </div>
    <div className="try-demo-layout__reports__content">
      { demoReportsRequest ? 
        <ReportCardPlaceHolder />: ''
      }
      { reportCards ?
        <div className="row">
          { reportItems(reportCards, paramsText) }
        </div>: ''
      }
      {
        !demoReportsRequest && reportCards.length < 1 ? 
        <NoResultContent />: ''
      }
    </div>
  </div>
  )
}

const getFreemiumContent = (freemiumReports, freemiumReportsRequest, paramsText) => {
  return (
    <div className={ !freemiumReportsRequest && freemiumReports.length < 1 ? "try-demo-layout__reports bg-light margin-t-20" : "try-demo-layout__reports" }>
      <div className="try-demo-layout__reports__content">
        { freemiumReportsRequest ? 
          <ReportCardPlaceHolder size={6} />: ''
        }
        { !freemiumReportsRequest ?
          <div className="row">
            { reportItems(freemiumReports, paramsText) }
          </div>: ''
        }
        {
          !freemiumReportsRequest && freemiumReports.length < 1 ? 
          <NoResultContent type={"freemium"} />: ''
        }
      </div>
    </div>
  )
}

export class TryDemo extends Component {

  constructor(props) {
    super(props);
    this.state = { paramsText: '', userType: '' };
  }

  componentDidMount() {
    this.props.fetchCurrentUserData();
  }

  componentWillReceiveProps(props) {
    const { currentUserData = {}} = props;
    const { userType = '' } = currentUserData;
    const urlParams = new URLSearchParams(this.props.location.search);
    const paramsText = `&area=${urlParams.get('area')}&vertical=${urlParams.get('vertical')}`;
    this.setState({ paramsText: paramsText });
    const cookieService = new CookieService();
    cookieService.setCookie({ name: 'user_type', value: userType }, 10);
    if(userType == this.state.userType && this.props.currentUserData) {
      return;
    }

    this.setState({ userType: userType });
    switch(userType) {
      case 'demo':
        this.props.fetchDemoReports({ 
          area: urlParams.get('area'),
          vertical: urlParams.get('vertical')
        });
        break;
      case 'freemium':
      case 'premium':
        this.props.fetchFreemiumReports({ 
          area: urlParams.get('area'),
          vertical: urlParams.get('vertical')
        });
        break;
    }
  }

  onFormHandler = (params) => {
    const { area, vertical } = params;
    const paramsText = `&area=${area}&vertical=${vertical}`;
    this.setState({ paramsText: paramsText });
    this.props.history.push(`${window.location.pathname}?${paramsText}`);
    this.props.fetchFreemiumReports(params);
  }

  render() {
    const { currentUserData = {}, reportCards, demoReportsRequest, freemiumReportCards, freemiumReportsRequest, mobileNavigationStatus } = this.props;
    const { userType = '' } = currentUserData;
    const { paramsText } = this.state;
    
    return (
      <div className={ `try-demo-layout sidenav ${mobileNavigationStatus ? 'open':''}`} data-theme={userType == 'demo' ? 'default':'dark'}>
          { userType == 'freemium' ?
            <FreemiumBanner />: '' 
          }
        <div className="try-demo-layout__head">
          <NavBar isDemo={userType == 'demo'} theme="transparent" dropdownTheme="light" withoutNavigation={userType == 'demo'} />
          { userType == 'demo' ?
            <PageHead />:         
            <FreemiumPageHead onFormHandler={this.onFormHandler} />
          } 
          <div className="try-demo-layout__head__bottom"></div>
        </div>
        { userType == 'demo' ?
          getDemoContent(reportCards, demoReportsRequest, paramsText):
          getFreemiumContent(freemiumReportCards, freemiumReportsRequest, paramsText)
        }
      </div>
    )
  }
}

const mapStateToProps = function (store) {
  const{ demoReducers, rootReducers, userReducers } = store; 
  const { reportReducers } = demoReducers;
  const { mobileNavigationReducers } = userReducers;
  const { userDataReducers, routeReducers } = rootReducers;

  return {
    mobileNavigationStatus: mobileNavigationReducers.status,
    currentUserData: userDataReducers.currentUserData,
    reportCards: reportReducers.demoReports,
    demoReportsRequest: reportReducers.demoReportsRequest,
    freemiumReportCards: reportReducers.freemiumReports,
    freemiumReportsRequest: reportReducers.freemiumReportsRequest,
    search: routeReducers.location && routeReducers.location.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFreemiumReports: (params) => dispatch(fetchFreemiumReports(params)),
    fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
    fetchDemoReports: (params) => dispatch(fetchDemoReports(params)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(TryDemo); 
