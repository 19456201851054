import React from 'react';
import ReactECharts from 'echarts-for-react';

export class LineChart extends React.Component {
  getOption(data) {
    let option = {
      tooltip: {
          trigger: 'axis'
      },
      xAxis: {
          data: data.map(function (item) {
              return new Date(item.key).toLocaleDateString();
          })
      },
      yAxis: {
          splitLine: {
              show: false
          }
      },
      visualMap: {
          top: 10,
          right: 10,
          pieces: [{
              gt: 0,
              lte: 10,
              color: '#096'
          }, {
              gt: 10,
              lte: 20,
              color: '#ffde33'
          }, {
              gt: 20,
              lte: 30,
              color: '#ff9933'
          }, {
              gt: 30,
              lte: 40,
              color: '#cc0033'
          }, {
              gt: 40,
              lte: 50,
              color: '#660099'
          }, {
              gt: 50,
              color: '#7e0023'
          }],
          outOfRange: {
              color: '#999'
          }
      },
      series: {
          type: 'line',
          data: data.map(function (item) {
              if (item.aggregations && item.aggregations[0].results[0]) {
                return item.aggregations[0].results[0]['matching_results'];
              }
              return item['matching_results'];
          }),
          markLine: {
              silent: true,
              data: [{
                  yAxis: 10
              }, {
                  yAxis: 20
              }, {
                  yAxis: 30
              }, {
                  yAxis: 40
              }, {
                  yAxis: 50
              }]
          }
      }
    };

    return option;
  }

  render() {
    return (
      <ReactECharts 
          option={this.getOption(this.props.data)}
          style={{height: '470px'}}
      />
    );
  }
}

export default LineChart;
