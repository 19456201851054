import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import './NavigationDrawerItem.scss';

export class NavigationDrawerItem extends Component {
  getPathName = () => {
    const history = createBrowserHistory();
    const { location } = history;
    const { pathname = '' } = location;

    return pathname;
  }

  render() {
    const { name, icon, path, redirectTo, subLinks = [], target = "_self" } = this.props;
    const pathname = this.getPathName();
    const className = (path == pathname) ? 'navigation-drawer-item__content active' : 'navigation-drawer-item__content';
    return (
      <div className="navigation-drawer-item__wrapper">
        <Link to={redirectTo || path} target={target}>
          <div className="navigation-drawer-item">
            <div className={className}>
              <div className="navigation-drawer-item__content__icon">
                <i className="material-icons">{ icon } </i>
              </div>
              <div className="navigation-drawer-item__content__text">
                { name }
              </div>
            </div>
          </div>
        </Link>
        {
          subLinks.length > 0 ?
          <div className="navigation-drawer-item__sub-links">
            { 
              subLinks.map((item, key) => {
                const { name, icon, path, target = "_self" } = item;
                const className = (path == pathname) ? 'navigation-drawer-item__content active' : 'navigation-drawer-item__content';
                return <Link to={path} key={key} target={target}>
                  <div className="navigation-drawer-item">
                    <div className={className}>
                      <div className="navigation-drawer-item__content__icon">
                        <i className="material-icons">{ icon } </i>
                      </div>
                      <div className="navigation-drawer-item__content__text">
                        { name }
                      </div>
                    </div>
                  </div>
                </Link>
              })
            }
          </div>: ''
        }
      </div>
    );
  }
}

export default NavigationDrawerItem;
