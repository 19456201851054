import { 
  SEARCH_TEXT_IN_REPORT, 
  SORT_VALUE_IN_REPORT,
  UPDATE_REPORT_COMPANY_NAME,
  ARTICLE_FILTER_CHANGED,
} from 'store/actions/dashboard/report';

const initialState = {
  searchText: '',
  sortValue: 'desc',
  companyName: null,
  articleFilter: { filteredMonth: 0 },
};
const reportReducers = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_TEXT_IN_REPORT:
      return Object.assign({}, state, {
        type: action.type,
        searchText: action.searchText,
      });
    case SORT_VALUE_IN_REPORT:
      return Object.assign({}, state, {
        type: action.type,
        sortValue: action.sortValue,
      });
    case UPDATE_REPORT_COMPANY_NAME:
      return Object.assign({}, state, {
        type: action.type,
        companyName: action.companyName,
      });
    case ARTICLE_FILTER_CHANGED:
      return Object.assign({}, state, {
        type: action.type,
        articleFilter: action.articleFilter,
      });
    default:
      return state
  }
}

export default reportReducers;
