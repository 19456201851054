class CookieService {

  setCookie(cookieObject, expireDays) {
    const d = new Date();
    d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cookieObject.name + "=" + cookieObject.value + ";" + expires + ";path=/";
  }

  setCookies(cookieArray, expireDays) {
    const d = new Date();
    d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    const expires = "expires="+ d.toUTCString();
    cookieArray.forEach(cookieObject => {
      document.cookie = cookieObject.name + "=" + cookieObject.value + ";" + expires + ";path=/";
    });
  }
  
  getCookie(cookieName) {
    const name = cookieName + "=";
    if (document.cookie.indexOf(name) < 0) {
      return "";
    }
    
    const cookies = document.cookie.split(';');
    const cookieIndex = this.getCookieIndex(cookieName, cookies);
    if (cookieIndex < 0 ) {
      return "";
    }

    const cookie = cookies[cookieIndex];
    return cookie.split('=')[1] || "";
  }

  getCookieIndex(cookieName, cookies) {
    return cookies.findIndex((cookie) => {
      const cookieId = cookie.split('=')[0] || '';
      return cookieId.trim() == cookieName
    });
  }

  deleteCookie(cookieName) {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
  }
  
}

export default CookieService;
