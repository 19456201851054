const defaultSections = [
  { name: 'Heatmap', type: 'heatmapData' },
  { name: 'Challenges and solutions', type: 'painPointV2' },
  { name: 'Favorites', type: 'relevance' },
  { name: 'Competitors', type: 'competitors' },
  { name: 'Key decision makers', type: 'quotes' },
  { name: 'Executive insights', type: 'executiveQuotes' },
  { name: 'Market thought leaders', type: 'externalQuotes' },
  { name: 'Top prospects', type: 'attributePeople' },
  { name: 'Partnerships', type: 'partnership' },
  { name: 'Investments and acquisitions', type: 'investment' },
  { name: 'Hiring', type: 'hiring' },
  { name: 'Strategic initiatives', type: 'initiative' },
  { name: 'Recent wins', type: 'win' },
  { name: 'Opportunities', type: 'opportunity' },
  { name: 'Growth', type: 'growth' },
  { name: 'Executive moves', type: 'promotion' },
  { name: 'Other', type: 'generic' },
];

export default defaultSections;
