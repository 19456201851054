import React, { Component } from 'react';
import './ToastView.scss';

export class ToastView extends Component {
  getButtons = (buttons) => {
    return <div className="toast-view__foot">
     { 
      buttons.map(({ text, color, icon, onClick }, key) => {
        return <div className="toast-view__foot__button" key={key} color={color} onClick={onClick}>
          <i className="material-icons"> { icon } </i>
          { text }
        </div>
      })
      }
    </div>
  }

  render() {
    const { iconName = 'get_app', title, text, buttons = [], iconColor = '' } = this.props;

    return (
      <div className="toast-view active">
        <div className="toast-view__head">
          <div className="toast-view__head__icon" color={iconColor} >
            <i className="material-icons"> { iconName } </i>
          </div>
          <div className="toast-view__head__title">
            { title }
          </div>
        </div>
        { text ? 
          <div className="toast-view__body">
            <div className="toast-view__body__text">
              { text }
            </div>
          </div>: ''
        }
        { buttons.length > 0 ?
          this.getButtons(buttons) : ''
        }
      </div>
    )
  }
}

export default ToastView;
