import { 
  SET_TOOLTIP_DATA, 
  SET_TOOLTIP_SCROLL_POSITION,
  SET_TOOLTIP_HAS_FOCUS 
} from 'store/actions/root/tooltip';

const initialState = {
  top: 0,
  left: 0,
  isActive: false,
  data: {},
  parentScroll: 0,
  tooltipHasFocus: false
}

const tooltipDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOOLTIP_DATA:
      return Object.assign({}, state, {
        type: action.type,
        tooltipData: action.tooltipData,
      });
    case SET_TOOLTIP_SCROLL_POSITION:
      return Object.assign({}, state, {
        type: action.type,
        parentScroll: action.parentScroll,
      });
    case SET_TOOLTIP_HAS_FOCUS:
      return Object.assign({}, state, {
        type: action.type,
        tooltipHasFocus: action.tooltipHasFocus,
      });
    default:
      return state
  }
}

export default tooltipDataReducers;
