import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from 'components/Navigations/NavBar/NavBar';
import { pushToastMessage } from 'store/actions/root/toast';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import { fetchCurrentUserData, receiveCurrentUserData } from 'store/actions/root/user';
import { HttpService } from 'services/HttpService';
import { getParams } from 'util.jsx';
import NotFound from 'routes/NotFound/NotFound';
import './Visualization.scss';


export class Visualization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportUrl: undefined,
            data: [],
            navBarMenuItems: [],
            userType: '',
        }
    }

    componentDidMount() {
        document.body.style.overflow = "hidden";

        const params = getParams(this.props.location);
        const report = params.has('report') && params.get('report');
        if (report) {
            this.loadReport(report);
        }
    }

    componentWillMount() {
        this.props.fetchCurrentUserData();
    }

    componentWillUnmount(){
        document.body.style.overflow = "visible";
    }

    loadReport = (id) => {
        const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
        let url = `${host}/api/visualization`;
        url += `/${id}`;

        new HttpService().sendRequest(url, {
            method: 'GET',
        }).then((response) => {
            const { url } = response;
            if (url) {
                this.setState({ reportUrl: url });
            }else{
                this.setState({ reportUrl: null });
            }
        });
    }

    render() {
        const { mobileNavigationStatus, currentUserData = {} } = this.props;
        const { reportUrl } = this.state;
        const { userType = 'demo' } = currentUserData;

        return (
            <div className={`visualization margin-t-0 sidenav ${mobileNavigationStatus ? 'open' : ''}`}>
                <NavBar theme="dark" logo={{ small: true, desktop: true }} isDemo={(userType === 'demo')} withoutNavigation={false} iconMenuItems={
                    []
                } />

                {typeof reportUrl === 'string' ?
                    <iframe title="b1" width="100%" height="100%" src={reportUrl} frameborder="0" allowFullScreen="true"></iframe>
                    : null}
                {reportUrl === null ? <NotFound /> : null}
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
        pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
        receiveCurrentUserData: (params) => dispatch(receiveCurrentUserData(params)),
        updateMessagePopup: (data) => dispatch(updateMessagePopup(data)),
    }
};

const mapStateToProps = function (store) {
    const { rootReducers, userReducers } = store;
    const { userDataReducers } = rootReducers;
    const { mobileNavigationReducers } = userReducers;
    return {
        currentUserData: userDataReducers.currentUserData,
        mobileNavigationStatus: mobileNavigationReducers.status,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Visualization);
