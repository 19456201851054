export class ParentItemHelper {
  findAncestor = (el, cls) => {
    while (el && !el.classList.contains(cls)) {
      el = el.parentElement;
    }

    return el;
  }
}

export default ParentItemHelper;
