import React, { Component } from 'react';
import { connect } from 'react-redux';
import InlineSelectBox from 'components/form/InlineSelectBox/InlineSelectBox';
import { ReportsService } from 'services/ReportsServices';
import { PositionList, CompanyList } from 'commons/SelectDatas';
import './NaturalLanguageForm.scss';

export class NaturalLanguageForm extends Component {

  constructor(props) {
    super(props);
    this.state = { vertical: '', area: '', positionList: PositionList, verticalList: CompanyList, verticalByAreas: {} };
  }

  componentWillReceiveProps(props) {
    const { location } = props;
    const { action } = location;
    if(action === 'POP') {
      const { search } = location.location;
      const urlParams = new URLSearchParams(search);
      if(urlParams && urlParams.hasOwnProperty('area')) {
        this.setState({
          area: urlParams.get('area'),
          vertical: urlParams.get('vertical')
        });
      } else {
        this.loadStateFromLocalStorage()
      }
    }
  }

  componentWillMount() {
    this.updateVerticalList().then((result) => {
      this.loadStateFromLocalStorage();
    });
  }

  loadStateFromLocalStorage() {
    const data = localStorage.getItem("demoReportParams");
    let area = '';
    let vertical = '';
    if(data && !data.isNullOrEmpty()) {
      const dataJson = JSON.parse(data);
      area = dataJson.area;
      vertical = dataJson.vertical;
    } else {
      const { positionList } = this.state;
      area = positionList[0].value;
    } 
    
    const { newVerticals, currentVertical } = this.getVerticalByAreas(area);
    this.setState({ area: area, verticalList: newVerticals, vertical: vertical ? vertical:currentVertical});
  }

  updateVerticalList = () => {
    return new Promise((resolve) => {
      new ReportsService().initAvailableOptions().then(({ areas, verticalByAreas }) => {
        this.setState({ positionList: areas, verticalByAreas });
        resolve(200);
      });
    })
  }

  getVerticalByAreas = (value) => {
    const { verticalByAreas, vertical } = this.state;
    const newVerticals = verticalByAreas[value] || []; 
    if(newVerticals.length === 0) {
      return { newVerticals: [], currentVertical: ''};
    }

    const currentVertical = newVerticals.some(({ value }) => value === vertical) ? vertical : newVerticals[0].value;
    return { newVerticals, currentVertical };
  }

  handleSubmit = (event) => {
    const { area, vertical } = this.state;
    if(!area.isNullOrEmpty() && !vertical.isNullOrEmpty()) {
      const reportParams = { vertical: vertical, area: area };
      localStorage.setItem("demoReportParams", JSON.stringify(reportParams));
      this.props.history.push({ pathname: '/reports', search: `?area=${area}&vertical=${vertical}` });
    }

    event.preventDefault();
  }

  onChangeCompany = (value) => {
    this.setState({ vertical: value });
    this.onFormHandler();
  }

  onChangeProfessional = (value) => {
    const { newVerticals, currentVertical } = this.getVerticalByAreas(value);
    this.setState({ area: value, verticalList: newVerticals, vertical: currentVertical });
    this.onFormHandler();
  }

  onFormHandler = () => {
    setTimeout(() => {
      const { area, vertical } = this.state;
      const paramsText = `&area=${area}&vertical=${vertical}`;
      this.setState({ paramsText: paramsText });
      this.props.history.push(`${window.location.pathname}?${paramsText}`);
    }, 250);
  }

  render() {
    const { area, vertical, positionList, verticalList } = this.state;

    return (
      <div className="natural-language-form">
        <div className="natural-language-form__title">
          Let's Get You Started
        </div>
        <form onSubmit={this.handleSubmit}>
          I am an <InlineSelectBox selectedValue={area} list={positionList} onChangeValue={this.onChangeProfessional} highlightText="Select your solution type" isHighlightText={true} /> solution provider and I want to sell to  <InlineSelectBox selectedValue={vertical} list={verticalList} onChangeValue={this.onChangeCompany} highlightText="Select your target industry" isHighlightText /> companies.
          <br />
          <button className="natural-language-form__button" type="submit">
            <i className="material-icons color-primary-700"> chevron_right </i> Get Sales Intelligence
          </button>
        </form>
        <div className="natural-language-form__content">
          <iframe src='https://www.youtube.com/embed/8DF1a0fpp9w' frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video'/>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { routeReducers } = rootReducers;
  return {
    location: routeReducers,
  };
};

export default connect(mapStateToProps)(NaturalLanguageForm);
