import React, { Component } from 'react';
import { Image } from 'components/Image/Image';
import './CompanyAttributes.scss';

export class CompanyAttributes extends Component {
  render() {
    const { companyList = [], size = '' } = this.props;
    let { max } = this.props;
    if (!max) {
      max = companyList.length;
    }

    return (
      <div className="company-attributes">
        {
          companyList.slice(0, max).map(({ image, name, url }, key) => {
            if(name) {
              return <div className={`company-attributes__item ${size}`} key={key}>
                <div className="company-attributes__item__image">
                  <Image src={image} alt={name} />
                </div>
                <div className="company-attributes__item__name" dangerouslySetInnerHTML={{__html: name }}>
                </div>
              </div>
            }
          })
        }
        { companyList.length > max ? 
          <div className={`company-attributes__item ${size} more--item`}>
            <span />
            <span />
            <span />
          </div>:'' 
        }
      </div>
    )
  }
}

export default CompanyAttributes;
