export const SEARCH_TEXT_IN_REPORT = 'SEARCH_TEXT_IN_REPORT';
export const searchTextInReport = (searchText) => {
  return {
    type: SEARCH_TEXT_IN_REPORT,
    searchText
  }
}

export const UPDATE_REPORT_COMPANY_NAME = 'UPDATE_REPORT_COMPANY_NAME';
export const updateReportCompanyName = (companyName) => {
  return {
    type: UPDATE_REPORT_COMPANY_NAME,
    companyName
  }
}

export const ARTICLE_FILTER_CHANGED = 'ARTICLE_FILTER_CHANGED';
export const articleFilterChanged = (articleFilter) => {
  return {
    type: ARTICLE_FILTER_CHANGED,
    articleFilter
  }
}

export const SORT_VALUE_IN_REPORT = 'SORT_VALUE_IN_REPORT';
export const sortValueInReport = (sortValue) => {
  return {
    type: SORT_VALUE_IN_REPORT,
    sortValue
  }
}
