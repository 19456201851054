import { HttpService } from 'services/HttpService';
import DateUtils from 'utils/DateUtils';
import JsonUtils from 'utils/JsonUtils';
import { demoUserData } from 'commons/UserData';
import { TokenService } from 'services/TokenService';

const COLORS = [
  "green",
  "green-dark",
  "purple",
  "red",
  "blue",
  "blue-2",
  "yellow",
  "gray",
  "light-green",
  "pink"
];

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
export class UserDataProvider {
  fetchDemoReports(params) {
    return new Promise((resolve, reject) => {
      new HttpService().sendRequest(`${host}/api/getDemoReports`,{
        body: JSON.stringify(params),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((data) => {
        const mapData  = data.map((item) => {
          return this.mapReportItem(item);
        }).sort((a, b) => {
          return a.rank - b.rank;
        });
        resolve(mapData);
      })
      .catch((err, code) => {
        reject(err);
      });
    });
    
  }

  fetchFreemiumReports(params) {
    return new Promise((resolve, reject) => {
      new HttpService().sendRequest(`${host}/api/getFreemiumReports`,{
        body: JSON.stringify(params),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((data) => {
        const mapData  = data.map((item) => {
          return this.mapReportItem(item);
        }).sort((a, b) => {
          return a.rank - b.rank;
        });
        resolve(mapData);
      })
      .catch((err, code) => {
        reject(err);
      });
    });
    
  }

  getUserNLPData() {
    return new Promise((resolve) => {
      const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
      const body = {
        'addToken': true
      };
      new HttpService().sendRequest(`${host}/api/userNlp`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        const { data } = result;
        const mapData  = data.map((item) => {
          return this.mapReportItem(item);
        });
        resolve(mapData);
      })
      .catch((err) => {
        console.log(err);
      });
    })
  }
  
  getUserCompanyData(body) {
    return new Promise((resolve, reject) => {
      const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
      new HttpService().sendRequest(`${host}/api/userCompany/pageable`,{
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        const { data, pageable = {} } = result;
        const mapData  = data.map((item) => {
          return this.mapReportItem(item);
        });
        resolve({ data: mapData, pageable });
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  filterAttributes(attributes) {
    return attributes.map((attribute) => {
      return {
        text: attribute,
        color: COLORS[Math.floor(Math.random()*COLORS.length)] 
      }
    })
  }

  mapReportItem(item) {
    const { reportId = '' } = item;

    if(!reportId.isNullOrEmpty()) {
      item.company_id = reportId;
    }
    
    item.filter = !item.filter.isNullOrEmpty() ? JSON.parse(item.filter) : [];
    item.filter = this.filterAttributes(item.filter);
    if(item.hasOwnProperty('titles')) {
      item.titles = !item.titles.isNullOrEmpty() ? JSON.parse(item.titles) : [];
      item.titles = this.filterAttributes(item.titles);
    }

    if(item.hasOwnProperty('keywords')) {
      item.keywords = this.filterAttributes(item.keywords);
    }

    if(item.hasOwnProperty('painPoints')) {
      item.painPoints = this.filterAttributes(item.painPoints);
    }

    if(item.hasOwnProperty('competitors')) {
      item.competitors = item.competitors.map((item) => ({ name: item, text: item }));
    }

    if(item.hasOwnProperty('companies')) {
      item.companies = item.companies.map((item) => ({ name: item, text: item }));
    }

    if(!item.hasOwnProperty('rank')) {
      item.rank = 0;
    }

    if(!item.hasOwnProperty('startDate')) {
      item.startDate = new Date();
      item.startDate.setFullYear(2010);
      item.startDate.setMonth(1);
      item.startDate.setDate(1);
      item.hideDate = true;
      let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      item.date = new Date(item.startDate).toLocaleDateString('en-US', options);
    } else {
      let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      let { startDate } = item;
      item.date = new Date(startDate).toLocaleDateString('en-US', options);
      item.formattedStartDate = new DateUtils().getFormattedDate(startDate);
    }

    return item
  }

  getCurrentUsersData() {
    const that = this;
    return new Promise(function (resolve, reject) {
      const userData = localStorage.getItem('userData');
      if (userData) {
        resolve(JSON.parse(userData));
      } else {
        that.fetchCurrentUserData().then((result) => {
          resolve(result);
        }).catch((err) => {
          reject(err);
        });
      }
    });
  }

  fetchCurrentUserData() {
    return new Promise((resolve, reject) => {
      const body = {
        'addToken': true,
      };
      new HttpService().sendRequest(`${host}/api/users/current`,{
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((result) => {
        if (!new JsonUtils().isJson(result)) {
          result = demoUserData;
          new TokenService().saveDemoUserToken('demo_token');
        }

        localStorage.setItem('userData', JSON.stringify(result));
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
     });
  }
}

export default UserDataProvider;
