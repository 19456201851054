import React, { Component } from 'react'
import ChangePasswordForm from 'components/ChangePasswordForm/ChangePasswordForm'
import SocialFooter from 'components/SocialFooter/SocialFooter'
import './ChangePassword.scss'
import LoginIllustration from 'components/Image/LoginIllustration'
import EnkiLogo from 'components/Image/EnkiLogo'
import { Link }from 'react-router-dom'

export class ChangePasswordPage extends Component {

  constructor(props) {
    super(props)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    const { email, code } = props.match.params;
    this.state = { email: email || '', code: code || '' };
  }

  onFormSubmit(e) {
    this.props.history.push('/login');
  }
  
  render() {
    return (
      <div>
        <div className="login-page">
          <div className="login-page__content">
            <div className="login-page__content__logo">
              <Link to={`/`}>
                <EnkiLogo />
              </Link>
            </div>
            <div className="login-page__content__form">
              <ChangePasswordForm email={this.state.email} code={this.state.code} onSubmit={this.onFormSubmit} />
            </div>
            <SocialFooter />
          </div>
          <div className="login-page__slider">
            <div className="login-page__slider__head">
              <h2>
                Get a Deeper Understanding of Your Market With Enki
              </h2>
              <h5>
                Our AI solution provides forward looking intelligence about emerging markets and technologies to help you get ahead
              </h5>
            </div>
            <div className="login-page__slider__image">
              <LoginIllustration />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePasswordPage
