import {
  ON_UPDATE_SECTION_NAMES,
  RECEIVED_FILTERED_INSIGHTS,
  IS_INSIGHTS_LOADING,
  SET_SECTION_LIST,
  ON_CHANGE_INSIGHT_FILTER,
} from "store/actions/reports/report";

const DEFAULT_STATE = {
  heatmap: [],
  sections: [],
  sectionList: [],
  isInsightsLoading: false,
  insightFilter: {
    didFilterChanged: false,
  },
};
const reportReducers = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ON_UPDATE_SECTION_NAMES:
      return {
        ...state,
        type: action.type,
        sections: action.sections,
      };
    case RECEIVED_FILTERED_INSIGHTS:
      return {
        ...state,
        type: action.type,
        filteredMetaData: action.metadata,
      };
    case IS_INSIGHTS_LOADING:
      return {
        ...state,
        type: action.type,
        isInsightsLoading: action.isLoading,
      };
    case SET_SECTION_LIST:
      return {
        ...state,
        type: action.type,
        sectionList: action.sectionList,
      };
    case ON_CHANGE_INSIGHT_FILTER:
      return {
        ...state,
        type: action.type,
        insightFilter: action.insightFilter,
      };
    default:
      return state;
  }
};

export default reportReducers;
