import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reportFilterOptions } from 'commons/SelectDatas';
import CardView from 'components/Views/CardView/CardView';
import MultipleSelectBox from 'components/form/MultipleSelectBox/MultipleSelectBox';
import IconButton from 'components/form/IconButton/IconButton';
import { onUpdateSectionNames, setSectionList } from 'store/actions/reports/report';
import { articleFilterChanged } from 'store/actions/dashboard/report';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import RangeSlider from 'components/form/Inputs/RangeSlider/RangeSlider';
import AttributeViews from 'components/Views/AttributeViews/AttributeViews';
import { HttpService } from 'services/HttpService';
import { SingleSelectBox } from 'components/form/SingleSelectBox/SingleSelectBox';
import RadioButtons from 'components/form/Inputs/RadioButtons/RadioButtons';
import './ReportFilterView.scss';

const COLORS = [
  'green',
  'green-dark',
  'purple',
  'red',
  'blue',
  'blue-2',
  'yellow',
  'gray',
  'light-green',
  'pink',
];
const staticSections = [
  {
    checked: false,
    defaultValue: false,
    text: 'OTHER',
    type: 'generic',
    value: 'Other',
  },
];
export class ReportFilterView extends Component {
  constructor(props) {
    super(props);
    const insightFilter = this.getInsightFilter(props);
    this.state = {
      sectionList: [],
      selectedSections: [],
      selectedKeywords: [],
      keywords: [],
      ...insightFilter,
    };
  }

  componentDidMount() {
    this.onChangeArticleFilter();
    this.loadCompanyKeywords();
  }

  componentWillReceiveProps(props) {
    const { isLoading = false } = props;
    let { sectionList = [] } = props;
    let {
      selectedSections = [],
      titleCompanyMatched = null,
      titleAttributeMatched = null,
      dateFilter = 0,
    } = this.state;
    if (selectedSections.length < 1) {
      selectedSections = sectionList
        .filter(({ checked }) => checked)
        .map(({ value }) => value.toUpperCase());
    }

    sectionList = this.getSectionList(sectionList);
    this.setState({
      sectionList,
      selectedSections,
      isLoading,
    });

    this.props.articleFilterChanged({
      filteredMonth: dateFilter,
      titleCompanyMatched,
      titleAttributeMatched,
    });
  }

  getInsightFilter = ({ insightFilter = {} }) => {
    let titleCompanyMatched = null;
    let titleAttributeMatched = null;
    let matchCount = 1;
    let dateFilter = 0;
    if (Object.keys(insightFilter).length > 0) {
      titleCompanyMatched = insightFilter.titleCompanyMatched;
      titleAttributeMatched = insightFilter.titleAttributeMatched;
      matchCount = insightFilter.matchCount;
      dateFilter = insightFilter.dateFilter;
    }

    const selectedFilterValue =
      reportFilterOptions.find(({ value }) => value === dateFilter);
    return {
      titleCompanyMatched,
      titleAttributeMatched,
      matchCount,
      dateFilter,
      selectedFilterValue,
    };
  }

  getSectionList = (sectionList) => {
    const sectionsMap = new Set([...sectionList.map(({ value }) => value)]);
    staticSections.forEach((section) => {
      const { value } = section;
      if (!sectionsMap.has(value)) {
        sectionList = [...sectionList, section];
      }
    });

    return sectionList.map((section) => ({
      ...section,
      color: this.getRandomColor(),
    }));
  }

  loadCompanyKeywords = () => {
    const { companyId, heatmap = [], insightFilter } = this.props;
    const body = { companyId };
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    new HttpService().sendRequest(`${host}/api/company/getNlpDataKeywords`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      const { keywords = [] } = response;
      const keywordList = [...new Set([...keywords, ...heatmap.map(({ area }) => area)])];
      const selectedKeywords =
        Object.keys(insightFilter).length > 0 ? insightFilter.keywords : keywordList;
      this.setState({
        keywords: keywordList.map((name) => ({
          text: name.toUpperCase(),
          value: name,
          checked: selectedKeywords.includes(name.toUpperCase()),
          color: this.getRandomColor(),
        })),
        selectedKeywords,
      });
    });
  }

  didAnyFilterChanged = () => {
    const {
      matchCount = 1,
      selectedKeywords = [],
      titleCompanyMatched,
      titleAttributeMatched,
      selectedSections,
      dateFilter,
      keywords = [],
    } = this.state;
    const { sectionList = [] } = this.props;

    return (
      matchCount !== 1 || selectedSections.length !== sectionList.length
      || keywords.length !== selectedKeywords.length || dateFilter !== 0
      || titleAttributeMatched !== null || titleCompanyMatched !== null
    );
  }

  onSectionsChange = (sectionList) => {
    const selectedSections = sectionList
      .filter(({ checked }) => checked)
      .map(({ value }) => value);
    this.setState({ selectedSections, sectionList }, () => this.props.setSectionList(sectionList));
  }

  onKeywordsChange = (keywords) => {
    let { matchCount } = this.state;
    const selectedKeywords = keywords
      .filter(({ checked }) => checked)
      .map(({ value }) => value);
    if (selectedKeywords.length === 0) {
      matchCount = 1;
    } else if (matchCount > selectedKeywords.length) {
      matchCount = selectedKeywords.length;
    }

    this.setState({ keywords, selectedKeywords, matchCount });
  }

  onChangeDateFilter = ({ value }) => {
    const selectedFilterValue = reportFilterOptions
      .find(({ value: itemValue }) => itemValue === value);
    this.setState({ dateFilter: value, selectedFilterValue }, () => {
      this.onChangeArticleFilter();
    });
  }

  onChangeArticleFilter = () => {
    const { titleCompanyMatched, titleAttributeMatched, dateFilter } = this.state;

    this.props.articleFilterChanged({
      filteredMonth: dateFilter,
      titleCompanyMatched,
      titleAttributeMatched,
    });
  }

  onMatchCountChanged = (selectedCount) => {
    this.setState({ matchCount: selectedCount.value });
  }

  getSelectedSections = (cacheSectionList = null) => {
    const { sectionList = [] } = this.props;
    const selectedSectionTypes = (cacheSectionList || sectionList)
      .filter(({ checked }) => checked)
      .map(({ type = '' }) => type);
    const filteredSections = (cacheSectionList || sectionList).filter(({ type = '' }) => selectedSectionTypes.includes(type));
    this.props.onUpdateSectionNames(filteredSections);
    return [...new Set(selectedSectionTypes)];
  }

  getRandomColor = () => COLORS[Math.floor(Math.random() * COLORS.length)];

  onFilterClear = () => {
    let { sectionList = [] } = this.props;
    let { keywords = [] } = this.state;
    const selectedKeywords = keywords.map(({ value }) => value);
    const selectedSections = sectionList.map(({ value }) => value.toUpperCase());
    keywords = keywords.map((keyword) => ({
      ...keyword,
      checked: true,
    }));
    sectionList = sectionList.map((section) => ({
      ...section,
      checked: section.defaultValue === false ? section.defaultValue : true,
      color: this.getRandomColor(),
    }));
    const insightFilter = {
      selectedKeywords,
      selectedSections,
      matchCount: 1,
      sectionList,
      keywords,
      dateFilter: 0,
      titleCompanyMatched: null,
      titleAttributeMatched: null,
      selectedFilterValue: reportFilterOptions.find(({ value }) => value === 0),
    };
    this.setState({
      ...insightFilter,
    }, () => {
      this.props.setSectionList(sectionList);
      this.onFilterHandle(sectionList);
    });
  }

  onFilterHandle = (sectionList = null) => {
    const {
      matchCount = 1,
      selectedKeywords = [],
      titleCompanyMatched,
      titleAttributeMatched,
      dateFilter,
    } = this.state;
    const { companyId = '', titles = [] } = this.props;
    this.props.onFilterChange({
      sections: this.getSelectedSections(sectionList),
      companyId,
      matchCount,
      titleCompanyMatched,
      titleAttributeMatched,
      keywords: selectedKeywords,
      dateFilter,
      titles: titles.map(({ value }) => value),
    });
  }

  isFilterDisabled = () => {
    const {
      selectedSections = [],
      selectedKeywords = [],
      keywords = [],
    } = this.state;

    return (
      selectedSections.length === 0
      || (keywords.length !== 0 && selectedKeywords.length === 0)
    );
  }

  footerContent = () => {
    const {
      isLoading = false,
    } = this.state;

    if (isLoading) {
      return (
        <div className="report-filter-view__content__footer">
          <CircleLoader isActive={true} size="small" />
        </div>
      );
    }

    return (
      <div className="report-filter-view__content__footer">
        <IconButton
          color="blue-2"
          size="medium"
          iconName="filter_alt"
          text="Get Filtered Insights"
          handleClick={this.onFilterHandle}
          disabled={this.isFilterDisabled()}
        />
        { this.didAnyFilterChanged() && (
          <IconButton
            color="red-light"
            size="medium"
            iconName="clear_all"
            text="Clear Filters"
            handleClick={this.onFilterClear}
          />
        )}
      </div>
    );
  }

  filterViewContent = () => {
    const {
      selectedKeywords = [],
      sectionList = [],
      matchCount,
      keywords = [],
      selectedFilterValue,
      titleAttributeMatched,
      titleCompanyMatched,
    } = this.state;
    const titleCompanyMatchedButtons = [
      {
        label: 'Title',
        value: true,
        icon: 'check',
        color: 'accent-green',
      },
      {
        label: 'Paragraph',
        value: null,
        icon: 'search_off',
        color: 'gray',
      },
    ];
    return (
      <div className="report-filter-view__content">
        <div className="report-filter-view__content__items">
          <div className="report-filter-view__content__item">
            <SingleSelectBox selectedValue={selectedFilterValue} title="Time Range" highlightText="Choose Time Range" list={reportFilterOptions} onChangeValues={this.onChangeDateFilter} />
          </div>
          <div className="report-filter-view__content__item">
            <RadioButtons
              buttons={titleCompanyMatchedButtons}
              title="Company Relevance"
              selectedValue={titleCompanyMatched}
              onValueChange={ (value) => this.setState({ titleCompanyMatched: value }, () => this.onChangeArticleFilter())} 
            />
          </div>
          <div className="report-filter-view__content__item">
            <RadioButtons
              buttons={titleCompanyMatchedButtons}
              title="Attribute Relevance"
              selectedValue={titleAttributeMatched}
              onValueChange={ (value) => this.setState({ titleAttributeMatched: value }, () => this.onChangeArticleFilter())}
            />
          </div>
        </div>
        <div className="report-filter-view__content__items">
          <div className="report-filter-view__content__item">
            { sectionList.length > 0 && <MultipleSelectBox list={sectionList} onChangeValues={this.onSectionsChange} title="Sections" searchable="true" /> }
          </div>
          <div className="report-filter-view__content__item">
            { keywords.length > 0 && <MultipleSelectBox list={keywords} onChangeValues={this.onKeywordsChange} title="Attributes & Contextual Concepts" searchable="true" /> }
          </div>
          <div className="report-filter-view__content__item">
            <div className="report-filter-view__content__item__title">
              Minimum Match
            </div>
            <RangeSlider max={selectedKeywords.length || 1} min="1" value={matchCount} onChange={(value) => this.setState({ matchCount: value })} />
            <AttributeViews list={[{ text: `Match Count: ${matchCount}`, color: 'blue-2' }]} hideMoreButton="true" max={5} />
          </div>
        </div>
        { this.footerContent() }
      </div>
    );
  }

  render() {
    return (
      <div className="report-filter-view">
        <div className="report-filter-view__title">
          FILTERS
        </div>
        <CardView
          classes="cursor--default"
          childComponent={
            this.filterViewContent()
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { reportReducers } = store.reportReducers;
  const {
    heatmap,
    sections,
    isInsightsLoading = false,
    sectionList = [],
  } = reportReducers;

  return {
    heatmap,
    sections,
    sectionList,
    isLoading: isInsightsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSectionList: (sectionList) => dispatch(setSectionList(sectionList)),
  onUpdateSectionNames: (sections) => dispatch(onUpdateSectionNames(sections)),
  articleFilterChanged: (articleFilter) => dispatch(articleFilterChanged(articleFilter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportFilterView);
