const filterViews = (state = {}, action) => {
  switch (action.type) {
    case 'SET_REPORT_LIST_TYPE':
      return Object.assign({}, state, {
        type: action.type,
        listType: action.listType,
      });
    default:
      return state
  }
}

export default filterViews;
