import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateAuthModal } from 'store/actions/root/auth-modal';
import './SignUpBanner.scss';

export class SignUpBanner extends Component {
  render() {
    return (
      <div className="signup-banner">
        <div className="signup-banner__content">
          <div className="signup-banner__content__text">
            Sign up To Unlock the Full insights Report 
          </div>
          <a className="signup-banner__content__button" onClick={ () => { this.props.updateAuthModal(true) } }>
            Sign Up
          </a>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateAuthModal: (status) => dispatch(updateAuthModal(status)),
  }
};

export default connect(null, mapDispatchToProps)(SignUpBanner);
