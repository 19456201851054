import React from 'react';
import './InsightCardAttributes.scss';

const InsightCardAttributes = ({ tagList = [] }) => (
  <div className="insight-card-attributes">
    {
    tagList.map(({ value, type, color }, i) => (
      <div className="insight-card-attributes__item" color={`${color}`} key={i}>
        <i className="material-icons md-18">
          {type}
        </i>
        { value }
      </div>
    ))
    }
  </div>
);

export default InsightCardAttributes;
