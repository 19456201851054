import { HttpService } from 'services/HttpService';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
export class InsightsDataProvider {
  fetchFilteredInsights = (requestData) => new Promise((resolve, reject) => {
    requestData.addToken = true;
    new HttpService().sendRequest(`${host}/api/userNlp/filterInsights`, {
      body: JSON.stringify(requestData),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((data) => {
      resolve(data);
    }).catch((err, code) => {
      reject(err);
    });
  });
}

export default InsightsDataProvider;
