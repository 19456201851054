import React, { Component } from 'react';
import { CircleLoader } from 'components/CircleLoader/CircleLoader';
import './NavBarIconMenuItem.scss';

export class NavBarIconMenuItem extends Component {
  render() {
    const { disabled, icon, color, onClick, title, isLoading } = this.props;
    if(isLoading) {
      return (
        <span className="margin-t-0 margin-r-10">
          <CircleLoader isActive={true} size="small" />
        </span>
      )
    }

    return (
      <div className="navbar-icon-menu-item" disabled={disabled} color={color} onClick={() => { onClick() }}>
        { title ?
          <div className="navbar-icon-menu-item__title">
            { title }
          </div> :''
        }
        <i className="material-icons"> { icon } </i>
      </div>
    )
  }
}

export default NavBarIconMenuItem;
