import React, { Component } from 'react';
import { Image } from 'components/Image/Image';
import linkedinLogo from 'assets/img/icons/linkedin.svg';
import './AttributeInsightPeople.scss';
import StringUtils from 'utils/StringUtils';

export class AttributeInsightPeople extends Component {
  render() {
    const { profile = {} } = this.props;
    const { 
      linkedin = '', 
      name = '', 
      profile_image = '', 
      title = '', 
      current_experience = {},
      location = ''
    } = profile;
    const { user_experience_company = '', user_experience_title = '' } = current_experience || {};
    if (name.isNullOrEmpty()) {
      return null
    }
    
    return (
      <a className={`attribute-insight-people ${linkedin.isNullOrEmpty() ? 'disabled' : ''}`} href={linkedin || '#'} target="_blank">
        <div className='attribute-insight-people__head'>
          <div className="attribute-insight-people__head__image">
            <Image src={profile_image} />
          </div>
          <div className='attribute-insight-people__head__title'  data-tooltip={!linkedin.isNullOrEmpty() ? "Open Profile in linkedin.com": ''}>
            <span>
              {name.toUpperCase()}
              { !linkedin.isNullOrEmpty() ?
                <div className="attribute-insight-people__head__link">
                  <img src={linkedinLogo} />
                </div>: ''
              }
            </span>
            { (user_experience_title || title) ? 
              <div className='attribute-insight-people__head__sub-title'>
                <Image src={new StringUtils().getCompanyLogo(user_experience_company)} /> { user_experience_company },
                {user_experience_title || title}
              </div>: ''
            }
          </div>
        </div>
        <div className='attribute-insight-people__body'>
          <div className="attribute-insight-people__body__company">
            <Image src={new StringUtils().getCompanyLogo(user_experience_company)} />
            {user_experience_company}
          </div>
          { 
            !location.isNullOrEmpty() ? 
            <span className="attribute-insight-people__body__location">
              <i className="material-icons">place</i>
              {location}
            </span>: ''
          }
        </div>
      </a>
    )
  }
}

export default AttributeInsightPeople;
