export const navigationItemList = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: 'home',
    isActive: true,
    redirectTo: "/dashboard/reports",
    subLinks: [
      { name: "Single Company", path: "/dashboard/reports", icon: 'show_chart', isActive: true },
      { name: "Multiple Companies", path: "/dashboard/reports/multiple", icon: 'multiline_chart', isActive: true }
    ]
  },
  {
    name: "New Report",
    path: "/new-report",
    icon: 'add',
    isActive: true,
    redirectTo: "/",
    target: "_blank",
    subLinks: [
      { name: "Single Company", path: "/", icon: 'search', isActive: true, target: "_blank" },
      { name: "Multiple Companies", path: "/multiple", icon: 'equalizer', isActive: true, target: "_blank" },
    ]
  },
  {
    name: "Discover Companies",
    path: "/discover-companies",
    icon: 'work',
    isActive: true
  },
  {
    name: "Research",
    path: "/research",
    icon: 'science',
    isActive: true
  }
];

export const freemiumNavigationItemList = [
  { name: "Research", path: "/research", icon: 'science', isActive: true },
];

export const demoNavigationItemList = [

];
