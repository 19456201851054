import React, { Component } from 'react'
import enkiDarkLogo from 'assets/img/icons/enki-logo-dark.png';

export class EnkiLogo extends Component {
  render() {
    return (
      <div>
        <img src={enkiDarkLogo} alt="Enki AI logo"/>
      </div>
    )
  }
}

export default EnkiLogo;
