import React from 'react';
import { Creatable } from 'react-select';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
const options = [
    { value: "TECHNOLOGY INSIGHT", label: "TECHNOLOGY INSIGHT" },
    { value: "IOT INSIGHT", label: "IOT INSIGHT" },
    { value: "IT INSIGHT", label: "IT INSIGHT" },
    { value: "MESSAGING PLATFORM INSIGHT", label: "MESSAGING PLATFORM INSIGHT" },
    { value: "PURPOSE DRIVEN MARKETING", label: "PURPOSE DRIVEN MARKETING" }
];

const customStyles = {
  placeholder: (provided) => {
    return {
      ...provided,
      paddingLeft: 0,
      fontWeight: 400,
    }
  },
  control: (provided, state) => {
    return {
      ...provided,
      borderWidth: 0,
      borderRadius: 20,
      backgroundColor: "#F5F5F5",
      opacity : state.isDisabled ? 0.5 : 1,
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow: 0,
      ':hover,:focus': {
        borderColor: "#9753e1"
      }
    };
  }, 
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "#FFFFFF",
      borderRadius: 20,
      paddingLeft: 5,
      paddingRight: 5
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: "15px",
      fontWeight: "500",
    }
  }
}

const customTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral20: "#464646",
    }
  };
}

const getNewOptionData = (inputValue, optionLabel) => {
  return {
    value: inputValue.toUpperCase(),
    label: optionLabel.toUpperCase()
  }
}

const logCustomInput = (inputValue) => {
  const body = JSON.stringify(
    { 
      inputType: 'area',
      inputValue: inputValue 
    }
  );
  return fetch(`${host}/api/userInput`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
  .then((response) => response.json())
}

export default class AreaSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = { inputValue: '' };
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue: inputValue.toUpperCase() }); 
    return inputValue.toUpperCase();
  };

  handleChange = (newValue, { action }) => {
    this.runOnChanged(action, newValue);
    this.props.onChange(newValue || '');
  };

  createOption = (inputValue) => {
    logCustomInput(inputValue).then((response) => {
      console.log(response);
    });
  }

  runOnChanged(type, newValue) {
    const options =  { 
      'select-option': () => {},
      'remove-value': () => {},
      'create-option': () => {
        const valueLength = newValue && newValue.value.length;
        if(valueLength > 0) {
          this.createOption(newValue.value) 
        }
      } ,
    }[type] || (() => {})
    return options()
  }

  render() {
    const { inputValue } = this.state;
    return (
      <Creatable
        placeholder="e.g. Technology Insight, IoT Insight, or IT Insight"
        onChange={this.handleChange}
        options={options}
        inputValue={ inputValue }
        value={ this.props.value }
        styles={customStyles}
        theme={customTheme}
        onInputChange={this.handleInputChange}
        getNewOptionData={getNewOptionData}
        createOptionPosition="first"
        isDisabled={this.props.disabled}
        isClearable
      />
    );
  }
}
