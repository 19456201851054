import React, { Component } from 'react';
import './ProfileListView.scss';
import facebookLogo from 'assets/img/icons/facebook.svg';
import linkedinLogo from 'assets/img/icons/linkedin.svg';
import twitterLogo  from 'assets/img/icons/twitter.svg';
import instagramLogo  from 'assets/img/icons/instagram.svg';
import youtubeLogo  from 'assets/img/icons/youtube.svg';
import pinterestLogo from 'assets/img/icons/pinterest.svg';
import noImage from 'assets/img/no_image.png';

export class ProfileListView extends Component {

  getProfileLogo = (name) => {
    name = name.toLowerCase();

    const images = { 
      "twitter": twitterLogo,
      "facebook": facebookLogo,
      "instagram": instagramLogo,
      "linkedin": linkedinLogo,
      "youtube": youtubeLogo,
      "pinterest": pinterestLogo
    }

    return images[name] || noImage;
  }
  
  render() {

    const { profileURLS = [] } = this.props;
    return (
      <div className="profile-list">
        {  
          profileURLS.map(({ name, url }, i) => {
            if(name) {
              return <a className="profile-list__item" href={url} target="_blank" key={i}>
                <img src={this.getProfileLogo(name)} />
                { name }
                <i className="material-icons"> launch </i>
              </a>
            }
          })
        }
      </div>
    )
  }
}

export default ProfileListView;
