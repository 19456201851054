import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import './TabBarView.scss';

export class TabBarView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTabPath: ''
    };
  }

  getPathName = () => {
    const history = createBrowserHistory();
    const { location } = history;
    const { pathname = '' } = location;

    return pathname
  }

  componentDidMount = () => {
    this.setState({ currentTabPath: this.getPathName()});
  }

  onTabChanged = (to) => {
    const { currentTabPath } = this.state;
    this.setState({ currentTabPath: to });
    this.props.onTabChanged({ from: currentTabPath, to: to });
  }

  render() {
    const { tabs = [] } = this.props;
    const pathname = this.getPathName();
    return (
      <ul className="tab-bar-view">
        {
          tabs.map(({ to, name, icon }, i) => {
            return (<li className={`tab-bar-view__item ${pathname == to ? 'active':''}`} key={i} onClick={() => this.onTabChanged(to)}>
              <Link to={to}>
                <i className="material-icons"> { icon } </i>
                { name }
              </Link>
            </li>)
          })
        }
      </ul>
    )
  }
}

export default TabBarView;
