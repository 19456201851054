import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { fetchCurrentUserData } from 'store/actions/root/user';
import Dashboard from 'layouts/user/Dashboard/Dashboard';
import NavBar from 'components/Navigations/NavBar/NavBar';
import './UserDashboardPage.scss';

export class UserDashboardPage extends Component {
  componentWillMount() {
    this.props.fetchCurrentUserData();
  }

  getNavBar = () => {
    const { pathname = '/dashboard' } = this.props.location;
    return {
      '/dashboard/reports': <NavBar theme="light" logo={{ small: true, desktop: true }} isDemo={false} withoutNavigation={false} />,
      '/dashboard/reports/multiple': <NavBar theme="light" logo={{ small: true, desktop: true }} isDemo={false} withoutNavigation={false} />,
    }[pathname];
  }

  render() {
    const { mobileNavigationStatus, location } = this.props;
    const { pathname = '/dashboard' } = location;
    return (
      <div className="dashboard-page">
        <div className="row">
          <div className={ `col col-md-12 col-xl-12 margin-t-0 sidenav ${mobileNavigationStatus ? 'open' : ''}` }>
            { this.getNavBar() }
            { (pathname === '/dashboard/' || pathname === '/dashboard') && <Redirect to="/dashboard/reports" /> }
            <Route exact path="/dashboard/reports" render={(props) => <Dashboard {...props} />} />
            <Route exact path="/dashboard/reports/multiple" render={(props) => <Dashboard {...props} />} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { userReducers } = store;
  const { mobileNavigationReducers } = userReducers;
  return {
    mobileNavigationStatus: mobileNavigationReducers.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardPage);
