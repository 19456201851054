import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './InlineSelectBox.scss';


const renderListElements = (classReferance, list) => {
  return list.map((item, i) => {
    return <div className="inline-select-box__menu__item" value={item.value}  onClick={onSelectItem(item, classReferance)}  key={i}>
      { item.text }
    </div>
  })
}

const onSelectItem = (item, classReferance) => {
  classReferance.setState({ isMenuActive: false , selectedValue: item.text });
}

export class InlineSelectBox extends Component {

  constructor(props) {
    super(props);
    this.state = { isMenuActive: false, selectedValue: '' };
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onSelectBoxClicked = this.onSelectBoxClicked.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  
  componentWillReceiveProps(props) {
    const { selectedValue } = props;
    this.setState({ selectedValue: selectedValue });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
  
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isMenuActive: false });
    }
  }

  onSelectBoxClicked() {
    this.setState({ isMenuActive: true });
  }

  onSelectItem(item) {
    this.setState({ isMenuActive: false , selectedValue: item.text });
    this.props.onChangeValue(item.value);
  }

  renderListElements = (list) => {
    return list.map((item, i) => {
      return <div className="inline-select-box__menu__item" value={item.value}  onClick={() => this.onSelectItem(item)}  key={i}>
        { item.text }
      </div>
    })
  }

  render() {
    const { list, highlightText, isHighlightText } = this.props;
    const menuClass = this.state.isMenuActive ? 'inline-select-box__menu active' : 'inline-select-box__menu';
    const menuButtonClass = this.state.selectedValue == '' ? 'inline-select-box__button disabled' : 'inline-select-box__button';
    const highlightClass = !isHighlightText ? 'visibility--hidden': '';
    return (
      <div className="inline-select-box">
        <div className={menuButtonClass} onClick={this.onSelectBoxClicked}>
          { this.state.selectedValue == '' ?
            <span className={ highlightClass }> { highlightText } </span> : 
            <span> 
              { this.state.selectedValue } 
            </span> 
          }
          <i className="material-icons"> expand_more </i>
        </div>
        <div className={menuClass}>
          {
            list.map((item, i) => {
              return <div className="inline-select-box__menu__item" value={item.value}  onClick={() => this.onSelectItem(item)}  key={i}>
                { item.text }
              </div>
            })
          }
        </div>
      </div>
    )
  }
}

export default InlineSelectBox
