import React, { Component } from 'react';
import ElementService from 'services/ElementService';
import './ScrollToTop.scss';

export class ScrollToTop extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buttonIsActive: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { scrollY } = window;
    const { buttonIsActive } = this.state;
    if (scrollY > 500 && !buttonIsActive) {
      this.setState({ buttonIsActive: true });
    } else if (scrollY < 500 && buttonIsActive) {
      this.setState({ buttonIsActive: false });
    }
  }

  scrollToTop = (to, duration) => {
    const start = window.pageYOffset;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;
    const animateScroll = () => {
      currentTime += increment;
      const val = new ElementService().easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo({ top: val, behavior: 'smooth' });
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  onClick = (e) => {
    const start = window.pageYOffset / 600;
    this.scrollToTop(0, (start - (start % 100)));
  }

  isSafari = () => {
    return /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  }

  render() {
    const { buttonIsActive } = this.state;
    return (
      <div className={`scroll-to-top ${buttonIsActive ? 'active' : ''}`} onClick={this.onClick}>
        <i className="material-icons">arrow_upward</i>
      </div>
    )
  }
}

export default ScrollToTop;
