import React, { Component } from 'react';
import CardView from 'components/Views/CardView/CardView';
import cardViewLoader from 'assets/img/placeholders/multiple_report_card_view.svg';

export class MultipleCardViewLoader extends Component {
  render() {
    return (
      <div>
        <CardView childComponent={
          <img className="responsive-image" src={cardViewLoader} />
        } />
      </div>
    )
  }
}

export default MultipleCardViewLoader;
