import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PositionList, CompanyList } from 'commons/SelectDatas';
import { InlineSelectBox } from 'components/form/InlineSelectBox/InlineSelectBox';
import { ReportsService } from 'services/ReportsServices';
import './FreemiumPageHead.scss';

export class FreemiumPageHead extends Component {
  constructor(props) {
    super(props);
    this.state = { vertical: '', area: '', positionList: PositionList, verticalList: CompanyList, verticalByAreas: {} };
  }

  componentWillReceiveProps(props) {
    const { location } = props;
    const { action } = location;
    if(action === 'POP') {
      const { search } = location.location;
      const urlParams = new URLSearchParams(search);
      this.setState({
        area: urlParams.get('area'),
        vertical: urlParams.get('vertical')
      });
      this.onFormChange();
    }
  }

  componentWillMount() {
    this.updateVerticalList().then((result) => {
      this.setDefaultOptions();
    });
  }

  onChangeCompany = (value) => {
    this.setState({ vertical: value });
    this.onFormChange();
  }

  onChangeProfessional = (value) => {
    const { newVerticals, currentVertical } = this.getVerticalByAreas(value);
    this.setState({ area: value, verticalList: newVerticals, vertical: currentVertical });
    this.onFormChange();
  }

  setDefaultOptions = () => {
    const data = localStorage.getItem("demoReportParams");
    let area = '';
    let vertical = '';
    if (data && !data.isNullOrEmpty()) {
      const dataJson = JSON.parse(data);
      area = dataJson.area;
      vertical = dataJson.vertical;
    } else {
      const { positionList } = this.state;
      area = positionList[0].value;
    }

    const { newVerticals } = this.getVerticalByAreas(area);
    if (vertical.isNullOrEmpty()) {
      vertical = newVerticals[0].value;
    }

    this.setState({ area: area, verticalList: newVerticals, vertical: vertical });
    if (window.location.search === "") {  // only if on /reports page
      this.updateFormHandler();
    }
  }

  getVerticalByAreas = (value) => {
    const { verticalByAreas, vertical } = this.state;
    const newVerticals = verticalByAreas[value] || [];
    if (newVerticals.length === 0) {
      return { newVerticals: [], currentVertical: ''};
    }

    const currentVertical = newVerticals.some(({ value }) => value === vertical) ? vertical : newVerticals[0].value;
    return { newVerticals, currentVertical };
  }

  onFormChange = () => {
    setTimeout(() => {
      this.updateFormHandler();
    }, 300);
  } 

  updateFormHandler = () => {
    const { area, vertical } = this.state;
    if(area && vertical) {
      const params = { area, vertical };
      localStorage.setItem("demoReportParams", JSON.stringify(params));
      this.props.onFormHandler(params);
    }
  }

  updateVerticalList = () => {
    return new Promise((resolve) => {
      new ReportsService().initAvailableOptions().then(({ areas, verticalByAreas }) => {
        this.setState({ positionList: areas, verticalByAreas });
        resolve(200);
      });
    })
  }

  render() {
    const { area, vertical, positionList, verticalList } = this.state;

    return (
      <div className="freemium-page-head">
        <div className="freemium-page-head__title">
          Find insights about your target account
        </div>
        <div className="freemium-page-head__content">
          <form className="freemium-page-head__content__form">
            I am an <InlineSelectBox  selectedValue={area} list={positionList} onChangeValue={this.onChangeProfessional} highlightText="Select your solution type" isHighlightText={true} /> solution provider for  <InlineSelectBox selectedValue={vertical} list={verticalList} onChangeValue={this.onChangeCompany} highlightText="Select your target industry" isHighlightText={true} /> companies.
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { routeReducers } = rootReducers;
  return {
    location: routeReducers,
  };
};

export default connect(mapStateToProps)(FreemiumPageHead);
