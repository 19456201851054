export const PositionList = [
  { text: 'IT', value: 'IT' },
  { text: 'IoT', value: 'IOT' },
];

export const CompanyList = [
  { text: 'Oil and Gas', value: 'Oil and Gas' },
  { text: 'E-commerce', value: 'E-commerce' },
  { text: 'Pharmaceutical', value: 'Pharmaceutical' },
  { text: 'Telecommunication', value: 'Telecommunication' },
  { text: 'Gaming ', value: 'Gaming' },
  { text: 'Payment', value: 'Payment' },
  { text: 'Financial Services', value: 'Financial Services' },
  { text: 'Technology', value: 'Technology' },
];

export const SorterOptions = [
  { text: 'Sort By Oldest', value: 'asc' },
  { text: 'Sort By Newest', value: 'desc' },
];

export const reportFilterOptions = [
  {
    text: 'Any time', value: 0, checked: true, color: 'primary-dark',
  },
  {
    text: 'Last 2 years', value: 24, checked: true, color: 'green',
  },
  {
    text: 'Last 1 year', value: 12, checked: true, color: 'red',
  },
  {
    text: 'Last 6 months', value: 6, checked: true, color: 'orange',
  },
  {
    text: 'Last 3 months', value: 3, checked: true, color: 'pink',
  },
];

export const timeFrameOptions = [
  { value: "d", label: "PAST DAY" },
  { value: "w", label: "LAST WEEK" },
  { value: "m", label: "PAST MONTH" },
  { value: "m6", label: "LAST 6 MONTH" },
  { value: "y", label: "LAST YEAR" },
  { value: "m36", label: "LAST 3 YEAR" },
];

export const CompanySorterOptions = [
  { text: 'Sort By Revenue asc', value: "revenue_range:asc" },
  { text: 'Sort By Revenue desc', value: "revenue_range:desc" },
  { text: 'Sort By Employees asc', value: "number_of_employees:asc" },
  { text: 'Sort By Employees desc', value: "number_of_employees:desc" },
];
