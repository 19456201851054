import { combineReducers } from 'redux'
import dashBoardReducers from './dashboard';
import filterViews from './views/FilterViews';
import userReducers from './user';
import rootReducers from './root';
import demoReducers from './demo';
import reportReducers from './reports';

export default combineReducers({
  rootReducers,
  dashBoardReducers,
  filterViews,
  demoReducers,
  reportReducers,
  userReducers
})
