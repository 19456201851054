import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Router, Switch } from 'react-router-dom';
import { onChangeRoute } from 'store/actions/root/route';
import { mobileNavigation } from 'store/actions/user/navigations';
import { createBrowserHistory } from 'history';
import KingEdition from './kingEdition';
import KingEditionReportV5 from './kingEdition.report.v5';
import KingEditionReportV5People from './kingEdition.report.v5.people';
import LoginPage from 'routes/login-page/login-page';
import SignupPage from 'routes/signup-page/signup-page';
import LogOutPage from 'routes/LogOutPage/LogOutPage';
import NotFound from 'routes/NotFound/NotFound';
import ChangePasswordPage from 'routes/change-password/ChangePassword';
import ForgotPasswordPage from 'routes/ForgotPassword/ForgotPasswordPage';
import UserDashboardPage from 'routes/user/UserDashboardPage/UserDashboardPage';
import NaturalLanguageForm from 'routes/NaturalLanguageForm/NaturalLanguageForm';
import { RoutePermissonService } from 'services/RoutePermissonService';
import TryDemo from 'routes/TryDemo/TryDemo';
import AuthModal from 'components/AuthModal/AuthModal';
import MessagePopup from 'components/Popups/MessagePopup/MessagePopup';
import NavigationDrawer from 'components/Navigations/NavigationDrawer/NavigationDrawer';
import AnalyticsService from 'services/AnalyticsService';
import { setReportListType } from 'store/actions';
import CompanyReports from 'layouts/user/CompanyReports/CompanyReports';
import DiscoverCompanies from 'layouts/user/DiscoverCompanies/DiscoverCompanies';
import Research from 'layouts/user/Research/Research';
import Idea from 'layouts/user/Research/Idea';
import TooltipView from './components/User/Reports/TooltipView/TooltipView';
import ToastContent from 'components/Popups/ToastContent/ToastContent';
import ModalBox from 'components/Popups/ModalBox/ModalBox';
import Visualization from 'layouts/user/Visualization/Visualization';

const KingEditionReportV5Component = (props) => <KingEditionReportV5 {...props} source='gs' />
const UserDashboardPageRoute = (props) => <UserDashboardPage {...props} source='gs' />
const MultiplePageRoute = (props) => <CompanyReports {...props} source='gs' />
const DiscoveryPageRoute = (props) => <DiscoverCompanies {...props} source='gs' />
const ResearchRoute = (props) => <Research {...props} source='gs' />
const IdeaRoute = (props) => <Idea {...props} source='gs' />
const VisualizationRoute = (props) => <Visualization {...props} source='gs' />

const PrivateRoute = ({ component, path, location, ...rest }) => {
  const { result, alternative = '/login' } = new RoutePermissonService().checkRoutePermmissiom(location);
  return <Route  {...rest} path={path} render={(props) => (
      result
      ? component(props)
      : <Redirect
          to={{
            pathname: alternative,
            propsData: props,
            state: {
              prevLocation: path,
              error: "You need to login first!",
            },
          }}
        />
    )}
  />
};

const history = createBrowserHistory();

const App = (props) => {
  const { localSettings } = window;
  props.setReportListType(localSettings.reportListType);
  history.listen((location, action) => {
    props.mobileNavigation(false);
    props.onChangeRoute({ action: action, location: location });
    AnalyticsService.logPageview(location.pathname + location.search);
  });
  const { mobileNavigationStatus } = props;
  const sliderClass = mobileNavigationStatus ? 'dashboard-page__slider margin-t-0 open' : 'dashboard-page__slider margin-t-0 ';

  return (
    <Router history={history}>
      <div className="margin-t-0">
        <AuthModal />
        <MessagePopup />
        <TooltipView />
        <ToastContent />
        <ModalBox />
        <div className={sliderClass}>
          <NavigationDrawer />
        </div>
        <Switch>
          <PrivateRoute exact path='/' component={KingEditionReportV5Component} />
          <PrivateRoute path='/dashboard' component={UserDashboardPageRoute} />
          <PrivateRoute exact path='/multiple' component={MultiplePageRoute} />
          <PrivateRoute exact path='/discover-companies' component={DiscoveryPageRoute} />
          <PrivateRoute exact path='/research' component={ResearchRoute} />
          <PrivateRoute exact path='/idea' component={IdeaRoute} />
          <Route exact path='/visualization' component={VisualizationRoute} />
          <Route exact path="/login"   render={(props) => <LoginPage {...props} />} />
          <Route exact path="/log-out" render={(props) => <LogOutPage {...props} />} />
          <Route exact path="/sign-up" render={(props) => <SignupPage {...props} />} />
          <Route exact path="/forgot-password/:email?" render={(props) => <ForgotPasswordPage {...props} />} />
          <Route exact path="/change-password/:email?/:code?" render={(props) => <ChangePasswordPage {...props} />} />
          <Route exact path="/people" render={(props) => <KingEditionReportV5People {...props} source='gs' />} />
          <Route exact path="/enkilab" render={(props) => <KingEdition {...props} />} />
          <Route exact path="/demo" render={(props) => <NaturalLanguageForm {...props} />} />
          <Route exact path="/reports/:area?/:vertical?" render={(props) => <TryDemo {...props} />} />
          <Route path='/link/:link' component={(props) => {
            const linkBase64 = props.match.params.link;
            const link = Buffer.from(linkBase64, "base64").toString("utf8");
            window.location.href = link;
            return null;
          }}/>
          <Route render={(props) => <NotFound {...props} />} />
        </Switch>
      </div>
    </Router>
  );
};

const mapStateToProps = (store) => {
  const { userReducers } = store;
  const { mobileNavigationReducers } = userReducers;
  return {
    mobileNavigationStatus: mobileNavigationReducers.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeRoute: (action) => dispatch(onChangeRoute(action)),
  mobileNavigation: () => dispatch(mobileNavigation(false)),
  setReportListType: (type) => dispatch(setReportListType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
