import monthNames from "commons/DateValues";

export class DateUtils {
  getFormattedDate(dateString) {
    const date = dateString ? new Date(dateString) : new Date();
    return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  }
}

export default DateUtils;
