import React, { Component } from 'react';
import CircleCheckbox from 'components/form/CircleCheckbox/CircleCheckbox';
import StringUtils from 'utils/StringUtils';
import { Image } from 'components/Image/Image';
import CompanyTableInsights from '../CompanyTableInsights/CompanyTableInsights';
import './CompanyCheckBox.scss';

export class CompanyCheckBox extends Component {
  constructor(props) {
    super(props);
    this.mouseEventTimeOut = null;
    this.tableInsightsRef = null;
    this.state = {
      companyCheckBox: {
        id: 'company_' + props.name,
        text: '',
        value: false,
        color: 'blue',
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { selected = false } = nextProps;
    const { companyCheckBox } = this.state;
    this.setState({ companyCheckBox: { ...companyCheckBox, value: selected } });
  }

  onCheckBoxChange = (e) => {
    this.props.onSelectChange(e);
  }

  onMouseEnter = () => {
    this.mouseEventTimeOut = setTimeout(() => {
      this.tableInsightsRef.onLoadInsights();
    }, 500);
  }

  onMouseLeave = () => {
    this.tableInsightsRef.setDeActive();
    clearTimeout(this.mouseEventTimeOut);
  }

  render() {
    const { name, company_name: companyName, location, website_url: webSiteUrl } = this.props;
    const { companyCheckBox } = this.state;
    return (
      <div className="company-check-box" onMouseLeave={this.onMouseLeave}>
        <CircleCheckbox {...companyCheckBox} handleChange={this.onCheckBoxChange} />
        <Image className="company-check-box__image" src={new StringUtils().getCompanyLogo(name)} />
        <div className="company-check-box__head" onMouseEnter={this.onMouseEnter}>
          { webSiteUrl ? (
            <a href={webSiteUrl} target="_blank" rel="noreferrer" className="company-check-box__head__title">
              { companyName }
              <i className="material-icons">open_in_new</i>
            </a>
          ) : (
            <div className="company-check-box__head__title">
              { companyName }
            </div>
          )}
          <CompanyTableInsights name={name} webSiteUrl={webSiteUrl} ref={(ref) => { this.tableInsightsRef = ref; }} />
        </div>
      </div>
    );
  }
}

export default CompanyCheckBox;
