import React, { Component } from 'react';
import StringUtils from 'utils/StringUtils';
import Image from 'components/Image/Image';
import './CompanyTableInsightsItem.scss';

export class CompanyTableJobsItem extends Component {
  render() {
    const { article = {} } = this.props;
    const { title : title, via : publisher, url : link } = article;
    return (
      <a className="company-table-insights-item" href={link} target="_blank" title={title}>
        <div className="company-table-insights-item__image">
          <Image src={new StringUtils().getCompanyLogo()} />
        </div>
        <div className="company-table-insights-item__title">
          { title }
        </div>
        <div className="company-table-insights-item__text">
          { link }
        </div>
      </a>
    )
  }
}

export default CompanyTableJobsItem;
