import React from 'react';
import CircleButton from 'components/form/CircleButton';
import Papa from 'papaparse';
import FileService from 'services/FileService';

function getCsvData(attributeSummary) {
  const pick = ({ name, value }) => ({ attribute: name, summary: value });
  let attributeSummaryMap = [];

  Object.keys(attributeSummary).forEach((key) => {
    attributeSummaryMap = [...attributeSummaryMap, { name: key, value: attributeSummary[key][0] }];
  });

  const csv = Papa.unparse(attributeSummaryMap.map(pick));

  if (csv) {
    return `\ufeff${csv}`;
  }

  return '';
}

export default class ExportAttributeSummaryButton extends React.Component {
  handleButtonClick() {
    const { attributeSummary } = this.props;
    const csvContent = getCsvData(attributeSummary);
    new FileService().downloadFileContent(document, csvContent, 'attribute_summary.csv');
  }

  render() {
    const { attributeSummary } = this.props;

    if (!attributeSummary) {
      return null;
    }

    return (
      <div className="margin-l-10">
        <CircleButton color="blue-2" icon="attachment" title="Export Attribute Summary" handleClick={() => { this.handleButtonClick(); }} />
      </div>
    );
  }
}
