import UserDataProvider from 'providers/user/UserDataProvider';

export const SET_CURRENT_USER_DATA = 'SET_CURRENT_USER_DATA';
export const setCurrentUserData = currentUserData=> ({
  type: SET_CURRENT_USER_DATA,
  currentUserData: currentUserData
})

export const CURRENT_USER_DATA_REQUEST_POSTS = 'CURRENT_USER_DATA_REQUEST_POSTS';
export const requestUserNLPData = (from) => {
  return {
    type: CURRENT_USER_DATA_REQUEST_POSTS,
    from
  }
}

export const RECEIVE_CURRENT_USER_DATA = 'RECEIVE_CURRENT_USER_DATA';
export const receiveCurrentUserData = (json) => {
  return {
    type: RECEIVE_CURRENT_USER_DATA,
    currentUserData: json,
  }
}

export function fetchCurrentUserData() {
  return (dispatch) => {
    new UserDataProvider().getCurrentUsersData().then((data) => {
      dispatch(receiveCurrentUserData(data));
    });
  }
}
