import React from 'react';
import { parseGQueryResults, randomId, setParams, getParams } from './util.jsx';
import { StickyNote } from './stickyNote';
import Insights from './heatmap.insight';
import Overview from './overview';
import Ontology from './ontology';
import AttributeSelector from './selector.attributes';
import { Icon } from 'watson-react-components/dist/components';

class KingEdition extends React.Component {
  constructor(props) {
    super(props);
    this.eventSource = {
      current: null,
      setEventSource: (eventSource) => this.eventSource.current = eventSource
    };
    this.state = {
      loading: false,
      data: [],
      error: null,
      result: null,
      techHeatmap: {},
      marketHeatmap: {}
    }
  }

  updateURL = (key, value) => {
    setParams(key, value, this.props.location, this.props.history);
  };

  onKeyDown(event) {
    if (event.keyCode == 13) {
      if (this.state.company) {
        this.refresh();
      }
    }
  }

  onOverviewChange = (event) => {
    this.setState({
      overview: event.target.value
    });
  }

  onAttributeChange = (value) => {
    console.log(value);
    
    this.setState({
      attributes: value,
      showReport: false
    });
  }

  onInputChange = (name, event) => {
    let value = event.target.value.toUpperCase();
  
    if (value != null) {
      if (value.trim() == '') {
        value = null;
      }

      let attributes = this.state.attributes;

      if (value && !attributes && name === 'area') {
        const normalizedArea = value.trim();
        if (Ontology[normalizedArea]) {
          attributes = Ontology[normalizedArea].map(val => ({
            label: val,
            value: val
          }));
        }
      }

      this.setState({
        [name]: value,
        attributes,
        showReport: false
      });
    }
  }

  refresh = () => {
    const normalizedCompany = this.state.company && this.state.company.trim();
    const companies = [{ [normalizedCompany]: [normalizedCompany] }];

    const normalizedArea = this.state.area && this.state.area.trim();
    const areas = normalizedArea && [{ [normalizedArea]: [normalizedArea] }] || [];

    const attributes = this.state.attributes && this.state.attributes.map(attribute => attribute.value) || [];

    let marketHeatmap = {
      companies,
      areas,
      attributes
    };

    // set loading state
    this.setState({
      showReport: true,
      marketHeatmap
    });

    const query = {
      companies,
      areas,
      attributes,
      queryType: 'people'
    }

    this.loadGData(query);
  }

  loadGData(query) {
    // set loading state
    this.setState({
      loading: true,
    });

    if (this.eventSource && this.eventSource.current) {
      this.eventSource.current.close();
    }

    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const endpoint = this.props.source === 'googlenews' ? 'gnews' : 'gsearchV2';
    const body = JSON.stringify(query);
    fetch(`${host}/api/${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          console.log(json);
          const normalizedCompany = this.state.company && this.state.company.trim();
          const normalizedArea = this.state.area && this.state.area.trim();
          const subAreas = this.state.attributes && this.state.attributes.length > 0 ? this.state.attributes.map(a => a.value) : [normalizedArea];
          const debug = getParams(this.props.location).has('debug');
          
          let results = parseGQueryResults([{}], json, this.props.source, body, debug);
          this.setState({
            loading: false,
            insights: {
              query: query,
              sentences: results[0].result,
              company: normalizedCompany,
              area: normalizedArea,
              showTitle: true,
              showHeatmap: !!normalizedArea && results[0].done,
              subCompanies: [normalizedCompany],
              subAreas: subAreas,
              source: this.props.source
            }
          });
          if (this.props.source === 'gs' && !json[0].metadata.done) {
            const eventSource = new EventSource(
              `${host}/api/${endpoint}/sse?key=${body}`
            );
            eventSource.onmessage = e => {
              let results = parseGQueryResults([{}], JSON.parse(e.data), this.props.source, body, debug);
              this.setState({
                loading: false,
                insights: {
                  query: query,
                  sentences: results[0].result,
                  company: normalizedCompany,
                  area: normalizedArea,
                  showTitle: true,
                  showHeatmap: !!normalizedArea && results[0].done,
                  subCompanies: [normalizedCompany],
                  subAreas: subAreas,
                  source: this.props.source
                }
              });
            };
            eventSource.onerror = e => {
              eventSource.close();
            }
            this.eventSource.setEventSource(eventSource);
          }
        });
      } else {
        response.text().then((err) => {
          this.setState({ error: JSON.parse(err).error, loading: false });
          alert(JSON.parse(err).error);
        }).catch(() => {
          this.setState({
            error: 'There was a problem with the request, please try again',
            loading: false,
          });
        });
      }
    }).catch((err) => {
      alert(err);
    });
  }

  saveReport = () => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const newId = randomId(8);
    const { currentDashboard, company, area, attributes, techHeatmapState, marketHeatmapState, overview } = this.state;

    fetch(`${host}/api/saveReport`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: newId,
        source: currentDashboard,
        company: company,
        area: area,
        attributes: attributes,
        overview: overview,
        techHeatmap: {
          areas: techHeatmapState && techHeatmapState.areas,
          companies: techHeatmapState && techHeatmapState.companies
        },
        marketHeatmap: {
          areas: marketHeatmapState && marketHeatmapState.areas,
          companies: marketHeatmapState && marketHeatmapState.companies
        }
      }),
    }).then((response) => {
      this.updateURL('report', newId);
    });
  }

  loadReport = (id) => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    let url = `${host}/api/loadReport`;
    url += `?name=${id}`;

    fetch(url, {
      method: 'GET'
    }).then((response) => {
      if (response.ok) {
        response.json().then((storedData) => {
          this.setState({
            currentDashboard: storedData.source,
            company: storedData.company,
            area: storedData.area,
            attributes: storedData.attributes,
            techHeatmap: storedData.techHeatmap,
            marketHeatmap: storedData.marketHeatmap,
            overview: storedData.overview
          });
          this.refresh();
        });
      } else {
        response.text().then((err) => {
          this.setState({ error: JSON.parse(err).error, loading: false });
          alert(JSON.parse(err).error);
        }).catch(() => {
          this.setState({
            error: 'There was a problem with the request, please try again',
            loading: false,
          });
        });
      }
    });
  }

  componentDidMount() {
    const params = getParams(this.props.location);
    const report = params.has('report') && params.get('report');
    if (report) {
      this.loadReport(report);
    }
  }

  render() {
    const debug = getParams(this.props.location).has('debug');

    return (
      <div>
        <StickyNote/>
        <div className="page-header">
          <h1 className="page-title">Enki</h1>
          <button className="share-link" onClick={this.saveReport} disabled={!this.state.company}>
            <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#000000" width="16" height="16" class="icon jumbotron--get-started-icon icon icon_small"><path d="M14.9,1.1C14.2,0.4,13.2,0,12.1,0c-1.1,0-2,0.4-2.8,1.1L7.7,2.8C7,3.5,6.5,4.5,6.5,5.6 c0,0.4,0.1,0.8,0.2,1.2C6.4,6.6,6,6.5,5.6,6.5c-1.1,0-2,0.4-2.8,1.1L1.1,9.4C0.4,10.1,0,11,0,12.1c0,1.1,0.4,2.1,1.1,2.8 C1.8,15.6,2.8,16,3.9,16c1.1,0,2-0.4,2.8-1.1l1.7-1.7c0.7-0.7,1.1-1.7,1.1-2.8c0-0.4-0.1-0.8-0.2-1.2c0.4,0.1,0.8,0.2,1.2,0.2 c1.1,0,2-0.4,2.8-1.1l1.7-1.7C15.6,5.9,16,5,16,3.9C16,2.8,15.6,1.8,14.9,1.1z M7.3,12.2l-1.7,1.7c-0.5,0.5-1.1,0.7-1.7,0.7 c-0.7,0-1.3-0.3-1.8-0.7c-0.4-0.4-0.7-1.1-0.7-1.8c0-0.7,0.3-1.3,0.7-1.7l1.7-1.7C4.3,8.3,4.9,8,5.6,8C6,8,6.3,8.1,6.7,8.3l-2.1,2.1 l1,1l2.1-2.1C7.9,9.7,8,10,8,10.4C8,11.1,7.7,11.7,7.3,12.2z M13.8,5.6l-1.7,1.7C11.7,7.7,11.1,8,10.4,8C10,8,9.6,7.9,9.3,7.7 l2.1-2.1l-1-1L8.3,6.7C8.1,6.4,8,6,8,5.6c0-0.7,0.3-1.3,0.7-1.7l1.7-1.7c0.5-0.5,1.1-0.7,1.7-0.7c0.7,0,1.3,0.3,1.7,0.7 s0.7,1.1,0.7,1.7C14.5,4.5,14.3,5.2,13.8,5.6z"></path></svg></span>
          </button>
        </div>
        <div className="search-container">
          <div className="tool-config">
            <h2>Company / Market</h2>
            <input className="text-input" type="text" placeholder="e.g. Chevron, Shell, or Oil and gas" value={this.state.company} onKeyDown={this.onKeyDown.bind(this)} onChange={this.onInputChange.bind(this, 'company')}/>
          </div>
          <div className="tool-config">
            <h2>Area</h2>
            <input className="text-input" type="text" placeholder="e.g. Technology, IoT, or IT" value={this.state.area} onKeyDown={this.onKeyDown.bind(this)} onChange={this.onInputChange.bind(this, 'area')}/>
          </div>
          <div className="tool-config">
            <h2>Attributes</h2>
            <div className="attribute-input">
              <AttributeSelector onChange={this.onAttributeChange} value={this.state.attributes} />
            </div>
          </div>
          <div className="action-bar">
            <button className="add-button base--button_fill" disabled={!this.state.company} onClick={() => this.refresh()}>Get Insight</button>
          </div>
        </div>
        <div className="tool-container">
          {this.state.showReport && <div className="report-heatmap">
            {this.state.loading && <div className="loader--container"><Icon type="loader" size="large" /></div>}
            {!this.state.loading && this.state.insights && <Insights overview={<Overview isEdit={debug} overview={this.state.overview} onChange={this.onOverviewChange}/>} eventSource={this.eventSource} {...this.state.insights} onInsight={(insights) => insights && this.setState({ insights: { ...this.state.insights, company: insights.company, area: insights.area, sentences: insights.sentences } })}/>}
          </div>}
        </div>
      </div>
    );
  }
}

export default KingEdition;
