import React, { Component } from 'react';
import { CompanyInformationHead } from './CompanyInformationHead';
import { CompanyInformationBody } from './CompanyInformationBody';
import CompanyInformationFooter from './CompanyInformationFooter';
import './CompanyInformationCard';


export class CompanyInformationCard extends Component {

  onReportHandle = () => {
    this.props.onReportHandle({});
  }

  render() {
    const { companyData = {} } = this.props; 
    return (
      <div className="company-information cursor--default">
        <CompanyInformationHead companyData={companyData} />
        <CompanyInformationBody companyData={companyData} />
        <CompanyInformationFooter onReportHandle={this.onReportHandle} />
      </div>
    )
  }
}

export default CompanyInformationCard;
