import React, { Component } from 'react'
import FloatingInputComponent from 'components/FloatingInput/FloatingInput'
import './ForgotPasswordForm.scss'
import { HttpService } from 'services/HttpService';
import { TokenService } from 'services/TokenService';
import MessageBox from 'components/MessageBox/MessageBox';
import CircleLoader from 'components/CircleLoader/CircleLoader';

export class ForgotPasswordFormComponent extends Component {

  messageBox = {
    title: "Login Failed",
    message: "Check your Email and enter the verification code to reset your Password.",
    color: "danger",
    icon: "error"
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onSuccessRequest = this.onSuccessRequest.bind(this);
    this.onMessageClose = this.onMessageClose.bind(this);
    this.state = { 
      email: '', 
      loaderIsActive: false,
      showMessageBox: false
    };
  }

  componentDidMount() {
    this.setState({ email: this.props.email });
  }

  onEmailChange(email) {
    this.setState({email: email})
  }

  onMessageClose(event) {
    this.setState({ showMessageBox: false });
  }

  handleSubmit(event) {
    this.setState({loaderIsActive: true})
    this.messageBox.isActive = false;
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      "email": this.state.email,
    };
    
    new HttpService().sendRequest(`${host}/api/users/forgetPassword`,{
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((result) => {
      console.log("Got result");
      this.messageBox.message = result.response;

      if (result.res) {
        this.messageBox.title = 'Request Successful';
        this.messageBox.color = 'primary';
      } else {
        this.messageBox.title = 'Request Failed';
        this.messageBox.color = 'danger';
      }

      this.setState({ loaderIsActive: false, showMessageBox: true });
    })
    .catch((err) => {
      this.messageBox.title = 'Request Failed';
      this.messageBox.message = err;
      this.messageBox.color = 'danger';
      this.setState({ loaderIsActive: false, showMessageBox: true });
    });
    event.preventDefault();
  }

  onSuccessRequest(result) {
    const tokenService = new TokenService();
    tokenService.saveToken(result.token);

    this.props.onSubmit({ result: 'success' });
  }

  render() {
    return (
      <div>
        <form className="o-forgot-password-form" onSubmit={this.handleSubmit}>
          <div className="o-forgot-password-form__title">
            Forgot Password ?
          </div>
          <div className="o-forgot-password-form__info-text margin-t-10 margin-b-40">
            We'll send a reset link to your e-mail. Please type your e-mail address below.
          </div>
          <FloatingInputComponent title="Email Address" type="email" iconName="mail" value={this.state.email} onValueChange={this.onEmailChange} />
          { 
            !this.state.loaderIsActive ?
            <button className="a-button a-button--primary a-button--full-width" type="submit" value="Submit">
              Send
            </button>
            : <CircleLoader isActive={this.state.loaderIsActive} />
          }
          {
            this.state.showMessageBox ?
            <MessageBox {...this.messageBox} onClose={this.onMessageClose} />
            : ''
          }
        </form>
      </div>
    )
  }
}

export default ForgotPasswordFormComponent
