import React from 'react';
import CircleButton from 'components/form/CircleButton';
import Papa from 'papaparse';
import FileService from 'services/FileService';

function getCsvData(metadata, company) {
  const pick = ({ name, title, linkedin, location, about, matchedAttributes, corporatePhone, email, seniority, current_experience }) => ({ company, name, headline: title, title: current_experience && current_experience.user_experience_title, seniority, linkedin, location, about, matchedAttributes, corporatePhone, email });
  let attributePeopleData = [];

  Object.values(metadata.attributePeople).forEach(value => {
    attributePeopleData = [...attributePeopleData, ...value];
  });

  const csv = Papa.unparse(attributePeopleData.map(pick));

  if (csv) {
    return '\ufeff' + csv;
  }

  return '';
}

export default class ExportPeopleButton extends React.Component {
  handleButtonClick() {
    const { metadata, company } = this.props;
    const csvContent = getCsvData(metadata, company);
    new FileService().downloadFileContent(document, csvContent, 'top_prospects.csv');
  }

  render() {
    return (
      <div className="export-people-button">
        <CircleButton color="blue-2" icon="attachment" title="Export Top Prospects" handleClick={this.handleButtonClick.bind(this)}/>
      </div>
    );
  }
}
