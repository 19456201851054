import React, { Component } from 'react';
import { connect } from 'react-redux';
import Articles from 'Articles.jsx';
import SimpleWordle from 'wordle.simple.jsx';
import ScrollableTabNavigation from 'components/Navigations/TabNavigations/ScrollableTabNavigation/ScrollableTabNavigation';
import { PrioritizeLinkedInButton } from 'util.jsx';
import defaultSections from 'commons/sections';
import ExportPeopleButton from 'exportPeopleButton.jsx';
import { ReceivedRequestCard } from 'components/User/Reports/ReceivedRequestCard/ReceivedRequestCard';
import { onUpdateSectionNames, setSectionList } from 'store/actions/reports/report';
import HeatmapView from 'components/User/Reports/HeatmapView/HeatmapView';
import AttributePeopleGroup from '../Attributes/AttributePeopleGroup/AttributePeopleGroup';
import ExportAttributeSummaryButton from 'components/Commons/ExportAttributeSummaryButton/ExportAttributeSummaryButton';
import ExportFavoritesButton from 'components/Commons/ExportFavoritesButton/ExportFavoritesButton';
import './InsightsView.scss';

export class InsightsView extends Component {
  constructor(props) {
    super(props);
    const { sentences, subAreas = [] } = props;
    const { metadata } = sentences.props;
    this.state = {
      ...(metadata && { metadata }),
      search: null,
      loading: false,
      sections: [],
      subAreas,
      didHeatmapChange: false,
    };

    this.titles = {
      "Executive insights": { title: "Executive insights", text: "Enki discovers what your target account's C-suite say about your solution by searching the entire Web. What Executives say about your \"Solution\" will be critical when you craft a personalized message, or when you present your solution. Remember that the first impression matters." },
      "Top prospects": { title: "Top prospects", text: "Enki categorizes Top Prospects by matching their titles, job histories, responsibilities with your Solution Attributes. Unlike \"Key Decision Makers\",  Top Prospects may not have a narrative about your solution. However, these are the most likely people who may be interested in your solution. You can personalize your message with \"Pain Point\", \"Strategic Initiatives\" when you reach out them. Once requested, Enki also captures their contact information and with one click you can integrate with your CRM.  " },
      "Partnerships": { title: "Partnerships", text: "Enki finds the recent partnership of your target account that are relevant to the Solution Attributes you defined. Partnership will help you to understand recent initiatives of your target company and tailor your message and positioning accordingly.  "},
      "Investment and acquisitions": { title: "Investment and acquisitions", text: "Investment and acquisitions indicate how your target company spends its financial resources. It can also capture recent fund raising, and M&A activities. " },
      "Strategic initiatives": { title: "Strategic initiatives", text: "Enki discovers your Target Account's \"Strategic Initiatives\" based on your \"Solution Attribute\". Strategic Initiatives reveal how your \"Target Company\" plans to implement or use your solution. You can use this section to personalize your deck or message.  "},
      "Challenges and solutions": { title: "Challenges and solutions", text: `Enki is able to discover your target account's challenges, issues, solutions based on what's published on the web. You can compare various Target Company's "Pain Points" to personalize your message, your deck and provide insights to your prospects. "Challenges and solutions" position a Sales rep as industry expert by providing just enough information about their Target Companies and Industries.` },
      "Executive moves": { title: "Executive moves", text: `Enki summarizes the recent executive hires and departures. The department shake ups provide opportunities to sell new solutions. Since the new executives don't have established traditions in their new company and are looking to prove their value quickly they're more open to fresh ideas than most business leaders. It's a perfect time for your pitch.` },
      "Market outlook": { title: "Market outlook", text: `Enki summarizes the financial news of the target company and vertical. Market outlook gives a high level understanding of the company's market share, spend, and competitor activities.`},
      "Competitors": { title: "Competitor analysis", text: `Enki discovers whether any of your competitors may have a relationship or have a mention with your Target Company or attributes. The competitor section provides you Insights on positioning of your solution and emphasize on your differentiation.`},
      "Hiring": { title: "Hiring initiatives", text: `Enki captures your Target Company's job posts as well as new hires based on your solution attributes. Growth in your target departments indicates a demand for solution. Massive layoffs or shift in hiring could be a trigger event.`},
      "Recent wins": { title: "Recent wins", text: `Enki discovers the recent wins of your Target Accounts based on your Solution. These recent wins could be customer announcements, winning a new tender or a JV. Recent Wins about your Solution Attributes indicate new cash flow, and spending subsequently. ` },
      "Market thought leaders": { title: "Market thought leaders ", text: `"Market Thought Leaders" will make you an industry insider and open new doors for you. When you enter a "Target Company" with your "Solution Attributes", Enki captures industry players that talk about your "Target Companies". This will give you an opportunity to understand what other players in the same industry think and say about your solution. Besides, this insight helps you target new companies and find your ideal customers.` },
      "HeatMap": { title: "Heatmap (All Sections) ", text: `"Heatmap" provides trendy topics that your "Target Company" talks about. Tailoring your value proposition to "Heat Map" topics could increase conversation rate. Comparing various "Heat Map" within the peers could give you an idea of which "Solution Attributes" are more important for certain companies.` },
      "Favorites": { title: "Favorites", text: `Favorites is a simple and fast way to bring your team up to speed. When you mark an "Insight", as "Relevant", Enki will copy them under "Favorites" so that you can share with your team before a sales meeting to bring everybody up to speed.` },
      "Opportunities": { title: "Opportunities", text: "Companies are very generous about sharing the good news such as expansion, recent wins, new office buildings, and hiring activities. Enki captures these insights related to your solution attributes and provides the right timing to reach out to your prospects / customers. This could be an up sell or cross sell or an introduction opportunity for you." },
      "Growth": { title: "Growth", text: "Companies are very generous about sharing the good news such as expansion, recent wins, new office buildings, and hiring activities. Enki captures these insights related to your solution attributes and provides the right timing to reach out to your prospects / customers. This could be an up sell or cross sell or an introduction opportunity for you." },
      "Generic": { title: 'Other insights', text: 'The other insights section contains info that does not fit in existing Enki sections.' },
      "Key decision makers": { title: "Key decision makers", text: "Anyone from your Target company who speaks about your Solution Attributes are grouped under Key Decision Makers. Enki finds Key Decision Makers insight based on relevancy to the attributes and titles. Key decision makers could be the budget owners, influencers, or champions." }
    };
  }

  componentDidMount(props) {
    const { metadata } = this.state;
    let sections = defaultSections;
    if (metadata && metadata.done && metadata.processed !== 0) {
      sections = this.prepareSections(metadata);
      this.setState({ sections });
    }

    const sectionList = this.getSelectedSections(sections);
    this.props.setSectionList(sectionList);
  }

  componentWillReceiveProps(props) {
    const { filteredMetaData = [], filteredAreas = [], didFilterChanged = false } = props;
    if (didFilterChanged && filteredMetaData.length > 0) {
      const { metadata } = filteredMetaData[0];
      this.setState({ metadata, subAreas: filteredAreas }, () => {
        const sections = this.prepareSections(metadata);
        this.setState({ sections });
      });
    }
  }

  isDemoUser = () => {
    const { currentUserData = {} } = this.props;
    const { userType = 'demo' } = currentUserData;
    return (userType === 'demo');
  }

  getSelectedSections = (sectionList) => {
    const { insightFilter = {} } = this.props;
    if (Object.keys(insightFilter).length > 0) {
      const { sections = [] } = insightFilter;
      return sectionList.map(({ name, type }) => ({
        text: name.toUpperCase(),
        value: name,
        checked: sections.includes(type),
        type,
      }));
    }

    return sectionList.map(({ name, type }) => ({
      text: name.toUpperCase(),
      value: name,
      checked: true,
      type,
    }));
  }

  getTypeOfElement = (metadata, type, elementType) => {
    const { isEdit, source } = this.props;
    const elements = {
      people: <AttributePeopleGroup peopleGroups={metadata[type]} debug={isEdit} />,
      article: <Articles metadata={metadata} type={type} debug={isEdit} />,
      simplewordle: (
        <SimpleWordle data={metadata[type]} type={type} source={metadata.source || source} />
      ),
      heatmap: this.getHeatMapView(metadata),
    };
    return elements[elementType];
  }

  onHeatmapChanged = (heatmapSize) => {
    const { sections, didHeatmapChange = false } = this.state;
    const isActive = heatmapSize > 0;
    const index = sections.findIndex(({ name }) => name === 'Heatmap');
    if (index !== -1 && sections[index].isActive !== isActive) {
      sections[index].isActive = isActive;
    }

    this.setState({
      sections,
      ...(!didHeatmapChange) && { idHeatmapChange: true },
    });
  }

  mapSectionNames = (sectionName) => {
    const { metadata } = this.state;
    const {
      painPointV2 = {},
    } = metadata;
    const sectionNames = {
      'Heatmap': 'heatmapData',
      'Challenges and solutions': Object.keys(painPointV2).length > 0 ? 'painPointV2' : 'painPoints',
      'Favorites': 'relevance',
      'Competitors': 'competitors',
      'Key decision makers': 'quotes',
      'Executive insights': 'executiveQuotes',
      'Market thought leaders': 'externalQuotes',
      'Top prospects': 'attributePeople',
      'Partnerships': 'partnership',
      'Investments and acquisitions': 'investment',
      'Hiring': 'hiring',
      'Strategic initiatives': 'initiative',
      'Recent wins': 'win',
      'Opportunities': 'opportunity',
      'Growth': 'growth',
      'Executive moves': 'promotion',
      'Market outlook': 'market',
      Generic: 'generic',
    };

    return sectionNames[sectionName];
  }

  getHeatMapView = (metadata) => {
    const { area = '', company = '', source = '', query, subCompanies, id } = this.props;
    return (
      <HeatmapView
        key={company + area}
        type="insight"
        id={id}
        query={query}
        metadata={metadata}
        source={source}
        companies={[{ [company]: subCompanies }]}
        onHeatmapChanged={this.onHeatmapChanged}
      />
    );
  }

  getPainPoint = (metadata) => {
    const { painPoints = {}, painPointV2 = {} } = metadata;
    const showPainPoint = Object.keys(painPoints).length > 0 || Object.keys(painPointV2).length > 0;
    return {
      isActive: showPainPoint,
      isBlur: true,
      type: Object.keys(painPointV2).length > 0 ? 'painPointV2' : 'painPoints',
      name: 'Challenges and solutions',
      title: this.getTitleContent('Challenges and solutions'),
      child: <Articles debug={this.props.isEdit} metadata={metadata} type={Object.keys(painPointV2).length > 0 ? 'painPointV2' : 'painPoints'} />,
      icon: 'psychology',
    };
  }

  getTitleContent = (name, extraElements) => {
    const content = this.titles[name];
    return (
      <h4 className="title">
        { content.title }
        { extraElements }
        <span className="a-tooltip margin-l-10">
          <i className="material-icons">help</i>
          <span className="a-tooltip__content">
            <span className="a-tooltip__content__title">
              { content.title }
            </span>
            <div className="a-tooltip__content__text">
              { content.text }
            </div>
          </span>
        </span>
      </h4>
    );
  }

  updateSectionNames = (sections) => {
    if (this.state.sections.length > 0) {
      return;
    }

    const sectionNameList = sections.map(({ name, icon, title, type }) => ({
      name,
      icon,
      title,
      type,
    }));
    this.props.onUpdateSectionNames(sectionNameList);
  }

  prepareSections = (metadata) => {
    const { isEdit, filteredSections = [], company } = this.props;
    const types = [
      { isActive: true, isBlur: false, type: 'heatmapData', name: 'Heatmap', title: this.getTitleContent('HeatMap', <ExportAttributeSummaryButton attributeSummary={metadata.attributeSummary} />), elementType: 'heatmap', icon: 'insert_chart', forceShow: (filteredSections.length < 1 || filteredSections.includes('heatmapData')) },
      { isActive: true, isBlur: false, type: 'relevance', name: 'Favorites', title: this.getTitleContent('Favorites', <ExportFavoritesButton metadata={metadata} company={company} />), elementType: 'article', icon: 'grade' },
      { isActive: true, isBlur: false, type: 'competitors', name: 'Competitors', title: this.getTitleContent('Competitors'), elementType: 'article', icon: 'business' },
      { isActive: true, isBlur: false, type: 'executiveQuotes', name: 'Executive insights', title: this.getTitleContent('Executive insights'), elementType: 'article', icon: 'insights' },
      { isActive: true, isBlur: false, type: 'quotes', name: 'Key decision makers', title: this.getTitleContent('Key decision makers', <PrioritizeLinkedInButton metadata={metadata} />), elementType: 'article', icon: 'group' },
      { isActive: true, isBlur: false, type: 'externalQuotes', name: 'Market thought leaders', title: this.getTitleContent('Market thought leaders'), elementType: 'article' },
      { isActive: true, isBlur: false, type: 'attributePeople', name: 'Top prospects', title: this.getTitleContent('Top prospects', <ExportPeopleButton metadata={metadata} company={company} />), elementType: 'people', icon: 'search' },
      { isActive: true, isBlur: false, type: 'partnership', name: 'Partnerships', title: this.getTitleContent('Partnerships'), elementType: 'article', icon: 'group' },
      { isActive: true, isBlur: false, type: 'investment', name: 'Investments and acquisitions', title: this.getTitleContent('Investment and acquisitions'), elementType: 'article', icon: 'attach_money' },
      { isActive: true, isBlur: false, type: 'hiring', name: 'Hiring', title: this.getTitleContent('Hiring'), elementType: 'article', icon: 'person_add' },
      { isActive: true, isBlur: true, type: 'initiative', name: 'Strategic initiatives', title: this.getTitleContent('Strategic initiatives'), elementType: 'article', icon: 'emoji_objects' },
      this.getPainPoint(metadata),
      { isActive: true, isBlur: true, type: 'win', name: 'Recent wins', title: this.getTitleContent('Recent wins'), elementType: 'article', icon: 'emoji_events' },
      { isActive: true, isBlur: true, type: 'opportunity', name: 'Opportunities', title: this.getTitleContent('Opportunities'), elementType: 'article', icon: 'campaign' },
      { isActive: true, isBlur: true, type: 'growth', name: 'Growth', title: this.getTitleContent('Growth'), elementType: 'article', icon: 'trending_up' },
      { isActive: true, isBlur: true, type: 'promotion', name: 'Executive moves', title: this.getTitleContent('Executive moves'), elementType: 'article', icon: 'follow_the_signs' },
      { isActive: isEdit, isBlur: true, type: 'market', name: 'Market outlook', title: this.getTitleContent('Market outlook'), elementType: 'article', icon: 'store' },
      { isActive: isEdit, isBlur: true, type: 'other', name: 'Other insights', elementType: 'article', icon: 'store' },
      { isActive: true, isBlur: true, type: 'generic', name: 'Other', title: this.getTitleContent('Generic'), elementType: 'article', icon: 'emoji_objects', forceShow: false },
    ];

    let sections = [];
    types.forEach(({
      isActive, type, name, elementType, isBlur, title, icon, child, forceShow = false,
    }) => {
      if (
        (isActive && (metadata.hasOwnProperty(type) && Object.keys(metadata[type]).length > 0))
        || forceShow
      ) {
        sections = [...sections, {
          name,
          to: name.replace(/\s+/g, '_'),
          icon: icon || 'lens',
          child: child || this.getTypeOfElement(metadata, type, elementType),
          isBlur,
          title,
          type,
          contentType: this.mapSectionNames(name),
          isActive: (filteredSections.length < 1 || filteredSections.includes(type)),
        }];
      }
    });

    return sections;
  }

  renderNotifyContent() {
    if (this.isDemoUser()) {
      return <ReceivedRequestCard message="Enki is working hard to prepare your report." />;
    }

    return <ReceivedRequestCard />;
  }

  render() {
    const { sections, metadata } = this.state;
    const tabNavigationOptions = {};
    tabNavigationOptions.enableCopy = !this.isDemoUser();
    const { isInsightsLoading } = this.props;

    if (!metadata && isInsightsLoading) {
      return (
        <div className="insights-view" />
      );
    }

    return (
      <div className={`insights-view ${isInsightsLoading ? 'is-loading' : ''}`}>
        {
          (!metadata || (!metadata.done && metadata.processed === 0)) ? this.renderNotifyContent() :
          <ScrollableTabNavigation options={tabNavigationOptions} sections={sections} />
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { reportReducers } = store.reportReducers;
  const { userDataReducers } = rootReducers;
  const {
    filteredMetaData = {},
    insightFilter = {},
    isInsightsLoading = false,
  } = reportReducers;
  const { sections = [], didFilterChanged = false } = insightFilter;
  const { metadata = [], subAreas = [] } = filteredMetaData;
  return {
    currentUserData: userDataReducers.currentUserData,
    filteredMetaData: metadata,
    filteredAreas: subAreas,
    filteredSections: sections,
    didFilterChanged,
    isInsightsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSectionList: (sectionList) => dispatch(setSectionList(sectionList)),
  onUpdateSectionNames: (sections) => dispatch(onUpdateSectionNames(sections)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsView);
