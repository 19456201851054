import React, { Component } from 'react'
import { connect } from 'react-redux';
import './MessagePopup.scss';
import { updateMessagePopup } from 'store/actions/root/message-popup';

export class MessagePopup extends Component {

  constructor(props) {
    super(props)
    this.state = { modalClass: 'message-popup' };
  }

  componentWillReceiveProps(props) {
    const { isOpen, afterClose = 2000, text, title, icon, iconColor } = props.messagePopup;

    if(isOpen) {
      setTimeout(() => {
        this.props.updateMessagePopup({
          isOpen: false,
          text, title, icon, iconColor
        });
      }, afterClose);
      this.setState({ modalClass: 'message-popup open' });
    } else {
      this.setState({ modalClass: 'message-popup close' });
    }
  }

  render() {
    const { modalClass } = this.state;
    const { title, text, icon, iconColor } = this.props.messagePopup;
    return (
      <div className={modalClass}>
        <div className="message-popup__blur-effect"></div>
        <div className="message-popup__content">
          <div className="message-popup__content__icon" color={iconColor}>
            <i className="material-icons"> {icon} </i>
          </div>
          <div className="message-popup__content__title">
            { title }
          </div>
          <div className="message-popup__content__text">
            { text }
          </div>
        </div>        
      </div>
    )
  }
}

const mapStateToProps = function (store) {
  const { rootReducers } = store; 
  const { messagePopupReducers } = rootReducers;
  return {
    messagePopup: messagePopupReducers.messagePopup
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMessagePopup: (data) => dispatch(updateMessagePopup(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagePopup);
