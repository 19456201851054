import { 
  UPDATE_COMPANY_LIST, 
  CLEAR_COMPANY_LIST, 
  ADD_COMPANY_LIST,
  SET_REQUEST_STATUS,
  UPDATE_SELECTED_COMPANIES,
  ADD_ATTRIBUTES
} from "store/actions/user/companies";

const defaultState = {
  companyList: [],
  selectedCompanies: [],
  requestStatus: false,
  attributes: []
};

const companiesReducers = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_LIST:
      return Object.assign({}, state, {
        type: action.type,
        companyList: action.companyList,
      });
    case CLEAR_COMPANY_LIST:
      return Object.assign({}, state, {
        type: action.type,
        companyList: [],
      });
    case ADD_COMPANY_LIST:
      return Object.assign({}, state, {
        type: action.type,
        companyList: [...state.companyList, ...action.companyList],
      });
    case UPDATE_SELECTED_COMPANIES:
      return Object.assign({}, state, {
        type: action.type,
        selectedCompanies: action.selectedCompanies,
      });
    case SET_REQUEST_STATUS:
      return Object.assign({}, state, {
        type: action.type,
        requestStatus: action.status,
      });
    case ADD_ATTRIBUTES:
      return Object.assign({}, state, {
        type: action.type,
        attributes: action.attributes,
      });
    default:
      return state
  }
}

export default companiesReducers;
