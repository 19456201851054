export class ClipboardService {

  constructor(document) {
    this.document = document;
  }

  copyText = (text) => {
    var copyText = this.document.createElement('textarea');
    copyText.value = text;
    copyText.style.top = "0";
    copyText.style.left = "0";
    copyText.style.position = 'fixed';
    copyText.style.opacity = 0;
    this.document.body.appendChild(copyText);
    copyText.focus();
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    this.document.execCommand("copy");
    this.document.body.removeChild(copyText);
  }
}

export default ClipboardService;
