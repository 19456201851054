import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './MobileNavigationMenu.scss';

export class MobileNavigationMenu extends Component {
  render() {
    const { isActive, itemList } = this.props;
    const menuClass = isActive ? 'mobile-navigation-menu active' : 'mobile-navigation-menu';
    return (
      <div className={menuClass}>
        { itemList.map((item, i) => {
            return <Link to={item.path} key={i}>
              <div className="mobile-navigation-menu__item">
                <div className="mobile-navigation-menu__item__circle" color={item.color}>
                  <i className="material-icons">
                    { item.icon }
                  </i>
                </div>
                <div className="mobile-navigation-menu__item__text">
                  { item.name }
                </div>
              </div>
            </Link>
          })
        }
      </div>
    )
  }
}

export default MobileNavigationMenu;
