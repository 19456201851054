import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastView } from '../ToastView/ToastView';
import { randomId } from 'util.jsx';
import './ToastContent.scss';

export class ToastContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toastList: [],
    };
  }

  componentWillReceiveProps(props) {
    const { toastData } = props;
    if (toastData) {
      toastData.id = randomId(8);
      if (toastData.duration) {
        setTimeout(() => {
          this.clearToastItem(toastData.id);
        }, toastData.duration);
      }

      const { toastList } = this.state;
      this.setState({ toastList: [toastData, ...toastList] });
    }
  }

  clearToastItem(toastID) {
    let { toastList = [] } = this.state;
    toastList = toastList.filter(({ id}) => id !== toastID);
    this.setState({ toastList: toastList });
  }

  render() {
    const { toastList = [] } = this.state;

    return (
      <div className="toast-content tr">
        {
          toastList.map((toast, id) => {
            return <ToastView key={id} {...toast} />;
          })
        }
      </div>
    )
  }
}

const mapStateToProps = function (store) {
  const{ rootReducers } = store; 
  const { toastDataReducers } = rootReducers;
  return {
    toastData: toastDataReducers.toastData
  };
};

export default connect(mapStateToProps, null)(ToastContent);
