import { MOBILE_NAVIGATION } from 'store/actions/user/navigations';

const mobileNavigationReducers = (state = { status: false }, action) => {
  switch (action.type) {
    case MOBILE_NAVIGATION:
      return Object.assign({}, state, {
        type: action.type,
        status: action.status,
      });
    default:
      return state
  }
}

export default mobileNavigationReducers;
