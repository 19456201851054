import React from 'react';
import Heatmap from './heatmap.company';

export default class Insights extends React.Component {
  render() {
    const { query, overview, company, subCompanies, area, subAreas, sentences, filter, showHeatmap, showTitle, source, eventSource } = this.props;
    return (
      <div className="insight-box">
        {showTitle && <h3>{company} {area && '&'} {area}</h3>}
        <div className="results">
          {overview}
          {
            <div>
              <ul>
                {sentences && sentences.length === 0 && <li>No insights</li>}
                {sentences}
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}
