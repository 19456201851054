import { combineReducers } from 'redux'
import routeReducers from './route';
import userDataReducers from './user';
import authModalReducers from './auth-modal';
import messagePopupReducers from './message-popup';
import tooltipDataReducers from './tooltip';
import toastDataReducers from './toast';
import modalReducers from './modal';

export default combineReducers({
  userDataReducers,
  routeReducers,
  authModalReducers,
  messagePopupReducers,
  tooltipDataReducers,
  toastDataReducers,
  modalReducers
})
