import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import LinkedinContent from 'components/TooltipContents/LinkedinContent/LinkedinContent';
import CompanyContent from 'components/TooltipContents/CompanyContent/CompanyContent';
import { setTooltipHasFocus } from 'store/actions/root/tooltip';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import MessageContent from 'components/TooltipContents/MessageContent/MessageContent';
import { HttpService } from 'services/HttpService';
import './TooltipView.scss';

export class TooltipView extends Component {

  constructor(props) {
    super(props)
    this.calculatePositionTimeOut = undefined;
    this.onContentMouseLeaveTimeOut = undefined;
    this.state = {
      ref: undefined,
      left: 0,
      top: 0,
      parentScroll: 0,
      lastName: ''
    }
  }

  componentDidMount() {
    const ref = ReactDOM.findDOMNode(this);
    this.setState({ ref: ref });
    const observer = new MutationObserver((mutations) =>  {
      this.calculatePosition(this.props);
    });
    observer.observe(ref, {
      attributes: true
    });
  }

  componentWillReceiveProps(props) {
    this.calculatePosition(props);
  }

  calculatePosition = (props) => {
    clearTimeout(this.calculatePositionTimeOut);
     this.calculatePositionTimeOut = setTimeout(() => {
      const { tooltipData = {}, parentScroll = 0, tooltipHasFocus = false } = props;
      const { left, isActive, width } = tooltipData;
      let { top } = tooltipData;      
      const isVisible = tooltipHasFocus ? tooltipHasFocus:isActive;

      const lastParentScroll = this.state.parentScroll;
      if (lastParentScroll !== 0 && lastParentScroll !== parentScroll) {
        top -= (parentScroll - lastParentScroll);
      }
  
      const positionLeft = this.calculateLeftPosition(left);
      const positionTop = this.calculateTopPosition(top);
      const indicatorPosition = positionLeft.different > 0 ? (positionLeft.different + (width / 2)) : (width / 2);
      const indicatorDirection = positionTop.different > 0 ? 'bottom': '';
      this.setState({ left: positionLeft.left, indicatorPosition, top: positionTop.top, isVisible, indicatorDirection });
    }, 50);
  }

  calculateLeftPosition = (left) => {
    const { ref } = this.state;
    const { width } = ref.getBoundingClientRect();
    const windowWidth =  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const differentX = windowWidth - (left + width);

    if(differentX < 0) {
      return {
        left: (left + differentX),
        different: Math.abs(differentX)
      };
    }

    return {
       left: left, 
       different: 0
    };
  }

  calculateTopPosition = (top) => {
    const { ref } = this.state;
    const { height } = ref.getBoundingClientRect();
    const topOffset = window.pageYOffset || document.documentElement.scrollTop;
    const maxHeightViewport = (window.innerHeight + topOffset);
    const differentY = (maxHeightViewport - (top +  height));

    if(differentY < 0) {
      return {
        top: (top - (height + 60)),
        different: Math.abs(differentY)
      };
    }

    return {
       top: top + 10, 
       different: 0
    };
  }

  onTooltipError = (e) => {
    this.calculatePosition(this.props);
  }

  onTooltipReport = (e) => {
    this.props.setTooltipHasFocus(false);
    const name = this.getCurrentName();
    this.resetCompanyInformations(name);
    setTimeout(() => {
      this.props.updateMessagePopup({
        isOpen: true,
        title: 'Thank you',
        text: 'Your feedback is very important to us. We will check this data as soon as possible.',
        icon: 'check', 
        iconColor: "accent"
      });
    }, 250);
  }

  resetCompanyInformations = (name) => {
    const body = {
      "use_cache": false,
      "search_text":  name.toLowerCase()
    };
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    new HttpService().sendRequest(`${host}/api/reset/company_informations`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      window.tooltipDatas[name] = null
    });
  }
  
  onContentReady = (e) => {
    const { lastName } = this.state;
    const currentName = this.getCurrentName();
    if(lastName !== currentName) {
      this.setState({ lastName: currentName });
      this.calculatePosition(this.props);
    }
  }

  onStartContentLoading = (e) => {
    this.calculatePosition(this.props);
  }

  onContentMouseEnter = (e) => {
    clearTimeout(this.onContentMouseLeaveTimeOut);
    this.props.setTooltipHasFocus(true);
  }

  onContentMouseLeave = (e) => {
    clearTimeout(this.onContentMouseLeaveTimeOut);
    this.onContentMouseLeaveTimeOut = setTimeout(() => {
      this.props.setTooltipHasFocus(false);
    }, 50);
  }

  getCurrentName = () => {
    const { tooltipData = {} } = this.props;
    const { data = {}} = tooltipData;
    const { name = '' } = data;

    return name;
  }

  getEndPointType = () => {
    const { tooltipData = {} } = this.props;
    const { data = {}} = tooltipData;
    const { type = 'company' } = data;
    const types = {
      'company': 'companies',
      'linkedin': 'people'
    };

    return types[type];
  }

  onTooltipAction = (type) => {
    const actions = {
      'report': this.onTooltipReport,
      'irrelevant': this.setIrrelevantEntity
    }

    actions[type]();
  }

  setIrrelevantEntity = () => {
    const name = this.getCurrentName();
    const body = {
      type: this.getEndPointType(),
      entities: name
    };
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    
    new HttpService().sendRequest(`${host}/api/entities/forbidden`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      this.props.updateMessagePopup({
        isOpen: true,
        title: 'Thank you',
        text: 'Your feedback is very important to us. We will check this data as soon as possible.',
        icon: 'check', 
        iconColor: "accent"
      });
    });
  }

  getTooltipContent = ({ type, name, url, companyList }) => {
    const contents = {
      "company": <CompanyContent 
        name={name} 
        onTooltipError={this.onTooltipError} 
        onContentReady={this.onContentReady} 
        onTooltipAction={this.onTooltipAction}
        onStartContentLoading={this.onStartContentLoading} 
      />,
      "linkedin": <LinkedinContent 
        name={name} 
        url={url} 
        companyList={companyList} 
        onTooltipError={this.onTooltipError} 
        onContentReady={this.onContentReady}
        onTooltipAction={this.onTooltipAction}
        onStartContentLoading={this.onStartContentLoading}
        />,
      "message_content": <MessageContent />,
      "attribute": null
    }

    return contents[type]
  }

  render() {
    const { tooltipData = {} } = this.props;
    const { data = {}, isActive = false } = tooltipData;
    const { left, top, isVisible = false, indicatorPosition, indicatorDirection } = this.state;
    const { name, url, type = 'company', companyList = [] } = data;
    return (
      <div 
        className={ `tooltip-view ${ isVisible ? 'active':'' }` } style={{ left: left + 'px', top: top + 'px' }} 
        onMouseEnter={this.onContentMouseEnter}
        onMouseLeave={this.onContentMouseLeave}
        >
        <div className={ `tooltip-view__indicator ${indicatorDirection}`} style={ indicatorDirection == '' ? { left: indicatorPosition + 'px', top: 0 }: { left: indicatorPosition + 'px', bottom: 0 }}></div>
        { isActive ?
          this.getTooltipContent({name, url, type, companyList}) : ''
        }
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { tooltipDataReducers } = rootReducers;
  return {
    tooltipData: tooltipDataReducers.tooltipData,
    parentScroll: tooltipDataReducers.parentScroll
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTooltipHasFocus: (hasFocus) => dispatch(setTooltipHasFocus(hasFocus)),
    updateMessagePopup: (data) => dispatch(updateMessagePopup(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TooltipView);
