import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { CircleButton } from 'components/form/CircleButton';
import { onGenerateURL } from 'store/actions/root/route';
import { createBrowserHistory } from 'history';
import BlurComponent from 'components/BlurComponent/BlurComponent';
import './ScrollableTabNavigation.scss';

const colorList = [
  "aqua-green",
  "green",
  "green-dark",
  "purple",
  "red",
  "red-light",
  "orange",
  "blue",
  "blue-grey",
  "blue-2",
  "blue-3",
  "blue-dark",
  "gray",
  "light-green",
  "pink",
  "accent-blue",
  "accent-red",
  "accent-orange",
  "accent-green",
  "accent-green-2",
];
export class ScrollableTabNavigation extends Component {
  constructor(props) {
    super(props);
    const { sections } = props;
    const sectionNames = JSON.stringify(sections.map(({ name }) => name));
    this.state = {
      currentTabPath: '',
      ref: undefined,
      isStickyTab: false,
      currentActiveTabId: '',
      hideScrollLeftButton: false,
      hideScrollRightButton: false,
      sectionNames,
    };
    this.handleScrollTimeout = null;
  }

  getTabName = () => {
    const { href } = window.location;
    const lastIndex = href.lastIndexOf('#');
    if (lastIndex > -1) {
      const pathName = href.slice(lastIndex, href.length);
      return pathName;
    }

    return;
  }

  componentDidMount() {
    const ref = ReactDOM.findDOMNode(this);
    const tabWrapper = ref.querySelector('div.scrollable-tab-navigation__tab-wrapper');
    this.setState({ ref: ref });
    tabWrapper.addEventListener('scroll', this.handleTabWrapperScroll);
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      this.updateActiveTabIndicator();
      this.goCurrentSection();
    }, 200);
  }

  componentWillReceiveProps(props) {
    const { sections } = props;
    const { sectionNames } = this.state;
    const newSectionNames = JSON.stringify(sections.map(({name}) => name));
    if (sectionNames !== '[]' && sectionNames !== newSectionNames) {
      this.setState({ sectionNames: newSectionNames },() => {
        this.updateActiveTabIndicator();
        this.goCurrentSection();
      });
    } else {
      this.setState({ sectionNames: newSectionNames });
    }
  }

  componentWillUnmount() {
    const { ref } = this.state;
    const tabWrapper = ref.querySelector('div.scrollable-tab-navigation__tab-wrapper');
    tabWrapper.removeEventListener('scroll', this.handleTabWrapperScroll);
    window.removeEventListener('scroll', this.handleScroll)
  }

  getPathName = () => {
    const history = createBrowserHistory();
    const { location } = history;
    const { pathname = '' } = location;

    return pathname;
  }

  goCurrentSection = () => {
    const { ref } = this.state;
    const pathName = this.getTabName() || '';
    if (pathName.isNullOrEmpty() || pathName === '#') {
      return;
    }

    const currentSection = ref.querySelector(`${pathName}`) || null;
    if (!currentSection) {
      this.updatePageURL();
      return;
    }

    const { offsetTop = 0 } = currentSection;
    window.scrollTo({ top: (offsetTop + ref.offsetTop) - 60, behavior: 'smooth' });
  }

  handleTabWrapperScroll = () => {
    clearTimeout(this.handleScrollTimeout);
    this.handleScrollTimeout = setTimeout(() => {
      const { ref } = this.state;
      const tabWrapper = ref.querySelector('div.scrollable-tab-navigation__tab-wrapper');
      this.calculateTabWrapperScroll(tabWrapper);
    }, 50);
  }

  handleScroll = () => {
    const { ref, currentActiveTabId = '' } = this.state;
    const { scrollY } = window;
    const { offsetTop } = ref;
    const sections = ref.querySelectorAll('section:not(.hidden)');
    if (sections.length == 0)
      return
    const activeTabId = this.getActiveTabId(((scrollY) + 100), offsetTop);
    const isStickyTab = ((scrollY + 40) >= offsetTop);
    this.setState({ isStickyTab, currentActiveTabId: activeTabId });
    if (isStickyTab && activeTabId && currentActiveTabId !== activeTabId) {
      this.updateActiveTabIndicator(activeTabId);
      this.updatePageURL(activeTabId);
    }
  }

  getColorName = () => {
    const index = Math.floor(Math.random() * colorList.length);
    return colorList[index];
  }

  clearActiveTabs = () => {
    const { ref } = this.state;
    const tabs = ref.querySelectorAll('li.scrollable-tab-navigation__tabs__item');
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      tab.classList.remove('active');
    }
  }

  updatePageURL = (activeTabID = '') => {
    const history = createBrowserHistory();
    const { location } = history;
    const { pathname = '', search = '' } = location;
    history.push(`${pathname}${search}${!activeTabID.isNullOrEmpty() ? `#${activeTabID}`: ''}`);
  }

  getActiveTabElement = (activeTabId = '') => {
    const { ref } = this.state;
    const tabs = ref.querySelectorAll('li.scrollable-tab-navigation__tabs__item');

    if (activeTabId.isNullOrEmpty()) {
      return tabs[0];
    }

    return ref.querySelector(`#${activeTabId}__tab`);
  }

  copyURL = (target) => {
    this.updatePageURL(target);
    this.props.onGenerateURL(target);
  }

  updateActiveTabIndicator = (activeTabId = '') => {
    const { ref } = this.state;
    const tabIndicator = ref.querySelector('div.scrollable-tab-navigation__tab-indicator');
    const tabWrapper = ref.querySelector('div.scrollable-tab-navigation__tab-wrapper');
    this.clearActiveTabs();
    if (activeTabId.isNullOrEmpty()) {
      this.clearTabIndicator();
      this.calculateTabWrapperScroll(tabWrapper);
      return;
    }

    const activeTab = this.getActiveTabElement(activeTabId);
    const colorName = this.getColorName();
    const { clientWidth, offsetLeft } = activeTab;
    const shouldScroll = ((offsetLeft + clientWidth) > (tabWrapper.clientWidth + tabWrapper.scrollLeft)) || (tabWrapper.scrollLeft > offsetLeft);
    activeTab.classList.add('active');
    if (shouldScroll) {
      const scrollLeft = (offsetLeft + clientWidth) - tabWrapper.clientWidth;
      this.updateTabWrapperScrollByActiveTab(tabWrapper, scrollLeft);
    }

    tabIndicator.style.width = clientWidth + 'px';
    tabIndicator.style.left = offsetLeft + 'px';
    tabIndicator.setAttribute('color', colorName);
  }

  clearTabIndicator = () => {
    const { ref } = this.state;
    const tabIndicator = ref.querySelector('div.scrollable-tab-navigation__tab-indicator');
    tabIndicator.style.width = '0px';
    tabIndicator.style.left = '0px';
  }

  updateTabWrapperScrollByActiveTab = (tabWrapper, scrollLeft) => {
    const { scrollWidth, clientWidth } = tabWrapper;
    if (tabWrapper.scrollLeft > scrollLeft) {
      this.smootScrollLeft(tabWrapper, scrollLeft);
    } else {
      let nextScroll = scrollLeft + 40;
      if ((clientWidth + nextScroll) > scrollWidth) {
        scrollLeft = (scrollWidth - clientWidth);
      }

      this.smootScroll(tabWrapper, scrollLeft);
    }

    setTimeout(() => {
      this.calculateTabWrapperScroll(tabWrapper);
    }, 200);
  }

  calculateTabWrapperScroll = (tabWrapper) => {
    const { scrollWidth = 0, clientWidth = 0, scrollLeft = 0 } = tabWrapper;
    this.setState({ hideScrollLeftButton: (scrollLeft <= 25), hideScrollRightButton: ((clientWidth + scrollLeft + 40) >= scrollWidth) });
  }

  getActiveTabId = (scrollPosition, parentOffset) => {
    const { ref } = this.state;
    const sections = ref.querySelectorAll('section:not(.hidden)');
    let activeTab;
    if (scrollPosition > ref.scrollHeight) {
      const section = sections[sections.length - 1];
      const { id } = section;
      return id;
    }

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const { offsetTop, id } = section;

      if (scrollPosition > (offsetTop + parentOffset)) {
        activeTab = id;
      }
    }

    return activeTab;
  }

  onScrollButtonClick = (direction) => {
    const { ref } = this.state;
    const tabWrapper = ref.querySelector('div.scrollable-tab-navigation__tab-wrapper');
    const { scrollLeft, clientWidth } = tabWrapper;
    const scrollSize = ((clientWidth) / 100) * 25;
    switch (direction) {
      case 'right':
        this.smootScroll(tabWrapper, (scrollLeft + scrollSize));
        break;
      case 'left':
        this.smootScrollLeft(tabWrapper, (scrollLeft - scrollSize));
        break;
    }

    setTimeout(() => {
      this.calculateTabWrapperScroll(tabWrapper);
    }, 100);
  }

  smootScroll = (tabWrapper, targetPosition) => {
    const { scrollLeft, scrollWidth, clientWidth } = tabWrapper;
    if (targetPosition > scrollLeft) {
      setTimeout(() => {
        let nextScroll = scrollLeft + 30;
        if ((clientWidth + nextScroll) > scrollWidth) {
          targetPosition = nextScroll = scrollWidth - clientWidth;
        }

        tabWrapper.scrollLeft = nextScroll;
        if (tabWrapper.scrollLeft < nextScroll) {
          targetPosition = tabWrapper.scrollLeft;
        }

        this.smootScroll(tabWrapper, targetPosition);
      }, 10);
    }
  }

  smootScrollLeft = (tabWrapper, targetPosition) => {
    const { scrollLeft } = tabWrapper;
    if (scrollLeft > 0 && targetPosition < scrollLeft) {
      setTimeout(() => {
        let nextScroll = (scrollLeft - 30);
        if (nextScroll < 0 ) {
          targetPosition = nextScroll = 0;
        }

        tabWrapper.scrollLeft = nextScroll;
        this.smootScrollLeft(tabWrapper, targetPosition);
      }, 10);
    }
  }

  getBlurSections = (section) => {
    return (<BlurComponent level={5}
      options={
        {
          title: "Market Outlook, Initiatives, Pain Points, Growth, Opportunities. <br /> More insights to enable you swift judgement call. <br /> Sign up Today to unlock the full report!",
          contentPosition: "top",
          button: {
            text: "Create an account"
          }
        }
      }
      child={section}
    />)
  }

  render() {
    const { sections = [], currentUserData = {}, options = {} } = this.props;
    const { enableCopy = false } = options;
    const { isStickyTab, hideScrollLeftButton, hideScrollRightButton } = this.state;
    const { userType = '', hasSecretId = false } = currentUserData;
    return (
      <div className={`scrollable-tab-navigation position--relative`}>
        <div className={`scrollable-tab-navigation__tab-wrapper__button left ${ hideScrollLeftButton ? 'hidden': '' } ${userType === 'demo' ? 'margin-t-45': ''} ${isStickyTab ? 'active': ''}`} onClick={() => {this.onScrollButtonClick('left')}}>
          <i className="material-icons">chevron_left</i>
        </div>
        <div className={`scrollable-tab-navigation__tab-wrapper__button right ${ hideScrollRightButton ? 'hidden': ''} ${userType === 'demo' ? 'margin-t-45': ''} ${isStickyTab ? 'active': ''}`} onClick={() => {this.onScrollButtonClick('right')}}>
          <i className="material-icons">chevron_right</i>
        </div>
        <div className={`scrollable-tab-navigation__tab-wrapper ${userType === 'demo' ? 'margin-t-60': ''} ${isStickyTab ? 'on_sticky' : ''}`}>
          <ul className={`scrollable-tab-navigation__tabs`}>
            <div className={`scrollable-tab-navigation__tab-indicator`}></div>
            {
              sections.map(({ to, name, icon, isActive }, i) => {
                if (!isActive) { return; }
                return (<li className={`scrollable-tab-navigation__tabs__item`} id={`${to}__tab`} key={i}>
                  <a href={`#${to}`}>
                    <i className="material-icons"> { icon } </i>
                    { name }
                  </a>
                </li>)
              })
            }
          </ul>
        </div>
        <div className="scrollable-tab-navigation__sections">
          {
            sections
            .map(({ isBlur, title, name, to, child, isActive }, index) => {
              return <section className={`scrollable-tab-navigation__section ${!isActive ? 'hidden' : ''}`} id={to} key={index}>
                <div className="scrollable-tab-navigation__section__title">
                  { title ? title : name }
                  { enableCopy ?
                    <span className="margin-l-5 margin-t-0 display-inline-flex">
                      <CircleButton color="blue" icon="content_copy" title="Generate a shareable link to this section" handleClick={() => this.copyURL(to)}/>
                    </span>:''
                  }
                </div>
                {
                  !isBlur || (userType != 'demo' || hasSecretId) ?
                  child: this.getBlurSections(child)
                }
              </section>
            })
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { userDataReducers } = rootReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGenerateURL: (urlHash) => dispatch(onGenerateURL(urlHash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScrollableTabNavigation);
