import React, { Component } from 'react';
import { Image } from 'components/Image/Image';
import StringUtils from 'utils/StringUtils';
import './CompanyValueItem.scss';


export class CompanyValueItem extends Component {

  getValueContent = (item) => {
    const { name } = item;
    const contents = {
      "Parent organization": this.getCompanyContent,
      "Production output": this.getTextContent,
      "Headquarters": this.getTextContent,
      "Founded": this.getTextContent,
      "CEO": this.getTextContent,
      "Founders": this.getTextContent
    }

    const contentFunction = contents[name];
    return contentFunction && contentFunction(item) || this.getTextContent(item);
  }

  getCompanyContent = (item) => {
    const { value } = item;
    return (
      <div className="company-value-item__content">
        <div className="company-value-item__content__img">
          <Image src={new StringUtils().getCompanyLogo(value.toLowerCase())} alt={value} />
        </div>
        <div className="company-value-item__content__name">
          { value }
        </div>
      </div>
    )
  }

  getTextContent = (item) => {
    const { value } = item;
    return (
      <div className="company-value-item__content">
        <div className="company-value-item__content__text"> 
          { value }
        </div>
      </div>
    )
  }

  render() {
    const { item } = this.props;
    return (
      <div className="company-value-item">
        <div className="company-value-item__title">
          { item.name }
        </div>
        { this.getValueContent(item) }
      </div>
    )
  }
}

export default CompanyValueItem
