import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CardView from 'components/Views/CardView/CardView';
import startImage from 'assets/img/stats.svg';
import './ReceivedRequestCard.scss';

const DEFAULT_MESSAGE = 'Enki is working hard to prepare your report. <br /> We\'ll send you an email when it\'s ready.'
export class ReceivedRequestCard extends Component {
  render() {
    const { message = DEFAULT_MESSAGE } = this.props;
    return (
      <CardView classes="cursor--default" childComponent={
        <div className="received-request-card">
          <div className="received-request-card__image">
            <img src={startImage} />
          </div>
          <div className="received-request-card__content">
            <div className="received-request-card__content__title" dangerouslySetInnerHTML={{__html: message}} />
          </div>
        </div>
      } />
    )
  }
}

export default ReceivedRequestCard;
