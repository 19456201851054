import React, { Component } from 'react';
import ReactGA from 'react-ga';
 
export class StickyNote extends Component {
    constructor(props) {
      super(props);
      this.state = {
        notes: ''
      }
    }

    componentDidMount() {
        let previous = null;
        setInterval(() => {
            if (this.state.notes !== '' && this.state.notes !== previous) {
                ReactGA.event({
                    category: 'Note',
                    action: this.state.notes
                });
                previous = this.state.notes;
            }
        }, 5000);
    }

    onChange = (event) => {
        this.setState({
            notes: event.target.value
        });
    }

    render() {
        return (
            <div className='note-container'>
                <h4 className='note-title'>Notes</h4>
                <textarea className='note' placeholder='Add notes here...' value={this.state.notes} onChange={this.onChange.bind(this)}/>
                <div className='save-button-div'><button className="save-button base--button_fill">Submit</button></div>
            </div>
        );
    }
};