import React, { Component } from 'react';
import './AttributeViews.scss';

const attributeList = (list, max) => {
  let listMap = mapList(list, max);
  if (list.length > max) {
    listMap = [...listMap,
      <div className="attribute-views__item more--item" color="gray" key={ (max + 1) }>
        <div className="attribute-views__item__text">
          <span />
          <span />
          <span />
        </div>
      </div>,
    ];
  }

  return listMap;
};

const showEmptyItem = (emptyItem) => {
  const { color, text } = emptyItem;
  return (
    <div className="attribute-views__item" color={color}>
      <div className="attribute-views__item__text" dangerouslySetInnerHTML={{__html: text}} />
    </div>
  );
};

const mapList = (list, max) => {
  return list.map(({ text, color }, id) => {
    if (id < max) {
      return <div className="attribute-views__item" color={color} key={ id }>
        <div className="attribute-views__item__text" dangerouslySetInnerHTML={{__html: text}} />
      </div>
    }
  });
}

export class AttributeViews extends Component {
  render() {
    const { list = [], max, emptyItem, hideMoreButton = false } = this.props;
    if (list.length === 0 && emptyItem) {
      return showEmptyItem(emptyItem);
    }

    return (
      <div className={`attribute-views ${ hideMoreButton ? 'hide-more-button':'' }`}>
        {
          attributeList(list, max)
        }
      </div>
    );
  }
}

export default AttributeViews;
