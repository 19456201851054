import React, { Component } from 'react'
import BrandContent from '../BrandContent/BrandContent';
import LinkedinHeader from './LinkedinHeader';
import { HttpService } from 'services/HttpService';
import ToolTipContentLoader from 'components/Loaders/TooltipContentLoader';
import StringUtils from 'utils/StringUtils';
import { IconButton } from 'components/form/IconButton/IconButton';
import './LinkedinContent.scss';

const REQUEST_TIMEOUT = (10 * 1000);
const IRRELEVANT_ACTION_KEY = 'irrelevant';

export class LinkedinContent extends Component {

  constructor(props) {
    super(props);
    this.state = { userData: {}, contentNotFound: false }; 
  }

  componentWillMount() {
    const { url, name, companyList } = this.props;
    this.setState({ name: name, url: url, companyList: companyList });
    if(!this.checkData(url, name)) {
      this.loadUserData({ url, name, companyList});
    } else {
      this.onContentReady();
    }
  }

  checkData = (url, name) => {
    if (url && window.profileDatas[url]) {
      this.setState({ userData: window.profileDatas[url] });
      return true
    }
    
    if (name && window.profileDatas[name]) {
      this.setState({ userData: window.profileDatas[name] });
      return true
    }

    return false
  }

  getQuery = ({ url, name, companyList}) => {
    if (url) {
      return {
        "profile_url": url
      }
    }

    return {
      "name": name,
      "company_list": companyList
    }
  }

  updateState = (result, key) => {
    const { response, is_ready } = result;
    window.tooltipDatas[key] = {};
    this.setState({ companyData: {} });

    if(is_ready) {
      window.profileDatas[key] = response;
      this.setState({ userData: response });
      this.onContentReady();
    } else {
      this.onError();
    }
  }

  loadUserData = ({ url=null, name, companyList}) => {
    const body = this.getQuery({ url, name, companyList});
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    setTimeout(() => this.checkRequestStatus(), REQUEST_TIMEOUT);
    new HttpService().sendRequest(`${host}/api/get/member`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((result) => {
      this.updateState(result, url || name);
    });
  }

  checkRequestStatus = () => {
    const { userData = {} } = this.state;
    const { name } = userData;
    if(!name) {
      this.onError();
    }
  }

  onError = () => {
    this.setState({ contentNotFound: true });
    this.props.onTooltipError({});
  }

  onStartContentLoading = () => {
    this.props.onStartContentLoading({});
  }

  onContentReady = () => {
    setTimeout(() => {
      this.props.onContentReady({});
    }, 300);
  }

  onActionButtonHandle = (action) => {
    this.props.onTooltipAction(action);
  }

  getLinkedinContent = () => {
    const { userData = {} } = this.state;
    const { name, user_name, about, profile_image, headline, current_experience = {}, linkedin, profile_url  } = userData;
    const { user_experience_company = '', user_experience_title = '', user_experience_extra_details = '' } = current_experience || {};
    return (
      <div className="linkedin-content">
        <div className='linkedin-content__head'>
          <LinkedinHeader name={name || user_name} image={profile_image} head={user_experience_title ? user_experience_title : headline} />
          { user_experience_company ?
            <BrandContent name={user_experience_company} image={new StringUtils().getCompanyLogo(user_experience_company)} description={user_experience_title} />: ''
          } 
        </div>
        <div className='linkedin-content__body'>
          { about || user_experience_extra_details }
        </div>
        <div className='linkedin-content__foot'>
          <IconButton color="red-light" size="small" iconName="announcement" text="Report" title="Report invalid entity" value={IRRELEVANT_ACTION_KEY} handleClick={this.onActionButtonHandle} />
          <IconButton color="blue-2" size="small" iconName="visibility" text="Read More" title="Open Linkedin profile in a new tab" url={profile_url || linkedin} />
        </div>
      </div>
    )
  }

  render() {
    const { userData = {}, contentNotFound = false } = this.state;
    const { name, user_name } = userData;
    if (name || user_name) {
      return this.getLinkedinContent()
    } else if (!contentNotFound) {
      this.onStartContentLoading();
      return (<ToolTipContentLoader /> )
    }

    return (<div className="tooltip-view__text">
      Crawling user data. Will be available soon.
    </div>)
  }

}

export default LinkedinContent;
