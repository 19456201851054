import React, { Component } from 'react';
import CardView from 'components/Views/CardView/CardView';
import AttributeViews from 'components/Views/AttributeViews/AttributeViews';
import CircleButton from 'components/form/CircleButton';
import IconButton from 'components/form/IconButton/IconButton';
import Image from 'components/Image/Image';
import { updateModalData } from 'store/actions/root/modal';
import { pushToastMessage } from 'store/actions/root/toast';
import { connect } from 'react-redux';
import { deleteSingleCompanyItem } from 'store/actions/dashboard/UserNlp';
import ReportCardService from 'services/ComponentServices/ReportCardService';
import StringUtils from 'utils/StringUtils';
import './ReportCardView.scss';

export class ReportCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExportButtonLoading: false,
      isDeleteButtonLoading: false,
      isContactButtonLoading: false,
      contactDataRequested: props.contactDataRequested,
      isDeleted: false,
      isDestroyed: false,
    };
  }

  deleteReport = () => {
    const { _id } = this.props;
    this.setState({ isDeleteButtonLoading: true });
    new ReportCardService().deleteReport(_id).then((response) => {
      const { deleted } = response;
      this.setState({ isDeleted: deleted });
      setTimeout(() => {
        this.props.deleteSingleCompanyItem(_id);
        this.setState({ isDestroyed: deleted, isDeleteButtonLoading: false });
      }, 800);
    });
  }

  onContactRequestButtonClick = () => {
    const { _id } = this.props;
    this.setState({ isContactButtonLoading: true });
    new ReportCardService().sendContactDataRequest(_id, this.pushNotification).then((data) => {
      this.setState(data);
    });
  }

  pushNotification = (message) => {
    const toastData = {
      ...message,
      duration: 5000,
    };
    this.props.pushToastMessage(toastData);
  }

  onDeleteButtonClick = () => {
    this.props.updateModalData({
      isActive: true,
      title: 'Do you want to delete this report ?',
      text: 'Are you sure you want to delete this report ?',
      buttons: [
        {
          text: 'Cancel',
          icon: 'cancel',
          color: 'blue-r',
          onClick: () => {},
        },
        {
          text: 'Delete',
          icon: 'delete',
          color: 'red-light',
          onClick: () => this.deleteReport(),
        },
      ],
    });
  }

  onExportButtonClick = () => {
    this.setState({ isExportButtonLoading: true });
    const { company, company_id } = this.props;
    new ReportCardService().exportData({ company, company_id }).then(() => {
      this.setState({ isExportButtonLoading: false });
    });
  }

  onDateIconClick = () => { }

  render() {
    const {
      company,
      _id,
      finished = false,
      company_id: companyId,
      formattedStartDate,
      areaCopy,
      titlesCopy = [],
      filterCopy = [],
      highlightedText,
      isDemo,
      paramsText,
      hideDate,
    } = this.props;
    const reportCardClass = isDemo ? 'is-demo' : '';
    const params = paramsText ? `/?report=${_id || companyId}${paramsText}` : `/?report=${_id || companyId}`;
    const {
      isContactButtonLoading,
      isExportButtonLoading,
      isDeleteButtonLoading,
      isDeleted = false,
      isDestroyed = false,
      contactDataRequested = false,
    } = this.state;
    if (isDestroyed) {
      return null;
    }

    return (
        <CardView classes={`${ isDeleted ? 'deleted': ''}`} childComponent={
          <a href={params} target="_blank" >
            <div className={`report-card-view ${reportCardClass}`}>
              <div className="report-card-view__head">
                <div className="report-card-view__head__img">
                  <Image src={new StringUtils().getCompanyLogo(company.toLowerCase())} alt={company} />
                </div>
                <div className="report-card-view__head__content">
                  <div className="report-card-view__head__title" dangerouslySetInnerHTML={{__html: highlightedText}} />
                  <div className="report-card-view__head__subtitle" >
                    { hideDate ? '': formattedStartDate }
                  </div>
                </div>
                { !isDemo ?
                  <div className="report-card-view__head__icon">
                    { finished ?
                      <CircleButton color="green-dark" icon="done" handleClick={this.onDateIconClick} />:
                      <CircleButton color="blue" icon="watch_later" handleClick={this.onDateIconClick} />
                    }
                  </div>
                  :<div className="report-card-view__head__icon"></div>
                }
              </div>
              <div className="report-card-view__body">
                { !areaCopy.isNullOrEmpty() && (
                    <div className="report-card-view__body__section">
                      <div className="report-card-view__body__title">
                        Target Vertical
                      </div>
                      <span dangerouslySetInnerHTML={{__html: areaCopy}} />
                    </div>
                  )
                }
                { titlesCopy.length > 0  && (
                    <div className="report-card-view__body__section">
                      <div className="report-card-view__body__title">
                        Job Titles / Departments
                      </div>
                      <AttributeViews list={ titlesCopy } max={2} />
                    </div>
                  )
                }
                { filterCopy.length > 0 && (
                    <div className="report-card-view__body__section">
                      <div className="report-card-view__body__title">
                        Solution Attributes
                      </div>
                      <AttributeViews list={ filterCopy } max={5} />
                    </div>
                  )
                }
              </div>
              { !isDemo && finished ?
                <div className="report-card-view__foot">
                  <IconButton color="red-light" iconName="delete" text="Delete Report" title="Delete report from dashboard" isLoading={isDeleteButtonLoading} handleClick={this.onDeleteButtonClick} />
                  <IconButton color="blue-2" iconName="attachment" text="Export Data" title="Export the current report in csv format" isLoading={isExportButtonLoading} handleClick={this.onExportButtonClick} />
                  { !contactDataRequested && <IconButton color="green" iconName="contact_mail" text="Enrich Prospects" title="Enrich prospects in the report with emails and Linkedin profiles" isLoading={isContactButtonLoading} handleClick={this.onContactRequestButtonClick} /> }
                </div>
                :<div className="report-card-view__foot"></div>
              }
            </div>
          </a>
        } />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
  updateModalData: (data) => dispatch(updateModalData(data)),
  deleteSingleCompanyItem: (item) => dispatch(deleteSingleCompanyItem(item)),
});

export default connect(null, mapDispatchToProps)(ReportCardView);
