import React, { Component } from 'react';
import { connect } from 'react-redux';
import linkedinLogo from 'assets/img/icons/linkedin-oval.svg';
import { setTooltipData, setTooltipScrollPosition } from 'store/actions/root/tooltip';
import ReactDOM from 'react-dom';
import './InformationView.scss';

const tooltipColors = [
  "primary-dark",
  "aqua-green",
  "green",
  "green-dark",
  "purple",
  "red",
  "red-light",
  "blue",
  "blue-grey",
  "blue-2",
  "yellow",
  "gray",
  "light-green",
  "pink",
  "accent-blue",
  "accent-red",
  "accent-orange",
  "accent-green",
  "accent-green-2",
  "accent-yellow"
];

export class InformationView extends Component {
  constructor(props) {
    super(props);
    this.mouseEventTimeOut = undefined;
    this.onMouseLeaveTimeOut = undefined;
    this.state = {
      hasFocus: false, 
      isWaitingForMouseLeave: false,
      color: undefined,
      ref: undefined,
      hasTooltip: false,
    };
  }

  componentWillMount() {
    this.getTooltipColor(this.props);
  }

  componentDidMount() {
    this.setState({ ref: ReactDOM.findDOMNode(this)});
  }

  componentWillReceiveProps(props) {
    const { isWaitingForMouseLeave = false } = this.state;
    const { tooltipHasFocus = false } = props;
    if (isWaitingForMouseLeave && !tooltipHasFocus) {
      this.onContentMouseLeave();
    }
  }

  onContentMouseLeave = () => {
    clearTimeout(this.onMouseLeaveTimeOut);
    this.onMouseLeaveTimeOut = setTimeout(() => {
      const { hasFocus } = this.state;
      if (!this.props.tooltipHasFocus || !hasFocus) {
        clearTimeout(this.mouseEventTimeOut);
        const { name, url, type = 'company', companyList = [], source = null } = this.props;
        const { parent } = this.state;
        if (parent) {
          parent.removeEventListener('scroll', this.updateParentScroll);
        }

        this.props.setTooltipData({
          isActive: false, data: { name: source ? source : name, url, type, companyList },
        });
        this.setState({ hasFocus: false, isWaitingForMouseLeave: false });
      } else {
        this.setState({ isWaitingForMouseLeave: true, hasFocus: false, });
      }
    }, 50);
  }

  onContentMouseEnter = (e) => {
    clearTimeout(this.onMouseLeaveTimeOut);
    const { ref, hasFocus = false } = this.state;
    if (!hasFocus) {
      const { name, url, type = 'company', companyList = [], source = null } = this.props;
      const { width, left, top, height } = ref.getBoundingClientRect();
      const pageScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      let parent = ref.offsetParent;
      let topPosition = top;
      let leftPosition = parent.getBoundingClientRect().left + left;
      do {
        const parentElement = parent.offsetParent;
        parent = parent.parentElement;
        const { top = 0, left = 0 } = parentElement.getBoundingClientRect();
        leftPosition += left;
        topPosition += parentElement.scrollTop;
        if (top > 0) {
          topPosition += top;
        }
      } while (parent && parent.nodeName !== 'BODY');

      parent = this.getParentNode(ref.parentNode);
      if (parent.nodeName !== '#document') {
        parent.addEventListener('scroll', this.updateParentScroll);
        this.props.setTooltipScrollPosition(parent.scrollTop);
      }

      topPosition = ((topPosition + pageScrollTop) + (height + 10));
      this.props.setTooltipData({
        isActive: true, top: topPosition,
        left: leftPosition, width, height,
        data: { name: source ? source : name, url, type, companyList },
      });
      this.setState({ hasFocus: true, parent, isWaitingForMouseLeave: false });
    }
  }

  getParentNode = (parent) => {
    while ((parent.classList && !parent.classList.contains('insight-article-content')) || !parent) {
      parent = parent.parentNode;
    }

    return parent;
  }

  updateParentScroll = (event) => {
    if (event.target) {
      console.log("Parent scroll top: ", event.target.scrollTop);
      this.props.setTooltipScrollPosition(event.target.scrollTop);
    }
  }

  getColorPromise = (name) => new Promise((resolve) => {
    let colorName = window.tooltipColors[name];
    if (!colorName) {
      const index = Math.floor(Math.random() * tooltipColors.length);
      colorName = tooltipColors[index];
      window.tooltipColors[name] = colorName;
    }

    resolve(colorName);
  });

  getTooltipColor = (props) => {
    const { name, type = 'company', hasTooltip = false } = props;
    if (type === 'linkedin' && !window.profileDatas[name]) {
      this.setState({ color: null, hasTooltip: false });
      return;
    }

    this.getColorPromise(name).then((colorName) => {
      this.setState({ color: colorName, hasTooltip });
    });
  }

  render() {
    const { name, type = 'company', icon } = this.props;
    const { color, hasTooltip = false } = this.state;
    return (
      <span className="information-view" onMouseLeave={this.onContentMouseLeave} color={hasTooltip ? color : null}>
        <span className={`${type} ${hasTooltip ? 'has-tooltip' : ''}`} onMouseEnter={hasTooltip ? this.onContentMouseEnter : null}>
          { icon ? <i className="material-icons"> {icon} </i> : '' }
          { type === 'linkedin' && hasTooltip ?
            <img src={linkedinLogo} alt="Linkedin Page" /> : ''
          }
          { `${name || '' }` }
        </span>
      </span>
    );
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { tooltipDataReducers } = rootReducers;
  return {
    tooltipHasFocus: tooltipDataReducers.tooltipHasFocus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTooltipData: (data) => dispatch(setTooltipData(data)),
  setTooltipScrollPosition: (scroll) => dispatch(setTooltipScrollPosition(scroll)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationView);
