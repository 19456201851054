import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import NavigationDrawerHeader from 'components/Navigations/NavigationDrawerHeader/NavigationDrawerHeader'; 
import NavigationDrawerItem from 'components/Navigations/NavigationDrawerItem/NavigationDrawerItem';
import MobileNavigation from 'components/Navigations/MobileNavigation/MobileNavigation';
import { mobileNavigation } from 'store/actions/user/navigations';
import { ParentItemHelper } from 'helpers/ParentItemHelper';
import { navigationItemList, demoNavigationItemList, freemiumNavigationItemList } from 'commons/MenuItems';
import './NavigationDrawer.scss';

const getDrawerItem = (item, i) => {
  const drawerItems = {
    true: <NavigationDrawerItem { ...item } key={i} />,
  };

  return drawerItems[item.isActive];
};

const navigationItems = (items) => {
  return items.map((item, id) => {
    return getDrawerItem(item, id);
  });
};

const getNavigationItems = (userType) => {
  return {
    'premium': navigationItems(navigationItemList),
    'freemium': navigationItems(freemiumNavigationItemList),
    'demo': navigationItems(demoNavigationItemList),
  }[userType];
};

export class NavigationDrawer extends Component {
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  componentWillReceiveProps(props) {
    const { mobileNavigationStatus } = props;
    if (!mobileNavigationStatus) {
      document.removeEventListener('click', this.handleClickOutside, true);
    } else {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !new ParentItemHelper().findAncestor(event.target, 'navigation-drawer') && !new ParentItemHelper().findAncestor(event.target, 'navbar__brand__button')) {
      this.props.mobileNavigation(false);
    }
  }

  render() {
    const { currentUserData = {} } = this.props;
    const { userType = '' } = currentUserData;

    return (
      <div className="navigation-drawer">
        <NavigationDrawerHeader />
        <div className="d-block d-lg-none">
          <MobileNavigation />
        </div>
        {
          getNavigationItems(userType)
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mobileNavigation: (type) => dispatch(mobileNavigation(type)),
  }
};

const mapStateToProps = (store) => {
  const { rootReducers, userReducers } = store;
  const { mobileNavigationReducers } = userReducers;
  const { userDataReducers } = rootReducers;
  return {
    mobileNavigationStatus: mobileNavigationReducers.status,
    currentUserData: userDataReducers.currentUserData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDrawer);
