import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setReportListType } from 'store/actions';
import { searchTextInReport, sortValueInReport } from 'store/actions/dashboard/report';
import { LocalSettingsService } from 'services/LocalSettingsService';
import CardView from 'components/Views/CardView/CardView';
import CircleButton from 'components/form/CircleButton';
import SearchBar from 'components/form/SearchBar/SearchBar';
import Selectbox from 'components/form/Selectbox/Selectbox';
import { SorterOptions } from 'commons/SelectDatas';
import { createBrowserHistory } from "history";
import './FilterView.scss';

       
export class FilterView extends Component {
  constructor(props) {
    super(props)
    this.onSearchChangeTimeout = null;
    this.onListStyleButtonClick = this.onListStyleButtonClick.bind(this);
    this.state = { listIconColor: 'green-dark', gridIconColor: 'blue-2' };
  }

  onListStyleButtonClick(e) {
    const { localSettings } = window;
    localSettings.reportListType = e;
    new LocalSettingsService().updateLocalSettings(localSettings);
    this.props.setReportListType(e);
  }

  onSearchChange = (text) => {
    clearTimeout(this.onSearchChangeTimeout);
    this.onSearchChangeTimeout = setTimeout(() => {
      this.props.searchTextInReport(text);
    }, 450);
  }

  onSortChange = (value) => {
    this.props.sortValueInReport(value);
  }

  getPathName = () => {
    const history = createBrowserHistory();
    const { location } = history;
    const { pathname = '' } = location;

    return pathname;
  }

  render() {
    const { selectedSortValue } = this.props;
    return (
      <CardView classes="unhoverable cursor--default" childComponent={
        <div className="filter-view">
          <div className="filter-view__search">
            <SearchBar onValueChange={this.onSearchChange} /> 
          </div>
          <div className="filter-view__sort">
            <Selectbox selectedValue={selectedSortValue}  list={SorterOptions} onChangeValue={this.onSortChange} ></Selectbox>
          </div>
          { 
            this.getPathName() !== '/dashboard/reports/multiple' ?
            <div className="filter-view__buttons">
              <CircleButton color={this.state.listIconColor} icon="reorder" value="list" title="List view" handleClick={this.onListStyleButtonClick} />
              <CircleButton color={this.state.gridIconColor} icon="apps" value="grid" title="Card view" handleClick={this.onListStyleButtonClick} />
            </div>:''
          }
      </div>
       } />
    );
  }
}

const mapStateToProps = (store) => {
  const { dashBoardReducers } = store;
  const { reportReducers } = dashBoardReducers;
  const { sortValue = '2' } = reportReducers;
  return {
    selectedSortValue: sortValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchTextInReport: (text) => dispatch(searchTextInReport(text)),
    sortValueInReport: (value) => dispatch(sortValueInReport(value)),
    setReportListType: (e) => dispatch(setReportListType(e)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterView);
