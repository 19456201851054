import React, { Component } from 'react'
import './CompanyHeader.scss';


export class CompanyHeader extends Component {
  render() {
    const { name = '', subText = '', image = '' } = this.props;
    return (
      <div className="company-header">
        <div className="company-header__title">
          <div className="company-header__title__img">
            <img src={image} />
          </div>
          <div className="company-header__title__name">
            { name }
          </div>
        </div>
        <div className="company-header__text">
          { subText }
        </div>
      </div>
    )
  }
}

export default CompanyHeader;
