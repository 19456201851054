import React, { Component } from 'react';
import './Checkbox.scss';

export class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.state = {
      isChecked: props.checked
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isChecked: nextProps.checked});
  }

  onChangeCheckbox(e) {
    const { checked } = e.target;
    this.setState({ isChecked: checked });
    this.props.handleChange(checked, { ...this.props });
  }

  initNormalCheckBox = () => {
    const { isChecked } = this.state;
    const { text, value, color, id, classes = '' } = this.props;
    return (
      <div className={`a-checkbox ${classes}`}>
        <div className="a-checkbox__inner">
          <input
            className="a-checkbox__input"
            color={color} 
            id={`${id}__checkbox_id`}
            type="checkbox"
            checked={isChecked}
            value={value}
            onChange={this.onChangeCheckbox}
          />
          <label htmlFor={`${id}__checkbox_id`}>{text}</label>
        </div>
      </div>
    );
  }

  initItemCheckBox = () => {
    const { isChecked } = this.state;
    const { text, value, color, id, classes = '', count } = this.props;
    return (
      <div className={`a-checkbox ${classes}`}>
        <div className={`a-checkbox__item ${isChecked ? 'checked' : ''}`}>
          <input
            className="a-checkbox__item__input"
            color={color}
            id={`${id}__checkbox_id`}
            type="checkbox"
            checked={isChecked}
            onChange={this.onChangeCheckbox}
          />
          <label dangerouslySetInnerHTML={{__html: text}} htmlFor={`${id}__checkbox_id`} />
          { count ? 
            <span className="a-checkbox__item__count">
              {count}
            </span>:''
          }

        </div>
      </div>
    );
  }

  render() {
    const { type = 'normal' } = this.props;
    const checkBoxes = {
      'normal': this.initNormalCheckBox(),
      'item':  this.initItemCheckBox(),
    };

    return checkBoxes[type];
  }
}

export default Checkbox;
