import React, { Component } from 'react'
import { connect } from 'react-redux';
import CardView from 'components/Views/CardView/CardView';
import AttributeViews from 'components/Views/AttributeViews/AttributeViews';
import ReportItemDateView from './ReportItemDateView';
import CircleButton from 'components/form/CircleButton';
import { Image } from 'components/Image/Image';
import { updateModalData } from 'store/actions/root/modal';
import { deleteSingleCompanyItem } from 'store/actions/dashboard/UserNlp';
import { pushToastMessage } from 'store/actions/root/toast';
import StringUtils from 'utils/StringUtils';
import ReportCardService from 'services/ComponentServices/ReportCardService';
import './ReportListItem.scss';

export class ReportListItem extends Component {

  constructor(props) {
    super(props);
    this.onAttachmentClick = this.onAttachmentClick.bind(this);
    this.state = { 
      isExportButtonLoading: false, 
      isDeleteButtonLoading: false, 
      isContactButtonLoading: false,
      contactDataRequested: props.contactDataRequested,
      isDeleted: false,
      isDestroyed: false,
    };
  }

  deleteReport = () => {
    const { _id, } = this.props;
    this.setState({ isDeleteButtonLoading: true });
    new ReportCardService().deleteReport(_id).then((response) => {
      const { deleted } = response;
      this.setState({ isDeleted: deleted });
      setTimeout(() => {
        this.props.deleteSingleCompanyItem(_id);
        this.setState({ isDestroyed: deleted, isDeleteButtonLoading: true  });
      }, 800);
    });
  }

  onDeleteButtonClick = ()  => {
    this.props.updateModalData({ 
      isActive: true, 
      title: 'Do you want to delete this report ?',
      text: 'Are you sure you want to delete this report ?',
      buttons: [
        {
          text: "Cancel",
          icon: "cancel",
          color: "blue-r",
          onClick: () => {},
        },
        {
          text: "Delete",
          icon: "delete",
          color: "red-light",
          onClick: () => this.deleteReport(),
        }
      ]
    });
  }

  onAttachmentClick() {
    this.setState({ isExportButtonLoading: true });
    const { company, company_id } = this.props
    new ReportCardService().exportData({ company, company_id }).then(() => {
      this.setState({ isExportButtonLoading: false });
    });
  }

  onContactRequestButtonClick = () => {
    const { _id } = this.props;
    this.setState({ isContactButtonLoading: true });
    new ReportCardService().sendContactDataRequest(_id, this.pushNotification).then((data) => {
      this.setState(data);
    })
  }

  pushNotification = (message) => {
    const toastData = {
      ...message,
      duration: 5000,
    };
    this.props.pushToastMessage(toastData);
  }

  getCardButtons = () => {
    const { isExportButtonLoading, isDeleteButtonLoading, isContactButtonLoading = false, contactDataRequested } = this.state;
    const { finished } = this.props;

    if (!finished) {
      return(
        <div className="report-list-item__buttons">
        </div>
      )
    }

    return (
      <div className="report-list-item__buttons">
        <CircleButton color="red-light" icon="delete" title="Delete report from dashboard" isLoading={isDeleteButtonLoading} handleClick={this.onDeleteButtonClick}/>
        <CircleButton color="blue-2" icon="attachment" title="Export the current report in csv format" isLoading={isExportButtonLoading} handleClick={this.onAttachmentClick}/>
        { !contactDataRequested && <CircleButton color="aqua-green" icon="contact_mail" title="Enrich prospects" isLoading={isContactButtonLoading} handleClick={this.onContactRequestButtonClick}/> }
      </div>
    )
  }

  render() {
    const { _id, company, finished, titlesCopy, filterCopy, date, highlightedText, areaCopy, hideDate } = this.props;
    const statusClass = finished ? 'report-list-item__head__img--status' : 'report-list-item__head__img--status alert';
    const { isDeleted = false, isDestroyed = false } = this.state;

    if (isDestroyed) {
      return null;
    }

    return (
      <CardView classes={`margin-b-20 ${ isDeleted ? 'deleted':''}`} childComponent={
        <a href={`/?report=${_id}`} target="_blank">
        <div className="report-list-item">
          <div className="report-list-item__head">
            <div className="report-list-item__head__img">
              <Image src={ new StringUtils().getCompanyLogo(company.toLowerCase())} alt={company} />
              <div className={statusClass}>
                <i className="material-icons"> 
                  { finished ? 'check' : 'watch_later' }
                </i>
              </div>
            </div>
            <div className="report-list-item__head__content">
              { highlightedText ? 
                <div className="report-card-view__head__title" dangerouslySetInnerHTML={{__html: highlightedText}} />:       
                <div className="report-list-item__head__title">
                  { company }
                </div> 
              }
              <div className="report-list-item__head__subtitle">
                
              </div>
            </div>
          </div>
          <div className="report-list-item__date">
            { hideDate ?  '':<ReportItemDateView date={date} /> }
          </div>
          { !areaCopy.isNullOrEmpty() ?
            <div className="report-list-item__area">
              <div className="report-list-item__area__title"> Target Vertical</div>
              <span dangerouslySetInnerHTML={{__html: areaCopy}} /> 
            </div> : <div className="report-list-item__area" /> 
          }
          <div className="report-list-item__titles">
            <div className="report-list-item__titles__header">
              Job Titles / Departments
            </div>
            <AttributeViews list={ titlesCopy } max={2} />
          </div>
          <div className="report-list-item__body">
            <div className="report-list-item__body__header">
              Solution Attributes
            </div>
            <div className="report-list-item__body__section">
              <AttributeViews list={ filterCopy } max={3} />
            </div>
          </div>
            { 
              this.getCardButtons()
            }
        </div>
        </a>
      } />
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
    updateModalData: (data) => dispatch(updateModalData(data)),
    deleteSingleCompanyItem: (item) => dispatch(deleteSingleCompanyItem(item))
  }
};

export default connect(null, mapDispatchToProps)(ReportListItem);
