import React, { Component } from 'react'
import { IconButton } from 'components/form/IconButton/IconButton';
import './CompanyInformationFooter.scss';

export class CompanyInformationFooter extends Component {

  onReportHandle = () => {
    this.props.onReportHandle({});
  }

  render() {
    return (
      <div className="company-information-footer">
        <IconButton color="red-light" size="medium" iconName="refresh" text="Refresh" title="Request company data refresh" handleClick={this.onReportHandle} />
      </div>
    )
  }
}

export default CompanyInformationFooter;
