import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCombinedCompanyData, clearCombinedDatas } from 'store/actions/dashboard/UserNlp';
import ItemHighLighter from 'helpers/ItemHighLighter';
import { ReportCardPlaceHolder } from 'components/User/Reports/ReportCardPlaceHolder/ReportCardPlaceHolder';
import { ReportListPlaceHolder } from 'components/User/Reports/ReportListPlaceHolder/ReportListPlaceHolder';
import ReportCardView from 'components/User/Reports/ReportCardView/ReportCardView';
import ReportListItem from 'components/User/Reports/ReportListItem/ReportListItem'
import NoReportContent from '../NoReportContent/NoReportContent';
import './UserReportsListView.scss';

const reportItems = (data, searchText, sortValue) => {
  return data.map((item, id) => {
    const highlightedItem =  new ItemHighLighter().highlightReportItem(item, searchText);
    if(highlightedItem) {
      return <div className="col-md-12 col-lg-6 col-xl-4 margin-t-0 margin-b-20" key={id}>
        <ReportCardView {...highlightedItem} />
      </div>
    } 

    return '';
  });
};

const reportListItems = (data, searchText, sortValue) => {
  return data.map((item, id) => {
    const highlightedItem =  new ItemHighLighter().highlightReportItem(item, searchText);
    return highlightedItem ? <ReportListItem {...highlightedItem} key={id} /> : '';
  })
}

export class UserReportsListView extends Component {

  constructor(props) {
    super(props);
    this.state = { listType: 'grid', page: 1 };
  }

  componentWillReceiveProps(nextProps) {
    const { sortValue, userNLPDataRequest, searchText } = this.props;
    if(!userNLPDataRequest && (sortValue !== nextProps.sortValue || searchText !== nextProps.searchText)) {
      this.props.clearCombinedDatas([]);
      this.setState({ page: 1 }, () => {
        this.loadCombinedCompany(nextProps.sortValue, nextProps.searchText);
      });
    }
  }

  componentWillMount() {
    const { sortValue } = this.props;
    this.props.clearCombinedDatas([])
    this.loadCombinedCompany(sortValue);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  loadCombinedCompany = (sortValue, searchText = "") => {
    const { page } = this.state;
    const body = {
      'addToken': true,
      "isMultiple": false,
      "order": sortValue,
      "page": page,
      "pagesize": 15,
      "search": searchText
    };
    this.props.fetchCombinedCompanyData(body);
    this.setState({ page: page + 1 });
  }

  checkEndOfThePage = () => {
    const { body } = document;
    const { offsetHeight } = body;
    const { scrollY, innerHeight } = window;
    return (innerHeight + scrollY) >= (offsetHeight - 50);
  }

  handleScroll = () => {
    const { userNLPDataRequest = false, searchText, sortValue, pageable } = this.props;
    const { page } = this.state;
    const { totalpages } = pageable; 
    if (page <= totalpages && !userNLPDataRequest && this.checkEndOfThePage()) {
      this.loadCombinedCompany(sortValue, searchText);
    }
  }

  getContent() {
    const { userNLPDataRequest, searchText, userNLPData = [], listType } = this.props;
    return(
      <div className="margin-t-0">
        { (!userNLPDataRequest && userNLPData.length === 0) && <NoReportContent />}
        { listType === 'grid' ?
          <div className="row">
            { reportItems(userNLPData, searchText) }
          </div>:reportListItems(userNLPData, searchText)
        }
      </div>
    )
  }

  getLoadingContent() {
    const { listType } = this.props;
    return(
      listType === 'grid' ? 
      <ReportCardPlaceHolder size={6} />: 
      <ReportListPlaceHolder size={10} />
    )
  }

  render() {
    const { userNLPDataRequest } = this.props;
    return (
      <div className="user-reports-view__body">
        { this.getContent() }
        { userNLPDataRequest && this.getLoadingContent() }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearCombinedDatas: () => dispatch(clearCombinedDatas()),
    fetchCombinedCompanyData: (body) => dispatch(fetchCombinedCompanyData(body)),
  }
};

const mapStateToProps = function (store) {
  const{ filterViews, dashBoardReducers } = store; 
  const { userNLPReducers, reportReducers } = dashBoardReducers;
  return {
    listType: filterViews.listType,
    searchText: reportReducers.searchText,
    sortValue: reportReducers.sortValue,
    userNLPData: userNLPReducers.userNLPData,
    pageable: userNLPReducers.pageable,
    userNLPDataRequest: userNLPReducers.userNLPDataRequest
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserReportsListView);
