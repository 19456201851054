import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CardView } from 'components/Views/CardView/CardView';
import { AttributeViews } from 'components/Views/AttributeViews/AttributeViews';
import CompanyListView from './CompanyListView/CompanyListView';
import { CircleButton } from 'components/form/CircleButton';
import { IconButton } from 'components/form/IconButton/IconButton';
import { HttpService } from 'services/HttpService';
import { updateModalData } from 'store/actions/root/modal';
import { deleteCombinedDataItem } from 'store/actions/dashboard/UserNlp';
import './MultipleReportCardView.scss';

export class MultipleReportCardView extends Component {

  constructor(props) {
    super(props);
    this.state = { isExportButtonLoading: false, isDeleteButtonLoading: false, isDeleted: false, isDestroyed: false };
  }

  deleteReport = () => {
    const { _id, } = this.props;
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      addToken: true,
      companyId: _id,
    };
    this.setState({ isDeleteButtonLoading: true });
    new HttpService().sendRequest(`${host}/api/userCompany/delete`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      const { deleted } = response;
      this.setState({ isDeleted: deleted });
      setTimeout(() => {
        this.props.deleteCombinedDataItem(_id);
        this.setState({ isDestroyed: deleted, isDeleteButtonLoading: true });
      }, 800);
    });
  }

  onDeleteButtonClick = ()  => {
    this.props.updateModalData({ 
      isActive: true, 
      title: 'Do you want to delete this report ?',
      text: 'Are you sure you want to delete this report ?',
      buttons: [
        {
          text: "Cancel",
          icon: "cancel",
          color: "blue-r",
          onClick: () => {},
        },
        {
          text: "Delete",
          icon: "delete",
          color: "red-light",
          onClick: () => this.deleteReport(),
        }
      ]
    });
  }

  reportStatus = () => {
    const { 
      finished = false, 
      isDemo,
     } = this.props;
     return (
        !isDemo ? 
        <div className="multiple-report-card-view__icon">
          { finished?
            <CircleButton color="blue-2-high" icon="done" handleClick={() => {}} />:
            <CircleButton color="blue-high" icon="watch_later" handleClick={() => {}} />
          }
        </div>
        :<div className="multiple-report-card-view__icon"></div>
      )
  }

  onExportButtonClick = () => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const { companies, company_id } = this.props;
    this.setState({ isExportButtonLoading: true });
    fetch(`${host}/api/combinedCompany/export/${company_id}`)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = `${companies.map(({ name }) => name).join('_')}_${company_id}.csv`;
      document.body.appendChild(a);
      this.setState({ isExportButtonLoading: false });
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('There is an issue with the export, please try again!'));
  }

  render() {
    const { 
      finished = false,
      _id, 
      companiesCopy, 
      competitorsCopy, 
      painPointsCopy, 
      keywordsCopy, 
      areaCopy,
      titlesCopy,
      formattedStartDate,
      isDemo,
     } = this.props;
    const { isExportButtonLoading, isDeleteButtonLoading, isDeleted = false, isDestroyed = false } = this.state;
    const params = `/multiple?report=${_id}`;
    if (isDestroyed) {
      return null;
    }

    return (
      <CardView classes={`${ isDeleted ? 'deleted': ''}`} childComponent={
        <a href={params} target="_blank">
          <div className="multiple-report-card-view">
            { this.reportStatus() }
            <CompanyListView date={formattedStartDate} companies={companiesCopy} name="Companies" max={3} />
            <div className="multiple-report-card-view__section">
              <div className="multiple-report-card-view__title">
                Target Vertical
              </div>
              <span dangerouslySetInnerHTML={{__html: areaCopy }} />
            </div>
            { titlesCopy && titlesCopy.length > 0 ?
              <div className="multiple-report-card-view__section">
                <div className="multiple-report-card-view__title">
                  Job Titles / Departments
                </div>
                <AttributeViews list={ titlesCopy } max={2}/>
              </div>: ''
            }
            { keywordsCopy && keywordsCopy.length > 0 ?
              <div className="multiple-report-card-view__section">
                <div className="multiple-report-card-view__title">
                  Keywords
                </div>
                <AttributeViews list={ keywordsCopy } max={3}/>
              </div>: ''
            }
            { painPointsCopy && painPointsCopy.length > 0 ?
              <div className="multiple-report-card-view__section">
                <div className="multiple-report-card-view__title">
                  Pain Points
                </div>
                <AttributeViews list={ painPointsCopy } max={3}/>
              </div>: ''
            }
            <CompanyListView companies={competitorsCopy} name="Competitors" max={3} />
            { 
              !isDemo && finished ?
              <div className="multiple-report-card-view__foot">
                <IconButton color="red-light" iconName="delete" text="Delete Report" isLoading={isDeleteButtonLoading} handleClick={() => this.onDeleteButtonClick()} />
                <IconButton classes="margin-l-10" color="blue-2" iconName="attachment" text="Export Data" isLoading={isExportButtonLoading} handleClick={() => this.onExportButtonClick()} />
              </div>: ''
            }
          </div>
        </a>
        }
      />
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateModalData: (data) => dispatch(updateModalData(data)),
    deleteCombinedDataItem: (item) => dispatch(deleteCombinedDataItem(item)),
  }
};

export default connect(null, mapDispatchToProps)(MultipleReportCardView);
