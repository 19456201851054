import { InsightsDataProvider } from "providers/insights/InsightsDataProvider";

export const SET_SECTION_LIST = 'SET_SECTION_LIST';
export const setSectionList = (sectionList) => ({
  type: SET_SECTION_LIST,
  sectionList,
});

export const ON_UPDATE_SECTION_NAMES = 'ON_UPDATE_SECTION_NAMES';
export const onUpdateSectionNames = (sections) => ({
  type: ON_UPDATE_SECTION_NAMES,
  sections,
});

export const RECEIVED_FILTERED_INSIGHTS = 'RECEIVED_FILTERED_INSIGHTS';
export const receivedFilteredInsights = (metadata) => ({
  type: RECEIVED_FILTERED_INSIGHTS,
  metadata,
});

export const IS_INSIGHTS_LOADING = 'IS_INSIGHTS_LOADING';
export const isInsightsLoading = (isLoading) => ({
  type: IS_INSIGHTS_LOADING,
  isLoading,
});

export const ON_CHANGE_INSIGHT_FILTER = 'ON_CHANGE_INSIGHT_FILTER';
export const onChangeInsightFilter = (insightFilter) => ({
  type: ON_CHANGE_INSIGHT_FILTER,
  insightFilter: {
    didFilterChanged: true,
    ...insightFilter,
  },
});

export const onChangeFilter = (requestData) => {
  const { attributes } = requestData;
  return (dispatch) => {
    dispatch(onChangeInsightFilter(requestData));
    dispatch(isInsightsLoading(true));
    new InsightsDataProvider().fetchFilteredInsights(requestData).then((data) => {
      dispatch(receivedFilteredInsights({ metadata: data, subAreas: attributes }));
      dispatch(isInsightsLoading(false));
    });
  };
};
