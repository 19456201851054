import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCombinedCompanyData, clearCombinedDatas } from 'store/actions/dashboard/UserNlp';
import { ItemHighLighter } from 'helpers/ItemHighLighter';
import MultipleReportCardView from 'components/User/Reports/MultipleReportCard/MultipleReportCardView';
import MultipleReportCardPlaceHolders from 'components/User/Reports/PlaceHolders/MultipleReportCardPlaceHolders/MultipleReportCardPlaceHolders';
import NoReportContent from '../NoReportContent/NoReportContent';
import './UserMultipleReportsListView.scss';

const nextReportButton = { text: "Start New Multiple Company Report", to: '/multiple' };
const reportItems = (data, searchText) => {
  return data.map((item, id) => {
    const highlightedItem =  new ItemHighLighter().highlightMultipleReportItem(item, searchText);
    if(highlightedItem) {
      return <div className="col-md-12 col-lg-6 col-xl-4 margin-t-0 margin-b-20" key={id}>
        <MultipleReportCardView {...highlightedItem} />
      </div>
    } 

    return '';
  });
};

export class UserMultipleReportsListView extends Component {

  constructor(props) {
    super(props);
    this.state = { page: 1 };
  }


  componentWillReceiveProps(nextProps) {
    const { sortValue, combinedCompanyRequest, searchText } = this.props;
    if(!combinedCompanyRequest && (sortValue !== nextProps.sortValue || searchText !== nextProps.searchText)) {
      this.props.clearCombinedDatas([]);
      this.setState({ page: 1 }, () => {
        this.loadCombinedCompany(nextProps.sortValue, nextProps.searchText);
      });
    }
  }

  componentWillMount() {
    const { sortValue } = this.props;
    this.props.clearCombinedDatas([])
    this.loadCombinedCompany(sortValue);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  loadCombinedCompany = (sortValue, searchText = "") => {
    const { page } = this.state;
    const body = {
      'addToken': true,
      "isMultiple": true,
      "order": sortValue,
      "page": page,
      "pagesize": 15,
      "search": searchText
    };
    this.props.fetchCombinedCompanyData(body);
    this.setState({ page: page + 1 });
  }

  checkEndOfThePage = () => {
    const { body } = document;
    const { offsetHeight } = body;
    const { scrollY, innerHeight } = window;
    return (innerHeight + scrollY) >= (offsetHeight - 50)
  }

  handleScroll = () => {
    const { combinedCompanyRequest = false, searchText, sortValue, pageable } = this.props;
    const { page } = this.state;
    const { totalpages } = pageable; 
    if (page <= totalpages && !combinedCompanyRequest && this.checkEndOfThePage()) {
      this.loadCombinedCompany(sortValue, searchText);
    }
  }

  getContent = () => {
    const { combinedCompanyRequest, combinedData, sortValue, searchText } = this.props;
    return (
      <div className="row margin-t-0">
        { (!combinedCompanyRequest && combinedData.length === 0) && <NoReportContent button={nextReportButton} /> }
        { reportItems(combinedData, searchText, sortValue) }
      </div>
    )
  }

  getLoadingContent() {
    return(
      <div className="margin-t-0">
        <MultipleReportCardPlaceHolders size={6} />
      </div>
    )
  }

  render() {
    const { combinedCompanyRequest } = this.props;

    return (
      <div className="multiple-reports-list">
        { this.getContent() }
        { 
          combinedCompanyRequest && this.getLoadingContent()
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearCombinedDatas: () => dispatch(clearCombinedDatas()),
    fetchCombinedCompanyData: (body) => dispatch(fetchCombinedCompanyData(body)),
  }
};

const mapStateToProps = function (store) {
  const{ filterViews, dashBoardReducers } = store; 
  const { userNLPReducers, reportReducers } = dashBoardReducers;
  return {
    listType: filterViews.listType,
    searchText: reportReducers.searchText,
    sortValue: reportReducers.sortValue,
    combinedData: userNLPReducers.combinedData,
    pageable: userNLPReducers.pageable,
    combinedCompanyRequest: userNLPReducers.combinedCompanyRequest
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMultipleReportsListView);
