import React, { Component } from 'react'
import './SocialFooter.scss'
import facebookLogo from '../../assets/img/icons/facebook.svg'
import linkedinLogo from '../../assets/img/icons/linkedin.svg'
import twitterLogo  from '../../assets/img/icons/twitter.svg'

export class SocialFooter extends Component {

  render() {
    return (
      <div>
        <div className="m-social-footer">
          <a className="m-social-footer__item m-social-footer__item--facebook display--none">
            <img src={facebookLogo} alt="Facebook Page" />
          </a>
          <a className="m-social-footer__item m-social-footer__item--linkedin"
            href="https://www.linkedin.com/company/enkiai/" target="_blank">
            <img src={linkedinLogo} alt="Linkedin Page" />
          </a>
          <a className="m-social-footer__item m-social-footer__item--twitter"
            href="https://twitter.com/enkiai" target="_blank">
            <img src={twitterLogo} alt="Twitter Page" />
          </a>
        </div>
      </div>
    )
  }
}

export default SocialFooter
