import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CardView from 'components/Views/CardView/CardView';
import startImage from 'assets/img/stats.svg';
import './NextReportCard.scss';


export class NextReportCard extends Component {
  render() {
    return (
      <CardView childComponent={
        <Link to="/" className="next-report-card">
          <div className="next-report-card__image">
            <img src={startImage} />
          </div>
          <div className="next-report-card__content">
            <div className="next-report-card__content__title">
              Start Your Next Analysis
            </div>
            <div className="next-report-card__content__text">
              Find insights for a new corporation or organization
            </div>
            <button className="next-report-card__content__button">
              Start New Report
            </button>
          </div>
        </Link>
      } />
    )
  }
}

export default NextReportCard;
