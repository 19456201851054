import React from 'react';
import Select, { AsyncCreatable } from 'react-select';
import SelectorDataHelper from './helpers/SelectorDataHelper';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
let controller = new AbortController();
let signal = controller.signal;

const customStyles = {
  placeholder: (provided) => {
    return {
      ...provided,
      paddingLeft: 0,
      fontWeight: 400,
    }
  },
  control: (provided, state) => {
    return {
      ...provided,
      borderWidth: 0,
      borderRadius: 20,
      backgroundColor: "#F5F5F5",
      opacity : state.isDisabled ? 0.5 : 1,
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow: 0,
      ':hover,:focus': {
        borderColor: "#9753e1"
      }
    };
  }, 
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "#FFFFFF",
      borderRadius: 20,
      paddingLeft: 5,
      paddingRight: 5,
      borderColor: "#172b35",
      borderWidth: 1
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: "15px",
      fontWeight: "500",
    }
  }
}

const customTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral20: "#464646",
    }
  };
}

const getNewOptionData = (inputValue, optionLabel) => {
  return {
    value: inputValue.toUpperCase(),
    label: optionLabel.toUpperCase()
  }
}

const fetchCompanyJson = (inputValue) => {
  const body = JSON.stringify({ companyName: inputValue });
  try {
    return fetch(`${host}/api/companySuggestion`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
      signal: signal
    })
  } catch (error) {
    return null;
  }
}

const fetchCompanies = (inputValue) => {
  return fetchCompanyJson(inputValue)
  .catch((err) => {
    console.log(err);
  })
  .then((response) => {
    if (response && response.ok) {
      return new SelectorDataHelper().parseOptionsResponse(response);
    }
  });
};

const logCustomInput = (inputValue) => {
  const body = JSON.stringify(
    { 
      inputType: 'company',
      inputValue: inputValue 
    }
  );
  return fetch(`${host}/api/userInput`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
  .then((response) => response.json())
}

function abortFetching() {
  const { aborted } = signal;
  if (!aborted) {
    controller.abort()
    controller = new AbortController();
    signal = controller.signal;
  }
}

let requestTimeout = undefined;
const promiseOptions = (inputValue) => {
  return new Promise(resolve => {
      abortFetching();
      clearTimeout(requestTimeout);
      requestTimeout = setTimeout(() => {
        if (inputValue.length > 1) {
          fetchCompanies(inputValue).then((data) => {
            resolve(data);
          });
        }
      }, 500);
  });
}

export default class CompanySelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = { inputValue: '', onFocus: false };
  }

  onMultipleInputChange = (query, { action }) => {
    if (action !== "set-value") {
      this.setState({ inputValue: query.toUpperCase() });
      return query.toUpperCase();
    } else {
      this.setState({ inputValue: '' });
    }

    return this.state.inputValue;
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue: inputValue.toUpperCase() }); 
    return inputValue.toUpperCase();
  };

  handleChange = (newValue, { action }) => {
    const { isMulti } = this.props;

    if(isMulti) {
      this.runOnChanged(action, newValue);
      this.props.onChange(newValue);
    } else {
      this.props.onChange(newValue.value);
    }
  };

  createOption = (inputValue) => {
    logCustomInput(inputValue).then((response) => {
      console.log(response);
    });
  }

  runOnChanged(type, values) {
    const options =  { 
      'select-option': () => {},
      'remove-value': () => {},
      'create-option': () => { 
        const valueLength = values.length;
        if(valueLength > 0) {
          this.createOption(values[valueLength - 1].value) 
        }
      }
    }[type] || (() => {})
    return options()
  }

  handleFocus = (inputValue) => {
    this.setState({ inputValue: '', onFocus: true });
  };

  handleBlur = (inputValue) => {
    this.setState({ inputValue: this.props.value, onFocus: false });
  };

  render() {
    let value = '';
    const { inputValue } = this.state;
    const { demoCompanyList = [], isMulti = false } = this.props;
    if (!isMulti && this.props.value != undefined) {
      value  = { label: this.props.value , value: this.props.value };
    } else {
      value = this.props.value;
    }

    if(demoCompanyList.length > 0) {
      const upperCasedCompanyList = demoCompanyList.map(c => ({ value: c.value.toUpperCase(), label: c.label.toUpperCase() }));
      return (
        <Select 
          onChange={this.handleChange}
          options={upperCasedCompanyList}
          styles={customStyles}
          theme={customTheme}
          blurInputOnSelect={true}
          isDisabled={this.props.disabled}
          placeholder="e.g. Chevron, Google, or Adobe"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          value={this.state.onFocus ? this.state.inputValue : value}
         />
      )
    } else {
      return (
        <AsyncCreatable
          isMulti={isMulti}
          isCreatable
          inputValue={ inputValue }
          placeholder="e.g. Chevron, Google, or Adobe"
          onChange={this.handleChange}
          styles={customStyles}
          theme={customTheme}
          getNewOptionData={getNewOptionData}
          closeMenuOnSelect={false}
          onSelectResetsInput={false}
          isDisabled={this.props.disabled}
          loadOptions={promiseOptions}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onInputChange={isMulti ? this.onMultipleInputChange : this.handleInputChange}
          blurInputOnSelect={!isMulti}
          createOptionPosition="first"
          value={value}
        />
      );
    }
  }
}


