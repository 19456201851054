import React from 'react';
import { AsyncCreatable } from 'react-select';
import SelectorDataHelper from './helpers/SelectorDataHelper';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
let controller = new AbortController();
let signal = controller.signal;

const customStyles = {
  placeholder: (provided) => {
    return {
      ...provided,
      paddingLeft: 0,
      fontWeight: 400,
    }
  },
  control: (provided, state) => {
    return {
      ...provided,
      borderWidth: 0,
      borderRadius: 20,
      opacity : state.isDisabled ? 0.5 : 1,
      backgroundColor: "#F5F5F5",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow: 0,
      ':hover,:focus': {
        borderColor: "#9753e1"
      }
    };
  }, 
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "#FFFFFF",
      borderRadius: 20,
      paddingLeft: 5,
      paddingRight: 5
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: "15px",
      fontWeight: "500",
    }
  }
}

const customTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral20: "#464646",
    }
  };
}

const getNewOptionData = (inputValue, optionLabel) => {
  return {
    value: inputValue.toUpperCase(),
    label: optionLabel.toUpperCase()
  }
}

const fetchTitleJson = (inputValue) => {
  const body = JSON.stringify({ 
    attributeName: inputValue,
    targetAPI: 'titleSuggest',
    searchParameterName: 'search_title'
  });
  try {
    return fetch(`${host}/api/attributeSuggestion`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
      signal: signal
    });
  } catch {
    return null;
  }
}

function abortFetching() {
  const { aborted } = signal;
  if (!aborted) {
    controller.abort()
    controller = new AbortController();
    signal = controller.signal;
  }
}

let requestTimeout = undefined;
const promiseOptions = inputValue =>
  new Promise(resolve => {
    abortFetching();
    clearTimeout(requestTimeout);
    requestTimeout = setTimeout(() => {
      if (inputValue.length > 1) {
        fetchTitles(inputValue).then((data) => {
          resolve(data);
        });
      }
    }, 500);
});

const logCustomInput = (inputValue) => {
  const body = JSON.stringify(
    { 
      inputType: 'title',
      inputValue: inputValue 
    }
  );
  return fetch(`${host}/api/userInput`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
  .then((response) => response.json())
}

const fetchTitles = (inputValue) => {
  return fetchTitleJson(inputValue)
  .catch((err) => {
    console.log(err);
  })
  .then((response) => {
    if (response && response.ok) {
      return new SelectorDataHelper().parseSuggestionResult(response);
    }
  });
};

export default class TitleSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = { inputValue: '' };
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue: inputValue.toUpperCase() }); 
    return inputValue.toUpperCase();
  };

  handleChange = (newValue, { action }) => {
    this.runOnChanged(action, newValue);
    this.props.onChange(newValue);
  };

  createOption = (inputValue) => {
    logCustomInput(inputValue).then((response) => {
      console.log(response);
    });
  }

  runOnChanged(type, values) {
    const options =  { 
      'select-option': () => {},
      'remove-value': () => {},
      'create-option': () => { 
        const valueLength = values.length;
        if(valueLength > 0) {
          this.createOption(values[valueLength - 1].value) 
        }
      } ,
    }[type] || (() => {})
    return options()
  }

  render() {
    const { inputValue } = this.state;
    return (
      <AsyncCreatable
        isMulti
        placeholder="e.g. Director of IT, Enterprise Architect, or Chief Information Officer"
        onChange={this.handleChange}
        loadOptions={promiseOptions}
        value={this.props.value}
        inputValue={ inputValue }
        onInputChange={ this.handleInputChange }
        styles={customStyles}
        theme={customTheme}
        getNewOptionData={getNewOptionData}
        createOptionPosition="first"
        isDisabled={this.props.disabled}
      />
    );
  }
}
