import React, { Component } from 'react';
import './CardView.scss';

export class CardView extends Component {
  render() {
    const { childComponent, classes = '' } = this.props;
    return (
      <div className={ `card-view ${classes}` }>
        {childComponent}
      </div>
    )
  }
}

export default CardView;
