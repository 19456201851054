import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyTableInsightsItem from './CompanyTableInsightsItem';
import { HttpService } from 'services/HttpService';
import CompanyInsightsLoader from 'components/Loaders/CompanyInsightsLoader';
import StringUtils from 'utils/StringUtils';
import Image from 'components/Image/Image';
import desertImage from 'assets/img/desert.svg';
import './CompanyTableInsights.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export class CompanyTableInsights extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isActive: false,
      requestCompleted: false,
      articles: [],
      score: 0
    };
  }


  onLoadInsights = () => {
    const { isLoading, articles = [] } = this.state;
    if (isLoading || articles.length > 0) {
      this.setState({ isActive: true });
      return;
    }

    this.setState({ isLoading: true, isActive: true });
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const { name, attributes, webSiteUrl } = this.props;
    const body = { name, attributes, webSiteUrl };
    new HttpService().sendRequest(`${host}/api/get/company_insights`, { 
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      const { code, data : articles } = response;
      if (code === 200) {
        const score = articles.length == 0 ? 0 : Math.min(Math.round(20 + 15 * Math.log(articles.length)), 100);
        this.setState({ articles, isLoading: false, requestCompleted: true, score: score });
      }
    });
  } 

  setDeActive = () => {
    this.setState({ isActive: false });
  }
  getInsightsLoader = () => {
    return (
      <div className="company-table-insights__list">
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
        <CompanyInsightsLoader />
      </div>
    )
  }

  getCompanyInsights = () => {
    const { name } = this.props;
    const { articles = [], requestCompleted } = this.state;
    if (requestCompleted && articles.length == 0) {
      return(
        <div className="company-table-insights__error">
          <img src={desertImage} />
          <div className="company-table-insights__error__text">
            We couldn't find and Insights about {name}
          </div>
        </div>
      )
    }

    return (
      <div className="company-table-insights__list">
        { 
          articles.map((article, i) => {
            return <CompanyTableInsightsItem article={article} key={i} />
          })
        }
      </div>
    );
  }

  render() {
    const { name } = this.props;
    const { isLoading, isActive, score } = this.state;
    return (
      <div className={`company-table-insights ${isActive ? 'active' : ''}`}>
        <div className="company-table-insights__title">
          <div className="company-table-insights__title__image">
            <Image src={new StringUtils().getCompanyLogo(name)} />
          </div> 
          {name} Insights 
          
          { score > 0 && <div style={{ width: 25, height: 25, marginTop: 0, marginLeft: 3 }}>
            <CircularProgressbar
              value={score} text={score}
              background backgroundPadding={4}
              styles={buildStyles({
                textSize: '45px', backgroundColor: "#4a944f",
                textColor: "#fff", pathColor: "#fff",
                trailColor: "transparent"
              })}
            />
          </div> }
        
        </div>
        <div className="company-table-insights__indicator"></div>
        { isLoading ? this.getInsightsLoader(): this.getCompanyInsights() }
      </div>
    )
  }
}


const mapStateToProps = function (store) {
  const { userReducers } = store;
  const { companiesReducers } = userReducers;
  return {
    attributes: companiesReducers.attributes
  };
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(CompanyTableInsights);
