import React, { Component } from 'react'
import './SimpleInput.scss';

export class SimpleInput extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      passwordVisibility: false
    }
  }

  handleChange(e) {
    this.props.onValueChange(e.target.value);
  }

  togglePasswordVsibility = () => {
    const { passwordVisibility } = this.state;
    this.setState({ passwordVisibility: !passwordVisibility });
  }

  render() {
    const { value, type = 'text', placeHolder, iconName, name='', minLength = 0 } = this.props;
    const { passwordVisibility } = this.state;

    return (
      <div className="simple-input__box">
        { iconName ?
          <i className="material-icons"> {iconName} </i>:''
        }
        <input 
          className="simple-input" 
          value={value}
          name={name}
          minLength={minLength}
          placeholder={placeHolder}
          type={ passwordVisibility ? 'text':type} required
          onChange={this.handleChange} />
        {
          type === 'password' ?
          <div className="simple-input__visibility-button" onClick={this.togglePasswordVsibility}>
            <i className="material-icons"> { passwordVisibility ? 'visibility_off' :'visibility' } </i>
          </div>:''
        }
      </div>
    )
  }
}

export default SimpleInput;
