import React, { Component } from 'react'
import FloatingInputComponent from '../FloatingInput/FloatingInput'
import Checkbox from '../Checkbox/Checkbox'
import './ChangePasswordForm.scss'
import { HttpService } from 'services/HttpService';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import MessageBox from 'components/MessageBox/MessageBox';

export class ChangePasswordForm extends Component {

  messageBox = {
    title: "Login Failed",
    message: "Check your Email and enter the verification code to reset your Password.",
    color: "danger",
    icon: "error"
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.passwordChange = this.passwordChange.bind(this); 
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordRepeatChange = this.onPasswordRepeatChange.bind(this);
    this.onMessageClose = this.onMessageClose.bind(this);
    this.state = { 
      password: '', 
      passwordRepeat: '', 
      loaderIsActive: false,
      showMessageBox: false
    };
  }

  passwordChange() {
    this.setState({loaderIsActive: true, showMessageBox: false })

    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      "email": this.state.email,
      "code": this.state.code,
      "newpassword": this.state.password
    };
    new HttpService().sendRequest(`${host}/api/users/passwordChange`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((result) => {

      this.setState({ showMessageBox: true });
      this.messageBox.message = result.response;

      if (result.res) {
        this.messageBox.title = 'Request Successful';
        this.messageBox.color = 'primary';
        this.props.onSubmit({ result: 'success' });
      } else {
        this.messageBox.title = 'Request Failed';
        this.messageBox.color = 'danger';
      }

      this.setState({ loaderIsActive: false });
    })
    .catch((err) => {
      console.log(err)
    })
  }

  componentDidMount() {
    this.setState({ email: this.props.email, code: this.props.code });
  }

  onPasswordRepeatChange(password) {
    this.setState({passwordRepeat: password})
  }

  onPasswordChange(password) {
    this.setState({password: password})
  }

  onMessageClose(event) {
    this.setState({ showMessageBox: false });
  }

  handleSubmit(event) {
    const { password, passwordRepeat } = this.state;
    
    if (password !== passwordRepeat) {
      this.setState({ showMessageBox: true });
      this.messageBox.title = "Failed"
      this.messageBox.message = 'Passwords don\'t match';
      this.messageBox.color = 'danger';
    } else {
      this.passwordChange();
    }

    event.preventDefault();
  }

  render() {
    return (
      <div>
        <form className="o-login-form" onSubmit={this.handleSubmit}>
          <div className="o-login-form__title margin-b-40">
            Change Password
          </div>
          <FloatingInputComponent title="New Password" type="password" iconName="lock" onValueChange={this.onPasswordChange} />
          <FloatingInputComponent title="Password Confirm" type="password" iconName="lock" onValueChange={this.onPasswordRepeatChange} />
          { 
            !this.state.loaderIsActive ?
            <button className="a-button a-button--primary a-button--full-width" type="submit" value="Submit">
              Change Password
            </button>
            : <CircleLoader isActive={this.state.loaderIsActive} />
          }
          {
            this.state.showMessageBox ?
            <MessageBox {...this.messageBox} onClose={this.onMessageClose} />
            : ''
          }
        </form>
      </div>
    )
  }
}

export default ChangePasswordForm
