import React, { Component } from 'react';
import { connect } from 'react-redux';
import { randomId, setMultipleParams, getParams } from 'util.jsx';
import ClipboardService from 'services/ClipboardService';
import NavBar from 'components/Navigations/NavBar/NavBar';
import NavBarIconMenuItem from 'components/Navigations/NavBarIconMenuItem/NavBarIconMenuItem';
import { pushToastMessage } from 'store/actions/root/toast';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import { fetchCurrentUserData, receiveCurrentUserData } from 'store/actions/root/user';
import CompanyTable from 'components/User/Reports/CompanyTable/CompanyTable';
import AsyncSelector from 'components/form/AsyncSelector/AsyncSelector';
import AreaSelector from 'components/form/SelectorArea/SelectorArea.jsx';
import { addCompanyList, setRequestStatus, clearCompanyList, updateSelectedCompanies, addAttributes } from 'store/actions/user/companies';
import { CompanySorterOptions } from 'commons/SelectDatas';
import Selectbox from 'components/form/Selectbox/Selectbox';
import data from 'assets/json/country_list.json';
import ScrollToTop from 'components/Commons/ScrollToTop/ScrollToTop';
import './DiscoverCompanies.scss';
import Papa from 'papaparse';
import FileService from 'services/FileService';


export class DiscoverCompanies extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      area: '',
      data: [],
      error: null,
      result: null,
      techHeatmap: {},
      marketHeatmap: {},
      navBarMenuItems: [],
      userType: '',
      hasSecretId: false,
      shareButtonLoader: false,
      exportButtonLoader: false,
      hasRequestChanged: true,
      page: 1,
      loadMore: false,
      jobs: {},
      exporting: false
    }
    this.keywordSelector = {
      isMulti: true, 
      title: "Keywords",
      apiUrl: '/api/attributeSuggestion',
      requestValueName: 'attributeName',
      placeholder: "e.g. Artificial Intelligence, Digital Twin, or Blockchain",
      onChange: this.onChangeKeywords,
      parserFunction: this.parseJson,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      customInput: {
        shouldPost: true,
        apiUrl: '/api/userInput',
        inputType: 'keyword'
      }
    }
    this.attributeSelector = {
      isMulti: true, 
      title: "Attributes",
      apiUrl: '/api/attributeSuggestion',
      requestValueName: 'attributeName',
      placeholder: "e.g. Manager, NFT, or Blockchain",
      onChange: this.onChangeAttributes,
      parserFunction: this.parseJson,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      customInput: {
        shouldPost: true,
        apiUrl: '/api/userInput',
        inputType: 'keyword'
      }
    }
    const options = [
      { value: "FINANCE", label: "FINANCE", key: "L2J1c2luZXNzL25haWNzMjAwNy81MjpGaW5hbmNl" },
      { value: "INFORMATION", label: "INFORMATION", key: "L2J1c2luZXNzL25haWNzMjAwNy81MTpJbmZvcm1hdGlvbg==" },
      { value: "COMPUTER SERVICES", label: "COMPUTER SERVICES", key: "L2J1c2luZXNzL25haWNzMjAwNy81NDE1OkNvbXB1dGVyIFNlcnZpY2Vz" },
      { value: "CONSULTING", label: "CONSULTING", key: "L2J1c2luZXNzL25haWNzMjAwNy81NDE2OkNvbnN1bHRpbmc=" },
      { value: "MANUFACTURING", label: "MANUFACTURING", key: "L2J1c2luZXNzL25haWNzMjAwNy8zMTpNYW51ZmFjdHVyaW5n" },
      { value: "RETAIL", label: "RETAIL", key: "L2J1c2luZXNzL25haWNzMjAwNy80NDpSZXRhaWw=" },
      { value: "STAFFING", label: "STAFFING", key: "L2J1c2luZXNzL25haWNzMjAwNy81NjEzOlN0YWZmaW5n" },
      { value: "ENGINEERING SERVICES", label: "ENGINEERING SERVICES", key: "L2J1c2luZXNzL25haWNzMjAwNy81NDEzOkVuZ2luZWVyaW5nIFNlcnZpY2Vz" },
      { value: "ENTERTAINMENT", label: "ENTERTAINMENT", key: "L2J1c2luZXNzL25haWNzMjAwNy83MTpFbnRlcnRhaW5tZW50" },
      { value: "ADVERTISING", label: "ADVERTISING", key: "L2J1c2luZXNzL25haWNzMjAwNy81NDE4OkFkdmVydGlzaW5n" },
      { value: "CONSTRUCTION", label: "CONSTRUCTION", key: "L2J1c2luZXNzL25haWNzMjAwNy8yMzpDb25zdHJ1Y3Rpb24=" },
      { value: "EDUCATION", label: "EDUCATION", key: "L2J1c2luZXNzL25haWNzMjAwNy82MTpFZHVjYXRpb24==" }
  ];

    this.companyTypeSelector = {
      isMulti: true, 
      title: "Company Types",
      requestValueName: 'attributeName',
      placeholder: "e.g. Education, Finance, or Retail",
      options:options,
      onChange: this.onChangeCompanyTypes,
      parserFunction: this.parseJson,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,

    }
    this.employerSelector = {
      isMulti: true, 
      title: "Number Of Employees ",
      placeholder: "e.g. 1-10, 10-50 or 50-100",
      onChange: this.onChangeEmployees,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      selectType: 'select',
      options: [
        { value: "1-10", label: "1 - 10" },
        { value: "11-50", label: "11 - 50" },
        { value: "51-100", label: "51 - 100" },
        { value: "101-250", label: "101 - 250" },
        { value: "251-500", label: "251 - 500" },
        { value: "501-1000", label: "501 - 1000" },
        { value: "1001-5000", label: "1001 - 5000" },
        { value: "5001-10000", label: "5001 - 10000" },
        { value: "10001+", label: "10001+" },
      ]
    }
    this.revenueSelector = {
      isMulti: true, 
      title: "Revenue Range",
      placeholder: "e.g. $1M to $10M, $10M to $50M or $1B to $10B",
      onChange: this.onChangeRevenue,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      selectType: 'select',
      options: [
        { value: 'Less than $1M' , label: 'Less than $1M' },
        { value: '$1M to $10M', label: '$1M to $10M' },
        { value: '$10M to $50M', label: '$10M to $50M' },
        { value: '$50M to $100M', label: '$50M to $100M' },
        { value: '$100M to $500M', label: '$100M to $500M' },
        { value: '$500M to $1B', label: '$500M to $1B' },
        { value: '$1B to $10B', label: '$1B to $10B' },
        { value: '$10B+', label: '$10B+' },
      ]
    }
    this.countrySelector = {
      isMulti: true, 
      title: "Country",
      placeholder: "e.g. United States, Germany, Brazil",
      onChange: this.onChangeLocations,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      selectType: 'select',
      options: data.countryList,
      customInput: {
        isCreateable: false
      }
    }
  }

  componentDidMount() {
    const params = getParams(this.props.location);
    const report = params.has('report') && params.get('report');
    const secretId = params.has('secretId') && params.get('secretId');

    if (report && secretId) {
      this.setState({ didURLGenerated: true });
      this.loadSavedReport({ secretId, reportId: report, type: 'jobreports' });
    }
  }

  loadSavedReport(params) {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';

    fetch(`${host}/api/checkSecretId`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((response) => {
        const { status, data } = response;
        if (status) {
          const { currentUserData = {} } = this.props;
          currentUserData.hasSecretId = response.status;
          this.props.receiveCurrentUserData(currentUserData);
          this.setState({ hasSecretId: response.status, keywords: data.keywords, attributes: data.attributes, companyTypes: data.companyTypes });
          this.onFormSubmit();
        } else {
          this.redirectToLogin();
        }
      });
  }

  redirectToLogin = () => {
    this.props.updateMessagePopup({
      isOpen: true,
      title: 'Secret ID has expired.',
      text: 'Your Secret ID has expired, please login to view the report.',
      icon: 'https', 
      iconColor: "red-light",
      afterClose: 5000
    });
    this.props.history.push({ propsData: {...this.props} }, '/login');
  }

  onChangeLocations = (locations) => {
    this.setState({
      locations,
      hasRequestChanged: true
    });
  }

  onChangeKeywords = (value) => {
    this.setState({
      keywords: value,
      hasRequestChanged: true
    });
  }

  onChangeAttributes = (value) => {
    this.setState({
      attributes: value,
      hasRequestChanged: true
    });
  }

  onChangeCompanyTypes = (value) => {
    this.setState({
      companyTypes: value,
      hasRequestChanged: true
    });
  }

  onChangeEmployees = (value) => {
    this.setState({
      employees: value,
      hasRequestChanged: true
    });
  }

  onChangeRevenue = (value) => {
    this.setState({
      revenues: value,
      hasRequestChanged: true
    });
  }

  parseJson = (response) => {
    return response.json().then((json) => {
      const { results = [] } = json;
      const uniqueResults = results.filter((e, i) => results.findIndex(a => a.search_term === e.search_term) === i);
        
      let output;
      if (uniqueResults.length === 1) {
        output = uniqueResults[0].associated_attributes;
      } else {
        output = uniqueResults.map(o => o.search_term);
      }

      return output.map(search_term => ({ value: search_term.toUpperCase() , label: search_term.toUpperCase() }));
    });
  }

  componentWillMount() {
    this.props.fetchCurrentUserData();
  }

  onTitleChange = (value) => {
    this.setState({
      titles: value,
      showReport: false,
      hasRequestChanged: true,
      id: null
    });
  }

  onCompanyChange = (value) => {
    this.setState({
      company: value,
      showReport: false,
      hasRequestChanged: true,
      id: null
    });
  }

  onFormSubmit = () => {
    this.setState({ loadMore: true, page: 1, lastPageReached: true, companyList: [], selectedCompanies: [], sorting: '', sortingDirection: '' }, () => { 
      this.props.updateSelectedCompanies([]);
      this.props.clearCompanyList();
      this.findCompanies() 
    });
  }

  onLoadMore = () => {
    this.setState({ loadMore: false, page: 1, lastPageReached: true, companyList: [], selectedCompanies: [], sorting: '', sortingDirection: '' }, () => { 
      this.props.updateSelectedCompanies([]);
      this.props.clearCompanyList();
      this.findCompanies(true) 
    });
  }

  exportFullReport = async () => {
    this.setState({ exportButtonLoader: true });

    const { attributes } = this.props;
    const companyList = this.state.companyList;
    await this.generateCsv(companyList, attributes);

    this.setState({ exportButtonLoader: false });
  }

  exportReport = async () => {
    this.setState({ exporting: true });

    const { selectedCompanies, attributes } = this.props;
    await this.generateCsv(selectedCompanies, attributes);

    this.setState({ exporting: false });
  }

  generateCsv = (companyList, attributes) => {
    const jobs = companyList.map(i => i.jobs).flat();
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = { data:jobs, attributes };

    return new Promise((resolve) => {
      fetch(`${host}/api/get/attribute_indexes`, { 
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then((response) => {
        let { data } = response;
  
        // if there are no attributes, export job descriptions
        data = data.map(i => {
          if (i.attributeIndexes && i.attributeIndexes.length > 0) {
            return i.attributeIndexes.map(k => {
              return {
                ...i, attribute: k[0]
              };
            })
          } else {
            return i;
          }
        }).flat();
    
        const pick = ({ company_name, company_url, description_text, fullDescription, locations,
          title, url, via, keywords, company_type, published_date, attributes, attribute }) => ({
            "Company": company_name,
            "Company Url": company_url,
            "Company Description": description_text,
            "Job Description": fullDescription,
            "Job Title": title,
            "Job Url": url,
            "Via": via,
            "Keywords": keywords && keywords.join(", "),
            "Company Type": company_type,
            "Published Date": published_date,
            "Attributes": attributes,
            "Attribute Snippet": attribute
          });
  
        let csv = Papa.unparse(data.map(pick));
  
        if (csv) {
          csv = '\ufeff' + csv;
        }else{
          csv = '';
        }
        new FileService().downloadFileContent(document, csv, 'export.csv');
        this.setState({ exporting: false });
        this.pushExportNotification(`export.csv`);
        resolve();
      });
    });    
  }

  pushExportNotification = (fileName) => {
    const toastData = {
      title: "Export is ready",
      text: `"${fileName}" has been downloaded.`,
      iconName: "cloud_done",// insert_chart
      iconColor: "blue",
      duration: 8000,
    };
    this.props.pushToastMessage(toastData);
  }

  findCompanies = (loadMore) => {
    this.setState({ loading: true });
    this.props.setRequestStatus(true);
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const { keywords = [], attributes = [], companyTypes = [], employees = [], page, revenues = [], locations = [], sorting = '', sortingDirection } = this.state;
    const params = { 
      keywords: keywords.map(keyword => keyword.value),
      attributes: attributes.map(attribute => attribute.value),
      companyTypes: companyTypes,
      "number_of_employees": employees.map(employer => employer.value),
      "revenue_range": revenues.map(revenue => revenue.value),
      "locations":  locations.map(location => location.value),
      "sort": sorting !== '' ? [sorting, sortingDirection]: [],
      "size": 10, 
      "page": page,
      "multiple_match": true,
      "exact_match": true,
      addToken: true,
      json:true,
      loadMore:loadMore
    };

    fetch(`${host}/api/search/company`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    })
    .then(response => response.json()) 
    .then((response) => {
      const { data } = response;
      let state = { loading: false, hasRequestChanged: false };
      if (data.length > 0) {
        this.props.addCompanyList(data);
        state.companyList = data;
      } else {
        state.lastPageReached = true;
      }

      this.props.addAttributes([...new Set(attributes.map(attribute => attribute.value))]);
      this.setState(state, () => {
        this.props.setRequestStatus(false);
      });
    });
  }

  onSortChange = (nextSorting) => {
    if (!nextSorting.hasOwnProperty('sorting')) {
      nextSorting = { sorting: nextSorting.split(':')[0], sortingDirection: nextSorting.split(':')[1]}
    }

    const { sortingDirection, sorting } = nextSorting;
    this.setState({ page: 1, lastPageReached: false, companyList: [], selectedCompanies: [], sorting, sortingDirection, selectedSortValue: sorting +':'+ sortingDirection }, () => {
      this.props.clearCompanyList();
      this.findCompanies() 
    });
  }

  getInsights = () => {
    const { selectedCompanies } = this.props;
    const companyList = selectedCompanies.map(company => company.company_name.toUpperCase() || company.name.toUpperCase());
    const keywords = [...new Set((this.state.keywords || []).concat(this.state.attributes || []).map(i => i.value))];
    window.open("/multiple?companies=" + encodeURIComponent(JSON.stringify(companyList)) + "&keywords=" + encodeURIComponent(JSON.stringify(keywords)), "_blank");
  }

  getFooter = () => {
    const { exporting = []  } = this.state;
    const { selectedCompanies = []  } = this.props;
    return (
      <div className="discover-companies__footer">
        <div className="discover-companies__footer__content">
          <span>
            { selectedCompanies.length } 
          </span>
          Company Selected 
          <button className="discover-companies__footer__button" onClick={this.getInsights}>
            Get Insights
            <i className="material-icons">
              launch
            </i>
          </button>
          <button className="discover-companies__footer__button" onClick={() => this.exportReport()} disabled={exporting}>
            Export
            <i className="material-icons">
            attachment
            </i>
          </button>
        </div>
      </div>
    )
  }

  saveReport = (urlHash = '') => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const newId = randomId(8);
    const { keywords = [], attributes = [], companyTypes = [] } = this.state;
    this.setState({ shareButtonLoader: true });
    fetch(`${host}/api/saveJobReport`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: newId,
        keywords,
        attributes,
        companyTypes
      })
    })
      .then((response) => response.json())
      .then((response) => {
        this.updateURL([
          { key: 'report', value: newId },
          { key: 'secretId', value: response.code },
        ], urlHash);
        const location = this.getWindowLocation();
        this.setState({ didURLGenerated: true, shareButtonLoader: false });
        this.isSafari() ? this.copyTextForSafari(`${location}#${urlHash}`) : this.copyText(`${location}#${urlHash}`);
      });
  }

  updateURL = (params, urlHash) => {
    setMultipleParams(params, this.props.location, this.props.history, urlHash);
  };

  getWindowLocation = () => {
    const { href } = window.location;
    let lastIndex = href.lastIndexOf('#');
    if (lastIndex === -1) {
      lastIndex = href.length;
    }

    return href.substr(0, lastIndex);
  }

  isSafari = () => (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor));
  
  copyText = (text) => {
    new ClipboardService(document).copyText(text);
    this.showCopiedTextMessage();
  }

  showCopiedTextMessage = () => {
    const toastData = {
      title: "Share link is copied to clipboard",
      iconName: "content_copy",
      iconColor: "green-light",
      duration: 3000,
    };
    this.props.pushToastMessage(toastData);
  }

  copyTextForSafari = (text) => {
    const toastData = {
      title: "Click 'Copy' below to get the share link.",
      iconName: "done",
      iconColor: "blue-2",
      duration: 10000,
      buttons:[
        { 
          text: "Copy",
          icon: "content_copy",
          color: "blue",
          onClick: () => {
            new ClipboardService(document).copyText(text);
            this.showCopiedTextMessage();
          }
        }
      ]
    };
    this.props.pushToastMessage(toastData);
  }

  render() {
    const { mobileNavigationStatus, currentUserData = {}, selectedCompanies = [] } = this.props;
    const { keywords = [], attributes = [], companyTypes = [], loading, runningHeatMap, shareButtonLoader, hasRequestChanged, exportButtonLoader, companyList = [] } = this.state;
    const { userType = 'demo' } = currentUserData;
    const isDemoOrFremiumUser = (userType === 'demo' || userType === 'freemium');
    return (
      <div className={`margin-t-0 sidenav ${mobileNavigationStatus ? 'open' : ''}`}>
        <NavBar theme="dark" logo={{ small: true, desktop: true }} isDemo={(userType === 'demo')} withoutNavigation={ false } iconMenuItems={
          [
            <NavBarIconMenuItem key={1} title="Export the current report in csv format" isLoading={exportButtonLoader} onClick={() => this.exportFullReport()} value="export" path="/" color='primary-dark' icon='attachment' disabled={ loading || hasRequestChanged || runningHeatMap || isDemoOrFremiumUser } />,
            <NavBarIconMenuItem key={2} title="Generate a shareable report link"  isLoading={shareButtonLoader} onClick={() => this.saveReport()} value="share" path="/" color='primary-dark' icon='share' disabled={ loading || hasRequestChanged || isDemoOrFremiumUser} />
          ]
        } />
        <div className="discover-companies">
          <div className="discover-companies__form">
            <div className="company-input">
              <AsyncSelector {...this.keywordSelector}  value={keywords} disabled={loading || isDemoOrFremiumUser }  />
            </div>
            <div className="company-input">
              <AsyncSelector {...this.attributeSelector}  value={attributes} disabled={loading || isDemoOrFremiumUser }  />
            </div>
            <div className="company-input">
              <AsyncSelector {...this.companyTypeSelector}  value={companyTypes} disabled={loading || isDemoOrFremiumUser }  />
            </div>
            <button className="discover-companies__form__button" onClick={this.onFormSubmit} disabled={ loading || keywords.length < 1 } style={{ 'pointer-events': loading || keywords.length < 1? 'none': 'auto' }}>Search Companies</button>
          </div>
          <div className={`discover-companies__table ${(companyList.length < 1 && !loading) ? 'visibility--hidden' : ''}`}>
            <CompanyTable onSortChange={this.onSortChange} onLoadMore={this.onLoadMore} loadMore={this.state.loadMore} />
          </div> 
          { selectedCompanies.length > 0 && this.getFooter() }
        </div>
        <ScrollToTop />
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
    pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
    receiveCurrentUserData: (params) => dispatch(receiveCurrentUserData(params)),
    updateMessagePopup: (data) => dispatch(updateMessagePopup(data)),
    addCompanyList: (companyList) => dispatch(addCompanyList(companyList)),
    setRequestStatus: (status) => dispatch(setRequestStatus(status)),
    clearCompanyList: () => dispatch(clearCompanyList()),
    updateSelectedCompanies: (selectedCompanies) => dispatch(updateSelectedCompanies(selectedCompanies)),
    addAttributes: (attributes) => dispatch(addAttributes(attributes)),
  }
};

const mapStateToProps = function (store) {
  const { rootReducers, userReducers } = store;
  const { userDataReducers } = rootReducers;
  const { mobileNavigationReducers, companiesReducers } = userReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
    mobileNavigationStatus: mobileNavigationReducers.status,
    selectedCompanies: companiesReducers.selectedCompanies,
    attributes: companiesReducers.attributes
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverCompanies);
