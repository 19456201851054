class StringUtils {
  getCompanyLogo = (companyName) => {
    if (!companyName) {
      return 'https://img.icons8.com/fluent/344/company.png';
    }

    const defaultUrl = `http://a85d0cd31764c416db1103d194c5b1b1-1418577275.us-east-1.elb.amazonaws.com` + `/static/company_images/${encodeURIComponent(companyName.toLowerCase())}.png`;
    // return `https://images.weserv.nl/?url=https://enkiimg.s3.amazonaws.com/${encodeURIComponent(companyName.toLowerCase())}.png&default=${defaultUrl}`;
    return `https://images.weserv.nl/?url=https://enkiai.blob.core.windows.net/enki/${encodeURIComponent(companyName.toLowerCase())}.png`;
  }

  generateKey = (index, name) => (`${index * new Date().getMilliseconds()}_${name}`);

  formatToUSDCurrency = (numberString = '') => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    return formatter.format(numberString);
  }
}

export default StringUtils;
