import React, { Component } from 'react';
import CardView from 'components/Views/CardView/CardView';
import companyInformationloader from 'assets/img/placeholders/company_information_values.svg';

export class CompanyInformationValuesLoader extends Component {
  render() {
    return (
      <CardView childComponent={
        <img className="responsive-image" src={companyInformationloader} />
      } />
    )
  }
}

export default CompanyInformationValuesLoader;
