import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import NavBar from 'components/Navigations/NavBar/NavBar';
import { pushToastMessage } from 'store/actions/root/toast';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import { fetchCurrentUserData, receiveCurrentUserData } from 'store/actions/root/user';
import { addCompanyList, setRequestStatus, clearCompanyList, updateSelectedCompanies, addAttributes } from 'store/actions/user/companies';
import CircleCheckbox from 'components/form/CircleCheckbox/CircleCheckbox';
import ScrollToTop from 'components/Commons/ScrollToTop/ScrollToTop';
import './Idea.scss';

function mdToAnchor(md) {
  const rxCommonMarkLink = /(\[([^\]]+)])\(([^)]+)\)/g;
  const anchor = md
    ? md.replace(rxCommonMarkLink, '<a target="_blank" href="$3">$2</a>')
    : md
    ;
  return anchor;
}

export class Idea extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chat: [],
      message: "",
      typing: false,
      myDataCheckBox : {
        id: 'useMyReportsData',
        text: 'Use Data From Enki Reports',
        value: true,
        color: 'blue',
      },
      liteDataCheckBox : {
        id: 'liteData',
        text: 'Use Data From On-Demand Crawlers',
        value: false,
        color: 'blue',
      },
      modelDataCheckBox : {
        id: 'modelData',
        text: 'Use Idea Generation Data',
        value: false,
        color: 'blue',
      },
      longReportCheckBox : {
        id: 'longReport',
        text: 'Write A Detailed Report',
        value: false,
        color: 'blue',
      },
      shortReportCheckBox : {
        id: 'shortReport',
        text: 'Write A Short Bullet List',
        value: true,
        color: 'blue',
      },
      customReportCheckBox : {
        id: 'customReport',
        text: 'Generate A Custom Output For Idea Generation',
        value: false,
        color: 'blue',
      },
      myDataDate : '2020-01-01'
    }
  }

  componentWillMount() {
    this.props.fetchCurrentUserData();
  }

  getAnswer = async (message, chat) => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      chat: chat,
      modelData: true
    };
    try {
      const response = await fetch(`${host}/api/nlp/chatStream`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body)
      })
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const message = value
        const messages = this.state.chat;
        const recentMessage = messages[this.state.chat.length - 1];

        if (recentMessage.role === "assistant") {
          recentMessage.content += message;
          recentMessage.content = mdToAnchor(recentMessage.content);
          this.setState({ chat: messages })
        } else {
          this.setState({ typing: false, chat: [...this.state.chat, { role: "assistant", content: mdToAnchor(message) }] })
        }
      }
    } catch {
      const messages = this.state.chat;
      const recentMessage = messages[this.state.chat.length - 1];

      if (recentMessage.role === "assistant")
        this.setState({ typing: true, chat: messages.slice(0, -1) });
      else
        this.setState({ typing: true });

      this.getAnswer(message, chat);
    }
  }

  onKeyUp = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.onClick()
    }
  }

  onClick = () => {
    const message = this.state.message;
    const chat = [...this.state.chat, { role: "user", content: message }];
    this.setState({ typing: true, chat: chat })
    this.getAnswer(message, chat)
    this.setState({ message: "" })
  }

  onInputChange = (e) => {
    this.setState({ message: e.target.value });
  }

  onDataCheckBoxChange = (checkbox) => {
    return (e) => {
      const { myDataCheckBox, liteDataCheckBox, modelDataCheckBox } = this.state;

      myDataCheckBox.value = false;
      liteDataCheckBox.value = false;
      modelDataCheckBox.value = false;
      checkbox.value = true;

      this.setState({ myDataCheckBox, liteDataCheckBox, modelDataCheckBox });
    }
  }

  onLengthCheckBoxChange = (checkbox) => {
    return (e) => {
      const { longReportCheckBox, shortReportCheckBox, customReportCheckBox } = this.state;

      longReportCheckBox.value = false;
      shortReportCheckBox.value = false;
      customReportCheckBox.value = false;
      checkbox.value = true;

      this.setState({ longReportCheckBox, shortReportCheckBox, customReportCheckBox });
    }
  }

  onChangeDate = ({ target }) => {
    this.setState({ myDataDate: target.value });
  }

  render() {
    const { chat, typing, myDataCheckBox, liteDataCheckBox, modelDataCheckBox, longReportCheckBox, shortReportCheckBox, customReportCheckBox, myDataDate } = this.state;
    const { mobileNavigationStatus, currentUserData = {} } = this.props;
    const { userType = 'demo' } = currentUserData;

    return (
      <div className={`margin-t-0 sidenav ${mobileNavigationStatus ? 'open' : ''}`}>
        <NavBar theme="dark" logo={{ small: true, desktop: true }} isDemo={(userType === 'demo')} withoutNavigation={false} iconMenuItems={
          [
          ]
        } />
        <div className="idea">
          <div className="idea__form">
            <div className="">
              <div className="idea__head__title">Ask Enki to generate ideas</div>
              <div className="idea__head__subtitle">Describe your problem. Ask for ideas.</div>
              {/* <div className='idea__form__checkboxes'>
                <div className='idea__form__checkbox'>
                  <CircleCheckbox {...myDataCheckBox} handleChange={this.onDataCheckBoxChange(myDataCheckBox)} />
                  {myDataCheckBox.value && <input type="date" value={myDataDate} min="2000-01-01" onChange={this.onChangeDate}/>}
                </div>
                <div className='idea__form__checkbox'><CircleCheckbox {...liteDataCheckBox} handleChange={this.onDataCheckBoxChange(liteDataCheckBox)} /></div>
              </div>
              <div className='idea__form__checkboxes'>
                <div className='idea__form__checkbox'><CircleCheckbox {...shortReportCheckBox} handleChange={this.onLengthCheckBoxChange(shortReportCheckBox)} /></div>
                <div className='idea__form__checkbox'><CircleCheckbox {...longReportCheckBox} handleChange={this.onLengthCheckBoxChange(longReportCheckBox)} /></div>
              </div> */}
            </div>

            <div class="idea__form__history-container">
              {chat.length === 0
                ? ""
                : chat.map((msg) => <div className={"idea__form__" + msg.role} dangerouslySetInnerHTML={{__html: msg.content}}></div>)}

              {typing === true ? <div className={"idea__form__typing"}><div class="typing">
                <span></span>
                <span></span>
                <span></span>
              </div></div> : ""}
            </div>

            <div class="idea__form__input-container">
            <div class="idea__form__icon" onClick={this.onClick} > <i class="material-icons">send</i></div>
            <TextareaAutosize
              rows="1"
              autoFocus
              className="idea__form__input"
              onChange={this.onInputChange}
              onKeyPress={this.onKeyUp}
              value={this.state.message}
            />
            </div>

          </div>
        </div>
        <ScrollToTop />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
    pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
    receiveCurrentUserData: (params) => dispatch(receiveCurrentUserData(params)),
    updateMessagePopup: (data) => dispatch(updateMessagePopup(data)),
    setRequestStatus: (status) => dispatch(setRequestStatus(status)),
  }
};

const mapStateToProps = function (store) {
  const { rootReducers, userReducers } = store;
  const { userDataReducers } = rootReducers;
  const { mobileNavigationReducers } = userReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
    mobileNavigationStatus: mobileNavigationReducers.status
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Idea);
