import React, { Component } from 'react';
import { Image } from 'components/Image/Image';
import './CompanyContentAttributes.scss';

export class CompanyContentAttributes extends Component {
  render() {
    const { companyList = [] } = this.props;
    return (
      <div className="company-content-attributes">
        {
          companyList.map(({ image, name, url }, i) => {
            if(name) {
              return <div className="company-content-attributes__item" key={i}>
                <div className="company-content-attributes__item__image">
                  <Image src={image} alt={name} />
                </div>
                <div className="company-content-attributes__item__name">
                  { name }
                </div>
              </div>
            }
          })
        }
      </div>
    )
  }
}

export default CompanyContentAttributes;
