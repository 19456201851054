import React, { Component } from 'react';
import RankedCompanyCard from '../../../Reports/RankedCompanyCard/RankedCompanyCard';
import './RankedCompanyList.scss';

export class RankedCompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = { rankedData: [] };
  }

  componentWillMount() {
    const { rankedDatas } = this.props;
    const firstActiveElement = rankedDatas.findIndex(
      ({ dataList }) => dataList && dataList.length > 0
    );
    if (firstActiveElement > -1) {
      this.setState({ rankedData: rankedDatas[firstActiveElement] });
    }
  }

  handleSourceChange = (dataItem) => {
    this.setState({ rankedData: dataItem });
  }

  render() {
    const { rankedDatas, userType = 'demo' } = this.props;
    const { rankedData } = this.state;
    const { dataList = [], sourceName = '' } = rankedData;
    return (
      <div className="ranked-company-list">
        <div className="ranked-company-list__sources">
          {
            rankedDatas.map((dataItem, index) => (
              <div
                className={ `ranked-company-list__sources__item ${ sourceName == dataItem.sourceName ? 'selected':'' }` } 
                onClick={() => { this.handleSourceChange(dataItem) } }
                disabled={ !dataItem.dataList || dataItem.dataList.length < 1 }
                key={index} 
              >
                { dataItem.sourceName }
              </div>
            ))
          }
        </div>
        {
          dataList.sort((a, b) => (b.percent - a.percent))
            .map(({ name, percent, reportId, infoList }, index) => (
              <RankedCompanyCard
                company={name}
                percent={percent}
                reportId={reportId}
                key={index}
                infoList={infoList}
                userType={userType}
              />
            ))
        }
      </div>
    );
  }
}

export default RankedCompanyList;
