import React from 'react';
import { connect } from 'react-redux';
import BlurComponent from 'components/BlurComponent/BlurComponent';
import { relevancyCompare, isSimilar } from './util';
import SimpleWordle from './wordle.simple';
import InformationView from 'components/User/Reports/InformationView/InformationView';
import InsightCard from 'components/Reports/InsightCard/InsightCard';
import AttributePeople from 'components/Reports/Attributes/AttributePeople/AttributePeople';
import { IconButton } from 'components/form/IconButton/IconButton';
import NoResultContent from './components/Demo/NoResultContent/NoResultContent';
import NumberUtils from './utils/NumberUtils';
import StringUtils from 'utils/StringUtils';

const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      like: {},
      unlike: {},
      selectedWordleLabels: {},
    };
  }

  onTrain(article, voteType) {
    const newState = this.state[voteType];
    newState[article.title.toLowerCase()] = true;
    this.setState({
      [voteType]: newState,
    });
    this.train(article, voteType, this.props.type);
  }

  train(article, voteType, type) {
    const { metadata } = this.props;
    const { fetchKey } = metadata;
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const { currentUserData = {} } = this.props;
    const body = JSON.stringify({
      url: article.url,
      title: article.title,
      insightType: type,
      relevance: voteType === 'unlike' ? 1 : 10,
      user: currentUserData.email,
    });
    fetch(`${host}/api/trainV2?key=${encodeURIComponent(fetchKey)}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }).then((response) => {

    }).catch((err) => {
      alert(err);
    });
  }

  scoreCompare(a, b) {
    const { metadata, type, debug } = this.props;
    const articleA = metadata[type][a] && metadata[type][a][0];
    const articleB = metadata[type][b] && metadata[type][b][0];
    return relevancyCompare(articleA, articleB);
  }

  onWordleLabelClick(type, field, params) {
    this.setState({
      selectedWordleLabels: {
        ...this.state.selectedWordleLabels,
        [type]: { attribute: params.name, field },
      },
    });
  }

  clearWordleLabel(type) {
    this.setState({
      selectedWordleLabels: { ...this.state.selectedWordleLabels, [type]: undefined },
    });
  }

  populateWordle(metadata, article, matchedAttributeField, wordle) {
    if (article[matchedAttributeField]) {
      article[matchedAttributeField].forEach((attribute) => {
        if (metadata.area !== attribute.toUpperCase()) {
          if (!wordle[attribute]) {
            wordle[attribute] = [];
          }
          wordle[attribute].push(article);
        }
      });
    }
  }

  isExcludedByWordleAttribute(article, selectedWordleLabel) {
    if (selectedWordleLabel) {
      const field = selectedWordleLabel.field;
      const attribute = selectedWordleLabel.attribute;
      if ((!article[field]) || (article[field] && article[field].indexOf(attribute) === -1)) {
        return true;
      }
    }

    return false;
  }

  getWordleHtml(metadata, articleGroups, type, selectedWordleLabels, debug) {
    const ITWordleData = {};
    const ITPainPointWordleData = {};
    const ITCompetitorWordleData = {};
    const IoTWordleData = {};
    const IoTPainPointWordleData = {};
    const IoTValuePropWordleData = {};
    const InsurTechInitWordle = {};
    const InsurTechValuePropWordle = {};
    const TechInitWordle = {};
    const InsurTechPainPointWordle = {};
    const InsurTechCompanyWordle = {};
    const InsurTechStartupWordle = {};
    const InsurTechVCWordle = {};
    const SalesInsightWordle = {};
    const SalesValuePropWordle = {};
    const SalesCompetitionTechWordle = {};
    const SalesPainPointWordle = {};
    const MessagingPlatformInsightWordle = {};
    const attributeWordleData = {};
    const contextualConceptWordleData = {};
    const companyWordleData = {};
    const locationWordleData = {};

    Object.keys(metadata[type]).forEach(s => {
      const articles = articleGroups.get(s);
      articles.forEach(article => {
        this.populateWordle(metadata, article, 'matchedAttributesIT', ITWordleData);
        this.populateWordle(metadata, article, 'matchedAttributesITPainPoint', ITPainPointWordleData);
        this.populateWordle(metadata, article, 'matchedAttributesITCompetitor', ITCompetitorWordleData);

        this.populateWordle(metadata, article, 'matchedAttributesIoT', IoTWordleData);
        this.populateWordle(metadata, article, 'matchedAttributesIoTPainPoint', IoTPainPointWordleData);
        this.populateWordle(metadata, article, 'matchedAttributesIoTValueProp', IoTValuePropWordleData);

        this.populateWordle(metadata, article, 'matchedAttributesInsurTechInit', InsurTechInitWordle);
        this.populateWordle(metadata, article, 'matchedAttributesInsurTechValueProp', InsurTechValuePropWordle);
        this.populateWordle(metadata, article, 'matchedAttributesTechInit', TechInitWordle);
        this.populateWordle(metadata, article, 'matchedAttributesInsurTechPainPoint', InsurTechPainPointWordle);
        this.populateWordle(metadata, article, 'matchedAttributesInsurTechCompany', InsurTechCompanyWordle);
        this.populateWordle(metadata, article, 'matchedAttributesInsurTechStartup', InsurTechStartupWordle);
        this.populateWordle(metadata, article, 'matchedAttributesInsurTechVC', InsurTechVCWordle);

        this.populateWordle(metadata, article, 'matchedAttributesSalesInsight', SalesInsightWordle);
        this.populateWordle(metadata, article, 'matchedAttributesSalesValueProp', SalesValuePropWordle);
        this.populateWordle(metadata, article, 'matchedAttributesSalesCompetitionTech', SalesCompetitionTechWordle);
        this.populateWordle(metadata, article, 'matchedAttributesSalesPainPoint', SalesPainPointWordle);

        this.populateWordle(metadata, article, 'matchedAttributesMessagingPlatformInsight', MessagingPlatformInsightWordle);

        this.populateWordle(metadata, article, 'matchedAttributes', attributeWordleData);
        this.populateWordle(metadata, article, 'contextualConcepts', contextualConceptWordleData);
        this.populateWordle(metadata, article, 'companies', companyWordleData);
        this.populateWordle(metadata, article, 'locations', locationWordleData);
      });
    });

    const showITWordle = Object.keys(ITWordleData).length > 0;
    const showITPainPointWordle = Object.keys(ITPainPointWordleData).length > 0;
    const showITCompetitorWordle = Object.keys(ITCompetitorWordleData).length > 0;

    const showIoTWordle = Object.keys(IoTWordleData).length > 0;
    const showIoTPainPointWordle = Object.keys(IoTPainPointWordleData).length > 0;
    const showIoTValuePropWordle = Object.keys(IoTValuePropWordleData).length > 0;

    const showInsurTechInitWordle = Object.keys(InsurTechInitWordle).length > 0;
    const showInsurTechValuePropWordle = Object.keys(InsurTechValuePropWordle).length > 0;
    const showTechInitWordle = Object.keys(TechInitWordle).length > 0;
    const showInsurTechPainPointWordle = Object.keys(InsurTechPainPointWordle).length > 0;
    const showInsurTechCompanyWordle = Object.keys(InsurTechCompanyWordle).length > 0;
    const showInsurTechStartupWordle = Object.keys(InsurTechStartupWordle).length > 0;
    const showInsurTechVCWordle = Object.keys(InsurTechVCWordle).length > 0;

    const showSalesInsightWordle = Object.keys(SalesInsightWordle).length > 0;
    const showSalesValuePropWordle = Object.keys(SalesValuePropWordle).length > 0;
    const showSalesCompetitionTechWordle = Object.keys(SalesCompetitionTechWordle).length > 0;
    const showSalesPainPointWordle = Object.keys(SalesPainPointWordle).length > 0;

    const showMessagingPlatformInsightWordle = Object.keys(MessagingPlatformInsightWordle).length > 0;

    const showAttributeWordle = Object.keys(attributeWordleData).length > 0;
    const showContextualConceptWordle = Object.keys(contextualConceptWordleData).length > 0;
    const showCompanyWordle = metadata.companyWordleEnabled && Object.keys(companyWordleData).length > 0;
    const showLocationWordle = Object.keys(locationWordleData).length > 0;

    const activeList = [
      showITWordle,
      showITPainPointWordle,
      showITCompetitorWordle,
      showIoTWordle,
      showIoTPainPointWordle,
      showIoTValuePropWordle,
      showInsurTechInitWordle,
      showInsurTechValuePropWordle,
      showTechInitWordle,
      showInsurTechPainPointWordle,
      showInsurTechCompanyWordle,
      showInsurTechStartupWordle,
      showInsurTechVCWordle,
      showSalesInsightWordle,
      showSalesValuePropWordle,
      showSalesCompetitionTechWordle,
      showSalesPainPointWordle,
      showMessagingPlatformInsightWordle,
      showAttributeWordle,
      showContextualConceptWordle,
      showCompanyWordle,
      showLocationWordle
    ];

    const showWordle = activeList.some(item => item === true);
    const wordleHtml = selectedWordleLabels[type] 
      ? <h4><IconButton classes="cursor--pointer" iconName="arrow_back" size="large" text={selectedWordleLabels[type].attribute.toUpperCase()} color="blue-2-high" handleClick={this.clearWordleLabel.bind(this, type)} /> </h4> 
      : <div className='article-wordle-area'>
      {showITWordle && <div className='article-world-child'><h5 className='title'>IT</h5><SimpleWordle data={ITWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesIT')} /></div>}
      {showITPainPointWordle && <div className='article-world-child'><h5 className='title'>IT pain points</h5><SimpleWordle data={ITPainPointWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesITPainPoint')} /></div>}
      {showITCompetitorWordle && <div className='article-world-child'><h5 className='title'>IT competitors</h5><SimpleWordle data={ITCompetitorWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesITCompetitor')} /></div>}
      {showIoTWordle && <div className='article-world-child'><h5 className='title'>IoT</h5><SimpleWordle data={IoTWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesIoT')} /></div>}
      {showIoTPainPointWordle && <div className='article-world-child'><h5 className='title'>IoT pain points</h5><SimpleWordle data={IoTPainPointWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesIoTPainPoint')} /></div>}
      {showIoTValuePropWordle && <div className='article-world-child'><h5 className='title'>IoT value propositions</h5><SimpleWordle data={IoTValuePropWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesIoTValueProp')} /></div>}
      {showInsurTechInitWordle && <div className='article-world-child'><h5 className='title'>InsurTech initiatives</h5><SimpleWordle data={InsurTechInitWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesInsurTechInit')} /></div>}
      {showInsurTechValuePropWordle && <div className='article-world-child'><h5 className='title'>InsurTech value propositions</h5><SimpleWordle data={InsurTechValuePropWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesInsurTechValueProp')} /></div>}
      {showTechInitWordle && <div className='article-world-child'><h5 className='title'>Technology initiatives</h5><SimpleWordle data={TechInitWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesTechInit')} /></div>}
      {showInsurTechPainPointWordle && <div className='article-world-child'><h5 className='title'>InsurTech pain points</h5><SimpleWordle data={InsurTechPainPointWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesInsurTechPainPoint')} /></div>}
      {showInsurTechCompanyWordle && <div className='article-world-child'><h5 className='title'>InsurTech companies</h5><SimpleWordle data={InsurTechCompanyWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesInsurTechCompany')} /></div>}
      {showInsurTechStartupWordle && <div className='article-world-child'><h5 className='title'>InsurTech startups</h5><SimpleWordle data={InsurTechStartupWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesInsurTechStartup')} /></div>}
      {showInsurTechVCWordle && <div className='article-world-child'><h5 className='title'>InsurTech VCs</h5><SimpleWordle data={InsurTechVCWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesInsurTechVC')} /></div>}
      {showSalesInsightWordle && <div className='article-world-child'><h5 className='title'>Sales insights</h5><SimpleWordle data={SalesInsightWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesSalesInsight')} /></div>}
      {showSalesValuePropWordle && <div className='article-world-child'><h5 className='title'>Sales value propositions</h5><SimpleWordle data={SalesValuePropWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesSalesValueProp')} /></div>}
      {showSalesCompetitionTechWordle && <div className='article-world-child'><h5 className='title'>Competition technologies</h5><SimpleWordle data={SalesCompetitionTechWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesSalesCompetitionTech')} /></div>}
      {showSalesPainPointWordle && <div className='article-world-child'><h5 className='title'>Sales pain points</h5><SimpleWordle data={SalesPainPointWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesSalesPainPoint')} /></div>}
      {showMessagingPlatformInsightWordle && <div className='article-world-child'><h5 className='title'>Messaging platform insights</h5><SimpleWordle data={MessagingPlatformInsightWordle} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributesMessagingPlatformInsight')} /></div>}
      {showAttributeWordle && <div className='article-world-child'><h5 className='title'>Solution attributes</h5><SimpleWordle data={attributeWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'matchedAttributes')} /></div>}
      {showContextualConceptWordle && <div className='article-world-child'><h5 className='title'>Contextual concepts</h5><SimpleWordle data={contextualConceptWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'contextualConcepts')} /></div>}
      {showCompanyWordle && <div className='article-world-child'><h5 className='title'>Companies</h5><SimpleWordle data={companyWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'companies')} /></div>}
      {showLocationWordle && <div className='article-world-child'><h5 className='title'>Locations</h5><SimpleWordle data={locationWordleData} type={type} source={metadata.source} onLabelClick={this.onWordleLabelClick.bind(this, type, 'locations')} /></div>}
    </div>;

    return showWordle && wordleHtml;
  }

  onInsightCardButtonClick = (article, type) => {
    this.onTrain(article, type);
  }

  getHtmlContent = (article) => {
    const { title } = article;
    const { indexes } = article;
    const { reportCompanyName, metadata } = this.props;
    const { hasContextualConcept = false } = metadata;
    let newContent = [];
    let lastIndex = 0;

    if (indexes) {
      let infoItems = [];
      const {
        attributes = [], company = [], company_entities = [], person_entities = [], contextual_concepts = [],
        speaker = [], competitors = [], location_entities = [],
      } = indexes;
      [...attributes, ...location_entities].forEach((item) => {
        infoItems = [...infoItems, { startIndex: item[0], endIndex: item[1], type: 'attribute' }];
      });

      if (hasContextualConcept) {
        contextual_concepts.forEach((item) => {
          infoItems = [...infoItems, { startIndex: item[0], endIndex: item[1], type: "contextual_concepts" }];
        });
      }

      company.forEach((item) => {
        infoItems = [...infoItems, { startIndex: item[0], endIndex: item[1], type: 'company' }];
      });

      [...company_entities, ...competitors].forEach((item) => {
        infoItems = [...infoItems, { startIndex: item[0], endIndex: item[1], type: 'company_entities' }];
      });

      [...speaker, ...person_entities].forEach((item) => {
        infoItems = [...infoItems, { startIndex: item[0], endIndex: item[1], type: 'person_entities' }];
      });

      infoItems.sort((a, b) => a.startIndex - b.startIndex);
      let companyList = [];
      infoItems.forEach((item) => {
        newContent = [...newContent, { content: title.slice(lastIndex, item.startIndex), type: 'text' }];
        const keyword = title.slice(item.startIndex, item.endIndex);
        let sourceName = null;
        lastIndex = item.endIndex;
        if (item.type === 'company' && keyword.toLowerCase() !== reportCompanyName.toLowerCase()) {
          sourceName = reportCompanyName.toLowerCase();
        }

        if ((item.type === 'company' || item.type === 'company_entities') && companyList.indexOf(keyword) < 0) {
          companyList = [...companyList, keyword];
        }

        newContent = [...newContent, { content: keyword, type: item.type, source: sourceName }];
      });

      if (lastIndex < title.length) {
        const contentText = title.slice(lastIndex, title.length);
        newContent = [...newContent, { content: contentText, type: 'text' }];
      }

      newContent = newContent.map((item) => {
        if (item.type === 'person_entities') {
          item['company_list'] = companyList;
        }

        return item;
      });
    } else {
      newContent = [{ content: title, type: 'text' }];
    }

    return newContent;
  }

  getMatchedAttributesList(article) {
    const attributeFields = [
      'matchedAttributes',
      'matchedAttributesIT',
      'matchedAttributesITPainPoint',
      'matchedAttributesITCompetitor',
      'matchedAttributesIoT',
      'matchedAttributesIoTPainPoint',
      'matchedAttributesIoTValueProp',
      'matchedAttributesInsurTechInit',
      'matchedAttributesInsurTechValueProp',
      'matchedAttributesTechInit',
      'matchedAttributesInsurTechPainPoint',
      'matchedAttributesInsurTechCompany',
      'matchedAttributesInsurTechStartup',
      'matchedAttributesInsurTechVC',
      'matchedAttributesSalesInsight',
      'matchedAttributesSalesValueProp',
      'matchedAttributesSalesCompetitionTech',
      'matchedAttributesSalesPainPoint',
      'matchedAttributesMessagingPlatformInsight'
    ];

    const list = new Set();
    attributeFields.forEach(field => {
      if (article[field]) {
        article[field].forEach(field => {
          list.add(field);
        });
      }
    });
    return [...list];
  }

  

  getArticleContent = (article) => {
    const elementContents = this.getHtmlContent(article);

    return elementContents.map(({ source, content, type, company_list }, i) => {
      const key = new StringUtils().generateKey(i, type);
      const elements = {
        text: <span key={key}> {content} </span>,
        attribute: <InformationView key={key} name={content} hasTooltip={false} type="attribute" />,
        person_entities: <InformationView key={key} name={content} companyList={company_list} hasTooltip={true} type="linkedin" />,
        company: <InformationView key={key} name={content} hasTooltip={true} type="company" source={source} />,
        company_entities: <InformationView key={key} name={content} hasTooltip={true} type="company" source={source} />,
        default: <InformationView key={key} name={content} hasTooltip={false} type={type} />,
      };
      return elements[type] || elements.default;
    });
  }

  getPublishedDate = (article) => {
    return (article.published_date || article.date) && <span className="fadeText">{new Date(article.published_date || article.date).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}</span>;
  }

  groupContentBySubtitle = (articles) => {
    const articleList = {};

    articles
      .forEach((article) => {
        if (!articleList.hasOwnProperty(article.subtitle)) {
          articleList[article.subtitle || article.title] = [
            article,
          ];
        } else {
          articleList[article.subtitle || article.title] = [...articleList[article.subtitle], { ...article, isSubContent: true }];
        }
      });

    return Object.keys(articleList).map((key) => articleList[key]).flat();
  }

  getGroupInsights = (articles, data) => {
    const article = articles[0];
    const publishedDate = this.getPublishedDate(article);
    const { userType = '', hasSecretId = false, type, uniqueSet = [], s, index, relatedChampions = [] } = data;
    const { insightType, championKey } = article;
    const isQuotes = (
      type === 'quotes' || type === 'quotesV2' || type === 'executiveQuotes' || type === 'externalQuotes' ||
      insightType === 'quotes' || insightType === 'quotesV2' || insightType === 'executiveQuotes' || insightType === 'externalQuotes'
    );
    const championProfile = isQuotes && this.getChampion(championKey || s);
    articles = articles.filter(article => {
      if (article.key && !isSimilar(uniqueSet, article.key, article.matchedAttributes)) {
        uniqueSet.push(article.key);
        return true;
      }
    });
    articles = this.groupContentBySubtitle(articles);
    articles = articles
      .map((article) => {
        return {
          content: this.getArticleContent(article),
          matchedAttributeList: this.getMatchedAttributesList(article),
          publishedDate: this.getPublishedDate(article),
          onLikeButtonClick: (articleData, type) => this.onTrain(articleData, type),
          onUnLikeButtonClick: (articleData, type) => this.onTrain(articleData, type),
          ...article,
        };
      });
    if (articles.length > 0) {
      return (
        <div key={index}>
          <InsightCard 
            article={article}
            championProfile = {championProfile}
            publishedDate = {publishedDate}
            title = {article.group}
            url = {article.link || article.url}
            groupContent={
              articles
            }
            onInsightCardButtonClick={ (type) => this.onTrain(article, type) }
          />
          {
            relatedChampions && relatedChampions.length > 0 ?
            <div>
              <b>Champions in the Same Business Unit</b><br/>
              { 
                (userType !== 'demo' || hasSecretId) ? 
                <AttributePeople people={relatedChampions} />:
                <BlurComponent isHidden={(userType !== 'demo' || hasSecretId)} level={6} options={{button: { text: "Show Champions" }}} child={
                  <AttributePeople people={relatedChampions} />
                } />
              }
            </div>: ''
          }
        </div>
      );
    }
  }

  getChampion = (s) => {
    const { metadata = {} } = this.props;
    const { championProfiles = {} } = metadata;
    return championProfiles[s] || { name: s, linkedin: '' };
  }

  checkPublishedDate = (publishedDate, filteredMonth) => {
    const targetDate = new Date();
    targetDate.setMonth(targetDate.getMonth() - filteredMonth);
    const dateTime = targetDate.getTime();
    return new Date(publishedDate).getTime() > dateTime;
  }

  checktitleCompanyMatch = (titleCompanyMatched, { titleCompanyMatch }) => (
    titleCompanyMatched == null || titleCompanyMatched === titleCompanyMatch
  );

  checkAttributeCompanyMatch = (titleAttributeMatched, { titleAttributeMatch }) => (
    titleAttributeMatched == null || titleAttributeMatched === titleAttributeMatch
  );

  filterArticle = (article, type) => {
    const { articleFilter = {} } = this.props;
    const { filteredMonth = 0, titleCompanyMatched, titleAttributeMatched } = articleFilter;
    const dateFilter = filteredMonth === 0
      || this.checkPublishedDate(article.published_date, filteredMonth);
    return dateFilter
    && this.checktitleCompanyMatch(titleCompanyMatched, article)
    && this.checkAttributeCompanyMatch(titleAttributeMatched, article)
    && !this.isExcludedByWordleAttribute(article, type);
  }

  render() {
    const { metadata, type, currentUserData = {}, debug } = this.props;
    const { selectedWordleLabels } = this.state;
    const { userType = '', hasSecretId = false } = currentUserData;

    if (!metadata || !metadata[type]) {
      return null;
    }

    const uniqueSet = [];
    const articleGroups = new Map();
    let groups = [];
    Object.keys(metadata[type]).forEach(key => {
      const filteredArticles = metadata[type][key].filter(article => this.filterArticle(article, selectedWordleLabels[type]));
      articleGroups.set(key, filteredArticles.sort(relevancyCompare));
      if (filteredArticles.length > 0) {
        groups = [...groups, key];
      }
    });

    const wordleHtml = this.getWordleHtml(metadata, articleGroups, type, selectedWordleLabels, debug);

    const articlesHtml = groups
    .sort(this.scoreCompare.bind(this))
    .map((s, i) => {
        let articles = articleGroups.get(s);
        const article = articles[0];
        const { insightType, championKey } = article;
        const isQuotes = (
          type === 'quotes' || type === 'quotesV2' || type === 'executiveQuotes' || type === 'externalQuotes' || 
          insightType === 'quotes' || insightType === 'quotesV2' || insightType === 'executiveQuotes' || insightType === 'externalQuotes'
        );
        const championProfile = isQuotes && this.getChampion(championKey || s);
        
        const relatedChampions = isQuotes && metadata.relatedChampions[s];
        let hasContent = false;
        let elements;
        if (articles.length > 1) {
          elements = this.getGroupInsights(articles, { ...currentUserData, index: i, s, uniqueSet, type, selectedWordleLabels, relatedChampions } );
        } else {
          elements = articles.map((article, index) => {
            if (!article.key || isSimilar(uniqueSet, article.key, article.matchedAttributes)) {
              return '';
            }

            hasContent = true;
            uniqueSet.push(article.key);
            const { emailTemplates = [] } = article;
            const publishedDate = this.getPublishedDate(article);
            const attributeList = this.getMatchedAttributesList(article);
            return (
              <div key={index}>
                <InsightCard
                  article={article}
                  championProfile={championProfile}
                  matchedAttributes={attributeList}
                  publishedDate={publishedDate}
                  title={article.subtitle || stripHtml(s)}
                  url={article.link || article.url}
                  insightType={article.insightType || undefined}
                  childContent={
                    this.getArticleContent(article)
                  }
                  additionalProperties={{ emailTemplates }}
                  onInsightCardButtonClick={(eventType) => this.onTrain(article, eventType)}
                />
              </div>
            );
          });
        }

        if (relatedChampions && relatedChampions.length > 0 && hasContent) {
          elements.push(
            <div>
              <b>Champions in the Same Business Unit</b><br/>
              { 
                (userType !== 'demo' || hasSecretId) ?
                <AttributePeople people={relatedChampions} />:
                <BlurComponent isHidden={(userType !== 'demo' || hasSecretId)} level={6} options={{button: { text: "Show Champions" }}} child={
                  <AttributePeople people={relatedChampions} />
                } />
              }
            </div>
          );
        }
  
        return elements;
    });

    return <div>
      {wordleHtml}
      <div>
        {articlesHtml}
        { groups.length === 0 && <NoResultContent textContent={
            <div className="no-result-content__text">
              No match in the applied filters
              <div className="no-result-content__sub-text">
                Try expanding the filters to see more insights
              </div>
            </div>
          }
        />}
      </div>
    </div>;
  }
}

const mapStateToProps = (store) => {
  const { rootReducers, dashBoardReducers } = store;
  const { userDataReducers } = rootReducers;
  const { reportReducers } = dashBoardReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
    reportCompanyName: reportReducers.companyName,
    articleFilter: reportReducers.articleFilter,
  };
};

export default connect(mapStateToProps, null)(Articles);
