import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { SearchBar } from 'components/form/SearchBar/SearchBar';
import { AttributeViews } from 'components/Views/AttributeViews/AttributeViews';
import './MultipleSelectBox.scss';

export class MultipleSelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isMenuActive: false, search: '' };
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onSelectBoxClicked = this.onSelectBoxClicked.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isMenuActive: false });
    }
  }

  onSelectBoxClicked() {
    this.setState({ isMenuActive: true });
  }

  onSelectItem(checked, checkbox) {
    const { list = [] } = this.props;
    const index = list.findIndex((item) => item.value === checkbox.id);
    list[index].checked = checked;
    this.setState({ list });
    this.props.onChangeValues(list);
  }

  onSearchChange = (search = '') => {
    this.setState({ search });
  }

  getSearchContent = () => (
    <div className="multiple-select-box__menu__search">
      <SearchBar placeholder="Search" onValueChange={this.onSearchChange} />
    </div>
  );

  mapValuesToLabel = (values) => values.map(({ text, color }) => ({
    text,
    color,
  }))

  getSelectedValuesLabels = (selectedValues) => {
    const filteredValues = this.mapValuesToLabel(selectedValues);
    return (
      <div className="multiple-select-box__button__labels">
        <AttributeViews list={filteredValues} hideMoreButton="true" max={5} />
      </div>
    );
  }

  searchInText = (search, text) => (
    search.isNullOrEmpty() || text.toLowerCase().indexOf(search.toLowerCase()) >= 0
  );

  onClearAll = () => {
    let { list } = this.props;
    list = list.map(item => {
      item.checked = false;
      return item;
    });

    this.setState({ list });
    this.props.onChangeValues(list);
  }

  onSelectAll = () => {
    let { list } = this.props;
    list = list.map((item) => ({
      ...item,
      checked: true,
      id: item.value,
    }));
    this.setState({ list });
    this.props.onChangeValues(list);
  }

  clearHighlightedList = (list) => list.map((item) => {
    item.highlightedText = null;
    return item;
  });

  highlightList = (list, search) => {
    if (search.length > 0) {
      search = search.toLowerCase();
      return list
        .filter((item) => this.searchInText(search, item.text))
        .map((item) => {
          const replacedText = item.text.toLowerCase().replace(search, `<span class='HIGHLIGHT-TEXT'>${search}</span>`);
          item.highlightedText = replacedText.toUpperCase();
          return item;
        });
    }

    return this.clearHighlightedList(list);
  }

  renderButtons = () => {
    const selectedValues = this.getSelectedValues();
    if (selectedValues.length === 0) {
      return (
        <button className="a-button-link" color="blue-2" onClick={this.onSelectAll} type="button">
          Select All
        </button>
      );
    }

    return (
      <button className="a-button-link" color="blue-2" onClick={this.onClearAll} type="button">
        Clear All
      </button>
    );
  }

  getSelectedValues = () => {
    let { list = [] } = this.props;
    return list
      .filter((item) => item.checked);
  }

  render() {
    const { highlightText, isHighlightText, title = '', searchable = false } = this.props;
    const { search = '', isMenuActive } = this.state;
    const selectedValues = this.getSelectedValues();
    let { list = [] } = this.props;
    list = this.highlightList(list, search);
    const menuClass = isMenuActive ? 'multiple-select-box__menu active' : 'multiple-select-box__menu';
    const menuButtonClass = selectedValues.length === 0 ? 'multiple-select-box__button disabled' : 'multiple-select-box__button';
    const highlightClass = !isHighlightText ? 'visibility--hidden' : '';
    return (
      <div className="multiple-select-box">
        <div className="multiple-select-box__title">
          {title}
        </div>
        <div className="multiple-select-box__content">
          <div className={menuButtonClass} onClick={this.onSelectBoxClicked}>
            { selectedValues.length === 0 ?
              <span className={`multiple-select-box__button__label ${highlightClass}`}> {highlightText} </span> :
              this.getSelectedValuesLabels(selectedValues)
            }
            <i className="material-icons"> expand_more </i>
          </div>
          <div className={menuClass}>
            { searchable && this.getSearchContent() }
            <div className="multiple-select-box__menu__list">
              {
                list.map((item, i) => (
                  <div className={`multiple-select-box__menu__item`} value={item.value} key={i}>
                    <Checkbox {...{ checked: item.checked, id: item.value, value: item.value, text: item.highlightedText || item.text, count: item.count, color: "blue-2", type: "item" }} handleChange={this.onSelectItem} />
                  </div>
                ))
              }
            </div>
            <div className="multiple-select-box__menu__bottom">
              { this.renderButtons() }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MultipleSelectBox;
