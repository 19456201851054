import React, { Component } from 'react';
import { connect } from 'react-redux';
import MobileNavigationMenu from 'components/Navigations/MobileNavigationMenu/MobileNavigationMenu';
import NavBarDropdownButton from 'components/Navigations/NavBarDropdownButton/NavBarDropdownButton';
import { DropdownItemList } from 'commons/DropdownMenuItems';
import userDropDownPlaceHolder from 'assets/img/placeholders/user_dropdown_button.svg';
import './MobileNavigation.scss';

export class MobileNavigation extends Component {

  constructor(props) {
    super(props);
    this.state= { isMenuActive: false };
  }

  onDropdownButtonClick = () => {
    this.setState({ isMenuActive: !this.state.isMenuActive });
  }

  render() {
    const { isMenuActive } = this.state;
    const { currentUserData } = this.props;
    return (
      <div className="mobile-navigation">
        { currentUserData && currentUserData.firstName ?
          <NavBarDropdownButton isActive={isMenuActive} onDropdownButtonClick={this.onDropdownButtonClick} />:
          <img src={userDropDownPlaceHolder} />
        }
        <div className="mobile-navigation__menu">
          <MobileNavigationMenu isActive={isMenuActive} itemList={DropdownItemList} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { userDataReducers } = rootReducers;
  return {
    currentUserData: userDataReducers.currentUserData
  };
};


export default connect(mapStateToProps)(MobileNavigation);
