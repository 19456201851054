import React from 'react';
import { connect } from 'react-redux';
import { parseGQueryResults, randomId, setMultipleParams, getParams, getRandomCode } from './util.jsx';
import Ontology from './ontology';
import TitleOntology from './ontology.title';
import { fetchCurrentUserData, receiveCurrentUserData } from 'store/actions/root/user';
import { fetchDemoReports, fetchFreemiumReports } from 'store/actions/demo/report';
import AttributeSelector from './selector.attributes';
import CompanySelector from './selector.company';
import TitleSelector from './selector.titles'; 
import NavigationDrawer from 'components/Navigations/NavigationDrawer/NavigationDrawer';
import NavBar from 'components/Navigations/NavBar/NavBar';
import UpgradeAccountPopup from 'components/Demo/UpgradeAccountPopup/UpgradeAccountPopup';
import { NavBarIconMenuItem } from 'components/Navigations/NavBarIconMenuItem/NavBarIconMenuItem';
import SignUpBanner from 'components/Demo/SignUpBanner/SignUpBanner.jsx';
import { FreemiumBanner } from 'components/Demo/FreemiumBanner/FreemiumBanner';
import { introJs } from 'intro.js';
import { LocalSettingsService } from './services/LocalSettingsService';
import { CircleLoader } from 'components/CircleLoader/CircleLoader';
import CompanyInformationView from 'components/User/Reports/CompanyInformationView/CompanyInformationView';
import { articleFilterChanged, updateReportCompanyName } from 'store/actions/dashboard/report.js';
import ClipboardService from 'services/ClipboardService';
import { pushToastMessage } from 'store/actions/root/toast';
import AreaSelector from './components/form/SelectorArea/SelectorArea.jsx';
import InsightsView from 'components/Reports/InsightsView/InsightsView';
import ScrollToTop from 'components/Commons/ScrollToTop/ScrollToTop';
import SelectorInput from 'components/form/SelectorInput/SelectorInput.jsx';
import { onGenerateURL } from 'store/actions/root/route';
import { setTooltipData } from 'store/actions/root/tooltip';
import ReportFilterView from 'components/Reports/ReportFilterView/ReportFilterView';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import Checkbox from 'components/Checkbox/Checkbox';
import { timeFrameOptions } from 'commons/SelectDatas';
import { onChangeFilter, onChangeInsightFilter, receivedFilteredInsights } from 'store/actions/reports/report.js';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
const getNavigationElements = (sliderClass) => {
  return (
    <div className={sliderClass}>
      <NavigationDrawer />
    </div>,
    <div className="col d-none col-xl-2 margin-t-0">
      <NavigationDrawer />
    </div>
  )
};

const getTimeFrame = (timeRange) => timeFrameOptions.find(({ value }) => value === timeRange) || { label: 'Custom: ' + timeRange, value: timeRange };

class KingEdition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      error: null,
      result: null,
      techHeatmap: {},
      marketHeatmap: {},
      navBarMenuItems: [],
      userType: '',
      hasSecretId: false,
      exportButtonLoader: false,
      shareButtonLoader: false,
      didURLGenerated: false,
      didFilterChanged: false,
      insightFilter: {},
      generatedURL: '',
      urlHash: '',
      semanticAttributesEnabled: false,
    }
  }

  semanticAttributeCheckbox = {
    id: "semantic_attributes",
    text: "Enable Semantic Attributes",
    color: "primary",
  }

  componentWillMount() {
    this.locations();
    this.props.fetchCurrentUserData();
  }

  componentDidMount() {
    const params = getParams(this.props.location);
    const report = params.has('report') && params.get('report');
    const secretId = params.has('secretId') && params.get('secretId');
    if (report) {
      this.loadReport(report);
    }

    if (secretId) {
      this.setState({ didURLGenerated: true });
      this.checkSecretId({ secretId, reportId: report, type: 'reports' });
    }
  }

  componentWillReceiveProps(props) {
    const { currentUserData = {}, demoCompanyList = [], urlHash = '' } = props;
    const { userType = '' } = currentUserData;
    const data = localStorage.getItem("demoReportParams");
    this.setState({ userType });
    if ((demoCompanyList.length < 1 || userType !== this.state.userType) && data) {
      this.fetchDemoCompanies(userType, data);
      this.loadCompanyNameIntro(userType);
    }

    if (urlHash !== '' && this.state.urlHash !== urlHash) {
      this.setState({ urlHash });
      this.onCopyURLRequest(urlHash);
    }
  }

  updateURL = (params, urlHash) => {
    setMultipleParams(params, this.props.location, this.props.history, urlHash);
  };

  onKeyDown(event) {
    if (event.keyCode === 13) {
      if (this.state.company) {
        this.refresh();
      }
    }
  }

  onAreaChange = (area) => {
    let attributes = this.state.attributes;
    let titles = this.state.titles;
    let prevArea = this.state.area && this.state.area.value;

    const { value } = area;

    const shouldUpdateAttributes = !attributes 
      || (Ontology[prevArea] && JSON.stringify(Ontology[prevArea]) === JSON.stringify(attributes.map(o => o.value)));

    const shouldUpdateTitles = !titles
      || (TitleOntology[prevArea] && JSON.stringify(TitleOntology[prevArea]) === JSON.stringify(titles.map(o => o.value)));

    if (value) {
      const normalizedArea = value.trim();

      if (shouldUpdateAttributes && Ontology[normalizedArea]) {
        attributes = Ontology[normalizedArea].map(val => ({
          label: val,
          value: val
        }));
      }

      if (shouldUpdateTitles && TitleOntology[normalizedArea]) {
        titles = TitleOntology[normalizedArea].map(val => ({
          label: val,
          value: val
        }));
      }
    }

    this.setState({
      area: area,
      attributes,
      titles,
      showReport: false,
      didURLGenerated: false,
      id: null
    });
  }

  onTitleChange = (value) => {
    this.setState({
      titles: value,
      showReport: false,
      didURLGenerated: false,
      id: null
    });
  }

  onChangeLocation = (value) => {
    this.setState({
      location: value,
      showReport: false,
      id: null
    });
  }

  onChangeTimeFrame = (value) => {
    this.setState({
      timeframe: value,
      showReport: false,
      id: null
    });
  }

  onCompanyChange = (value) => {
    const { company, userType } = this.state;
    const hasCompanyChanged = (value !== company);
    this.props.updateReportCompanyName(value);
    this.setState({
      company: value,
      showReport: false,
      didURLGenerated: false,
      id: null,
      insights: null,
    }, () => {
      this.props.setTooltipData({
        data: {},
        height: 21,
        isActive: false,
        left: 0,
        top: 0,
        width: 80.515625,
      });
      if (hasCompanyChanged && userType !== 'premium') {
        this.refresh(); // has to be triggered after setState is done
      }
    });
  }

  onAttributeChange = (value) => {
    this.setState({
      attributes: value,
      showReport: false,
      didURLGenerated: false,
      id: null
    });
  }

  onInputChange = (name, event) => {
    let value = event.target.value.toUpperCase();

    if (value != null) {
      if (value.trim() === '') {
        value = null;
      }

      let attributes = this.state.attributes;

      if (value && !attributes && name === 'area') {
        const normalizedArea = value.trim();
        if (Ontology[normalizedArea]) {
          attributes = Ontology[normalizedArea].map(val => ({
            label: val,
            value: val,
          }));
        }
      }

      this.setState({
        [name]: value,
        attributes,
        showReport: false,
        didURLGenerated: false,
        id: null
      });
    }
  }

  getReportQuery = () => {
    const normalizedCompany = this.state.company && this.state.company.trim();
    this.props.updateReportCompanyName(normalizedCompany);
    const companies = [{ [normalizedCompany]: [normalizedCompany] }];
    const { area, location, timeframe, semanticAttributesEnabled } = this.state;
    const { currentUserData = {} } = this.props;
    const { email = '' } = currentUserData;
    const normalizedArea = area && area.value && area.value.trim();
    const normalizedLocation = location && location.value;
    const normalizedTimeFrame = timeframe && timeframe.value;
    
    const areas = normalizedArea && [{ [normalizedArea]: [normalizedArea] }] || [];

    const attributes = this.state.attributes && this.state.attributes.map(attribute => attribute.value) || [];
    const titles = this.state.titles && this.state.titles.map(title => title.value) || [];
    
    let marketHeatmap = {
      companies,
      areas,
      attributes,
      titles
    };

    // set loading state
    this.setState({
      showReport: true,
      marketHeatmap
    });
    return {
      companies,
      areas,
      attributes,
      titles,
      email,
      addToken: true,
      location: normalizedLocation,
      timeFrame: normalizedTimeFrame,
      semanticAttributesEnabled
    };
  }

  refresh = () => {
    const query = this.getReportQuery();
    this.props.onChangeInsightFilter({ didFilterChanged: false });
    this.props.receivedFilteredInsights({});
    this.props.articleFilterChanged({
      filteredMonth: 0,
      titleCompanyMatched: null,
      titleAttributeMatched: null,
    });
    this.loadGData(query);
  }

  loadGData(query) {
    // set loading state
    this.setState({
      loading: true,
      insightFilter: {},
      requestId: JSON.stringify(query),
    });

    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const endpoint = this.props.source === 'googlenews' ? 'gnews' : 'gsearchV2';
    const body = JSON.stringify(query);
    fetch(`${host}/api/${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          console.log("Metadata", json);
          console.log("Request ID", this.state.requestId);
          console.log("Body", body);
          if (this.state.requestId === body) {
            const { area, company, attributes } = this.state;
            const { source, location } = this.props;
            const normalizedCompany = company && company.trim();
            const normalizedArea = area && area.value && area.value.trim();
            const subAreas =
              attributes && attributes.length > 0 ? attributes.map(a => a.value) : [];
            const debug = getParams(location).has('debug');
            let results = parseGQueryResults([{}], json, source, body, debug);
            if (json.length > 0) {
              this.fillProfileData(json[0]);
            }

            this.setState({
              loading: false,
              id: results[0].id,
              reportFilterEnabled: results[0].reportFilterEnabled,
              insights: {
                query,
                sentences: results[0].result,
                company: normalizedCompany,
                area: normalizedArea,
                showTitle: true,
                showHeatmap: subAreas && subAreas.length > 0 && results[0].done,
                subCompanies: [normalizedCompany],
                subAreas,
                source,
              },
            });
          }
        });
      } else {
        response.text().then((err) => {
          this.setState({ error: JSON.parse(err).error, loading: false });
          alert(JSON.parse(err).error);
        }).catch(() => {
          this.setState({
            error: 'There was a problem with the request, please try again',
            loading: false,
          });
        });
      }
    }).catch((err) => {
      alert(err);
    });
  }

  fillProfileData = (data) => {
    const { metadata } = data;
    const { attributePeople = {}, championProfiles = {}, relatedChampions = {} } = metadata;
    Object.keys(attributePeople).forEach((key) => {
      const peopleList = attributePeople[key];
      if (peopleList && peopleList.constructor === Array) {
        peopleList.forEach((item) => {
          window.profileDatas[item.name] = item;
        });
      }
    });

    Object.keys(championProfiles).forEach((key) => {
      const item = championProfiles[key]
      window.profileDatas[item.name] = item;
    });

    Object.keys(relatedChampions).forEach((key) => {
      relatedChampions[key].forEach((item) => {
        window.profileDatas[item.name] = item;
      });
    })
  }

  exportReport = () => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const id = this.state.id;
    this.setState({ exportButtonLoader: true });
    fetch(`${host}/api/exportReport/${id}`)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = `${id}.csv`;
      document.body.appendChild(a);
      this.setState({ exportButtonLoader: false });
      this.pushExportNotification(`${id}.csv`);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('There is an issue with the export, please try again!'));
  }

  pushExportNotification = (fileName) => {
    const toastData = {
      title: "Export is ready",
      text: `"${fileName}" has been downloaded.`,
      iconName: "cloud_done",// insert_chart
      iconColor: "blue",
      duration: 8000,
    };
    this.props.pushToastMessage(toastData);
  }

  logOut = () => {
    this.props.history.push('/log-out');
  }

  saveReport = (urlHash = '') => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const newId = randomId(8);
    const {
      currentDashboard,
      company,
      area,
      attributes,
      titles,
      techHeatmapState,
      marketHeatmapState,
      location,
      timeframe,
      semanticAttributesEnabled,
      insightFilter,
      didFilterChanged,
    } = this.state;
    this.setState({ shareButtonLoader: true });
    fetch(`${host}/api/saveReport`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: newId,
        source: currentDashboard,
        company,
        area: area && area.value,
        attributes,
        titles,
        location: location && location.value,
        timeRange: timeframe && timeframe.value,
        semanticAttributesEnabled,
        techHeatmap: {
          areas: techHeatmapState && techHeatmapState.areas,
          companies: techHeatmapState && techHeatmapState.companies,
        },
        marketHeatmap: {
          areas: marketHeatmapState && marketHeatmapState.areas,
          companies: marketHeatmapState && marketHeatmapState.companies,
        },
        ...(didFilterChanged && { insightFilter }),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.updateURL([
          { key: 'report', value: newId },
          { key: 'secretId', value: response.code },
        ], urlHash);
        const location = this.getWindowLocation();
        this.setState({ didURLGenerated: true, shareButtonLoader: false });
        this.isSafari() ? this.copyTextForSafari(`${location}#${urlHash}`) : this.copyText(`${location}#${urlHash}`);
      });
  }

  onCopyURLRequest = (urlHash) => {
    const { didURLGenerated = false } = this.state;
    if (didURLGenerated) {
      const location = this.getWindowLocation();
      this.isSafari() ? this.copyTextForSafari(`${location}#${urlHash}`) : this.copyText(`${location}#${urlHash}`);
    } else {
      this.saveReport(urlHash);
    }

    setTimeout(() => {
      this.setState({ urlHash: '' });
      this.props.onGenerateURL('');
    }, 2500);
  }

  getInsightsForFilter = (storedData, insightFilter) => {
    const { area = '', company, attributes } = storedData;
    const { source } = this.props;
    const normalizedCompany = company && company.trim();
    const normalizedArea = area.trim();
    const subAreas =
      attributes && attributes.length > 0 ? attributes.map((a) => a.value) : [];
    return {
      insights: {
        id: insightFilter.companyId,
        sentences: {
          props: {},
        },
        source,
        company: normalizedCompany,
        subCompanies: [normalizedCompany],
        subAreas,
        area: normalizedArea,
        insightFilter,
      },
    };
  }

  getInsightsFromUrl = (urlParams, storedData) => {
    const { attributes } = storedData;
    const keywords = urlParams.has('keywords') ? urlParams.get('keywords').split(',') : attributes.map(({ value }) => value);
    let sections = [
      'heatmapData',
      'painPointV2',
      'competitors',
      'quotes',
      'executiveQuotes',
      'externalQuotes',
      'attributePeople',
      'partnership',
      'investment',
      'hiring',
      'initiative',
      'opportunity',
      'growth',
      'promotion',
      'win',
      'generic',
    ];

    if (urlParams.has('sections')) {
      sections = urlParams.get('sections').split(',');
    }

    return {
      titleAttributeMatched: null,
      titleCompanyMatched: null,
      companyId: urlParams.get('companyId'),
      matchCount: 1,
      dateFilter: 0,
      keywords,
      sections,
    };
  }

  loadReport = (id) => {
    const url = `${host}/api/loadReport?name=${id}`;
    fetch(url, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        response.json().then((storedData) => {
          if (storedData) {
            let {
              area,
              semanticAttributesEnabled,
              timeRange,
              location,
              insightFilter,
            } = storedData;
            const { search } = this.props.location;
            const urlParams = new URLSearchParams(search);

            if (urlParams.has('keywords') || urlParams.has('sections')) {
              insightFilter = this.getInsightsFromUrl(urlParams, storedData);
            }

            this.setState({
              ...storedData,
              ...(area && { area: { value: area, label: area } }),
              ...(timeRange && { timeframe: getTimeFrame(timeRange) }),
              currentDashboard: storedData.source,
              location: this.getLocation(location),
              semanticAttributesEnabled,
              ...(insightFilter && {
                id: insightFilter.companyId,
                ...this.getInsightsForFilter(storedData, insightFilter),
              }),
              loading: false,
              reportFilterEnabled: insightFilter,
            }, () => {
              if (insightFilter) {
                this.onFilterChange(insightFilter);
              } else {
                this.refresh();
              }
            });
          }
        });
      } else {
        response.text().then((err) => {
          this.setState({ error: JSON.parse(err).error, loading: false });
          alert(JSON.parse(err).error);
        }).catch(() => {
          this.setState({
            error: 'There was a problem with the request, please try again',
            loading: false,
          });
        });
      }
    });
  }

  loadCompanyNameIntro(userType) {
    const { localSettings } = window;
    if (userType !== 'premium' && !localSettings.hasCompanyIntroShown) {
      setTimeout(() => {
        introJs()
        .setOption('showBullets', false)
        .setOption('doneLabel', 'OK')
        .start();
      }, 500);
      localSettings.hasCompanyIntroShown = true;
      new LocalSettingsService().updateLocalSettings(localSettings);
    }
  }

  onToastClickEvent() {
    console.log("Toast Clicked");
  }

  copyText = (text) => {
    new ClipboardService(document).copyText(text);
    this.showCopiedTextMessage();
  }

  showCopiedTextMessage = () => {
    const toastData = {
      title: "Share link is copied to clipboard",
      iconName: "content_copy",
      iconColor: "green-light",
      duration: 3000,
    };
    this.props.pushToastMessage(toastData);
  }

  getLocation = (location) => {
    const { locations = [] } = this.state;
    return locations.find(({ value }) => value === location) || undefined;
  }

  getWindowLocation = () => {
    const { href } = window.location;
    let lastIndex = href.lastIndexOf('#');
    if (lastIndex === -1) {
      lastIndex = href.length;
    }

    return href.substr(0, lastIndex);
  }

  isSafari = () => (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor));

  copyTextForSafari = (text) => {
    const toastData = {
      title: "Click 'Copy' below to get the share link.",
      iconName: "done",
      iconColor: "blue-2",
      duration: 10000,
      buttons:[
        { 
          text: "Copy",
          icon: "content_copy",
          color: "blue",
          onClick: () => {
            new ClipboardService(document).copyText(text);
            this.showCopiedTextMessage();
          }
        }
      ]
    };
    this.props.pushToastMessage(toastData);
  }

  checkSecretId(params) {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';

    fetch(`${host}/api/checkSecretId`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((response) => {
        const { status } = response;
        if (status) {
          const { currentUserData = {} } = this.props;
          currentUserData.hasSecretId = response.status;
          this.props.receiveCurrentUserData(currentUserData);
          this.setState({ hasSecretId: response.status });
        } else {
          this.redirectToLogin();
        }
      });
  }

  redirectToLogin = () => {
    this.props.updateMessagePopup({
      isOpen: true,
      title: 'Secret ID has expired.',
      text: 'Your Secret ID has expired, please login to view the report.',
      icon: 'https', 
      iconColor: "red-light",
      afterClose: 5000
    });
    this.props.history.push({ propsData: {...this.props} }, '/login');
  }

  fetchDemoCompanies(userType, data) {
    switch(userType) {
      case 'demo':
        this.props.fetchDemoReports(JSON.parse(data));
        break;
      case 'freemium':
        this.props.fetchFreemiumReports(JSON.parse(data));
        break;
    }
  }

  locations = (inputValue) => {
    const body = JSON.stringify(
      { 
        inputType: 'title',
        inputValue: inputValue 
      }
    );
    fetch(`${host}/api/gsearchV2/geolocations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    })
      .then(response => response.json()) 
      .then((response) => {
        this.setState({ locations: response.map(({ code, name }) => {
          return { value: code, label: name.toUpperCase() }
        }) });
      });
  }

  renderDebugInputs = () => {
    const { currentUserData = {} } = this.props;
    const { userType = '' } = currentUserData;
    const { hasSecretId } = this.state;
    const disableInputs = (userType === 'demo' && hasSecretId);
    return (
      <div>
        <div className="tool-config">
          <h2>Locations</h2>
          <div className="attribute-input">
            <SelectorInput onChange={this.onChangeLocation} placeholder="e.g. United States, United Kingdom" value={this.state.location} options={this.state.locations} disabled={(this.state.locked || disableInputs)} />
          </div>
        </div>
        <div className="tool-config">
          <h2>Timeframe</h2>
          <div className="attribute-input">
            <SelectorInput onChange={this.onChangeTimeFrame} placeholder="e.g. Last Week, Last Month" value={this.state.timeframe} options={timeFrameOptions} disabled={(this.state.locked || disableInputs)} />
          </div>
        </div>
        <div className="padding-l-10 margin-t-15">
          <Checkbox {...{checked: this.state.semanticAttributesEnabled, ...this.semanticAttributeCheckbox }} handleChange={(isChecked) => this.setState({ semanticAttributesEnabled: isChecked})} />
        </div>
      </div>
    );
  }

  onFilterChange = (insightFilter) => {
    const reportQuery = this.getReportQuery();
    this.setState({ insightFilter, didFilterChanged: true });
    // @todo add heatmap interval
    this.props.onChangeFilter({
      ...insightFilter,
      reportQuery,
    });
  }

  render() {
    const debug = getParams(this.props.location).has('debug');
    const {
      mobileNavigationStatus,
      currentUserData = {},
      demoCompanyList = [],
    } = this.props;
    const { userType = '' } = currentUserData;
    const {
      hasSecretId,
      shareButtonLoader,
      exportButtonLoader,
      id,
      titles,
      insightFilter,
      showReport,
      loading,
      insights,
      reportFilterEnabled,
      company = []
    } = this.state;
    const sliderClass = mobileNavigationStatus ? 'dashboard-page__slider open' : 'dashboard-page__slider';
    const containerClass = ((userType === 'demo' && !hasSecretId) || userType === 'freemium') ? 'row margin-t-50' : 'row';
    const disableInputs = (userType === 'demo' && hasSecretId);
    const isDemoOrFremiumUser = (userType === 'demo' || userType === 'freemium');

    return ( 
      <div className="margin-t-0">
        {
          userType === 'demo' && !hasSecretId ?
            <SignUpBanner /> : ''
        }
        {
          userType === 'freemium' ?
            <FreemiumBanner theme="dark" isSticky={true} /> : ''
        }
        <div className={containerClass}>
          { userType === 'premium' ?
            getNavigationElements(sliderClass) : ''
          }
          <div className={ `col col-md-12 col-xl-12 margin-t-0 sidenav ${mobileNavigationStatus ? 'open':'' }` }>
            <NavBar isDemo={userType == 'demo'} withoutNavigation={userType === 'demo'} logo={{ small: true, desktop: (userType !== 'demo') }} iconMenuItems={
                [
                  <NavBarIconMenuItem key={1} title="Export the current report in csv format" isLoading={exportButtonLoader} onClick={() => this.exportReport()} value="export" path="/" color='primary-dark' icon='attachment' disabled={userType == 'demo' || (!this.state.id) } />,
                  <NavBarIconMenuItem key={2} title="Generate a shareable report link" isLoading={shareButtonLoader} onClick={() => this.saveReport()} value="share" path="/" color='primary-dark' icon='share' disabled={(userType == 'demo' || !this.state.company || this.state.locked)} />
                ]
              }
             />
             <div className="company-reports">
              <div className="company-reports__form">
            <div className="company-reports__form__item">
              <div className="company-input" data-intro="Change the company at any time to see a new report.">
                <div className="company-input__title">
                  Companies
                  <div className="a-tooltip right d-none d-sm-inline-block margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Company / Industry
                      </h4>
                      <div className="a-tooltip__content__text">
                        Enki discovers Insights based on your "Target company or industry". When you enter a "Target company", such as "Chevron" or "AAA", either choose a suggestion from Enki or you can "Create" a new entry.   
                      </div>
                    </span>
                  </div>
                  <div className="a-tooltip d-inline-block d-sm-none margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Company / Industry
                      </h4>
                      <div className="a-tooltip__content__text">
                        Enki discovers Insights based on your "Target company or industry". When you enter a "Target company", such as "Chevron" or "AAA", either choose a suggestion from Enki or you can "Create" a new entry.   
                      </div>
                    </span>
                  </div>
                </div>
                    {
                      this.state.lockedCompanies
                        ? <select value={this.state.company} onChange={this.onInputChange.bind(this, 'company')}>
                          {this.state.lockedCompanies.map(c => <option value={c.value}>{c.label}</option>)}
                        </select>
                        :
                        <CompanySelector value={this.state.company} onChange={this.onCompanyChange} demoCompanyList={userType !== 'premium' ? demoCompanyList : []} isMulti={false} disabled={disableInputs} />
                    }

              </div>
              <UpgradeAccountPopup isActive={(userType !== 'premium' && !hasSecretId || userType == 'freemium')} color={"white-transparent"} userType={userType} level={0} child={
                  <div>
              <div className="company-input">
                <div className="company-input__title">
                  Target Vertical
                  <div className="a-tooltip right  d-none d-sm-inline-block margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Target Vertical
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can choose a predefined "Target Vertical" you are selling to such as "IoT Insights", or create a new one such as "Auto Manufacturers", "Insurance", etc.
                      </div>
                    </span>
                  </div>
                  <div className="a-tooltip d-inline-block d-sm-none margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Target Vertical
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can choose a predefined "Target Vertical" you are selling to such as "IoT Insights", or create a new one such as "Auto Manufacturers", "Insurance", etc.
                      </div>
                    </span>
                  </div>
                </div>
                <AreaSelector onChange={this.onAreaChange} value={this.state.area} disabled={(this.state.locked || disableInputs)} />
              </div>



              <div className="company-input">
                <div className="company-input__title">
                Solution Attributes
                  <div className="a-tooltip right  d-none d-sm-inline-block margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                      Solution Attributes
                      </h4>
                      <div className="a-tooltip__content__text">
                      Type your "Solution", such as "Predictive Maintenance", "Pain" you are addressing, such as "Prevent Failures", and "Value Proposition" such as "Reduce Downtime". Enki will filter the insights based on your "Solution Attributes". We suggest you enter around 10 solution attributes, but you can enter up to 20.  
                           </div>
                    </span>
                  </div>
                  <div className="a-tooltip d-inline-block d-sm-none margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                      Solution Attributes
                      </h4>
                      <div className="a-tooltip__content__text">
                      Type your "Solution", such as "Predictive Maintenance", "Pain" you are addressing, such as "Prevent Failures", and "Value Proposition" such as "Reduce Downtime". Enki will filter the insights based on your "Solution Attributes". We suggest you enter around 10 solution attributes, but you can enter up to 20.  
                       </div>
                    </span>
                  </div>
                </div>
                <AttributeSelector onChange={this.onAttributeChange} value={this.state.attributes} disabled={(this.state.locked || disableInputs)} />
                     </div>
              
              <div className="company-input">
                <div className="company-input__title">
                  Job Titles / Departments
                  <div className="a-tooltip right  d-none d-sm-inline-block margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Job Titles / Departments
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can enter the Job Titles / Departments such as "Director of IT", "Digital Transformation", "Human Resources" you target into Enki. Enki will crawl the web and combine the job titles with your solution attributes and discover the "Top Prospects". Enki can also find their contact information if requested. 
                      </div>
                    </span>
                  </div>
                  <div className="a-tooltip d-inline-block d-sm-none margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Job Titles / Departments
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can enter the Job Titles / Departments such as "Director of IT", "Digital Transformation", "Human Resources" you target into Enki. Enki will crawl the web and combine the job titles with your solution attributes and discover the "Top Prospects". Enki can also find their contact information if requested. 
                      </div>
                    </span>
                  </div>
                </div>
                <TitleSelector onChange={this.onTitleChange} value={this.state.titles} disabled={(this.state.locked || disableInputs)} />
              </div>
                    { debug && this.renderDebugInputs() }
                  </div>
                } /> 
            </div>
            <div className="company-reports__form__footer">
              
                <button className="company-reports__form__button" onClick={() => this.refresh()} disabled={ loading || company.length < 1 } style={{ 'pointer-events': loading || company.length < 1? 'none': 'auto' }}>
                  Get Insight
                </button>
              
            </div>
          </div>
          </div>
              {
                showReport && <CompanyInformationView name={this.state.company} />
              }
              { 
                id && !loading && showReport && reportFilterEnabled &&
                <ReportFilterView 
                  companyId={id} 
                  titles={titles} 
                  insightFilter={insightFilter} 
                  onFilterChange={this.onFilterChange} 
                /> 
              }
            <div className="tool-container margin-t-0">
              {
                showReport &&
                <div>
                  { loading && <CircleLoader isActive={true} size="small" /> }
                  {!loading && insights ? 
                    <InsightsView
                      id={id} 
                      company={this.state.company}
                      isEdit={debug} {...this.state.insights} 
                      onInsight={(insights) => insights && this.setState({ insights: { ...this.state.insights, company: insights.company, area: insights.area, sentences: insights.sentences } })} /> : '' 
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  const { rootReducers, demoReducers, userReducers } = store;
  const { mobileNavigationReducers } = userReducers;
  const { userDataReducers, routeReducers } = rootReducers;
  const { reportReducers } = demoReducers;
  const { urlHash } = routeReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
    demoCompanyList: reportReducers.demoCompanyList,
    mobileNavigationStatus: mobileNavigationReducers.status,
    urlHash: urlHash
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeFilter: (data) => dispatch(onChangeFilter(data)),
  onChangeInsightFilter: (insight) => dispatch(onChangeInsightFilter(insight)),
  articleFilterChanged: (articleFilter) => dispatch(articleFilterChanged(articleFilter)),
  receivedFilteredInsights: (metadata) => dispatch(receivedFilteredInsights(metadata)),
  fetchFreemiumReports: (params) => dispatch(fetchFreemiumReports(params)),
  fetchDemoReports: (params) => dispatch(fetchDemoReports(params)),
  fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
  receiveCurrentUserData: (params) => dispatch(receiveCurrentUserData(params)),
  updateReportCompanyName: (companyName) => dispatch(updateReportCompanyName(companyName)),
  pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
  updateMessagePopup: (data) => dispatch(updateMessagePopup(data)),
  setTooltipData: (data) => dispatch(setTooltipData(data)),
  onGenerateURL: (urlHash) => dispatch(onGenerateURL(urlHash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KingEdition);
