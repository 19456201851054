import { ON_CHANGE_ROUTE, ON_GENERATE_URL } from 'store/actions/root/route';

const DEFAULT_STATE = {
  urlHash: ''
};
const routeReducers = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ON_CHANGE_ROUTE:
      return Object.assign({}, state, {
        type: action.type,
        action: action.action,
        location: action.location,
      });
    case ON_GENERATE_URL:
      return Object.assign({}, state, {
        type: action.type,
        action: action.action,
        urlHash: action.urlHash,
      });
    default:
      return state
  }
}

export default routeReducers;
