import React, { Component } from 'react'
import './FreemiumBanner.scss';

export class FreemiumBanner extends Component {
  render() {
    const { theme = '', isSticky=false } = this.props;
    return (
      <div className={ isSticky ? 'freemium-banner sticky':'freemium-banner' } data-theme={theme}>
        <div className="freemium-banner__content">
          <div className="freemium-banner__content__text"> 
            Looking for More <b>Personalized Insights?</b> Upgrade to premium and get full access to enki solution
          </div>
          <a className="freemium-banner__content__button" href="https://enkiai.com/contact" target="_blank">
            Upgrade
          </a> 
        </div>
      </div>
    )
  }
}

export default FreemiumBanner;
