import React from 'react';
import ReactECharts from 'echarts-for-react';

require('echarts-wordcloud');

export class Wordle extends React.Component {
  getOption(data) {
    let option = {
        tooltip: {
            confine: true,
            enterable: true,
            triggerOn: 'click',
            formatter: (params) => {
              const item = data[params.dataIndex];
              if (item && item.aggregations && item.aggregations[0] && item.aggregations[0].results) {
                  const sentences = item.aggregations[0].results.map(r => r.key);
                  return `<b>${params.name}</b><br/>`
                  + sentences.map(s => `- ${s}<br/>`).join('')
              }

              return params.value;
            },
            extraCssText: 'max-width: 400px; white-space: initial; user-select: text'
        },
        series: [{
            name: 'Count',
            type: 'wordCloud',
            size: ['80%', '80%'],
            rotationRange: [0, 0],
            textPadding: 0,
            autoSize: {
                enable: true,
                minSize: 14
            },
            textStyle: {
              color: function () {
                return 'rgb(' + [
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160)
                ].join(',') + ')';
              }
            },
            data: data.map(d => ({
                name: d.key.substr(d.key.lastIndexOf('/') + 1),
                value: d['matching_results']
            }))
        }]
    };

    return option;
  }

  render() {
    return (
      <ReactECharts 
          option={this.getOption(this.props.data)}
          style={{height: '470px'}}
      />
    );
  }
}

export default Wordle;
