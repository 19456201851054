import React, { Component } from 'react';
import { connect } from 'react-redux';
import circleProfile from 'assets/img/icons/circle_profile.png';
import { Image } from 'components/Image/Image';
import StringUtils from 'utils/StringUtils';
import './NavBarDropdownButton.scss';

export class NavBarDropdownButton extends Component {

  onClickHandle = (e) => {
    this.props.onDropdownButtonClick(e)
  }


  render() {
    const { currentUserData, isActive, theme="default" } = this.props;
    const { companyName } = currentUserData;

    const className = isActive ? "dropdown-button active" : "dropdown-button";
    return (
      <div className={className} onClick={this.onClickHandle} data-theme={theme}>
        <div className="dropdown-button__image">
          <Image src={circleProfile} />
        </div>
        <div className="dropdown-button__content">
          <i className="dropdown-button__content__icon material-icons">arrow_drop_up</i>
          <div className="dropdown-button__content__title">
          { `${currentUserData.firstName} ${currentUserData.lastName}` }
          </div>
          <div className="dropdown-button__content__sub-content">
            <Image src={new StringUtils().getCompanyLogo(companyName)} alt={companyName} />
            <span>
              { currentUserData.companyName }
            </span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { userDataReducers } = rootReducers;
  return {
    currentUserData: userDataReducers.currentUserData
  };
};

export default connect(mapStateToProps)(NavBarDropdownButton);
