import React, { Component } from 'react';
import './NavBarDropDownMenu.scss';
import { Link } from 'react-router-dom';

export class NavBarDropDownMenu extends Component {
  render() {
    const { isActive, itemList } = this.props;
    const menuClass = isActive ? 'navbar-dropdown-menu active' : 'navbar-dropdown-menu';
    return (
      <div className={menuClass}>
        {
          itemList.map((item, i) => {
            return <Link to={item.path} key={i}>
              <div className="navbar-dropdown-menu__item">
                <div className="navbar-dropdown-menu__item__circle" color={item.color}>
                  <i className="material-icons">
                    { item.icon }
                  </i>
                </div>
                <div className="navbar-dropdown-menu__item__text">
                  { item.name }
                </div>
              </div>
            </Link>
          })
        }
      </div>
    )
  }
}

export default NavBarDropDownMenu
