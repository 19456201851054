import React, { Component } from 'react';
import './CircleCheckbox.scss';

export class CircleCheckbox extends Component {

  constructor(props) {
    super(props)
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.state = {
      isChecked: props.value
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isChecked: nextProps.value})
  }

  onChangeCheckbox(e) {
    const { checked } = e.target;
    this.setState({ isChecked: checked });
    this.props.handleChange(checked);
  }

  render() {
    const { text, color, id } = this.props;
    const { isChecked } = this.state;
    return (
      <div className="a-circle-checkbox">
        <div className="a-circle-checkbox__inner">
          <input
            className="a-circle-checkbox__input"
            color={color}
            id={id}
            type="checkbox"
            checked={isChecked}
            onChange={this.onChangeCheckbox}
          />
          <label htmlFor={id}>{text}</label>
        </div>
      </div>
    )
  }
}

export default CircleCheckbox;
