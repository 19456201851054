import React, { Component } from 'react';
import FloatingInputComponent from 'components/FloatingInput/FloatingInput'
import Checkbox from 'components/Checkbox/Checkbox'
import './LoginForm.scss'
import { HttpService } from 'services/HttpService';
import { TokenService } from 'services/TokenService';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import CookieService from 'services/CookieService';
import MessageBox from 'components/MessageBox/MessageBox';
import { Link } from 'react-router-dom';
import AnalyticsService from 'services/AnalyticsService';

export class LoginFormComponent extends Component {

  rememberMeCheckbox = {
    id: "remember_me",
    text: "Remember Me",
    color: "secondary",
    classes: "margin-t-20 margin-b-10"
  }

  messageBox = {
    title: "Login Failed",
    message: "Check your Email and enter the verification code to reset your Password.",
    color: "danger",
    icon: "error",
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.saveLoginCookies = this.saveLoginCookies.bind(this);
    this.onSuccessRequest = this.onSuccessRequest.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.onMessageClose = this.onMessageClose.bind(this);
    this.state = {
      email: '',
      password: '',
      loaderIsActive: false,
      rememberMe: false,
      token: '',
      showMessageBox: false
    };
  }

  componentDidMount() {
    this.getCookies();
  }

  getCookies() {
    const email = new CookieService().getCookie('email');
    this.setState({
      email: email,
    });
  }

  onMessageClose(event) {
    this.setState({ showMessageBox: false });
  }

  handleChangeCheckbox(isChecked) {
    this.setState({ rememberMe: isChecked });
  }

  onEmailChange(email) {
    this.setState({email: email})
  }

  onPasswordChange(password) {
    this.setState({password: password})
  }

  handleSubmit(event) {
    localStorage.removeItem('userData');
    this.setState({ loaderIsActive: true, showMessageBox: false })
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      "email": this.state.email,
      "password": this.state.password
    };
    new HttpService().sendRequest(`${host}/api/users/login`,{
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((result) => {

      if(result.hasOwnProperty('token')) {
        this.onSuccessRequest(result);
      } else {
        this.setState({ showMessageBox: true });
        const { message = 'Email or Password incorrect.' } = result;
        this.messageBox.message = message.message || message;
      }

      this.setState({ loaderIsActive: false });
    })
    .catch((err, code) => {
      this.messageBox.message = `Login Failed: ${err}`;
      this.setState({ showMessageBox: true });
      this.setState({ loaderIsActive: false });
    })
    event.preventDefault();
  }

  onSuccessRequest(result) {
    this.setState({ token: result.token });
    const tokenService = new TokenService();
    const cookieService = new CookieService();
    cookieService.setCookie({ name: 'user_type', value: result.userType }, 10);
    AnalyticsService.setUserId(result.email);
    tokenService.saveToken(result.token);
    this.saveLoginCookies();
    this.props.onSubmit(result);
  }

  saveLoginCookies() {
    let cookies = [
      {
        name: "token",
        value: this.state.token
      },
    ];

    if (this.state.rememberMe) {
      cookies = [...cookies, {
        name: "email",
        value: this.state.email
      }];
    }

    new CookieService().setCookies(cookies, 30);
  }

  render() {
    const { showMessageBox = false } = this.state;
    return (
      <div>
        <form className="o-login-form" onSubmit={this.handleSubmit}>
          <div className="o-login-form__title margin-b-40">
            Log In
          </div>
          <FloatingInputComponent title="Email Address" type="email" iconName="mail" value={this.state.email} onValueChange={this.onEmailChange} />
          <FloatingInputComponent title="Password" type="password" iconName="lock" value={this.state.password} onValueChange={this.onPasswordChange} />
          <div className="o-login-form__half-content">
            <div className="o-login-form__half-content__item">
              <Checkbox {...this.rememberMeCheckbox} handleChange={this.handleChangeCheckbox} />
            </div>
            <div className="o-login-form__half-content__item justify-content-flex-end">
              <Link to={`/sign-up`} className="a-link a-link--underline">
                Sign Up ?
              </Link>
            </div>
            <div className="o-login-form__half-content__item justify-content-flex-end">
              <Link to={`/forgot-password/${this.state.email}`} className="a-link a-link--underline">
                Forgot Password ?
              </Link>
            </div>
          </div>
          {
            !this.state.loaderIsActive ?
            <button className="a-button a-button--primary a-button--full-width" type="submit" value="Submit">
              Login
            </button>
            : <CircleLoader isActive={this.state.loaderIsActive} />
          }
          {
            showMessageBox ?
            <MessageBox {...this.messageBox} onClose={this.onMessageClose} />
            : ''
          }

        </form>
      </div>
    )
  }
}

export default LoginFormComponent
