import React from 'react';

export default class Overview extends React.Component {
    render() {
        const { overview, onChange, isEdit } = this.props;

        if (!overview && !isEdit) {
            return null;
        }

        let content = <div className='insight-article-content' dangerouslySetInnerHTML={{__html: overview}}></div>;

        if (isEdit) {
            content = <textarea value={overview} onChange={onChange}></textarea>;
        }

      return (
        <div className='overview-box'>
          <div className='insight-article'>
            {content}
          </div>
        </div>
      );
    }
}
