import React, { Component } from 'react';
import { HttpService } from 'services/HttpService';
import { TokenService } from 'services/TokenService';
import CookieService from 'services/CookieService';
import AnalyticsService from 'services/AnalyticsService';
import { SimpleInput } from 'components/form/SimpleInput/SimpleInput';
import { CircleLoader } from 'components/CircleLoader/CircleLoader';
import { MessageBox } from 'components/MessageBox/MessageBox';
import './SignUpForm.scss';

export class SignUpForm extends Component {
  messageBox = {
    title: 'Sign up request failed',
    message: 'Please choose a different email',
    color: 'danger',
    icon: 'error',
  }

  constructor(props) {
    super(props);
    this.state = {
      firstName: '', lastName: '', company: '', jobFunction: '', email: '', password: '',
      loaderIsActive: false,
      showMessageBox: false,
    };
  }

  handleSubmit = (event) => {
    this.setState({ loaderIsActive: true, showMessageBox: false });
    const { firstName, lastName, company, jobFunction, email, password } = this.state;
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      firstName,
      lastName,
      jobFunction,
      email,
      password,
      companyName: company,
      userType: 'freemium',
      phoneNumber: '0000000',
    };
    new HttpService().sendRequest(`${host}/api/users/signup`,{
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      if (result.hasOwnProperty('token')) {
        this.onSuccessRequest(result);
        this.setState({ loaderIsActive: false });
      } else {
        this.printErrorMessage(result);
      }
    }).catch((err, code) => {
      this.printErrorMessage(err);
    });
    event.preventDefault();
  }

  printErrorMessage = (result) => {
    const { message } = result;
    this.messageBox.message = message || ' Email or Password incorrect.';
    if (typeof message === 'object' && message.hasOwnProperty('errors')) {
      const { errors } = message;
      const messageText = errors.map((item) => item.msg).join('\n');
      this.messageBox.message = messageText;
    }

    this.setState({ showMessageBox: true, loaderIsActive: false });
  }

  onSuccessRequest = (result) => {
    this.setState({ token: result.token });
    const tokenService = new TokenService();
    const cookieService = new CookieService();
    localStorage.setItem('userData', JSON.stringify(result));
    cookieService.setCookie({ name: 'user_type', value: result.userType }, 10);
    AnalyticsService.setUserId(result.email);
    tokenService.saveToken(result.token);
    this.saveLoginCookies();
    this.props.onSubmit(result);
  }

  saveLoginCookies = () => {
    const { token, email, rememberMe } = this.state;
    let cookies = [
      {
        name: 'token',
        value: token,
      },
    ];

    if (rememberMe) {
      cookies = [...cookies, {
        name: 'email',
        value: email,
      }];
    }

    new CookieService().setCookies(cookies, 30);
  }

  onMessageClose =(event) => {
    this.setState({ showMessageBox: false });
  }

  onNameChange = (name) => {
    this.setState({ firstName: name });
  }

  onLastNameChange = (lastName) => {
    this.setState({ lastName });
  }

  onCompanyChange = (company) => {
    this.setState({ company });
  }

  onJobTitleChange = (jobTitle) => {
    this.setState({ jobFunction: jobTitle });
  }

  onEmailChange = (email) => {
    this.setState({ email });
  }

  onPasswordChange = (password) => {
    this.setState({ password });
  }

  render() {
    const {
      name,
      lastName,
      email,
      company,
      jobFunction,
      password,
      loaderIsActive,
      showMessageBox,
    } = this.state;
    return (
      <form className="signup-form" onSubmit={this.handleSubmit}>
        <div className="signup-form__content">
          <div className="row">
            <div className="col-md-12 col-lg-6 margin-t-20">
              <SimpleInput value="" name="name" type="text" placeHolder="First Name" value={name} onValueChange={this.onNameChange} />
            </div>
            <div className="col-md-12 col-lg-6 margin-t-20">
              <SimpleInput value="" name="last_name" type="text" placeHolder="Last Name" value={lastName} onValueChange={this.onLastNameChange} />
            </div>
            <div className="col-md-12 col-lg-6 margin-t-20">
              <SimpleInput value="" name="company" type="text" placeHolder="Company" value={company} onValueChange={this.onCompanyChange} />
            </div>
            <div className="col-md-12 col-lg-6 margin-t-20">
              <SimpleInput value="" name="job_title" type="text" placeHolder="Job Title" value={jobFunction} onValueChange={this.onJobTitleChange} minLength="3" />
            </div>
            <div className="col-md-12 margin-t-20">
              <SimpleInput name="work mail" value="" type="email" placeHolder="Work Email" iconName="mail" value={email} onValueChange={this.onEmailChange}  />
            </div>
            <div className="col-md-12 margin-t-20">
              <SimpleInput value="" type="password" name="password" placeHolder="Password" iconName="https" value={password} onValueChange={this.onPasswordChange} />
            </div>
          </div>
        </div>
        <div className="signup-form__footer">
          <span> By registering, you agree to the processing of your personal data by Enki as described in the <a href="https://enkiai.com/privacy-policy" target="_blank">Privacy Statement.</a>
          </span>
          {
            !loaderIsActive ?
              (
                <button type="submit" value="Submit">
                  Sign up
                </button>
              )
              : <CircleLoader isActive={loaderIsActive} />
          }
          {
            showMessageBox ?
              <MessageBox {...this.messageBox} onClose={this.onMessageClose} />
              : ''
          }
        </div>
      </form>
    );
  }
}

export default SignUpForm;
