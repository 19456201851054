import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './RadioButtons.scss';

export class RadioButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.selectedValue,
    };
  }

  componentDidMount() {
    this.updateActiveTabIndicator();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedValue } = this.props;
    if (selectedValue !== prevProps.selectedValue) {
      this.updateActiveTabIndicator();
    }
  }

  updateActiveTabIndicator = () => {
    const ref = ReactDOM.findDOMNode(this);
    const activeTab = ref.querySelector('li.radio-buttons__list__button.active');
    const color = this.getActiveTabColor();
    this.updateIndicator(activeTab, color);
  }

  getActiveTabColor = () => {
    const { buttons = [], selectedValue } = this.props;
    const selectedItem = buttons.find(({ value }) => value === selectedValue) || { color: 'gray' };
    return selectedItem.color;
  }

  onSelectItem = (event, { value, color }) => {
    this.updateIndicator(event.currentTarget, color);
    this.props.onValueChange(value);
  }

  updateIndicator = (element, color) => {
    const ref = ReactDOM.findDOMNode(this);
    const tabIndicator = ref.querySelector('div.radio-buttons__list__indicator');
    const { clientWidth, offsetLeft } = element;
    tabIndicator.style.width = clientWidth + 'px';
    tabIndicator.style.left = offsetLeft + 'px';
    tabIndicator.setAttribute('color', color);
  }

  render() {
    const { buttons = [], title, selectedValue = null } = this.props;
    return (
      <div className="radio-buttons">
        <div className="radio-buttons__title"> {title} </div>
        <ul className="radio-buttons__list">
          <div className="radio-buttons__list__indicator" />
          {
            buttons.map((button, key) => {
              const { label, icon, value } = button;
              return (
                <li
                  className={`radio-buttons__list__button ${selectedValue === value ? 'active': ''}`}
                  onClick={(event) => this.onSelectItem(event, button)}
                  key={key}
                >
                  <a>
                    { icon && <i className="material-icons">{icon}</i> }
                    {label}
                  </a>
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  }
}

export default RadioButtons;
