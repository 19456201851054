import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyTableItem from './CompanyTableItem/CompanyTableItem';
import CompanyTableItemLoader from 'components/Loaders/CompanyTableItemLoader';
import { updateSelectedCompanies } from 'store/actions/user/companies';
import './CompanyTable.scss';

export class CompanyTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      selectedCompanies: [],
      sorting: '',
      sortingDirection: 'asc'
    };
  }

  onCompanySelected = (companyName, isSelected) => {
    const { companyList = [] } = this.state;
    const findIndex = companyList.findIndex(({ name }) => name == companyName);
    companyList[findIndex].selected = isSelected;
    const selectedCompanies = this.getSelectedCompanies(companyList[findIndex], isSelected);
    this.setState({ companyList, selectedCompanies }, () => {
      this.props.updateSelectedCompanies(selectedCompanies);
    });
  }

  onSortChange = (newSorting) => {
    const { sorting } = this.state;
    let { sortingDirection = 'asc' } = this.state;
    sortingDirection = sortingDirection == 'asc' ? 'desc': 'asc';
    if (sorting !== newSorting) {
      sortingDirection = 'desc'
    }

    this.setState({ sortingDirection, sorting: newSorting });
    this.props.onSortChange({ sortingDirection, sorting: newSorting });
  }
  
  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");
    const { companyList = [], selectedCompanies = [] } = nextProps;
    this.setState({ companyList, selectedCompanies });
  }

  getSelectedCompanies = (company, isSelected) => {
    let { selectedCompanies = [] } = this.state;
    if (isSelected) {
      selectedCompanies = [...selectedCompanies, company];
    } else {
      selectedCompanies = selectedCompanies.filter(({ name }) => name !== company.name);
    }

    return selectedCompanies;
  }

  getTableLoader = () => {
    return (
      <div className="company-table__contents">
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
      </div>
    )
  }

  getTableItemsLoader = () => {
    return (
      <div>
        <CompanyTableItemLoader />
        <CompanyTableItemLoader /> 
        <CompanyTableItemLoader />
        <CompanyTableItemLoader />
      </div>
    )
  }

  getTableContent = () => {
    const { companyList = [] } = this.state;
    const { isLoading } = this.props;
    return (
      <div className="company-table__contents">
      { companyList.map((company, i) => {
        return <CompanyTableItem {...company} key={i} onCompanySelected={this.onCompanySelected} />
      })}
      { isLoading && this.getTableItemsLoader() }
    </div>
    )
  }

  getTableHead = () => {
    const { isLoading } = this.props;
    const { companyList = [] } = this.state;
    if (companyList.length === 0 && !isLoading) {
      return ('')
    }

    return(
      <div className="company-table__head">
        <div className="company-table__head__title">
          Company Name
        </div>
        <div className="company-table__head__title">
          Company Description
        </div>
        <div className="company-table__head__title">
          Verticals
        </div>
        <div className="company-table__head__title">
          Keywords
        </div>
        <div className="company-table__head__title">
          Attributes
        </div>
        <div className="company-table__head__title">
          Company Type
        </div>
        <div className="company-table__head__title">
          Number Of Employees 
        </div>
        <div className="company-table__head__title">
          Score 
        </div>
      </div>
    )
  }

  getArrow = (name) => {
    const { sortingDirection, sorting } = this.state;

    if (name !== sorting) {
      return (<i className="material-icons opacity-15">arrow_drop_down</i>)
    }

    return sortingDirection === 'asc' ? <i className="material-icons">arrow_drop_up</i> : <i className="material-icons">arrow_drop_down</i>
  }

  render() {
    const { isLoading, loadMore, onLoadMore } = this.props;
    const { companyList = [] } = this.state;

    return (
      <div className="company-table">
        { this.getTableHead() }
        { companyList.length == 0 && isLoading ? this.getTableLoader(): this.getTableContent() }
        { (loadMore && !isLoading ) && <center><button className="discover-companies__form__button" onClick={onLoadMore}>Load More</button></center> }
      </div>
    )
  }
}

const mapStateToProps = function (store) {
  const {  userReducers } = store;
  const { companiesReducers } = userReducers;
  return {
    selectedCompanies: companiesReducers.selectedCompanies,
    companyList: companiesReducers.companyList,
    isLoading: companiesReducers.requestStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedCompanies: (selectedCompanies) => dispatch(updateSelectedCompanies(selectedCompanies))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTable);
