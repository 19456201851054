import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { SearchBar } from 'components/form/SearchBar/SearchBar';
import { AttributeViews } from 'components/Views/AttributeViews/AttributeViews';
import RadioButton from 'components/form/Inputs/RadioButton/RadioButton';
import './SingleSelectBox.scss';

export class SingleSelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isMenuActive: false, selectedValues: [], search: '' };
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onSelectBoxClicked = this.onSelectBoxClicked.bind(this);
  }

  componentDidMount() {
    const { list = [] } = this.props;
    this.setState({ list });
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isMenuActive: false });
    }
  }

  onSelectBoxClicked() {
    this.setState({ isMenuActive: true });
  }

  onSelectItem(checked, checkbox) {
    let { list } = this.state;
    const selectedValue = list.find((item) => item.value === checkbox.id);
    this.setState({ list, isMenuActive: false });
    this.props.onChangeValues(selectedValue);
  }

  onSearchChange = (search = '') => {
    this.setState({ search });
  }

  getSearchContent = () => (
    <div className="single-select-box__menu__search">
      <SearchBar placeholder="Search" onValueChange={this.onSearchChange} />
    </div>
  );

  mapValuesToLabel(values) {
    return values.map((value) => {
      return {
        text: value.text,
        color: value.color 
      }
    })
  }

  getSelectedValues = ({ text, color }) => {
    const filteredValues = [{ text, color }];
    return (
      <div className="single-select-box__button__labels">
        <AttributeViews list={filteredValues} hideMoreButton="true" max={5} />
      </div>
    );
  }

  searchInText = (search, text) => (
    search.isNullOrEmpty() || text.toLowerCase().indexOf(search.toLowerCase()) >= 0
  );

  clearHighlightedList = (list) => {
    list = list.map((item) => {
      item.highlightedText = null;
      return item;
    });
    return list;
  }

  highlightList = (list, search) => {
    if (search.length > 0) {
      search = search.toLowerCase();
      return list.filter(item => this.searchInText(search, item.text))
        .map(item => {
          const replacedText = item.text.toLowerCase().replace(search, `<span class='HIGHLIGHT-TEXT'>${search}</span>`);
          item.highlightedText = replacedText.toUpperCase();
          return item;
        });
    }

    return this.clearHighlightedList(list);
  }

  render() {
    const { highlightText, isHighlightText, title = "", searchable = false, name, selectedValue } = this.props;
    const { search = '' } = this.state;
    let { list = [] } = this.state;
    list = this.highlightList(list, search);
    const menuClass = this.state.isMenuActive ? 'single-select-box__menu active' : 'single-select-box__menu';
    const menuButtonClass = !selectedValue ? 'single-select-box__button disabled' : 'single-select-box__button';
    const highlightClass = !isHighlightText ? 'visibility--hidden' : '';
    return (
      <div className="single-select-box">
        <div className="single-select-box__title">
          {title}
        </div>
        <div className="single-select-box__content">
          <div className={menuButtonClass} onClick={this.onSelectBoxClicked}>
            { !selectedValue ?
              <span className={`single-select-box__button__label ${highlightClass}`}> {highlightText} </span> :
              this.getSelectedValues(selectedValue)
            }
            <i className="material-icons"> expand_more </i>
          </div>
          <div className={menuClass}>
            { searchable && this.getSearchContent() }
            <div className="single-select-box__menu__list">
              {
                list
                .map((item, i) => {
                  return <div className={`single-select-box__menu__item`} value={item.value} key={i}>
                    <RadioButton {...{ name, checked: item.value === selectedValue.value, id: item.value, text: item.highlightedText || item.text, count: item.count, color: "blue-2", type: "item" }} handleChange={this.onSelectItem} />
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SingleSelectBox;
