
import { HttpService } from 'services/HttpService';

const httpService = new HttpService();
const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';

export class ReportsService {

  initAvailableOptions = () => {
    return new Promise((resolve) => {
      const params = {
        addToken: true,
      }
      httpService.sendRequest(`${host}/api/companyUrl/onboardList/freemium`, {
        body: JSON.stringify(params),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((data) => {
        resolve(data);
      });
    })
  }
  
}
