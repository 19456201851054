import React, { Component } from 'react';
import './MessageBox.scss';

export class MessageBox extends Component {

  constructor(props) {
    super(props);
    this.onCloseHandler = this.onCloseHandler.bind(this);
  }

  onCloseHandler(e) {
    this.props.onClose({ type: 'close' });
  }

  render() {
    const { title, color, message, icon } = this.props;
    return (
      <div className="m-message-box" color={color}>
        <div className="m-message-box__head">
          <div className="m-message-box__head__icon" color={color}>
            <i className="material-icons"> {icon} </i>
          </div>
          <div className="m-message-box__head__title">
            {title}
          </div>
          <div className="m-message-box__head__close" onClick={this.onCloseHandler}>
            <i className="material-icons"> close </i>
          </div>
        </div>
        <div className="m-message-box__body">
          <div className="m-message-box__body__text">
            {message}
          </div>
        </div>
      </div>
    )
  }
}

export default MessageBox
