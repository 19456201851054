import React, { Component } from 'react';
import AttributePeople from '../AttributePeople/AttributePeople';
import './AttributePeopleGroup.scss';

const seniorityList = [
  'Executive',
  'Senior Leadership',
  'Mid-Level Management',
  'Individual Contributor',
  'Other',
];

const seniorityColorList = {
  Executive: 'primary-dark',
  'Senior Leadership': 'secondary-dark',
  'Mid-Level Management': 'accent-1',
  'Individual Contributor': 'accent-3',
  Other: 'accent-2',
};

export class AttributePeopleGroup extends Component {
  sortGroupbySeniority = (groupA, groupB) => {
    const groupAIndex = seniorityList.indexOf(groupA);
    const groupBIndex = seniorityList.indexOf(groupB);
    let result = 0;
    if (groupAIndex > groupBIndex) {
      result = 1;
    } else if (groupAIndex < groupBIndex) {
      result = -1;
    }

    return result;
  }

  render() {
    const { peopleGroups = {} } = this.props;

    return (
      <div className="attribute-people-view">
        {
          Object.keys(peopleGroups).sort(this.sortGroupbySeniority).map((key, i) => (
            <div className="attribute-people-view__section" key={i}>
              <div className="attribute-people-view__section__title" color={seniorityColorList[key]}>
                { key }
              </div>
              <AttributePeople people={peopleGroups[key]} />
            </div>
          ))
        }
      </div>
    );
  }
}

export default AttributePeopleGroup;
