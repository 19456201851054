import React, { Component } from 'react';
import CompanyTableJobsItem from './CompanyTableJobsItem';
import { HttpService } from 'services/HttpService';
import CompanyInsightsLoader from 'components/Loaders/CompanyInsightsLoader';
import StringUtils from 'utils/StringUtils';
import Image from 'components/Image/Image';
import desertImage from 'assets/img/desert.svg';
import './CompanyTableInsights.scss';

export class CompanyTableJobs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      articles: []
    };
  }

  onLoadInsights = () => {
    const { jobs } = this.props;
    this.setState({ isActive: true, articles:jobs });
  } 

  setDeActive = () => {
    this.setState({ isActive: false });
  }

  getCompanyInsights = () => {
    const { jobs } = this.props;
    const { articles = [] } = this.state;

    return (
      <div className="company-table-insights__list">
        { 
          articles.map((article, i) => {
            return <CompanyTableJobsItem article={article} key={i} />
          })
        }
      </div>
    );
  }

  render() {
    const { name } = this.props;
    const { isActive } = this.state;
    if (isActive){
    return (
      <div className={`company-table-insights ${isActive ? 'active' : ''}`}>
        <div className="company-table-insights__title">
          <div className="company-table-insights__title__image">
            <Image src={new StringUtils().getCompanyLogo(name)} />
          </div> 
          {name} Jobs
        </div>
        <div className="company-table-insights__indicator"></div>
        { this.getCompanyInsights() }
      </div>
    )
  }else{
    return (null);
  }
}
}

export default CompanyTableJobs;
