import React, { Component } from 'react';
import loadingGif from 'assets/img/loading.gif';
import './CircleLoader.scss'

export class CircleLoader extends Component {
  render() {
    const { isActive, size = '', alt = '' } = this.props
    return (
      <div>
        <div className="a-cicrle-loader" data-size={size}>
          <img className={
              isActive == true ? "a-cicrle-loader__bar is-open":"a-cicrle-loader__bar" 
            }
            alt={alt}
            src={loadingGif} />
        </div>
      </div>
    )
  }
}

export default CircleLoader;
