import React, { Component } from 'react';
import { connect } from 'react-redux';
import dataAnalysistImage from 'assets/img/data_analysis.svg';
import { updateAuthModal } from 'store/actions/root/auth-modal';
import './PageHead.scss';

export class PageHead extends Component {
  render() {
    return (
      <div className="page-head">
        <div className="page-head__image">
          <img src={dataAnalysistImage} alt="Sign up Today to See More Companies!" />
        </div>
        <div className="page-head__content">
          <div className="page-head__content__title">
            Partnerships. Investments. Executive Moves. Market Outlook. News. Initiatives. Pain Points. Opportunities. Technologies. <br /> enki Gathers The Insights that Enables You Swift <strong>Judgements Calls</strong>
          </div>
          <div className="page-head__content__title margin-b-10">
            Sign up Today to See More Companies!
          </div>
          <div className="page-head__content__buttons">
            <a className="page-head__content__button" onClick={ () => { this.props.updateAuthModal(true) } }>
              Sign Up
            </a>
            <span className="page-head__content__info-text">
              No credit card required
            </span> 
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateAuthModal: (status) => dispatch(updateAuthModal(status)),
  }
};

export default connect(null, mapDispatchToProps)(PageHead);
