import React, { Component } from 'react';
import './RangeSlider.scss';

export class RangeSlider extends Component {
  onChangeSlider = (e) => {
    const { value } = e.target;
    this.props.onChange(value);
  }

  render() {
    const { min, max, value, name } = this.props;
    return (
      <div className="slide-container">
        <input className="slider" type="range" min={min} max={max} value={value} name={name} onChange={this.onChangeSlider} />
      </div>
    );
  }
}

export default RangeSlider;
