import CookieService from 'services/CookieService';

const Consts = {
  TOKEN_KEY: 'access_token',
};

const cookieService = new CookieService();

export class TokenService {

  getToken() {
    return cookieService.getCookie('token');
  }

  getDemoToken() {
    return cookieService.getCookie('demo_token');
  }
  
  hasToken() {
    const token = this.getToken();
    return (token && token != undefined && token.length > 0);
  }

  hasDemoUser(path) {
    switch(path) {
      case "/":
        const token = this.getDemoToken();
        return (token && token != undefined && token.length > 0);
      default:
        return false;
    }
  }

  saveToken(access_token) {
    cookieService.setCookie('token', access_token);
  }

  saveDemoUserToken(access_token) {
    cookieService.setCookie({ name: 'demo_token', value: access_token }, 1);
  }

  removeTokenByName(name) {
    cookieService.deleteCookie(name);
  }

  removeToken() {
    cookieService.deleteCookie('token');
  }
}

export default TokenService;
