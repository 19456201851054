
import { PUSH_TOAST_MESSAGE } from 'store/actions/root/toast';

const initialState = {
  toastData: null
}

const toastDataReducers = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_TOAST_MESSAGE:
      return Object.assign({}, state, {
        type: action.type,
        toastData: action.toastData,
      });
    default:
      return state
  }
}

export default toastDataReducers;
