/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import 'watson-react-components/dist/css/watson-react-components.css';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import rootReducer from 'store/reducers';
import App from './app';
import './style.scss';
import { loadCustomStringPrototypes } from './string';
import HttpInterceptor from './services/HttpInterseptor.js';
import AnalyticsService from './services/AnalyticsService.js';
import { LocalSettingsService } from './services/LocalSettingsService';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk, logger),
);

loadCustomStringPrototypes();
new HttpInterceptor().load();
new LocalSettingsService().initLocalSettings();
window.tooltipDatas = {};
window.profileDatas = {};
window.tooltipColors = {};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('root')
);

AnalyticsService.init();
