export class ItemHighLighter {
  highlightReportItem(item, searchText) {
    let { company, area, titles = [] } = item;
    item.highlighted = false;
    item.highlightedText = this.getHighlightedText(company, searchText);
    if(item.highlightedText !== company.toLowerCase()) {
      item.highlighted = true;
    }

    item.areaCopy = this.getHighlightedText(area, searchText);
    if(item.areaCopy !== area.toLowerCase()) {
      item.highlighted = true;
    }

    const titlesString = JSON.stringify(titles);
    item.titlesCopy = this.getHighlightedArray(titlesString, searchText);
    if(JSON.stringify(item.titlesCopy) !== titlesString) {
      item.highlighted = true;
    }
    
    item.filterCopy = this.getHighlightedArray(JSON.stringify(item.filter), searchText);
    if(JSON.stringify(item.filterCopy) !== JSON.stringify(item.filter)) {
      item.highlighted = true;
    }
    
    if(item.highlighted || searchText.isNullOrEmpty()) {
      return item;
    }

    return undefined;
  }

  highlightMultipleReportItem = (item, searchText) => {
    let { area, companies = [], competitors = [], painPoints = [], keywords = [], titles = [] } = item;
    item.highlighted = false;

    item.areaCopy = this.getHighlightedText(area, searchText);
    if(item.areaCopy !== area.toLowerCase()) {
      item.highlighted = true;
    }

    const keywordsString = JSON.stringify(keywords);
    item.keywordsCopy = this.getHighlightedArray(keywordsString, searchText);
    if (JSON.stringify(item.keywordsCopy) !== keywordsString) {
      item.highlighted = true;
    }

    const titlesString = JSON.stringify(titles);
    item.titlesCopy = this.getHighlightedArray(titlesString, searchText);
    if (JSON.stringify(item.titlesCopy) !== titlesString) {
      item.highlighted = true;
    }

    const painPointString = JSON.stringify(painPoints);
    item.painPointsCopy= this.getHighlightedArray(painPointString, searchText);
    if (JSON.stringify(item.painPointsCopy) !== painPointString) {
      item.highlighted = true;
    }

    const competitorString = JSON.stringify(competitors);
    item.competitorsCopy = this.getHighlightedArray(competitorString, searchText);
    if (JSON.stringify(item.competitorsCopy) !== competitorString) {
      item.highlighted = true;
    }

    const companyString = JSON.stringify(companies);
    item.companiesCopy = this.getHighlightedArray(companyString, searchText);
    if (JSON.stringify(item.companiesCopy) !== companyString) {
      item.highlighted = true;
    }

    if (item.highlighted || searchText.isNullOrEmpty()) {
      return item;
    }

    return undefined;
  }

  getHighlightedText(text, searchText) {
    text = text.toLowerCase();
    const start = text.search(searchText.toLowerCase());
    if (start < 0 || searchText.isNullOrEmpty()) {
      return text;
    }

    const end = (start + searchText.length);
    text = `${text.substring(0, start)}<span class="highlight-text">${text.substring(start, end)}</span>${text.substr(end)}`
    return text;
  }

  getHighlightedArray(arr, searchText) {
    return JSON.parse(arr).map((item) => {
      item.text = this.getHighlightedText(item.text, searchText).toUpperCase();
      return item;
    });
  }
}

export default ItemHighLighter;
