import React, { Component } from 'react';
import { CompanyNameContent } from './CompanyNameContent';
import { SourceNameContent } from './SourceNameContent';
import './CompanyInformationHead.scss';


export class CompanyInformationHead extends Component {
  render() {
    const { companyData = {} } = this.props;
    const { website_url, url } = companyData;
    return (
      <div className="company-information-head">
        <CompanyNameContent companyData={companyData} />
        <SourceNameContent sourceUrl={url} />
        { 
          website_url ? 
          <a className="company-information-head__url" href={website_url} target="_blank">
            <i className="material-icons"> launch </i>
            { website_url }
          </a>: ''
        }
      </div>
    )
  }
}

export default CompanyInformationHead;
