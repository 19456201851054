export const UPDATE_COMPANY_LIST = 'UPDATE_COMPANY_LIST';
export const updateCompanyList = (companyList) => {
  return {
    type: UPDATE_COMPANY_LIST,
    companyList
  }
}

export const UPDATE_SELECTED_COMPANIES = 'UPDATE_SELECTED_COMPANIES';
export const updateSelectedCompanies = (selectedCompanies) => {
  return {
    type: UPDATE_SELECTED_COMPANIES,
    selectedCompanies
  }
}

export const CLEAR_COMPANY_LIST = 'CLEAR_COMPANY_LIST';
export const clearCompanyList = () => {
  return {
    type: CLEAR_COMPANY_LIST
  }
}

export const ADD_COMPANY_LIST = 'ADD_COMPANY_LIST';
export const addCompanyList = (companyList) => {
  return {
    type: ADD_COMPANY_LIST,
    companyList
  }
}

export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';
export const setRequestStatus = (status) => {
  return {
    type: SET_REQUEST_STATUS,
    status
  }
}

export const ADD_ATTRIBUTES = 'ADD_ATTRIBUTES';
export const addAttributes = (attributes) => {
  return {
    type: ADD_ATTRIBUTES,
    attributes
  }
}
