import React from 'react';
import { connect } from 'react-redux';
import BlurComponent from './components/BlurComponent/BlurComponent';
import nlp from 'compromise';
import SimpleWordle from './wordle.simple';
import { PrioritizeLinkedInButton, People } from './util';
import ExportPeopleButton from './exportPeopleButton';
import Articles from './Articles';
import { ReceivedRequestCard } from 'components/User/Reports/ReceivedRequestCard/ReceivedRequestCard';
import AttributePeople from 'components/Reports/Attributes/AttributePeople/AttributePeople';

export class Insight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      metadata: props.metadata,
      search: null,
      loading: false
    }
  }

  componentDidMount() {
    const { metadata, currentUserData = {} } = this.props;
    const { emailRegistered = false } = this.state;
    const { email = '', userType = 'demo' } = currentUserData;
    const hasUserData = (userType !== 'demo'  &&!email.isNullOrEmpty() && !emailRegistered);
    if (!metadata.done && metadata.processed === 0 && hasUserData) {
      this.submitEmail(email);
    }
  }

  onInputChange(name, event) {
    let value = event.target.value.toUpperCase();
    if (value != null) {
      if (value.trim() == '') {
        value = null;
      }

      this.setState({
        [name]: value
      });
    }
  }

  train(article, voteType, type) {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const { currentUserData = {}, fetchKey } = this.props;
    const body = JSON.stringify({
      url: article.url,
      title: article.title,
      insightType: type,
      relevance: voteType === 'unlike' ? 1 : 10,
      user: currentUserData.email
    });
    fetch(`${host}/api/trainV2?key=${encodeURIComponent(fetchKey)}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }).then((response) => {
      
    }).catch((err) => {
      alert(err);
    });
  }

  search() {
    this.setState({
      loading: true
    });

    const searchTask = () => {
      const metadata = this.filter(this.props.metadata, this.state.search);

      this.setState({
        metadata: metadata,
        loading: false
      });
    }

    setTimeout(searchTask, 0);
  }

  filter(metadata, query) {
    if (query) {
      const clonedMetadata = JSON.parse(JSON.stringify(metadata));
      
      const types = [
        'summary',
        'companies',
        'products',
        'events',
        'technologies',
        'industries',
        'places',
        'departments',
        'titles',
        'quotes',
        'executiveQuotes',
        'partnership',
        'investment',
        'patent',
        'initiative',
        'painPoints',
        'win',
        'opportunity',
        'growth',
        'promotion',
        'value',
        'oil'
      ];
        
      types.forEach(type => {
        if (clonedMetadata[type]) {
          Object.keys(clonedMetadata[type]).filter(s => {
            clonedMetadata[type][s] = clonedMetadata[type][s].filter(article => {
              return nlp(article.title + article.description).has(query);
            });
            if (clonedMetadata[type][s].length === 0) {
              delete clonedMetadata[type][s];
            }
          });
        }
      });
      
      return clonedMetadata;
    }
    return metadata;
  }

  renderNotifyContent() {
    const { currentUserData } = this.props;
    const { userType = 'demo' } = currentUserData
    if (userType == 'demo') {
      return <ReceivedRequestCard message="Enki is working hard to prepare your report." />
    }

    return <ReceivedRequestCard />
  }

  submitEmail(email) {
    console.log(this.state.email);

    this.setState({
      emailRegistered: true
    });

    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    fetch(`${host}/api/notify?key=${encodeURIComponent(this.props.fetchKey)}&email=${encodeURIComponent(email || this.state.email)}`, {
      method: 'GET'
    }).then((response) => {

    }).catch((err) => {
      alert(err);
    });
  }

  onKeyDown(event) {
    if (event.keyCode == 13) {
      this.submitEmail();
    }
  }

  render() {
    const metadata = this.state.metadata;

    const showHeatmapData = this.props.debug && metadata.heatmapData && Object.keys(metadata.heatmapData).length > 0;
    const showIT = false; // this.props.debug && metadata.IT && Object.keys(metadata.IT).length > 0;
    const showITPainPoint = false; // this.props.debug && metadata.ITPainPoint && Object.keys(metadata.ITPainPoint).length > 0;
    const showITCompetitor = false; // this.props.debug && metadata.ITCompetitor && Object.keys(metadata.ITCompetitor).length > 0;
    const showIoT = false; // this.props.debug && metadata.IoT && Object.keys(metadata.IoT).length > 0;
    const showIoTPainPoint = false; // this.props.debug && metadata.IoTPainPoint && Object.keys(metadata.IoTPainPoint).length > 0;
    const showIoTValueProp = false; // this.props.debug && metadata.IoTValueProp && Object.keys(metadata.IoTValueProp).length > 0;
    const showPeople = false; // metadata.people && Object.keys(metadata.people).length > 0;
    const showSummary = false; // metadata.summary && Object.keys(metadata.summary).length > 0;
    const showCompanies = false; // this.props.debug && metadata.companies && Object.keys(metadata.companies).length > 0;
    const showProducts = false; // this.props.debug && metadata.products && Object.keys(metadata.products).length > 0;
    const showEvents = false; // metadata.events && Object.keys(metadata.events).length > 0;
    const showPlaces = false; // metadata.places && Object.keys(metadata.places).length > 0;
    const showDepartments = false; // metadata.departments && Object.keys(metadata.departments).length > 0;
    const showTitles = false; // metadata.titles && Object.keys(metadata.titles).length > 0;
    const showTechnologies = false; // this.props.debug && metadata.technologies && Object.keys(metadata.technologies).length > 0;
    const showIndustries = false; // this.props.debug && metadata.industries && Object.keys(metadata.industries).length > 0;
    const showExecutiveQuotes = metadata.executiveQuotes && Object.keys(metadata.executiveQuotes).length > 0;
    const showExternalQuotes = this.props.debug && metadata.externalQuotes && Object.keys(metadata.externalQuotes).length > 0;
    const showQuotes = metadata.quotes && Object.keys(metadata.quotes).length > 0;
    const showQuotesV2 = false;
    const showAllQuotes = this.props.debug && metadata.allQuotes && Object.keys(metadata.allQuotes).length > 0;
    const showAttributePeople = metadata.attributePeople && Object.keys(metadata.attributePeople).length > 0;
    const showPartnership = metadata.partnership && Object.keys(metadata.partnership).length > 0;
    const showInvestment = metadata.investment && Object.keys(metadata.investment).length > 0;
    const showPatent = false; // metadata.patent && Object.keys(metadata.patent).length > 0;
    const showInitiative = metadata.initiative && Object.keys(metadata.initiative).length > 0;
    const showPainPoint = metadata.painPoints && Object.keys(metadata.painPoints).length > 0 || metadata.painPointV2 && Object.keys(metadata.painPointV2).length > 0;
    const showRecentWin = metadata.win && Object.keys(metadata.win).length > 0;
    const showOpportunity = metadata.opportunity && Object.keys(metadata.opportunity).length > 0;
    const showGrowth = metadata.growth && Object.keys(metadata.growth).length > 0;
    const showPromotion = metadata.promotion && Object.keys(metadata.promotion).length > 0;
    const showValue = false; // metadata.value && Object.keys(metadata.value).length > 0;
    const showOil = metadata.oil && Object.keys(metadata.oil).length > 0;
    const showMarket = metadata.market && Object.keys(metadata.market).length > 0;

    const { currentUserData = {} } = this.props;
    const { userType = '', hasSecretId = false } = currentUserData;
    return (
      <div>
        {
          !metadata.done && metadata.processed > 0
          ? <div className='insight-progress'>Reading... {metadata.processed}% so far</div>
          : this.state.loading 
            ? <div className='insight-progress'>Searching... </div>
            : <div className="query--search-container">
                {/* <label className="text-input">
                  <input type="text" value={this.state.search} onKeyDown={this.onKeyDown.bind(this)} onChange={this.onInputChange.bind(this, 'search')} className="text-input--input base--input empty" placeholder="Search the insights" />
                  <span className="text-input--dummy"></span>
                </label>
                <button type="button" className="query--icon-container" onClick={this.search.bind(this)}>
                  <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="#e0e0e0" className="icon icon"><path d="M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M12,21c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S17,21,12,21z M21,24 l3-3l7,7l-3,3L21,24z M21,20c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S20.4,20,21,20z"></path></svg></span>
                </button> */}
              </div>
        }
        { !metadata.done && metadata.processed === 0
          ? this.renderNotifyContent()
          : <div className='insight-wordle-container'>
            {showHeatmapData && <div className='insight-wordle'><h4 className='title'>Solution attributes</h4><SimpleWordle debug={this.props.debug} data={metadata.heatmapData} type='Solution Attributes' source={metadata.source}/></div>}
            {showCompanies && <div className='insight-wordle'><h4 className='title'>Companies</h4><SimpleWordle data={metadata.companies} type='Companies' source={metadata.source}/></div>}
            {showProducts && <div className='insight-wordle'><h4 className='title'>Products</h4><SimpleWordle data={metadata.products} type='Products' source={metadata.source}/></div>}
            {showEvents && <div className='insight-wordle'><h4 className='title'>Events</h4><SimpleWordle data={metadata.events} type='Events' source={metadata.source}/></div>}
            {showPlaces && <div className='insight-wordle'><h4 className='title'>Places</h4><SimpleWordle data={metadata.places} type='Places' source={metadata.source}/></div>}
            {showDepartments && <div className='insight-wordle'><h4 className='title'>Departments</h4><SimpleWordle data={metadata.departments} type='Departments' source={metadata.source}/></div>}
            {showTitles && <div className='insight-wordle'><h4 className='title'>Titles</h4><SimpleWordle data={metadata.titles} type='Titles' source={metadata.source}/></div>}
            {showTechnologies && <div className='insight-wordle'><h4 className='title'>Technology initiatives</h4><SimpleWordle data={metadata.technologies} type='Technologies' source={metadata.source}/></div>}
            {showIndustries && <div className='insight-wordle'><h4 className='title'>Industries</h4><SimpleWordle data={metadata.industries} type='Industries' source={metadata.source}/></div>}
            {showIT && <div className='insight-wordle'><h4 className='title'>IT</h4><SimpleWordle data={metadata.IT} type='IT' source={metadata.source}/></div>}
            {showITPainPoint && <div className='insight-wordle'><h4 className='title'>IT pain points</h4><SimpleWordle data={metadata.ITPainPoint} type='ITPainPoint' source={metadata.source}/></div>}
            {showITCompetitor && <div className='insight-wordle'><h4 className='title'>IT competitors</h4><SimpleWordle data={metadata.ITCompetitor} type='ITCompetitor' source={metadata.source}/></div>}
            {showIoT && <div className='insight-wordle'><h4 className='title'>IoT</h4><SimpleWordle data={metadata.IoT} type='IoT' source={metadata.source}/></div>}
            {showIoTPainPoint && <div className='insight-wordle'><h4 className='title'>IoT pain points</h4><SimpleWordle data={metadata.IoTPainPoint} type='IoTPainPoint' source={metadata.source}/></div>}
            {showIoTValueProp && <div className='insight-wordle'><h4 className='title'>IoT value propositions</h4><SimpleWordle data={metadata.IoTValueProp} type='IoTValueProp' source={metadata.source}/></div>}
            {showSummary && <div className='insight-article'><h4 className='title'>Insights</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='summary'/></div></div>}
            {showPeople && <div className='insight-wordle'><h4 className='title'>Decision makers</h4><People data={metadata.people} /></div>}
            {showExecutiveQuotes && <div className='insight-article'><h4 className='title'>Executive insights</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='executiveQuotes'/></div></div>}
            {showQuotes && <div className='insight-article'><h4 className='title'>Key decision makers<PrioritizeLinkedInButton metadata={metadata} /></h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='quotes'/></div></div>}
            {showExternalQuotes && <div className='insight-article'><h4 className='title'>Other talking points</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='externalQuotes'/></div></div>}
            {showQuotesV2 && <div className='insight-article'><h4 className='title'>Key decision makers BETA</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='quotesV2'/></div></div>}
            {showAttributePeople && <div className='insight-article'><h4 className='title'>Top prospects<ExportPeopleButton metadata={metadata} /></h4><div className='insight-article-content'><AttributePeople metadata={metadata} type='attributePeople'/></div></div>}
            {showAllQuotes && <div className='insight-article'><h4 className='title'>Potential talking points</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='allQuotes'/></div></div>}
            {showPartnership && <div className='insight-article'><h4 className='title'>Partnerships</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='partnership'/></div></div>}
            {showInvestment && <div className='insight-article'><h4 className='title'>Investments and acquisitions</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='investment'/></div></div>}
            <BlurComponent isHidden={(userType != 'demo' || hasSecretId)} level={5} options={
              {
                title: "Market Outlook, Initiatives, Pain Points, Growth, Opportunities. <br /> More insights to enable you swift judgement call. <br /> Sign up Today to unlock the full report!",
                contentPosition: "top",
                button: {
                  text: "Create an account"
                } 
              }
              } 
              child={
                <div>
                {showPatent && <div className='insight-article'><h4 className='title'>Patents</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='patent'/></div></div>}
                {showInitiative && <div className='insight-article'><h4 className='title'>Strategic initiatives</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='initiative'/></div></div>}
                {showPainPoint && <div className='insight-article'><h4 className='title'>Challenges and solutions</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type={ metadata.painPointV2 ? 'painPointV2' : 'painPoints' }/></div></div>}
                {showRecentWin && <div className='insight-article'><h4 className='title'>Recent wins</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='win'/></div></div>}
                {showOpportunity && <div className='insight-article'><h4 className='title'>Opportunities</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='opportunity'/></div></div>}
                {showGrowth && <div className='insight-article'><h4 className='title'>Growth</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='growth'/></div></div>}
                {showPromotion && <div className='insight-article'><h4 className='title'>Executive moves</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='promotion'/></div></div>}
                {showValue && <div className='insight-article'><h4 className='title'>Value</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='value'/></div></div>}
                {showOil && <div className='insight-article'><h4 className='title'>Oil & Gas</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='oil'/></div></div>}
                {showMarket && <div className='insight-article'><h4 className='title'>Market outlook</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type='market'/></div></div>}
                </div>
            } />
            {Object.keys(metadata).map(key => {
              if (key.startsWith('$')) {
                const title = key.slice(1);
                const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);
                return <div className='insight-article'><h4 className='title'>{formattedTitle}</h4><div className='insight-article-content'><Articles debug={this.props.debug} onTrain={this.train.bind(this)} metadata={metadata} type={key}/></div></div>;
              }
            })}
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  const { rootReducers } = store;
  const { userDataReducers } = rootReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
  };
};

export default connect(mapStateToProps, null)(Insight);
