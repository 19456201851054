import React, { useState } from 'react';
import StringUtils from 'utils/StringUtils';
import ProgressBar from 'components/Loaders/ProgressBar/ProgressBar';
import { Image } from 'components/Image/Image';
import { IconButton } from 'components/form/IconButton/IconButton';
import RankedCompanyCardInfo from '../RankedCompanyCardInfo/RankedCompanyCardInfo';
import { PropTypes } from 'prop-types';
import './RankedCompanyCard.scss';

const RankedCompanyCard = (props) => {
  const { company, percent, userType = 'demo', reportId, infoList = [] } = props;
  const [showContent, setShowContent] = useState(false);
  return (
    <div className="ranked-company-card">
      <div className="ranked-company-card__head">
        <div className="ranked-company-card__head__image">
          <Image src={new StringUtils().getCompanyLogo(company.toLowerCase())} alt={company} />
        </div>
        <div className="ranked-company-card__head__title">
          { company }
          {(userType !== 'demo') && <a href={`/?report=${reportId}`} target="_blank"> Open Report <i className="material-icons">launch</i> </a>}
        </div>
        <ProgressBar size={percent} />
        { infoList.length > 0 && (
          <IconButton
            color={showContent ? 'blue-high': 'blue-grey'}
            iconName={showContent ? 'unfold_less' : 'unfold_more'}
            text={showContent ? 'Collapse' : 'Expand'}
            isLoading={false}
            handleClick={() => { setShowContent(!showContent); }}
          />
        )}
      </div>
      { infoList.length > 0 && (
        <div className={`ranked-company-card__content ${showContent ? 'open' : ''}`}>
          {
            infoList.map(({
              title, text, icon, color, id,
            }) => (
              <RankedCompanyCardInfo
                title={title}
                text={text}
                icon={icon}
                color={color}
                key={id}
              />
            ))
          }
        </div>
      )}
    </div>
  );
};

RankedCompanyCard.propTypes = {
  company: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  reportId: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  infoList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
  })),
};

RankedCompanyCard.defaultProps = {
  infoList: [],
};

export default RankedCompanyCard;
