import React, { Component } from 'react';
import CompanyAttributes from 'components/User/Reports/CompanyInformationView/CompanyAttributes';
import StringUtils from 'utils/StringUtils';
import './CompanyListView.scss';

export class CompanyListView extends Component {

  getCompanyList = (companies) => {
    const stringUtils = new StringUtils();
    return companies.map(({ name, text}) => {
      return {
        name: text,
        image: stringUtils.getCompanyLogo(name.toLowerCase())
      }
    });
  }

  render() {
    const { name = '', companies = [], date, max = 5 } = this.props;
    return (
      <div className="company-list-view">
        <div className="company-list-view__title">
          { name }
        </div>
        { date ?
          <div className="company-list-view__sub-title">
            { date }
          </div>:''
        }
        <div className="company-list-view__list">
          <CompanyAttributes size="small" companyList={this.getCompanyList(companies)} max={max} />
        </div>
      </div>
    )
  }
}

export default CompanyListView;
