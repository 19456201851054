import React, { Component } from 'react';
import './RadioButton.scss';

export class RadioButton extends Component {

  constructor(props) {
    super(props)
    const { checked = false } = props;
    this.state = {
      isChecked: checked
    }
  }

  componentWillReceiveProps(props) {
    const { checked = false } = props;
    this.setState({ isChecked: checked });
  }

  onChangeRadio = (e) => {
    const { checked } = e.target;
    this.setState({ isChecked: checked });
    this.props.handleChange(checked, {...this.props});
  }

  render() {
    const { isChecked } = this.state;
    const { text, name, color, id, classes = '' } = this.props;
    return (
      <div className={`a-radio-button ${classes}`}>
        <div className="a-radio-button__inner">
          <input 
            className="a-radio-button__input" 
            color={color} 
            id={id} 
            type="radio"
            name={name}
            checked={isChecked}
            onChange={this.onChangeRadio} 
          />
          <label htmlFor={id}>{text}</label>
        </div>
      </div>
    )
  }
}

export default RadioButton;
