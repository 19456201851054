import React, { Component } from 'react';
import { connect } from 'react-redux';
import cardImage from 'assets/img/Market launch-rafiki.svg';
import { updateAuthModal } from 'store/actions/root/auth-modal';
import './UpgradeAccountCard.scss';

export class UpgradeAccountCard extends Component {
  render() {
    const { userType, color = 'white' } = this.props;

    return (
      <div className="upgrade-account-card" color={color}>
        <div className="upgrade-account-card__image">
          <img src={cardImage} />
        </div>
        { userType == 'demo' ? 
          <div className="upgrade-account-card__content">
            <div className="upgrade-account-card__content__title">
              I want to see more companies
            </div>
            <div className="upgrade-account-card__content__text">
              Get access to more companies in your vertical
            </div>
            <div className="upgrade-account-card__content__button" onClick={ () => { this.props.updateAuthModal(true) } }>
                <a>
                Start a Free Account
                </a>
            </div>
          </div>:
          <div className="upgrade-account-card__content">
            <div className="upgrade-account-card__content__title">
              I want to personalize my results
            </div>
            <div className="upgrade-account-card__content__text">
              Upgrade to edit Target Vertical, Solution Attributes, and Titles
            </div>
            <div className="upgrade-account-card__content__button">
              <a href="https://enkiai.com/contact" target="_blank">
                Request a Premium Account
              </a>
            </div>
          </div>
        }

      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateAuthModal: (status) => dispatch(updateAuthModal(status)),
  }
};

export default connect(null, mapDispatchToProps)(UpgradeAccountCard);
