import React, { Component } from 'react'
import { CardViewLoader } from 'components/Loaders/CardViewLoader';


const getContents = (size) => {
  const array = [];

  for (let index = 0; index < size; index++) {
    array[index] = index
  }

  return array.map((i) => {
    return (
      <div className="col-md-12 col-lg-6 col-xl-4 margin-t-0 margin-b-20" key={i}>
        <CardViewLoader />
      </div>
    )
  });
}

export class ReportCardPlaceHolder extends Component {
  render() {
    const { size = 3 } = this.props;

    return (
      <div className="row margin-t-0">
        {
          getContents(size)
        }
      </div>
    )
  }
}

export default ReportCardPlaceHolder;
