import React, { Component } from 'react';
import companyInsightsLoader from 'assets/img/placeholders/company_insight.svg';

export class CompanyInsightsLoader extends Component {
  render() {
    return (
      <div className="margin-t-0 company-insight-loader">
        <img src={companyInsightsLoader} />
      </div>
    )
  }
}

export default CompanyInsightsLoader;
