import ReactGA from 'react-ga';
import CookieService from './CookieService';

class GoogleAnalyticsService {
    init() {
        ReactGA.initialize('UA-135891413-1');

        const cookieService = new CookieService();
        const userId = cookieService.getCookie('_gtmUID');

        if (userId != undefined && userId.length > 0) {
            ReactGA.set({ userId: userId });
        }
        
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    logPageview(path) {
        ReactGA.pageview(path);
    }

    setUserId(userId) {
        const cookieService = new CookieService();
        cookieService.setCookie({ name: '_gtmUID', value: btoa(userId) }, 180);
        ReactGA.set({ userId: btoa(userId) });
    }
}

const GAService = new GoogleAnalyticsService();

export default GAService;