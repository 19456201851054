import React, { Component } from 'react';
import toolTipLoader from 'assets/img/placeholders/tooltip_content.svg';
import './ToolTipContentLoader.scss';

export class ToolTipContentLoader extends Component {
  render() {
    return (
      <div className="tooltip-loader">
        <img className="responsive-image" src={toolTipLoader} />
      </div>
    )
  }
}

export default ToolTipContentLoader;
