import React from 'react';
import ReactECharts from 'echarts-for-react';
import { isSimilar, relevancyCompare } from './util';

require('echarts-wordcloud');

export class Wordle extends React.Component {
  getOption(allData, slicedDataArray) {
    let option = {
        tooltip: {
            confine: true,
            enterable: true,
            triggerOn: 'click',
            backgroundColor: 'rgba(50,50,50,1)',
            formatter: (params) => {
              const compare = relevancyCompare;
              const sentences = allData[params.name].sort(compare);
              const source = this.props.source;
              if (source === 'googlenews' ) {
                return `<b>${params.name.toUpperCase()}</b><br/><div class="gap"></div>` + sentences.map((article) => `<li><a class='tooltip-link' href='${article.link || article.url}' target='_blank'>${article.title}</a></li><br/>`).join('<div class="gap"></div>');
              } else if (source === 'gs') {
                return `<b>${params.name.toUpperCase()}</b><br/><div class="gap"></div>` 
                + sentences.map((article) => {
                    const publishedDate = article.published_date ? `${new Date(article.published_date).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })} - ` : ``;
                    return `<li><a class='tooltip-link' href='${article.link || article.url}' target='_blank'>${publishedDate}${article.title}</a></li><br/>`
                  }).join('<div class="gap"></div>');
              } else {
                return `<b>${params.name.toUpperCase()}</b><br/>` + sentences.map(s => `- ${s}<br/>`).join('');
              }
            },
            extraCssText: 'max-width: 400px; white-space: initial; user-select: text'
        },
        series: [{
            name: 'Count',
            type: 'wordCloud',
            size: ['80%', '80%'],
            rotationRange: [0, 0],
            textPadding: 0,
            gridSize: 8,
            drawOutOfBound: true,
            sizeRange: [16, 30],
            autoSize: {
                enable: true,
                minSize: 16
            },
            textStyle: {
              color: function () {
                return 'rgb(' + [
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160)
                ].join(',') + ')';
              }
            },
            data: slicedDataArray
        }]
    };

    return option;
  }

  onChartClick(params) {
    this.props.onLabelClick && this.props.onLabelClick(params);
  }

  render() {
    const data = this.props.data;
    const uniqueData = {};
    Object.keys(data).map(key => {
      const sentences = data[key];
      if (sentences.length > 0) {
        const uniqueSet = [];
        uniqueData[key] = sentences.filter(s => {
          const sentence = s.title || s;
          if (!isSimilar(uniqueSet, sentence, s.matchedAttributes)) {
            uniqueSet.push(sentence);
            return true;
          }
          return false;
        });
      }
    });

    let uniqueDataArray = Object.keys(uniqueData)
    .map(key => ({
      name: key,
      value: data[key].length
    }))
    .sort((a, b) => b.value - a.value);

    uniqueDataArray = uniqueDataArray.slice(0, this.props.top || 20);

    return (
      <ReactECharts 
          option={this.getOption(uniqueData, uniqueDataArray)}
          style={{height: '300px'}}
          onEvents= {{ click: this.onChartClick.bind(this) }}
      />
    );
  }
}

export default Wordle;
