
const defaultSettings = {
  hasCompanyIntroShown: false,
  reportListType: 'grid'
}

export class LocalSettingsService {

  initLocalSettings() {
    window.localSettings = JSON.parse(localStorage.getItem('localSettings')) || defaultSettings
  }

  updateLocalSettings(localSettings) {
    window.localSettings = localSettings;
    localStorage.setItem('localSettings', JSON.stringify(localSettings));
  }

}

export default LocalSettingsService;
