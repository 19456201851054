import React from 'react';
import { connect } from 'react-redux';
import PremiumCardView from 'components/User/Reports/PremiumCardView/PremiumCardView';
import UserReportsView from 'components/User/Reports/UserReportsView/UserReportsView';
import NextReportCard from 'components/User/Reports/NextReportCard/NextReportCard';
import morningMessagePlaceLocader from 'assets/img/placeholders/morning_message.svg';
import ScrollToTop from 'components/Commons/ScrollToTop/ScrollToTop';
import './Dashboard.scss';

const Dashboard = ({ currentUserData }) => (
  <div className="user-dashboard">
    <div className="user-dashboard__head">
      { currentUserData && currentUserData.firstName ?
        (
          <div className="user-dashboard__head__title">
            Hi, { currentUserData.firstName }
          </div>
        )
        : <img src={morningMessagePlaceLocader} alt="morning message" />
      }
      <div className="user-dashboard__head__subtitle">
        Specify your target, and Enki will tell you everything you need to know.
      </div>
    </div>
    <div className="user-dashboard__body">
      <div className="row">
        <div className="col-md-12 col-lg-6 col-xs-12 margin-t-20 padding-r-5">
          <NextReportCard />
        </div>
        <div className="col-md-12 col-lg-6 col-xs-12 margin-t-20 padding-r-5">
          <PremiumCardView />
        </div>
      </div>
      <ScrollToTop />
      <UserReportsView />
    </div>
  </div>
);

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { userDataReducers } = rootReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
  };
};

export default connect(mapStateToProps)(Dashboard);
