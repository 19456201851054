import React, { Component } from 'react'
import './SearchBar.scss';

export class SearchBar extends Component {

  handleChange = (e) => {
    this.props.onValueChange(e.target.value);
  }

  render() {
    const { placeholder = "Search in reports" } = this.props;
    return (
      <div className="search-bar">
        <input placeholder={placeholder} onChange={this.handleChange} />
        <div className="search-bar__icon">
          <i className="material-icons">search</i>
        </div>
      </div>
    )
  }
}

export default SearchBar
