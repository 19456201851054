import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import emptyContentImage from 'assets/img/Empty-rafiki.svg';
import './NoReportContent.scss';

export class NoReportContent extends Component {
  render() {
    const { type = "demo", button = {} } = this.props;
    const { text = "Start New Report", to = '/' } = button;
    return (
      <div className="no-report-content" data-type={type}>
        <div className="no-report-content__title">
          No Reports Found
        </div>
        <div className="no-report-content__text">
          Find insights for a corporation or organization
        </div>
        <Link to={to} className="next-report-card__content__button">
          {text}
        </Link>
        <img className="no-report-content__img" src={emptyContentImage} /> 
      </div>
    )
  }
}

export default NoReportContent;
