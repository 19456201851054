import React from 'react';
import './ProgressBar.scss';

const getProcessColor = (size) => {
  const color = Math.ceil(size / 25);

  const colorSwitch = {
    1: 'low',
    2: 'middle',
    3: 'middle-up',
    4: 'full',
  };

  return colorSwitch[color];
};

const ProgressBar = (props) => {
  const { size } = props;
  return (
    <div className="progress-bar">
      <div className="progress-bar__inner" color={getProcessColor(size)} style={{ width: `${Math.round(size)}%` }}>
        <span className="progress-bar__inner__text">
          { `${Math.round(size)}%` }
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
