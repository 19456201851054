import React, { Component } from 'react'
import { CircleLoader } from 'components/CircleLoader/CircleLoader';
import './IconButton.scss';

export class IconButton extends Component {
  constructor(props) {
    super(props);
    this.state = { isHolded: false };
  }

  onClick = (e) => {
    e.preventDefault();
    const { isHolded = false } = this.state;
    this.setState({ isHolded: !isHolded });
    this.props.handleClick(this.props.value);
  }

  getIconButtonClass = () => {
    const { classes, size } = this.props;
    return `a-icon-button ${classes} ${size}`;
  }

  getButtonClass = () => {
    const { isHolded = false } = this.state;
    const {
      holdable = false,
      size = '',
      classes = '',
      holded = false,
    } = this.props;
    return `a-icon-button ${classes} ${size} ${(holdable && isHolded) || holded ? 'hold' : ''}`;
  }

  getCircleLoader = () => (
    <span className="margin-r-5 margin-t-0">
      <CircleLoader isActive={true} size="xx-small" />
    </span>
  );

  iconLinkButton = () => {
    const { target='_blank', title, color, iconName, isLoading, text, url } = this.props;
    return (
      <a
        data-tooltip={title}
        className={this.getIconButtonClass()}
        color={color}
        href={url}
        target={target}
      >
        { isLoading ?
          this.getCircleLoader():
          <i className="material-icons"> {iconName} </i>
        } 
        { text }
      </a>
    );
  }

  render() {
    const {
      color,
      iconName,
      text,
      isLoading = false,
      url,
      title,
      type = 'button',
      disabled = false,
    } = this.props;

    if (url) {
      return this.iconLinkButton();
    }

    return (
      <button
        data-tooltip={title}
        className={this.getButtonClass()}
        color={color}
        onClick={this.onClick}
        disabled={disabled}
        type={type}
      >
        {
          isLoading ? this.getCircleLoader() : (
            <i className="material-icons">
              {iconName}
            </i>
          )
        }
        { text }
      </button>
    );
  }
}

export default IconButton;
