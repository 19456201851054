import TokenService from "./TokenService";

class HttpInterceptor {
  load() {
    const that = this
    var originalFetch = fetch;
    fetch = function () {
      console.log(arguments);
      if (arguments.length > 1) {
        arguments[1] = that.setRequestConfigs(arguments[1])
      }

      return originalFetch.apply(this, arguments);
    };
  }

  setRequestConfigs(request) {
    const { body } = request;
    if (body) {
      const tokenService = new TokenService();
      const bodyParams = JSON.parse(body);
      if (bodyParams.hasOwnProperty('addToken') && tokenService.hasToken()) {
        delete bodyParams['addToken'];
        bodyParams['token'] = tokenService.getToken();
        request.body = JSON.stringify(bodyParams);
      }
    }

    return request
  }
}

export default HttpInterceptor;
