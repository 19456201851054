import React from 'react';
import Select from 'react-select';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';

const customStyles = {
  placeholder: (provided) => {
    return {
      ...provided,
      paddingLeft: 0,
      fontWeight: 400,
    }
  },
  control: (provided) => {
    return {
      ...provided,
      borderWidth: 0,
      borderRadius: 20,
      backgroundColor: "#F5F5F5",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow: 0,
      ':hover,:focus': {
        borderColor: "#9753e1"
      }
    };
  }, 
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "#FFFFFF",
      borderRadius: 20,
      paddingLeft: 5,
      paddingRight: 5
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: "15px",
      fontWeight: "500",
    }
  }
}

const customTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral20: "#464646",
    }
  };
}

const getNewOptionData = (inputValue, optionLabel) => {
  return {
    value: inputValue.toUpperCase(),
    label: optionLabel.toUpperCase()
  }
}

export default class SelectorInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = { inputValue: '' };
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue: inputValue.toUpperCase() }); 
    return inputValue.toUpperCase();
  };

  handleChange = (newValue, { action }) => {
    this.runOnChanged(action, newValue);
    this.props.onChange(newValue);
  };

  runOnChanged(type, values) {
    const options =  { 
      'select-option': () => {},
      'remove-value': () => {},
    }[type] || (() => {})
    return options()
  }

  render() {
    const { inputValue } = this.state;
    const { options, placeholder } = this.props;
    return (
      <Select
        placeholder={placeholder}
        onChange={this.handleChange}
        options={options}
        value={this.props.value}
        inputValue={ inputValue }
        onInputChange={ this.handleInputChange }
        styles={customStyles}
        theme={customTheme}
        getNewOptionData={getNewOptionData}
        isDisabled={this.props.disabled}
        isClearable
      />
    );
  }
}
