import React, { Component } from 'react'
import './ReportItemDateView.scss';

export class ReportItemDateView extends Component {
  render() {
    const { date } = this.props;
    return (
      <div className="report-item-date-view">
        <div className="report-item-date-view__icon">
          <i className="material-icons"> event_available </i>
        </div>
        <div className="report-item-date-view__text">
          { date || ''}
        </div>
      </div>
    )
  }
}

export default ReportItemDateView
