export default {
    "IOT INSIGHT": [
    ],
    "TECHNOLOGY INSIGHT": [
    ],
    "IT INSIGHT": [
    ],
    "MESSAGING PLATFORM INSIGHT": [
    ],
    "PURPOSE DRIVEN MARKETING": [
        "CHIEF CUSTOMER OFFICER",
        "CHIEF MARKETING OFFICER",
        "CHIEF FINANCIAL OFFICER",
        "DIRECTOR OF MARKETING",
        "VP OF MARKETING",
        "LOYALTY",
        "VP OF LOYALTY",
        "CORPORATE SOCIAL RESPONSIBILITY",
        "SOCIAL RESPONSIBILITY",
        "VP OF SOCIAL RESPONSIBILITY",
    ],
}