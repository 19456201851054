import React, { Component } from 'react';
import { CompanyHeader } from './CompanyHeader';
import { ToolTipContentLoader } from 'components/Loaders/TooltipContentLoader';
import { HttpService } from 'services/HttpService';
import { IconButton } from 'components/form/IconButton/IconButton';
import { CompanyContentAttributes } from './CompanyContentAttributes';
import './CompanyContent.scss';

const REQUEST_TIMEOUT = (10 * 1000);
const IRRELEVANT_ACTION_KEY = 'irrelevant';
const REPORT_ACTION_KEY = "report";

export class CompanyContent extends Component {

  constructor(props) {
    super(props);
    this.onContentReadyTimeout = undefined
    this.state = { companyData: {}, isPendingRequest: false, key: '', contentNotFound: false };
  }

  componentWillMount() {
    this.onUpdateContent(this.props);
  }

  componentWillReceiveProps(props) {
    this.onUpdateContent(props);
  }

  onUpdateContent(props) {
    const { name } = props;
    this.setState({ key: name });
    if(name && !window.tooltipDatas[name]) {
      this.loadCompanyData(name);
    } else {
      this.setState({ companyData: window.tooltipDatas[name] });
      this.onContentReady();
    }
  }

  onContentReady = () => {
    clearTimeout(this.onContentReadyTimeout);
    this.onContentReadyTimeout = setTimeout(() => {
      this.props.onContentReady({});
    }, 300);
  }

  loadCompanyData = (name) => {
    const body = {
    	"company_name": name.toLowerCase()
    };
    const { isPendingRequest } = this.state;
    if (!isPendingRequest) {
      this.setState({ isPendingRequest: true });
      const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
      setTimeout(() => this.checkRequestStatus(), REQUEST_TIMEOUT);
      new HttpService().sendRequest(`${host}/api/get/company_informations`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => {
        const { result, data } = response;
        if(result == 200) {
          window.tooltipDatas[name] = data;
          this.setState({ companyData: data, isPendingRequest: false });
        } else {
          window.tooltipDatas[name] = { not_found: true };
          this.setState({ companyData: {}, isPendingRequest: false, contentNotFound: true });
        }

        this.onContentReady();
      });
    }
  }

  checkRequestStatus = () => {
    const { userData = {} } = this.state;
    const { name } = userData;
    if(!name) {
      this.onError();
    }
  }

  onError = () => {
    this.props.onTooltipError({});
  }

  onStartContentLoading = () => {
    this.props.onStartContentLoading({});
  }

  getCompetitors = (related_search) => {
    if(related_search.length > 0) {
      return (
        <div>
          <div className='company-content__body__title'>
            Related Companies
          </div>
          <CompanyContentAttributes companyList={related_search} />
        </div>
      )
    }
  }

  onActionButtonHandle = (action) => {
    this.props.onTooltipAction(action);
  }

  render() {
    const { companyData, contentNotFound = false } = this.state;
    const { not_found = false, name, company_description = '', company_logo = '', description_text, url, website_url, related_search = []  } = companyData;
    if (name) {
      return (
        <div className="company-content">
          <div className='company-content__head'>
            <CompanyHeader {...{ name: name, subText: company_description, image: company_logo } } />
          </div>
          <div className='company-content__body'>
            {
              website_url ?
              <a className='company-content__body__url' href={website_url} target="_blank">
                <i className="material-icons"> launch </i>
                { website_url}
              </a> : ''
            }            
            { description_text }
            { 
              this.getCompetitors(related_search)
            }
          </div>
          <div className='company-content__foot'>
            <IconButton color="orange" size="small" iconName="refresh" text="Refresh" title="Request company data refresh" value = {REPORT_ACTION_KEY} handleClick={this.onActionButtonHandle} />
            <IconButton color="red-light" size="small" iconName="announcement" text="Report" title="Report invalid entity" value={IRRELEVANT_ACTION_KEY} handleClick={this.onActionButtonHandle} />
            {
              url && !url.isNullOrEmpty() ? 
              <IconButton color="blue-2" size="small" iconName="visibility" text="Read More" title="Open source in a new tab" isLoading={!url} url={url} /> : ''
            }
          </div>
        </div>
      )
    } else if (!contentNotFound && !not_found) {
      this.onStartContentLoading()
      return (<ToolTipContentLoader /> )
    }

    return (<div className="tooltip-view__text">
      Crawling data. Will be available soon.
    </div>)
  }
}

export default CompanyContent;
