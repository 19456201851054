import React, { Component } from 'react';
import { CompanyValueItem } from './CompanyValueItem';
import StringUtils from 'utils/StringUtils';
import './CompanyValuesCard.scss';

export class CompanyValuesCard extends Component {

  getITSpends = (it_spends) => {
    if(it_spends.isNullOrEmpty()) {
      return ''
    }

    const itSpend = { name: "IT Spends", value: new StringUtils().formatToUSDCurrency(it_spends) };
    return (
      <CompanyValueItem item={itSpend} />   
    )
  }

  render() {
    const { companyData } = this.props;
    const { values = [], it_spends = '' } = companyData;

    return (
      <div className="company-values-view cursor--default">
        <div className="company-values-view__title">
          Additional Info
        </div>
        <div className="company-values-view__content">
          {
            values.map((item, i) => {
              return <CompanyValueItem item={item} key={i} />
            })
          }    
          { this.getITSpends(it_spends) }
        </div>
      </div>
    )
  }
}

export default CompanyValuesCard
