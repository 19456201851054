
export const ON_CHANGE_ROUTE = 'ON_CHANGE_ROUTE';
export const onChangeRoute = ({ action, location }) => {
  return {
    type: ON_CHANGE_ROUTE,
    action: action,
    location: location
  }
}

export const ON_GENERATE_URL = 'ON_GENERATE_URL';
export const onGenerateURL =  urlHash  => {
  return {
    type: ON_GENERATE_URL,
    urlHash: urlHash
  }
}
