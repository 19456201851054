import React from 'react';
import './NoResultContent.scss';
import desertImage from 'assets/img/desert.svg';

const NoResultContent = ({ type = "demo", textContent }) => (
  <div className="no-result-content" data-type={type}>
    { !textContent ?
      (
        <div className="no-result-content__text">
          Please
           <a href="https://enkiai.com/contact" target="_blank" rel="noreferrer">contact us</a> 
           to see this section.
        </div>
      ) : textContent
    }
    <img className="no-result-content__img" src={desertImage} alt="No result" />
  </div>
);

export default NoResultContent;
