import React, { Component } from 'react';
import './MessageContent.scss';

export class MessageContent extends Component {
  render() {
    return (
      <div className="message-content">
        The Cambridge English Dictionary defines a decision maker as a “person who decides things, especially at a high level in an organization.” A decision maker might be responsible for strategic decisions like acquisitions, business expansion or capital investment.
      </div>
    )
  }
}

export default MessageContent;
