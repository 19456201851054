import React, { Component } from 'react';
import CardView from 'components/Views/CardView/CardView';
import filterViewLoader from 'assets/img/placeholders/filter_view.svg';

export class FilterViewLoader extends Component {
  render() {
    return (
      <div>
        <CardView childComponent={
          <img src={filterViewLoader} />
        } />
      </div>
    )
  }
}

export default FilterViewLoader
