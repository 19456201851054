import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateAuthModal } from 'store/actions/root/auth-modal';
import './BlurComponent.scss';

export class BlurComponent extends Component {
  render() {
    const { child, level, options, isHidden=false } = this.props;
    const { contentPosition='center' } = options;
    return (
      <div className={ isHidden ? "blur-view hide": "blur-view"} data-position={contentPosition}>
        <div className="blur-view__content" data-level={level}>
          { child }
        </div>
        <div className="blur-view__wrapper" data-level={level}>
        </div>
        <div className="blur-view__auth-content">
          {
            options.title ?
            <div className="blur-view__auth-content__title" dangerouslySetInnerHTML={{__html: options.title}} />: ''
          }
          {
            options.button ?
            <a className="blur-view__auth-content__button" href={options.button.href} target={options.button.target} onClick={ () => { this.props.updateAuthModal(true) } }>
              {
                options.button.text
              }
            </a>: ''
          }
          {
            options.message ?
            <div className="blur-view__auth-content__message">
              {options.message}
            </div>: ''
          }
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateAuthModal: (status) => dispatch(updateAuthModal(status)),
  }
};

export default connect(null, mapDispatchToProps)(BlurComponent);
