import React, { Component } from 'react';
import noImage from 'assets/img/no_image.png';

export class Image extends Component {
  render() {
    const { alt, src } = this.props;
    return (
      <img src={src || noImage} alt={alt} onError={this.onError} />
    )
  }

  onError = (event) => {
    const { src } = this.props;
    console.error(src);
    event.target.src = noImage;
  }
}

export default Image;
