import React from 'react';
import ReactECharts from 'echarts-for-react';
import { connect } from 'react-redux';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import Insights from './heatmap.insight';
import Ontology from './ontology';
import { parseQueryResults, parseGQueryResults } from './util.jsx';

class Heatmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      json: null,
      data: [],
      max: 0,
      companies: props.companies,
      areas: getUniqueAreas(props.areas),
      companyInput: '',
      areaInput: '',
      filterInput: props.filter || '',
      isCompanyPopoverOpen: false,
      isAreaPopoverOpen: false,
      isFilterPopoverOpen: false,
      insights: null,
      articleFilter: props.articleFilter,
      heatmapData: {}
    }
  }

  componentDidMount() {
    // const parsedData = parseHeatmapaResults(cachedData);
    // this.setState({ loading: false, ...parsedData });
    this.requestData(this.state.companies, this.state.areas, this.state.filterInput, parseHeatmapaResults);
  }

  getAreaList = (areas) => {
    return areas.map(area => Object.values(area)[0]).flat();
  }

  getFilteredJson = (jsonList, areas) => {
    const areaList = this.getAreaList(areas);
    const filteredJson = jsonList.filter(({area}) => {
      return areaList.some((areaItem) => {
        return areaItem.toLowerCase() === area.toLowerCase()
      })
    });
    return filteredJson;
  }

  initHeatmapFromNextProps = (nextProps) => {
    const { areas = [] } = nextProps;
    const { jsonList = [] } = this.state;
    const filteredJson = this.getFilteredJson(jsonList, areas);
    const parsedData = parseHeatmapaResults(filteredJson);
    this.setState({ areas: getUniqueAreas(areas), loading: false, ...parsedData });
    this.props.onChange && this.props.onChange(this.state);
  }

  checkArticleFilterChanges = ({ filteredMonth, titleAttributeMatched, titleCompanyMatched }) => {
    const { articleFilter } = this.props;
    return (
      filteredMonth !== articleFilter.filteredMonth 
      || titleAttributeMatched !== articleFilter.titleAttributeMatched 
      || titleCompanyMatched !== articleFilter.titleCompanyMatched
    );
  }

  componentWillReceiveProps(nextProps) {
    const { articleFilter = {}, areas = [] } = nextProps;
    const { loading } = this.state;
    if (!loading && areas.length > 0) {
      this.initHeatmapFromNextProps(nextProps);
    }

    if (this.checkArticleFilterChanges(articleFilter)) {
      this.setState({ articleFilter }, () => {
        this.requestData(this.state.companies, this.state.areas, this.state.filterInput, parseHeatmapaResults);
      })
    }
  }

  onPopoverChange(key, event) {
    this.setState({
      [key + 'Input']: event.target.value.toUpperCase() 
    });
  }

  onAdd(key, event) {
    this.setState({
      isCompanyPopoverOpen: false,
      isAreaPopoverOpen: false,
      isFilterPopoverOpen: false
    });

    if (key === 'company') {
      const newCompany = this.state.companyInput;

      if (newCompany === '') {
        return;
      }

      const companies = this.state.companies.concat([{
        [newCompany]: [newCompany]
      }]);

      this.setState({
        companies,
        companyInput: ''
      });

      this.requestData(companies, this.state.areas, this.state.filterInput, parseHeatmapaResults);
    } else if (key === 'area') {
      const newArea = this.state.areaInput;

      if (newArea === '') {
        return;
      }

      const areas = this.state.areas.concat([{
        [newArea]: Ontology[newArea] || [newArea]
      }]);

      this.setState({
        areas,
        areaInput: ''
      });

      this.requestData(this.state.companies, areas, this.state.filterInput, parseHeatmapaResults);
    } else {
      const filter = this.state.filterInput;

      this.requestData(this.state.companies, this.state.areas, filter, parseHeatmapaResults);
    }
  }

  getUniqueAreas = () => {
    const { json = [] } = this.state;
    if (!json) {
      return [];
    }

    return json.map(({ area }) => area.toUpperCase());
  }

  getOption() {
    const companies = this.state.companies.map(c => Object.keys(c)[0]);
    const areas = this.getUniqueAreas();
    const showInsight = this.props.type === 'insight';
    
    return {
        tooltip: {
            enterable: true,
            triggerOn: showInsight ? 'click' : 'mousemove',
            backgroundColor: 'rgba(50,50,50,1)',
            textStyle: {
              color: '#fff',
            },
            formatter: (params) => {
              const companyIndex = params.value[0];
              const areaIndex = params.value[1];
              const company = companies[companyIndex];
              const area = areas[areaIndex];
              return `<b>${company} & ${area}</b><br/>`;
            },
            extraCssText: 'max-width: 500px; white-space: initial; user-select: text; user-select: text;'
        },
        animation: false,
        grid: {
            top: 0,
            containLabel: true,
        },
        yAxis: {
            type: 'category',
            data: areas,
            axisLabel: {
              fontSize: 10,
              fontWeight: 'bold'
            },
            splitArea: {
              show: true
            },
            triggerEvent: true
        },
        xAxis: {
            type: 'category',
            data: companies,
            axisLabel: {
              fontSize: 10,
              fontWeight: 'bold'
            },
            splitArea: {
                show: true
            },
            triggerEvent: true
        },
        visualMap: {
            min: 0,
            max: this.state.max,
            calculable: true,
            orient: 'horizontal',
            left: '50%',
            bottom: '0%',
            inRange: {   
              color: ['#EDF5FF', '#D0E2FF', '#A6C8FF', '#78A9FF', '#4589FF', '#0F62FE', '#0043CE', '#002D9C', '#001D6C', '#001141' ] // smaller to bigger value ->
            },
            formatter: (value) => {
              let count = value;
              if (this.props.source === 'googlenews' && count === '100') {
                count = '100+';
              } 
              return count;
            }
        },
        series: [{
            name: 'Mentions in the news',
            type: 'heatmap',
            data: this.state.data.map(value => {
              let count = value[2];
              return {
                value: value,
                label:{
                  color: this.state.max > 0 && count / this.state.max < 0.5 ? '#000' : '#fff'
                }
              };
            }),
            label: {
                normal: {
                  fontWeight: 'bold',
                  color: 'auto',
                  formatter: (params) => {
                    let count = params.value[2];
                    if (this.props.source === 'googlenews' && count === '100') {
                      count = '100+';
                    }
                    return count;
                  },
                  show: true
                }
            },
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.3)'
                }
            }
        }]
    };
  }

  loadWatsonData(query, onLoad) {
    // set loading state
    this.setState({
      loading: true,
    });  

    // build payload
    const body = {
      data: [query],
      count: 1,
      return: 'title,text,url'
    };

    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    fetch(`${host}/api/batch`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let results = parseQueryResults([{}], json, true);
          onLoad(results);
          this.setState({ loading: false });
        });
      } else {
        response.text().then((err) => {
          this.setState({ error: JSON.parse(err).error, loading: false });
          alert(JSON.parse(err).error);
        }).catch(() => {
          this.setState({
            error: 'There was a problem with the request, please try again',
            loading: false,
          });
        });
      }
    });
  }

  loadGData(query, onLoad) {
    // set loading state
    this.setState({
      loading: true,
    });


    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const endpoint = this.props.source === 'googlenews' ? 'gnews' : 'gsearchV2';
    const body = JSON.stringify(query);
    fetch(`${host}/api/${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let results = parseGQueryResults([{}], json, this.props.source, body);
          onLoad(results);
          this.setState({ loading: false });
        });
      } else {
        response.text().then((err) => {
          this.setState({ error: JSON.parse(err).error, loading: false });
          alert(JSON.parse(err).error);
        }).catch(() => {
          this.setState({
            error: 'There was a problem with the request, please try again',
            loading: false,
          });
        });
      }
    }).catch((err) => {
      alert(err);
    });
  }

  requestData(companies, areas, filter, mapData) {
    this.setState({
      loading: true
    });
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const endpoint = this.props.source ? 'heatmapV5' : 'heatmapV3';
    const { articleFilter } = this.state;
    fetch(`${host}/api/${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...this.props.query, articleFilter }),
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          const parsedData = mapData(json);
          this.setState({ loading: false, ...parsedData, jsonList: json });
          this.props.onChange && this.props.onChange(this.state);
        });
      } else {
        response.text().then((err) => {
          this.setState({ error: JSON.parse(err).error, loading: false });
          alert(JSON.parse(err).error);
        }).catch(() => {
          this.setState({
            error: 'There was a problem with the request, please try again',
            loading: false,
          });
        });
      }
    });
  }

  onChartClick(params) {
    if (params.componentType === 'xAxis') {
      const filteredJson = this.state.json.filter(j => j.company.toLowerCase() !== params.value.toLowerCase());
      const parsedData = parseHeatmapaResults(filteredJson);
      this.setState({ ...parsedData });

      const filteredCompanies = this.state.companies.filter(c => Object.keys(c)[0].toLowerCase() !== params.value.toLowerCase());
      this.setState({ companies: filteredCompanies });

      this.props.onChange && this.props.onChange(this.state);
    } else if (params.componentType === 'yAxis') {
      const filteredJson = this.state.json.filter(j => j.area.toLowerCase() !== params.value.toLowerCase());
      const parsedData = parseHeatmapaResults(filteredJson);
      this.setState({ ...parsedData });

      const filteredAreas = this.state.areas.filter(a => Object.keys(a)[0].toLowerCase() !== params.value.toLowerCase());
      this.setState({ areas: filteredAreas });

      this.props.onChange && this.props.onChange(this.state);
    } else {
      // if (this.props.type === 'market' || this.props.type === 'technology' || this.props.type === 'insight') {
      //   const count = +params.value[2] || 1;
      //   const companyIndex = params.value[1];
      //   const areaIndex = params.value[0];
      //   const companyObj = this.state.companies[companyIndex];
      //   const company = Object.keys(companyObj)[0];
      //   const subCompanies = companyObj[company];
      //   const areaObj = this.state.areas[areaIndex];
      //   const area = Object.keys(areaObj)[0];
      //   const subAreas = areaObj[area];
      //   const filter = this.state.filterInput;
      //   const onLoad = (response) => {
      //     const insights = {
      //       filter,
      //       company,
      //       subCompanies: subCompanies,
      //       area,
      //       subAreas: subAreas,
      //       sentences: response[0].result,
      //       showTitle: true,
      //       showHeatmap: this.props.type === 'market',
      //       source: this.props.source
      //     };
      //     if (this.props.onInsight) {
      //       this.props.onInsight(insights);
      //     } else {
      //       this.setState({
      //         insights
      //       });
      //     }
      //   };

      //   this.setState({ insights: null });

      //   if (this.props.source === 'googlenews' || this.props.source === 'gs') {
      //     const query = {
      //       companies: [companyObj],
      //       areas: [areaObj],
      //       filter
      //     }
      //     this.loadGData(query, onLoad);
      //   } else {
      //     const filterQuery = filter && filter.length > 0 ? `,enriched_text.semantic_roles.sentence:"${filter}"` : ``;
      //     const query = {
      //       aggregation: `nested(enriched_text.semantic_roles).filter(enriched_text.semantic_roles.subject.text:("${company}"),enriched_text.semantic_roles.object.text:("${subAreas.join('"|"')}")${filterQuery}).term(enriched_text.semantic_roles.sentence,count:${count})`,
      //       filter: null,
      //       query: null,
      //     };
    
      //     this.loadWatsonData(query, onLoad);
      //   }
        
      //   this.props.onRefresh && this.props.onRefresh(this.state);  
      // }
    }
  }

  render() {
    return (
      <div>
        <div className="heatmap">
          <ReactECharts 
            option={this.getOption()} 
            style={{height: '800px'}}
            showLoading={this.state.loading}
            onEvents= {{ click: this.onChartClick.bind(this) }}
          />
          {this.state.insights && <Insights {...this.state.insights} />}
        </div>
      </div>
    );
  }
}

const getUniqueAreas = (areas) => {
  const areaSet = new Set();
  const uniqueAreas = areas.filter(a => {
    const areaValue = Object.keys(a)[0];
    if (!areaSet.has(areaValue)) {
      areaSet.add(areaValue);
      return true;
    }
    return false;
  });

  return uniqueAreas;
}

const parseHeatmapaResults = (json) => {
  console.log(json);

  let companies = [];
  let areas = [];
  let counts = [];
  let max = 0;
  
  json.forEach(data => {
    let companyIndex = companies.indexOf(data.company);
    if (companyIndex < 0) {
      companyIndex = companies.length;
      companies.push(data.company);
    }

    let areaIndex = areas.indexOf(data.area);
    if (areaIndex < 0) {
      areaIndex = areas.length;
      areas.push(data.area);
    }

    let count = data.count || 0;
    counts.push([companyIndex, areaIndex, count.toString() || '-'] );
    max = Math.max(count, max);
  });

  return {
    json,
    max,
    data: counts    
  };
}

const mapStateToProps = function (store) {
  const { dashBoardReducers } = store;
  const { reportReducers } = dashBoardReducers;
  return {
    articleFilter: reportReducers.articleFilter,
  };
};

export default connect(mapStateToProps, null)(Heatmap);
