import React, { Component } from 'react'
import illustration from '../../assets/img/image_1.jpg'

export class LoginIllustration extends Component {
  render() {
    return (
      <div>
        <img src={illustration} alt="Get a Deeper Understanding of Your Market With Enki" />
      </div>
    )
  }
}

export default LoginIllustration
