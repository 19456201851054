import React, { Component } from 'react';
import { HttpService } from 'services/HttpService';
import ReactECharts from 'echarts-for-react';
import './HeatmapView.scss';
import { connect } from 'react-redux';

const heatmapInterVals = [
  { label: 'By Quarter', value: 'quarter' },
  { label: 'By Year', value: 'year' },
];

export class HeatmapView extends Component {
  constructor(props) {
    super(props);
    const { articleFilter } = props;
    const {
      titleAttributeMatched,
      titleCompanyMatched,
      filteredMonth,
    } = articleFilter;
    this.keywordCheckbox = {
      id: 'enable_keywords',
      text: 'Enable Keywords',
      color: 'primary',
    };
    this.state = {
      companies: props.companies,
      heatmapInterval: 'year',
      chartData: {},
      id: props.id,
      keyword: true,
      loading: false,
      titleAttributeMatched,
      titleCompanyMatched,
      filteredMonth,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    const { articleFilter, insightFilter = {} } = nextProps;
    const {
      filteredMonth,
      titleAttributeMatched,
      titleCompanyMatched
    } = articleFilter;
    const didFilterChanged = this.didInsightsFilterChanged(insightFilter);
    if (didFilterChanged || this.didArticleFilterChanged(articleFilter)) {
      this.setState({
        filteredMonth,
        titleAttributeMatched,
        titleCompanyMatched,
        insightFilter,
      }, () => {
        this.getStatsData();
      });
    }
  }

  getStatsData = () => {
    const {
      heatmapInterval, id, keyword,
      filteredMonth,
      titleAttributeMatched,
      titleCompanyMatched,
    } = this.state;
    const { insightFilter = {} } = this.props;
    const { didFilterChanged, matchCount = 0 } = insightFilter;
    this.setState({ loading: true });
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      companies: [
        id,
      ],
      keyword,
      heatmap: 'company',
      interval: heatmapInterval,
      minimumMatchCount: parseInt(matchCount, 10),
      ...(titleAttributeMatched) && { titleAttributeMatch: titleAttributeMatched },
      ...(titleCompanyMatched) && { titleCompanyMatch: titleCompanyMatched },
      ...(filteredMonth) && { limit: filteredMonth },
      ...(didFilterChanged) && {
        filter: insightFilter.keywords.map((keyword) => keyword.toLowerCase()),
      },
    };
    new HttpService().sendRequest(
      `${host}/api/company/nlpData/stats/`,
      {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((result) => {
      const { chartData = { } } = this.state;
      chartData[heatmapInterval + keyword.toString()] = result;
      this.props.onHeatmapChanged(Object.keys(result).length);
      this.setState({ loading: false, chartData });
    });
  }

  getKeywordsAndTimes = (companyData) => {
    const times = new Set();
    const keywordSum = new Map();
    Object.keys(companyData).forEach((key) => {
      const targetObject = companyData[key];
      Object.keys(targetObject).forEach((objectKey) => {
        keywordSum.set(key, (keywordSum.get(key) || 0) + targetObject[objectKey]);
        times.add(objectKey);
      });
    });
    const keywordList = Object.keys(companyData).sort((a, b) => {
      if (keywordSum.get(a) > keywordSum.get(b)) return 1;
      if (keywordSum.get(a) < keywordSum.get(b)) return -1;
      return 0;
    });
    const timesArray = [...times].sort(this.sortTimes);
    return { keywordList, times: new Set(timesArray) };
  }

  getYearElement = (param) => {
    const { marker, seriesName, value } = param;
    return `<li class='sort'>${marker} ${seriesName} <b>${value}</b></li>`;
  }

  getQuarterElement = (param) => {
    const { marker, seriesName, value } = param;
    return `<li>${marker} ${seriesName} <b>${value}</b></li>`;
  }

  getFormattedTootip = (params) => {
    const primaryObject = params[0];
    const { axisValueLabel } = primaryObject;
    const { metadata } = this.props;
    const { heatmapInterval } = this.state;
    const { attributeSummary = {}, attributeSummaryEnabled } = metadata;

    let markers = `<div class="heatmap-tooltip">
      <h5 class="heatmap-tooltip__title">${axisValueLabel}</h5>
      <ul>`;
    params.forEach((param) => {
      markers += heatmapInterval === 'year' ? this.getYearElement(param) : this.getQuarterElement(param);
    });
    markers += `</ul>`;
    if (
      this.isArticleFilterDefault()
        && attributeSummaryEnabled
        && axisValueLabel.toLowerCase() in attributeSummary
    ) {
      const summary = attributeSummary[axisValueLabel.toLowerCase()].join('<br/>');
      markers += !summary.isNullOrEmpty() ? `<h5>Featured Insight</h5><p>${summary}</p>` : '';
    }
    markers += `</div>`;
    return `${markers}`;
  }

  getCompanyName = () => (
    this.state.companies.map(c => Object.keys(c)[0])
  );

  getnewOptions() {
    const { heatmapInterval, chartData = {}, loading, keyword } = this.state;
    const companies = this.getCompanyName();
    const chartDataName = heatmapInterval + keyword.toString();
    if (!loading && chartData.hasOwnProperty(chartDataName)) {
      const companyData = chartData[chartDataName][companies] || {};
      const { keywordList, times } = this.getKeywordsAndTimes(companyData);
      const seriesMap = new Map();
      keywordList.forEach(key => {
        const value = companyData[key];
        times.forEach(key => {
          seriesMap.set(key, [...seriesMap.get(key) || [], value[key] || '-']);
        });
      });
      let series = [];
      seriesMap.forEach((value, key) => {
        series = [...series, {
          name: key,
          type: 'bar',
          stack: 'total',
          cursor: 'default',
          label: {
            show: true,
          },
          emphasis: {
            focus: 'series',
          },
          data: value,
        },
        ];
      });
      this.props.onHeatmapChanged(Object.keys(companyData).length);
      return {
        options: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: this.getFormattedTootip,
          },
          legend: {
            type: 'scroll',
            data: [...times],
          },
          toolbox: {
            show: true,
            top: '25px',
            right: '60px',
            feature: {
              saveAsImage: {
                title: 'Download as image',
              },
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: keywordList.map((keyword) => keyword.toUpperCase()),
          },
          series,
        },
        seriesLength: keywordList.length,
      };
    }

    !loading && this.getStatsData();
    return { options: {} };
  }

  isArticleFilterDefault = () => {
    const { articleFilter } = this.props;
    const { filteredMonth, titleAttributeMatched, titleCompanyMatched } = articleFilter;
    return (
      filteredMonth === 0
        && titleAttributeMatched === null
        && titleCompanyMatched === null
    );
  }

  didArticleFilterChanged = ({
    filteredMonth: nextFilteredMonth,
    titleAttributeMatched: nextTitleAttributeMatched,
    titleCompanyMatched: nextTitleCompanyMatched,
  }) => {
    const { articleFilter } = this.props;
    const { filteredMonth, titleAttributeMatched, titleCompanyMatched } = articleFilter;
    return (
      filteredMonth !== nextFilteredMonth
      || titleAttributeMatched !== nextTitleAttributeMatched
      || titleCompanyMatched !== nextTitleCompanyMatched
    );
  }

  didInsightsFilterChanged = (
    { didFilterChanged, keywords, matchCount },
  ) => {
    const { insightFilter = {} } = this.props;
    const { keywords: propsKeywords = [], matchCount: propsMatchCount } = insightFilter;
    return (
      didFilterChanged
        && (
          propsKeywords.join('-') !== keywords.join('-')
          || propsMatchCount !== matchCount
        )
    );
  }

  sortTimes = (a, b) => {
    const sliptA = a.split('-');
    const splitB = b.split('-');
    const valA = sliptA[1] || a;
    const valB = splitB[1] || b;
    const quarterA = sliptA[0][1] || 0;
    const quarterB = splitB[0][1] || 0;
    if (valA > valB) return 1;
    if (valA < valB) return -1;
    if (quarterA > quarterB) return 1;
    if (quarterA < quarterB) return -1;
    return 0;
  }

  handleSourceChange = (dataItem) => {
    this.setState({ heatmapInterval: dataItem });
  }

  getOptionWithoutKeywords = () => {
    const { heatmapInterval, chartData = {}, loading, keyword, companies } = this.state;
    const company = companies.map(c => Object.keys(c)[0]);
    const keyName = heatmapInterval + keyword.toString();
    if (!loading && chartData.hasOwnProperty(keyName)) {
      const companyData = chartData[keyName][company];
      return {
        options: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          legend: {
            type: 'scroll',
            data: Object.keys(chartData),
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: Object.keys(companyData),
          },
          series: [
            {
              type: 'bar',
              stack: 'total',
              cursor: 'default',
              label: {
                show: true,
              },
              emphasis: {
                focus: 'series',
              },
              data: Object.values(companyData),
            },
          ],
        },
        seriesLength: Object.keys(companyData).length,
      };
    }

    !loading && this.getStatsData();
    return { options: {} };
  }

  getSourcesList = () => {
    const { heatmapInterval = 'year' } = this.state;
    return (
      <div className="heatmap-view__sources">
        {
          heatmapInterVals.map(({ label, value }, key) => (
            <div
              className={
                `heatmap-view__sources__item ${heatmapInterval === value ? 'selected' : ''}`
              }
              key={key}
              onClick={() => { this.handleSourceChange(value); }}
              aria-hidden="true"
            >
              { label }
            </div>
          ))
        }
      </div>
    );
  }

  render() {
    const { keyword, loading } = this.state;
    const { options, seriesLength } =
      keyword ? this.getnewOptions() : this.getOptionWithoutKeywords();
    return (
      <div className="heatmap-view">
        { this.getSourcesList() }
        <div className="heatmap-view__charts">
          <ReactECharts
            notMerge={true}
            option={options}
            showLoading={loading}
            style={{ height: `${70 + (seriesLength * 40)}px` }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { dashBoardReducers } = store;
  const { reportReducers } = dashBoardReducers;
  const { insightFilter } = store.reportReducers.reportReducers;
  return {
    articleFilter: reportReducers.articleFilter,
    insightFilter,
  };
};

export default connect(mapStateToProps, null)(HeatmapView);
