import UserDataProvider from 'providers/user/UserDataProvider';

export const SET_USER_NLP_DATA = 'SET_USER_NLP_DATA';
export const setUserNLPData = userNLPData => ({
  type: SET_USER_NLP_DATA,
  userNLPData: userNLPData
})

export const USER_NLP_DATA_REQUEST = 'USER_NLP_DATA_REQUEST';
export const userNLPDataRequest = (userNLPDataRequest) => {
  return {
    type: USER_NLP_DATA_REQUEST,
    userNLPDataRequest
  }
}

export const USER_COMBINED_DATA_REQUEST = 'USER_COMBINED_DATA_REQUEST';
export const userCombinedDataRequest = (userCombinedDataRequest) => {
  return {
    type: USER_COMBINED_DATA_REQUEST,
    userCombinedDataRequest
  }
}

export const RECEIVE_USER_NLP_DATA = 'RECEIVE_USER_NLP_DATA';
export const receiveUserNLPData = (json) => {
  return {
    type: RECEIVE_USER_NLP_DATA,
    userNLPData: json,
  }
}

export const RECEIVE_USER_COMBINED_DATA = 'RECEIVE_USER_COMBINED_DATA';
export const receiveCombinedData = (json) => {
  return {
    type: RECEIVE_USER_COMBINED_DATA,
    combinedData: json,
  }
}

export const DELETE_COMBINED_DATA_ITEM = 'DELETE_COMBINED_DATA_ITEM';
export const deleteCombinedDataItem = (item) => {
  return {
    type: DELETE_COMBINED_DATA_ITEM,
    item: item,
  }
}

export const DELETE_SINGLE_COMPANY_DATA_ITEM = 'DELETE_SINGLE_COMPANY_DATA_ITEM';
export const deleteSingleCompanyItem = (item) => {
  return {
    type: DELETE_SINGLE_COMPANY_DATA_ITEM,
    item: item,
  }
}

export const RESET_COMBINED_DATA = 'RESET_COMBINED_DATA';
export const resetCombinedData = (json) => {
  return {
    type: RESET_COMBINED_DATA,
    combinedData: json,
  }
}

export const CLEAR_COMBINED_DATAS = 'CLEAR_COMBINED_DATAS';
export const clearCombinedDatas = () => {
  return {
    type: CLEAR_COMBINED_DATAS,
    userNLPData: [],
    combinedData: [],
  }
}

const dispatchRequest = (dispatch, isMultiple) => {
  if(isMultiple) {
    dispatch(userCombinedDataRequest(true));
  } else {
    dispatch(userNLPDataRequest(true));
  }
}

export function fetchCombinedCompanyData(body) {
  return (dispatch) => {
    const { isMultiple } = body;
    dispatchRequest(dispatch, isMultiple);
    new UserDataProvider().getUserCompanyData(body).then((data) => {
      if (isMultiple) {
        dispatch(receiveCombinedData(data));
        dispatch(userCombinedDataRequest(false));
      } else {
        dispatch(userNLPDataRequest(false));
        dispatch(receiveUserNLPData(data))
      }
    });
  }
}


