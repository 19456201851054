import React from 'react';
import { Route } from 'react-router-dom';
import ReportsViewHeader from 'components/User/Reports/ReportsViewHeader/ReportsViewHeader';
import UserReportsListView from 'components/User/Reports/UserReportsListView/UserReportsListView';
import UserMultipleReportsListView from 'components/User/Reports/UserMultipleReportsListView/UserMultipleReportsListView';
import './UserReportsView.scss';

const UserReportsView = () => (
  <div className="user-reports-view">
    <div className="user-reports-view__head">
      <div className="user-reports-view__head__title">
        Your Recent Reports
      </div>
      <ReportsViewHeader />
    </div>
    <Route exact path="/dashboard/reports/" render={(props) => <UserReportsListView {...props} />} />
    <Route exact path="/dashboard/reports/multiple" render={(props) => <UserMultipleReportsListView {...props} />} />
  </div>
);

export default UserReportsView;
