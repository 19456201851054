import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import NavBarDropdownButton from 'components/Navigations/NavBarDropdownButton/NavBarDropdownButton';
import NavBarDropDownMenu from 'components/Navigations/NavBarDropDownMenu/NavBarDropDownMenu';
import userDropDownPlaceHolder from 'assets/img/placeholders/user_dropdown_button.svg';
import { DropdownItemList } from 'commons/DropdownMenuItems';
import './NavBarDropDown.scss';


export class NavBarDropDown extends Component {

  constructor(props) {
    super(props)
    this.state = { isActive: false, className: 'navbar-dropdown' };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
  
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isActive: false, className: 'navbar-dropdown' });
    }
  }

  onDropdownButtonClick = () => {
    this.setState({ isActive: true, className: 'navbar-dropdown active' });
    console.log("Dropdown button click");
  }

  render() {
    const { currentUserData, theme="default" } = this.props;
    return (
      <div className={this.state.className}>
        <div className="navbar-dropdown__button">
          { currentUserData && currentUserData.firstName ?
            <NavBarDropdownButton isActive={this.state.isActive} theme={theme} onDropdownButtonClick={this.onDropdownButtonClick} />:
            <img src={userDropDownPlaceHolder} />
          }
        </div>
        <div className="navbar-dropdown__menu">
          <NavBarDropDownMenu itemList={DropdownItemList} theme={theme} isActive={this.state.isActive} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  const { rootReducers } = store;
  const { userDataReducers } = rootReducers;
  return {
    currentUserData: userDataReducers.currentUserData
  };
};


export default connect(mapStateToProps)(NavBarDropDown);
