import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './Selectbox.scss';

export class Selectbox extends Component {

  constructor(props) {
    super(props);
    this.state = { isMenuActive: false, selectedValue: '' };
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onSelectBoxClicked = this.onSelectBoxClicked.bind(this);
  }

  componentDidMount() {
    const { selectedValue } = this.props;
    this.setState({ selectedValue: selectedValue });
    document.addEventListener('click', this.handleClickOutside, true);
  }
  
  componentWillReceiveProps(props) {
    const { selectedValue } = props;
    this.setState({ selectedValue: selectedValue });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }
  
  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
  
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isMenuActive: false });
    }
  }

  onSelectBoxClicked() {
    this.setState({ isMenuActive: true });
  }

  onSelectItem(item) {
    this.setState({ isMenuActive: false , selectedValue: item.value });
    this.props.onChangeValue(item.value);
  }

  render() {
    const { list, highlightText, isHighlightText } = this.props;
    const { selectedValue = '' } = this.state;
    const menuClass = this.state.isMenuActive ? 'select-box__menu active' : 'select-box__menu';
    const menuButtonClass = selectedValue === '' ? 'select-box__button disabled' : 'select-box__button';
    const highlightClass = !isHighlightText ? 'visibility--hidden': '';
    return (
      <div className="select-box">
        <div className={menuButtonClass} onClick={this.onSelectBoxClicked}>
          { selectedValue === '' ?
            <span className={ highlightClass }> { highlightText } </span> : 
            <span> 
              { list.find((item) => item.value === selectedValue).text } 
            </span> 
          }
          <i className="material-icons"> expand_more </i>
        </div>
        <div className={menuClass}>
          {
            list.map((item, i) => {
              return <div className={`select-box__menu__item ${(selectedValue === item.value) ? 'selected': ''}`} value={item.value}  onClick={() => this.onSelectItem(item)}  key={i}>
                { item.text }
              </div>
            })
          }
        </div>
      </div>
    )
  }
}

export default Selectbox;
