import React, { Component } from 'react';
import ProfileListView from 'components/Views/ProfileListView/ProfileListView';
import { CompanyAttributes } from './CompanyAttributes';
import './CompanyInformationBody.scss';


export class CompanyInformationBody extends Component {
  render() {
    const { companyData } = this.props;
    const { description_text = '', profile_urls = [], related_search = [] } = companyData
    return (
      <div className="company-information-body">
        <div className="company-information-body__title">
          Overview
        </div>
        <div className="company-information-body__text">
          { description_text }
        </div>
        { 
          profile_urls.length > 0 ? 
          <div className="company-information-body__title">
            Social Media Profile
          </div> : ''  
        }
        <ProfileListView profileURLS={profile_urls} />
        { 
          related_search.length > 0 ? 
          <div className="company-information-body__title">
            Related Companies
          </div> : ''
        }
        <CompanyAttributes companyList={related_search} />
        <div className="company-information-body__text">
        </div>
      </div>
    )
  }
}

export default CompanyInformationBody;
