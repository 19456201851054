import UserDataProvider from 'providers/user/UserDataProvider';

export const DEMO_REPORTS_REQUEST = 'DEMO_REPORTS_REQUEST';
export const demoReportsRequest = (demoReportsRequest) => {
  return {
    type: DEMO_REPORTS_REQUEST,
    demoReportsRequest
  }
}


export const DEMO_COMPANY_LIST = 'DEMO_COMPANY_LIST';
export const demoCompanyList = (demoCompanyList) => {
  return {
    type: DEMO_COMPANY_LIST,
    demoCompanyList
  }
}

export const FREEMIUM_REPORTS_REQUEST = 'FREEMIUM_REPORTS_REQUEST';
export const freemiumReportsRequest = (freemiumReportsRequest) => {
  return {
    type: FREEMIUM_REPORTS_REQUEST,
    freemiumReportsRequest
  }
}

export const RECEIVE_DEMO_REPORTS = 'RECEIVE_DEMO_REPORTS';
export const receiveDemoReports = (json) => {
  return {
    type: RECEIVE_DEMO_REPORTS,
    demoReports: json,
  }
}

export const RECEIVE_FREEMIUM_REPORTS = 'RECEIVE_FREEMIUM_REPORTS';
export const receiveFreemiumReports = (receiveFreemiumReports) => {
  return {
    type: RECEIVE_FREEMIUM_REPORTS,
    freemiumReports: receiveFreemiumReports,
  }
}

export function fetchDemoReports(params) {
  return (dispatch) => {
    dispatch(demoReportsRequest(true));
    new UserDataProvider().fetchDemoReports(params).then((data) => {
      const companyList = data.map((item) => {
        return { value: item.company, label: item.company };
      });
      localStorage.setItem("demoCompanyList", JSON.stringify(companyList));
      dispatch(demoCompanyList(companyList));
      dispatch(receiveDemoReports(data));
      dispatch(demoReportsRequest(false))
    });
  }
}

export function fetchFreemiumReports(params) {
  return (dispatch) => {
    dispatch(freemiumReportsRequest(true));
    params.addToken = true;
    new UserDataProvider().fetchFreemiumReports(params).then((data) => {
      const companyList = data.map((item) => {
        return { value: item.company, label: item.company };
      });
      dispatch(demoCompanyList(companyList));
      dispatch(receiveFreemiumReports(data));
      dispatch(freemiumReportsRequest(false))
    });
  }
}

