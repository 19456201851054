import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './NavigationDrawerHeader.scss';
import EnkiLogo from 'components/Image/EnkiLogo';

export class NavigationDrawerHeader extends Component {
  render() {
    return (
      <div className="navigation-draver-header">
        <div className="navigation-draver-header__content">
          <div className="navigation-draver-header__content__logo">
            <Link to={`/`}>
              <EnkiLogo />
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default NavigationDrawerHeader
