
import { UPDATE_MODAL_DATA } from 'store/actions/root/modal';

const initialState = {
  modalData: {
    isActive:false
  }
};

const modalReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MODAL_DATA:
      return Object.assign({}, state, {
        type: action.type,
        modalData: action.modalData,
      });
    default:
      return state
  }
}

export default modalReducers;
