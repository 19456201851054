import React, { Component } from 'react'
import './LinkedinHeader.scss';
import { Image } from 'components/Image/Image';

export class LinkedinHeader extends Component {
  render() {
    const { name, image, head } = this.props;
    return (
      <div className="linkedin-header">
        <div className="linkedin-header__title">
          <div className="linkedin-header__title__img">
            <Image src={image} />
          </div>
          <div className="linkedin-header__title__name">
            { name }
          </div>
        </div>
        <div className="linkedin-header__text">
          { head }
        </div>
      </div>
    )
  }
}

export default LinkedinHeader
