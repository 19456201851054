import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mobileNavigation } from 'store/actions/user/navigations';
import NavBarIconMenu from 'components/Navigations/NavBarIconMenu/NavBarIconMenu';
import NavBarDropDown from 'components/Navigations/NavBarDropDown/NavBarDropDown';
import CircleButton from 'components/form/CircleButton';
import EnkiLogo from 'components/Image/EnkiLogo';
import EnkiDarkLogo from 'components/Image/EnkiDarkLogo';
import './NavBar.scss';

const loadLogo = (theme) => {
  switch (theme) {
    case "dark":
      return (<EnkiLogo />);
    case "light":
      return (<EnkiLogo />);
    case 'transparent':
      return (<EnkiDarkLogo />);
    default:
      return (<EnkiLogo />);
  }
}

export class NavBar extends Component {
  onNavigationButtonClick = () => {
    const { mobileNavigationStatus } = this.props;
    this.props.mobileNavigation(!mobileNavigationStatus);
  }

  render() {
    const { iconMenuItems, isDemo, theme="light", dropdownTheme="default", withoutNavigation=false, logo = { small: true, desktop: true }, mobileNavigationStatus } = this.props;
    return (
      <div className="navbar" data-theme={theme}>
        { withoutNavigation ? 
          <div className="navbar__brand hold">
            <div className="navbar__brand__logo">
              <Link to={ `/` }>
                { 
                  loadLogo(theme)
                }
              </Link>
            </div>
          </div>:
          <div className="navbar__brand" data-small={logo.small} data-desktop={logo.desktop} >
            { !isDemo ? 
              <div className="navbar__brand__button">
                <CircleButton color="blue-grey-r" title={ mobileNavigationStatus ? 'Hide menu' : 'Show menu' } icon={ mobileNavigationStatus ?  "arrow_back" : "reorder" } value="list" handleClick={this.onNavigationButtonClick} />
              </div>: ''
            }
            <div className="navbar__brand__logo">
              <Link to={ `/` }>
                { 
                  loadLogo(theme)
                }
              </Link>
            </div>
          </div>
        }
        <div className="navbar__body">
          <div className="navbar__body__left">
            <span></span>
          </div>
          <div className="navbar__body__middle">
            <span></span>
          </div>
          <div className="navbar__body__right">
            { iconMenuItems ? 
              <NavBarIconMenu menuItems={iconMenuItems} />
              :<span></span>
            }
          </div>
        </div>
        <div className="navbar__end">
          { !isDemo ?
            <NavBarDropDown theme={dropdownTheme} />: ''
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
  const { userReducers } = store;
  const { mobileNavigationReducers } = userReducers;
  return {
    mobileNavigationStatus: mobileNavigationReducers.status,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    mobileNavigation: (status) => dispatch(mobileNavigation(status)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
