import React, { Component } from 'react';
import './NavBarIconMenu.scss';

export class NavBarIconMenu extends Component {

  render() {
    const { menuItems } = this.props;

    return (
      <div className="navbar-icon-menu">
        { 
          menuItems
        }
      </div>
    )
  }
}

export default NavBarIconMenu;
