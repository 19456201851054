import React, { Component } from 'react';
import SocialFooter from 'components/SocialFooter/SocialFooter';
import './ForgotPasswordPage.scss';
import LoginIllustration from 'components/Image/LoginIllustration'
import EnkiLogo from 'components/Image/EnkiLogo'
import { ForgotPasswordFormComponent } from 'components/ForgotPasswordForm/ForgotPasswordForm';
import { Link }from 'react-router-dom'

export class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    const { email } = props.match.params;
    this.state = { email: email || '' };
  }

  onFormSubmit(e) {
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="margin-t-0">
        <div className="login-page">
          <div className="login-page__content">
            <div className="login-page__content__logo">
              <Link to={`/`}>
                <EnkiLogo />
              </Link>
            </div>
            <div className="login-page__content__form">
              <ForgotPasswordFormComponent email={this.state.email} onSubmit={this.onFormSubmit} />
            </div>
            <SocialFooter />
          </div>
          <div className="login-page__slider">
            <div className="login-page__slider__head">
              <h2>
                Get a Deeper Understanding of Your Market With Enki
              </h2>
              <h5>
                Our AI solution provides forward looking intelligence about emerging markets and technologies to help you get ahead
              </h5>
            </div>
            <div className="login-page__slider__image">
              <LoginIllustration />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPasswordPage
