import React, { Component } from 'react'
import UpgradeAccountCard from 'components/Demo/UpgradeAccountCard/UpgradeAccountCard';
import './UpgradeAccountPopup.scss';

export class UpgradeAccountPopup extends Component {

  constructor(props) {
    super(props)
    this.state = { holded: false };
  }

  getPopUpClass = (isActive, hasClickSelector) => {
    const { holded } = this.state;
    if(hasClickSelector) {
      return holded ? 'upgrade-popup holded': 'upgrade-popup';
    }

    return isActive ? 'upgrade-popup active' : 'upgrade-popup';
  }

  onClickedPopup = () => {
    this.setState({ holded: true });
  }
  
  onMouseLeavePopup = () => {
    this.setState({ holded: false });
  }

  render() {
    const { child, level, isActive, userType, color, hasClickSelector = false } = this.props;
    const popupClass = this.getPopUpClass(isActive, hasClickSelector);

    return (
      <div className={popupClass} onClick={this.onClickedPopup} onMouseLeave={this.onMouseLeavePopup}>
        <div className="upgrade-popup__content" data-level={level}>
          { child }
        </div>
        <div className="upgrade-popup__wrapper" color={color} data-level={level}>
        </div>
        <div className="upgrade-popup__auth-content">
          <UpgradeAccountCard userType={userType} color={color} />
        </div>
      </div>
    )
  }
}

export default UpgradeAccountPopup
