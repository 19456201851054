import React, { Component } from 'react';
import cardImage from 'assets/img/Market launch-rafiki.svg';
import './PremiumCardView.scss';
import { CardView } from 'components/Views/CardView/CardView';

export class PremiumCardView extends Component {
  render() {
    return (
      <CardView childComponent={
        <a className="premium-card" href="https://enkiai.com/contact" target="_blank">
          <div className="premium-card__image">
            <img src={cardImage} />
          </div>
          <div className="premium-card__content">
            <div className="premium-card__content__title">
              Upgrade Your Insights
            </div>
            <div className="premium-card__content__text">
              Get access to experimental AI insights and features
            </div>
            <button className="premium-card__content__button" href="https://enkiai.com/contact" target="_blank">
              Contact Us Today
            </button>
          </div>
        </a>
      } />
    )
  }
}

export default PremiumCardView
