import { RECEIVE_CURRENT_USER_DATA } from 'store/actions/root/user';


const userDataReducers = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_CURRENT_USER_DATA:
      return Object.assign({}, state, {
        type: action.type,
        currentUserData: { ...state.currentUserData, ...action.currentUserData },
      });
    default:
      return state
  }
}

export default userDataReducers;
