import React, { Component } from 'react';
import { AsyncCreatable } from 'react-select';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import SelectorDataHelper from 'helpers/SelectorDataHelper';
import './AsyncSelector.scss';

const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
let controller = new AbortController();
let signal = controller.signal;

const customStyles = {
  placeholder: (provided) => {
    return {
      ...provided,
      paddingLeft: 0
    }
  },
  control: (provided, state) => {
    return {
      ...provided,
      borderWidth: 0,
      borderRadius: 20,
      backgroundColor: "#F5F5F5",
      opacity : state.isDisabled ? 0.5 : 1,
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
      boxShadow: 0,
      ':hover,:focus': {
        borderColor: "#9753e1"
      }
    };
  }, 
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "#FFFFFF",
      borderRadius: 20,
      paddingLeft: 5,
      paddingRight: 5
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: "15px",
      fontWeight: "500",
    }
  }
}

const customTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral20: "#464646",
    }
  };
}

const getNewOptionData = (inputValue, optionLabel) => {
  return {
    value: inputValue.toUpperCase(),
    label: optionLabel.toUpperCase()
  }
}

const fetchJsonData = ({ inputValue, apiUrl, requestValueName }) => {
  const requestBody = {};
  requestBody[requestValueName] = inputValue;
  const body = JSON.stringify(requestBody);
  try {
    return fetch(`${host + apiUrl}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
      signal: signal
    });
  } catch (error) {
    return null;
  }
}

const fetchOptions = (requestBody) => {
  const { inputValue, apiUrl, requestValueName, parserFunction } = requestBody;
  return fetchJsonData({ inputValue, apiUrl, requestValueName })
  .catch((err) => {
    console.log(err);
  })
  .then((response) => {
    if (response && response.ok) {
      if(parserFunction) {
        return parserFunction(response)
      } else {
        return new SelectorDataHelper().parseOptionsResponse(response);
      }
    }
  });
};

const logCustomInput = (inputValue, customInput) => {
  const { inputType = '', apiUrl = '' } = customInput;
  const body = JSON.stringify(
    { 
      inputType: inputType,
      inputValue: inputValue 
    }
  );
  return fetch(`${host + apiUrl}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
  .then((response) => response.json())
}

function abortFetching() {
  const { aborted } = signal;
  if (!aborted) {
    controller.abort()
    controller = new AbortController();
    signal = controller.signal;
  }
}

let requestTimeout;

export default class AsyncSelector extends Component {

  constructor(props) {
    super(props);
    this.state = { inputValue: '', onFocus: false, apiUrl: '', customInput: {}, parserFunction: null };
  }

  componentWillMount() { 
    const { apiUrl, customInput = {}, parserFunction, requestValueName, onSelectResetsInput } = this.props;
    this.setState({ apiUrl, customInput, requestValueName, parserFunction, onSelectResetsInput });
  }

  promiseOptions = (inputValue) => {
    const { apiUrl, parserFunction, requestValueName } = this.state;
    console.log("Promise");
    return new Promise(resolve => {
      abortFetching();
      clearTimeout(requestTimeout);
      requestTimeout = setTimeout(() => {
        if (inputValue.length > 1) {
          fetchOptions({ inputValue, apiUrl, parserFunction, requestValueName }).then((data) => {
            resolve(data);
          });
        }
      }, 500);
    });
  }

  onMultipleInputChange = (query, { action }) => {
    console.log("Input change", action);
    if (action !== "set-value") {
      this.setState({ inputValue: query.toUpperCase() });
      return query.toUpperCase();
    }

    return this.state.inputValue;
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue: inputValue.toUpperCase() });
    return inputValue.toUpperCase();
  };

  handleChange = (newValue, { action }) => {
    const { isMulti , onSelectResetsInput = false } = this.props;
    if(onSelectResetsInput) {
      this.setState({ inputValue: '' });
    }

    if(isMulti) {
      this.runOnChanged(action, newValue);
      this.props.onChange(newValue);
    } else {
      this.props.onChange(newValue.value);
    }
  };

  createOption = (inputValue) => {
    const { customInput = {} } = this.state;
    const { shouldPost = false } = customInput;

    if(!shouldPost) {
      return;
    }

    logCustomInput(inputValue, customInput).then((response) => {
      console.log(response);
    });
  }

  runOnChanged(type, values) {
    const options =  { 
      'select-option': () => {},
      'remove-value': () => {},
      'create-option': () => { 
        const valueLength = values.length;
        if(valueLength > 0) {
          this.createOption(values[valueLength - 1].value) 
        }
      },
    }[type] || (() => {})
    return options()
  }

  handleFocus = (inputValue) => {
    this.setState({ inputValue: '', onFocus: true });
  };

  handleBlur = (inputValue) => {
    this.setState({ inputValue: this.props.value, onFocus: false });
  };

  getSelect =  () => {
    let value = '';
    const { inputValue } = this.state;
    const { isMulti = false, placeholder, disabled = false, closeMenuOnSelect = false, onSelectResetsInput = false, options = [] } = this.props;
    if (this.props.value != undefined) {
      value  = { label: this.props.value , value: this.props.value };
    }

    return (
      <div className="multiple-selector__input">
        <Select
          isMulti={isMulti}
          inputValue={ inputValue }
          placeholder={ placeholder }
          onInputChange={isMulti ? this.onMultipleInputChange : this.handleInputChange}
          onChange={this.handleChange}
          styles={customStyles}
          theme={customTheme}
          getNewOptionData={getNewOptionData}
          closeMenuOnSelect={closeMenuOnSelect}
          onSelectResetsInput={onSelectResetsInput}
          isDisabled={disabled}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          blurInputOnSelect={!isMulti}
          createOptionPosition="first"
          options={options}
          {...!isMulti ? value={value}:'' }
        /> 
      </div>
    )
  }

  getAsyncSelect = () => {
    let value = '';
    const { inputValue, customInput = {} } = this.state;
    const { isMulti = false, placeholder, disabled = false, closeMenuOnSelect = false, onSelectResetsInput = false, options = [] } = this.props;
    if (this.props.value != undefined) {
      value  = { label: this.props.value , value: this.props.value };
    }

    return (<div className="multiple-selector__input">
      { customInput.apiUrl ? 
        <AsyncCreatable
          isMulti={isMulti}
          inputValue={ inputValue }
          placeholder={ placeholder }
          onChange={this.handleChange}
          styles={customStyles}
          theme={customTheme}
          getNewOptionData={getNewOptionData}
          closeMenuOnSelect={closeMenuOnSelect}
          onSelectResetsInput={onSelectResetsInput}
          isDisabled={disabled}
          loadOptions={this.promiseOptions}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onInputChange={isMulti ? this.onMultipleInputChange : this.handleInputChange}
          blurInputOnSelect={!isMulti}
          createOptionPosition="first"
          value={this.props.value}
          {...!isMulti ? value={value}:'' }
        />:
        <AsyncSelect
          isMulti={isMulti}
          inputValue={ inputValue }
          placeholder={ placeholder }
          loadOptions={this.promiseOptions}
          onInputChange={isMulti ? this.onMultipleInputChange : this.handleInputChange}
          onChange={this.handleChange}
          styles={customStyles}
          theme={customTheme}
          getNewOptionData={getNewOptionData}
          closeMenuOnSelect={closeMenuOnSelect}
          onSelectResetsInput={onSelectResetsInput}
          isDisabled={disabled}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          blurInputOnSelect={!isMulti}
          createOptionPosition="first"
          defaultOptions={options}
          value={this.props.value}
          {...!isMulti ? value={value}:'' }
        /> 
      }
    </div>)
  }

  render() {
    const { title, selectType } = this.props;
    const selectTypeSwitch = {
      "select": this.getSelect(),
      "async": this.getAsyncSelect(),
      undefined: this.getAsyncSelect()
    };

    return (
      <div className="multiple-selector">
        <div className="multiple-selector__title">
          {title}
        </div>
        { selectTypeSwitch[selectType] }
      </div>
    )
  }
}
