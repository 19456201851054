import React from 'react';
import LineChart from './lineChart';
import Wordle from './wordle';
import SimpleWordle from './wordle.simple';
import nlp from 'compromise';
import stringSimilarity from 'string-similarity';
import { Icon } from 'watson-react-components/dist/components';
import AttributeViews from 'components/Views/AttributeViews/AttributeViews';

import Insight from './Insights';

export function randomId(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getParams(location) {
  const searchParams = new URLSearchParams(location.search);
  return searchParams;
}

export function setParams(key, value, location, history) {
  const params = getParams(location);
  params.set(key, value);
    
  const url = params.toString();
  history.push(`?${url}`);
}

export function setMultipleParams(paramList, location, history, urlHash) {
  const params = getParams(location);
  paramList.forEach(({key ,value}) => {
    params.set(key, value);
  });
    
  const url = params.toString();
  history.push(`?${url}${urlHash && !urlHash.isNullOrEmpty() ? '#' + urlHash : ''}`);
}

export function isSimilar(docs, doc, toSkip) {
  if (!toSkip) {
    const lowerCaseDoc = doc.toLowerCase();

    for(let i = 0; i < docs.length; i++) {
      const lowerCaseDocToCheck = docs[i].toLowerCase();
      const similarity = stringSimilarity.compareTwoStrings(doc, docs[i]);
      const match = similarity > 0.7 || lowerCaseDoc.startsWith(lowerCaseDocToCheck) || lowerCaseDocToCheck.startsWith(lowerCaseDoc);
      if (match) {
        return true;
      }
    }
  }

  return false;
}

export function getRelevancyScore(article) {
  const attributeFields = [
    'matchedAttributes',
    'matchedAttributesIT',
    'matchedAttributesITPainPoint',
    'matchedAttributesITCompetitor',
    'matchedAttributesIoT',
    'matchedAttributesIoTPainPoint',
    'matchedAttributesIoTValueProp',
    'matchedAttributesInsurTechInit',
    'matchedAttributesInsurTechValueProp',
    'matchedAttributesTechInit',
    'matchedAttributesInsurTechPainPoint',
    'matchedAttributesInsurTechCompany',
    'matchedAttributesInsurTechStartup',
    'matchedAttributesInsurTechVC',
    'matchedAttributesSalesInsight',
    'matchedAttributesSalesValueProp',
    'matchedAttributesSalesCompetitionTech',
    'matchedAttributesSalesPainPoint',
    'matchedAttributesMessagingPlatformInsight'
  ];

  const list = new Set();

  attributeFields.forEach(field => {
    if (article[field]) {
      article[field].forEach(field => {
        list.add(field);
      });
    }
  });

  return list.size;
}

export function relevancyCompare(articleA, articleB) {
  if (articleB && !articleA) {
    return 1;
  }
  if (articleA && !articleB) {
    return -1;
  }

  if (articleA && articleB) {
    const { relevance:relevanceA } = articleA;
    const { relevance:relevanceB } = articleB;
    if (relevanceA && relevanceB) {
      if (relevanceB > relevanceA) {
        return 1;
      } else if (relevanceB < relevanceA) {
        return -1;
      }
  
      return 0;
    }

    // if relevancy score not equal, use it to sort
    const articleAScore = getRelevancyScore(articleA);
    const articleBScore = getRelevancyScore(articleB);
    if (articleAScore < articleBScore) {
      return 1;
    } 
    if (articleAScore > articleBScore) {
      return -1;
    }
    // if relevancy score are equal, use date to sort
    const articleADate = articleA.published_date || articleA.date;
    const articleBDate = articleB.published_date || articleB.date;
    if (articleADate < articleBDate) {
      return 1;
    } 
    if (articleADate > articleBDate) {
      return -1;
    }
  }
  return 0;
}

export function publishedDateCompare(articleA, articleB) {
  if (articleB && !articleA) {
    return 1;
  }
  if (articleA && !articleB) {
    return -1
  }
  if (articleA && articleB) {
    const articleADate = articleA.published_date || articleA.date;
    const articleBDate = articleB.published_date || articleB.date;
    if (articleADate < articleBDate) {
      return 1;
    } 
    if (articleADate > articleBDate) {
      return -1;
    }
  }
  return 0;
}

export function parseGQueryResults(data, json, source, fetchKey, debug) {
  json.forEach((result, index) => {
    const metadata = result.metadata;
    const { areas: [areaObj], companies: [companyObj] } = JSON.parse(fetchKey);
    const company = companyObj && Object.keys(companyObj)[0];
    const area = areaObj && Object.keys(areaObj)[0];
    // @Todo 
    metadata.source = source;
    metadata.company = company;
    metadata.area = area;
    metadata.fetchKey = fetchKey;
    data[index].result = <Insight key={metadata.lastUpdated} metadata={metadata} fetchKey={fetchKey} debug={debug} />;
    data[index].done = metadata.done;
    data[index].id = metadata.companyUrlId;
    data[index].reportFilterEnabled = metadata.reportFilterEnabled;
  });
  return data;
}

export function parseQueryResults(data, json, doNlp) {
  json.forEach((result, index) => {
    const docs = [];
    const metadata = {};
    let output = '';
    if (result.aggregations) {
      let aggregations = result.aggregations;
      while (aggregations[0] && aggregations[0].aggregations) {
        aggregations = aggregations[0].aggregations;
      }
      if (aggregations[0].type === 'timeslice') {
        output = <LineChart data={aggregations[0].results} />;
      } else if (aggregations[0].field === 'enriched_title.entities.text' 
        || aggregations[0].field === 'enriched_title.entities.type'
        || aggregations[0].field === 'enriched_title.concepts.text' 
        || aggregations[0].field === 'enriched_title.categories.label') {
        // if (aggregations[0].results[0] && aggregations[0].results[0].aggregations) {
        //   output = <BarChart data={aggregations[0].results} />;
        // } else {
          output = <Wordle data={aggregations[0].results} />;
        // }
      } else {
        // todo: use a faster similarity comparison so we don't have to filter data
        output = aggregations[0].results.slice(0, 100).map(term => {
          if (isSimilar(docs, term.key)) {
            return '';
          }
          docs.push(term.key);
          if (doNlp) {
            populateNlpMetadata(term.key, metadata);
          }
          return <li>{term.key}</li>;
        });
      }
    } else {
      output = result.results.map(article => {
        if (isSimilar(docs, article.title)) {
          return '';
        }
        docs.push(article.title);
        return <li><b>{article.title}</b><br/>{article.text}... <a href={article.url} target='_blank'>Read more</a></li>;
      });
    }
    if (metadata.exist) {
      const showCompanies = Object.keys(metadata.companies).length > 0;
      const showPlaces = Object.keys(metadata.places).length > 0;
      const showQuotes = Object.keys(metadata.quotes).length > 0;
      const showAll = !showCompanies && !showPlaces && !showQuotes;
      data[index].result = (
        <div className='insight-wordle-container'>
          {showCompanies && <div className='insight-wordle'><h4 className='title'>Companies</h4><SimpleWordle data={metadata.companies} type='Companies' /></div>}
          {showPlaces && <div className='insight-wordle'><h4 className='title'>Places</h4><SimpleWordle data={metadata.places} type='Places' /></div>}
          {showQuotes && <div className='insight-wordle'><h4 className='title'>Insights</h4>{Object.keys(metadata.quotes).map(s => <li>{metadata.quotes[s]}</li>)}</div>}
          {showAll && <div className='insight-wordle'><h4 className='title'>Insights</h4>{Object.keys(metadata.all).map(s => <li>{s}</li>)}</div>}
        </div>
      );
    } else {
      data[index].result = typeof output === 'string' ? <li>{output}</li> : output;
    }
  });
  return data;
}

function populateMetadata(type, array, sentence, metadata) {
  if (!metadata[type]) {
    metadata[type] = {};
  }

  const obj = metadata[type];

  array.forEach(c => {
    if (!obj[c]) {
      obj[c] = [];
    }
    obj[c].push(sentence);
  });
}

function populateNlpMetadata(sentence, metadata) {
  const enriched = nlp(sentence);
  const companies = enriched.organizations().out('array');
  populateMetadata('companies', companies, sentence, metadata);
  const places = enriched.places().out('array');
  populateMetadata('places', places, sentence, metadata);
  const quote = enriched.if(`[*]?`).out('normal');
  const quotes = quote ? [quote] : [];
  populateMetadata('quotes', quotes, sentence, metadata);
  populateMetadata('all', [sentence], sentence, metadata);
  metadata.exist = true;
}

export class PrioritizeLinkedInButton extends React.Component {
  constructor(props) {
    super(props);

    const metadata = props.metadata;
    const relatedDone = metadata.relatedChampionId && metadata.relatedChampionDone;
    const attributeDone = !metadata.attributeChampionId || metadata.attributeChampionId && metadata.attributeChampionDone;

    this.state = {
      icon: relatedDone && attributeDone ? 'success' : 'up'
    };
  }

  handleButtonClick() {
    const metadata = this.props.metadata;
    metadata.relatedChampionId && !metadata.relatedChampionDone && this.addToPriorityQueue(metadata.relatedChampionId);
    metadata.attributeChampionId && !metadata.attributeChampionDone && this.addToPriorityQueue(metadata.attributeChampionId);

    this.setState({
      icon: 'success'
    });
  }
  
  addToPriorityQueue(uuid) {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';

    fetch(`${host}/api/prioritize?uuid=${uuid}`, {
      method: 'GET'
    }).then((response) => {
    }).catch((err) => {
      alert(err);
    });
  }

  render() {
    return this.state.icon === 'success'
            ? (
              <span className='train-icon'>
                <Icon type={this.state.icon} size="small" />
              </span>
            )
            : (
              <a title="Prioritize LinkedIn users in this report" className='train-icon' onClick={this.handleButtonClick.bind(this)}>
                <Icon type={this.state.icon} size="small" />
              </a>
            );
  }
}

export class People extends React.Component {
  render() {
    const keys = Object.keys(this.props.data);
    const people = keys.map(key => {
      const person = this.props.data[key][0];
      const nameRegex = person.title.match(/[^-|–]*/i);
      const titleRegex = person.title.match(/[-|–](.*)(\||\.\.\.)/i);
      return {
        name: nameRegex && nameRegex[0],
        title: titleRegex && titleRegex[1],
        url: person.url
      };
    });
    return <div className='people-container'>
      {
        people.map(person => {
          return <div className='people-block'>
            <h5>{person.name} <a className='train-icon' target="_blank" href={person.url}><Icon type="link-out" size="small" /></a></h5> 
            <h6>{person.title}</h6>
          </div>;
        })
      }
    </div>;
  }
}
