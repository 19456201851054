import React, { Component } from 'react';
import { Image } from 'components/Image/Image';
import './BrandContent.scss';


export class BrandContent extends Component {
  render() {
    const { name, image, description } = this.props;
    return (
      <div className="brand-header">
        <div className="brand-header__title">
          <div className="brand-header__title__img">
            <Image src={image} />
          </div>
          <div className="brand-header__title__name">
            { name }
          </div>
        </div>
        <div className="brand-header__text">
          { description }
        </div>
      </div>
    )
  }
}

export default BrandContent;
