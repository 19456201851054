
import { UPDATE_AUTH_MODAL } from 'store/actions/root/auth-modal';

const initialState = {
  modalStatus: false
};

const authModalReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AUTH_MODAL:
      return Object.assign({}, state, {
        type: action.type,
        modalStatus: action.modalStatus,
      });
    default:
      return state
  }
}

export default authModalReducers;
