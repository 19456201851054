const dummyInfoObjects = {
  hiring: {
    id: 3,
    title: 'Hiring',
    icon: 'people',
    color: 'blue-2',
  },
  painPointV2: {
    id: 2,
    title: 'Challenges and Solutions',
    icon: 'psychology',
    color: 'red-light',
  },
  competitors: {
    id: 1,
    title: 'Competitions',
    icon: 'business',
    color: 'gray',
  },
  opportunity: {
    id: 4,
    title: 'Opportunities',
    icon: 'campaign',
    color: 'purple',
  },
  partnership: {
    id: 5,
    title: 'Partnerships',
    icon: 'group',
    color: 'green',
  },
  growth: {
    id: 6,
    title: 'Growth',
    icon: 'trending_up',
    color: 'blue-grey',
  },
  initiative: {
    id: 7,
    title: 'Initiatives',
    icon: 'emoji_objects',
    color: 'orange',
  },
  investment: {
    id: 8,
    title: 'Investments',
    icon: 'attach_money',
    color: 'blue-2',
  },
  internalQuotes: {
    id: 9,
    title: 'Decision Maker Insights',
    icon: 'people',
    color: 'orange',
  },
  win: {
    id: 10,
    title: 'Recent wins',
    icon: 'emoji_events',
    color: 'green',
  },
  promotion: {
    id: 11,
    title: 'Executive Moves',
    icon: 'work',
    color: 'blue-2',
  },
};

export default dummyInfoObjects;