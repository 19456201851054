export class ElementService {

  scrollTo = (element, to, duration) => {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;
    const that = this;
    const animateScroll = function () {
      currentTime += increment;
      const val = that.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  easeInOutQuad = (time, start, change, duration) => {
    time /= duration / 2;
    if (time < 1) {
      return change / 2 * time * time + start;
    }
  
    time--;
    return -change / 2 * (time * (time - 2) - 1) + start;
  }
  
} 

export default ElementService;
