import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { updateModalData } from 'store/actions/root/modal';
import { ParentItemHelper } from 'helpers/ParentItemHelper';
import './ModalBox.scss';

export class ModalBox extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      modalClass: 'modal-box-container'
    };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !new ParentItemHelper().findAncestor(event.target, 'modal-box')) {
      this.closeModal();
    }
  }

  componentWillReceiveProps(props) {
    const { modalData = {} } = props;
    const { isActive = false } = modalData;
    this.setState({ modalClass: `modal-box-container ${isActive ? 'open' : 'close'}` });
    if(!isActive) {
      document.removeEventListener('click', this.handleClickOutside, true);
    } else {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }

  getButtons = (buttons) => {
    return <div className="modal-box__foot">
     { 
        buttons.map(({ text, color, icon, onClick }, key) => {
          return <div className="modal-box__foot__button" key={key} color={color} onClick={() => { onClick(); this.closeModal() }}>
            <i className="material-icons"> { icon } </i>
            { text }
          </div>
        })
      }
    </div>
  }

  closeModal = () => {
    const { modalData = {} } = this.props;
    modalData.isActive = false;
    this.props.updateModalData({ ...modalData, isActive: false });
  }

  render() {
    const { modalClass } = this.state;
    const { modalData = {} } = this.props;
    const { buttons = [], title = '', text = '' } = modalData;

    return (
      <div className={modalClass}>
        <div className="modal-box">
          <div className="modal-box__head">
            <div className="modal-box__head__title">
              { title }
            </div>
          </div>
          <div className="modal-box__body">
            <div className="modal-box__body__text">
            { text }
            </div>
          </div>
          <div className="modal-box__foot">
            { buttons.length > 0 && this.getButtons(buttons) }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { rootReducers } = state;
  const { modalReducers } = rootReducers;
  const { modalData } = modalReducers;

  return {
    modalData: modalData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateModalData: (data) => dispatch(updateModalData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBox)
