import React, { Component } from 'react';
import { connect } from 'react-redux';
import Ontology from 'ontology';
import TitleOntology from 'ontology.title';
import CompanySelector from 'selector.company';
import AsyncSelector from 'components/form/AsyncSelector/AsyncSelector';
import { fetchCurrentUserData, receiveCurrentUserData } from 'store/actions/root/user';
import { CircleLoader } from 'components/CircleLoader/CircleLoader';
import { ReceivedRequestCard } from 'components/User/Reports/ReceivedRequestCard/ReceivedRequestCard';
import { HttpService } from 'services/HttpService';
import ReactECharts from 'echarts-for-react';
import TitleSelector from 'selector.titles';
import AreaSelector from 'components/form/SelectorArea/SelectorArea.jsx';
import NavBar from 'components/Navigations/NavBar/NavBar';
import { NavBarIconMenuItem } from 'components/Navigations/NavBarIconMenuItem/NavBarIconMenuItem';
import { randomId, getParams, setMultipleParams } from 'util.jsx';
import { pushToastMessage } from 'store/actions/root/toast';
import ClipboardService from 'services/ClipboardService';
import { updateMessagePopup } from 'store/actions/root/message-popup';
import SelectorDataHelper from 'helpers/SelectorDataHelper';
import RankedCompanyView from 'components/MultipleReports/RankedCompanyView/RankedCompanyView';
import NoResultContent from 'components/Demo/NoResultContent/NoResultContent';
import Checkbox from 'components/Checkbox/Checkbox';
import './CompanyReports.scss';

const sourceList = [
  { sourceName: 'By Year', source: 'year' },
  { sourceName: 'All Time', source: 'all' }
];

export class CompanyReports extends Component {
  keywordSelector = {};

  painPointSelector = {};

  competitorSelector = {};

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      area: '',
      data: [],
      error: null,
      result: null,
      techHeatmap: {},
      marketHeatmap: {},
      navBarMenuItems: [],
      userType: '',
      hasSecretId: false,
      shareButtonLoader: false,
      exportButtonLoader: false,
      hasRequestChanged: true,
      loadingChart: false,
      heatmapInterval: 'all',
      heatmapDatas: {},
      debug: false,
      bucketizeEnabled: false,
    };

    const urlCompanies = (new URLSearchParams(window.location.search)).get("companies")
    if (urlCompanies){
      this.state.company = JSON.parse(urlCompanies).map((keyword) => { return { value: keyword, label: keyword }});
    }

    const keywords = (new URLSearchParams(window.location.search)).get("keywords")
    if (keywords){
      this.state.keywords = JSON.parse(keywords).map((keyword) => { return { value: keyword, label: keyword }});
    }
  }

  bucketizeCheckbox = {
    id: "bucketize",
    text: "Bucketize",
    color: "primary",
  }

  componentWillMount() {
    const { companyList = [] } = (this.props.location && this.props.location.state) || {};
    this.props.fetchCurrentUserData();
    this.keywordSelector = {
      isMulti: true,
      title: "Keywords",
      apiUrl: '/api/attributeSuggestion',
      requestValueName: 'attributeName',
      placeholder: "e.g. Artificial Intelligence, Digital Twin, or Blockchain",
      onChange: this.onChangeKeywords,
      parserFunction: new SelectorDataHelper().parseSuggestionResult,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      customInput: {
        shouldPost: true,
        apiUrl: '/api/userInput',
        inputType: 'keyword'
      }
    }

    this.painPointSelector = {
      isMulti: true,
      title: "Pain Points",
      apiUrl: '/api/attributeSuggestion',
      requestValueName: 'attributeName',
      placeholder: "e.g. Outage, Lawsuit or Complaint",
      onChange: this.onChangePainPoints,
      parserFunction: new SelectorDataHelper().parseSuggestionResult,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      customInput: {
        shouldPost: true,
        apiUrl: '/api/userInput',
        inputType: 'painPoint'
      }
    }

    this.competitorSelector = {
      isMulti: true,
      title: "Competitors",
      apiUrl: '/api/companySuggestion',
      requestValueName: 'companyName',
      placeholder: "e.g. Splunk, New Relic, or Dynatrace",
      onChange: this.onChangeCompetitors,
      parserFunction: new SelectorDataHelper().parseOptionsResponse,
      closeMenuOnSelect: false,
      onSelectResetsInput: true,
      customInput: {
        shouldPost: true,
        apiUrl: '/api/userInput',
        inputType: 'competitor'
      }
    }

    if (companyList.length > 0) {
      const companies = companyList.map((keyword) => { return { value: keyword, label: keyword }});
      this.setState({ company: companies });
    }
  }

  componentDidMount() {
    const params = getParams(this.props.location);
    const report = params.has('report') && params.get('report');
    const secretId = params.has('secretId') && params.get('secretId');
    if (report) {
      this.loadReport(report);
    }

    if (secretId) {
      this.checkSecretId({ secretId, reportId: report, type: 'combined_reports' });
    }
  }

  componentWillReceiveProps(props) {
    const { currentUserData } = props;
    const { email } = currentUserData;
    this.setState({ relatedEmails: [email] });
  }

  initStateData = (request) => {
    const { area, keywords, companies, competitors, painPoints, titles, bucketizeEnabled, relatedEmails } = request;
    const keywordList = keywords.map((keyword) => ({ value: keyword, label: keyword }));
    const competitorList = competitors.map((keyword) => ({ value: keyword, label: keyword }));
    const painPointList = painPoints.map((keyword) => ({ value: keyword, label: keyword }));
    const companyList = companies.map((keyword) => ({ value: keyword, label: keyword }));
    const titleList = titles.map((keyword) => ({ value: keyword, label: keyword }));
    this.keywordSelector.value = keywordList;
    this.competitorSelector.value = competitorList;
    this.painPointSelector.value = painPointList;
    this.setState({
      area,
      company: companyList,
      keywords: keywordList,
      competitors: competitorList,
      painPoints: painPointList,
      titles: titleList,
      bucketizeEnabled: bucketizeEnabled,
      relatedEmails,
      debug: getParams(location).has('debug')
    });
    this.onFormSubmit();
  }

  loadReport = (id) => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    let url = `${host}/api/loadCombinedReport`;
    url += `?name=${id}`;

    new HttpService().sendRequest(url, {
      method: 'GET',
    }).then((response) => {
      const { request } = response;
      if (request) {
        this.initStateData(request);
      }
    });
  }

  checkSecretId(params) {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';

    fetch(`${host}/api/checkSecretId`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then((response) => {
      const { status } = response;
      if (status) {
        const { currentUserData = {} } = this.props;
        currentUserData.hasSecretId = response.status;
        this.props.receiveCurrentUserData(currentUserData);
        this.setState({ hasSecretId: response.status });
      } else {
        this.redirectToLogin();
      }
    })
  }

  redirectToLogin = () => {
    this.props.updateMessagePopup({
      isOpen: true,
      title: 'Secret ID has expired.',
      text: 'Your Secret ID has expired, please login to view the report.',
      icon: 'https',
      iconColor: 'red-light',
      afterClose: 5000,
    });
    this.props.history.push('/login');
  }

  onChangeKeywords = (value) => {
    this.setState({
      keywords: value,
      hasRequestChanged: true,
    });
  }

  onChangePainPoints = (value) => {
    this.setState({
      painPoints: value,
      hasRequestChanged: true,
    });
  }

  onChangeCompetitors = (value) => {
    this.setState({
      competitors: value,
      hasRequestChanged: true,
    });
  }

  onTitleChange = (value) => {
    this.setState({
      titles: value,
      showReport: false,
      hasRequestChanged: true,
      id: null,
    });
  }

  onCompanyChange = (value) => {
    this.setState({
      company: value,
      showReport: false,
      hasRequestChanged: true,
      id: null,
    });
  }

  onAreaChange = (area) => {
    let { keywords, titles } = this.state;
    const { area: prevArea } = this.state;
    const { value } = area;

    const shouldUpdateKeywords = !keywords
      || (Ontology[prevArea] && JSON.stringify(Ontology[prevArea]) === JSON.stringify(keywords.map(o => o.value)));

    const shouldUpdateTitles = !titles
      || (TitleOntology[prevArea] && JSON.stringify(TitleOntology[prevArea]) === JSON.stringify(titles.map(o => o.value)));

    if (value) {
      const normalizedArea = value.trim();

      if (shouldUpdateKeywords && Ontology[normalizedArea]) {
        keywords = Ontology[normalizedArea].map((val) => ({
          label: val,
          value: val,
        }));
      }

      if (shouldUpdateTitles && TitleOntology[normalizedArea]) {
        titles = TitleOntology[normalizedArea].map((val) => ({
          label: val,
          value: val,
        }));
      }
    }

    this.setState({
      area: area && area.value.toUpperCase(),
      keywords,
      titles,
      showReport: false,
      hasRequestChanged: true,
      id: null,
    });
  }

  getRequestBody = () => {
    const {
      keywords = [],
      competitors = [],
      painPoints = [],
      company = [],
      area,
      titles = [],
      relatedEmails,
      bucketizeEnabled
    } = this.state;
    return {
      area,
      relatedEmails,
      offset: 0,
      companies: company.map(({ value }) => value),
      keywords: keywords.map(({ value }) => value),
      competitors: competitors.map(({ value }) => value),
      painPoints: painPoints.map(({ value }) => value),
      titles: titles.map(({ value }) => value),
      ...(bucketizeEnabled) && {bucketizeEnabled: bucketizeEnabled},
    };
  }

  exportReport = () => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const { result } = this.state;
    const id = result.report_id;
    this.setState({ exportButtonLoader: true });
    fetch(`${host}/api/combinedCompany/export/${id}`)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = `${id}.csv`;
      document.body.appendChild(a);
      this.setState({ exportButtonLoader: false });
      this.pushExportNotification(`${id}.csv`);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => alert('There is an issue with the export, please try again!'));
  }

  pushExportNotification = (fileName) => {
    const toastData = {
      title: 'Export is ready',
      text: `"${fileName}" has been downloaded.`,
      iconName: 'cloud_done',
      iconColor: 'blue',
      duration: 8000,
    };
    this.props.pushToastMessage(toastData);
  }

  copyText = (text) => {
    new ClipboardService(document).copyText(text);
    this.showCopiedTextMessage();
  }

  showCopiedTextMessage = () => {
    const toastData = {
      title: 'Share link is copied to clipboard',
      iconName: 'content_copy',
      iconColor: 'green-light',
      duration: 3000,
    };
    this.props.pushToastMessage(toastData);
  }

  isSafari = () => /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

  copyTextForSafari = (text) => {
    const toastData = {
      title: 'Click \'Copy\' below to get the share link.',
      iconName: 'done',
      iconColor: 'blue-2',
      duration: 10000,
      buttons: [
        {
          text: 'Copy',
          icon: 'content_copy',
          color: 'blue',
          onClick: () => {
            new ClipboardService(document).copyText(text);
            this.showCopiedTextMessage();
          },
        },
      ],
    };
    this.props.pushToastMessage(toastData);
  }

  saveReport = () => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const newId = randomId(8);
    this.setState({ shareButtonLoader: true });
    new HttpService().sendRequest(`${host}/api/saveCombinedReport`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: newId,
        addToken: true,
        request: this.getRequestBody(),
      }),
    }).then((response) => {
      this.updateURL([
        { key: 'report', value: newId },
        { key: 'secretId', value: response.code }
      ]);
      this.setState({ shareButtonLoader: false });
      this.isSafari() ? this.copyTextForSafari(window.location.href) : this.copyText(window.location.href);
    });
  }

  updateURL = (params) => {
    const { location, history } = this.props;
    setMultipleParams(params, location, history);
  };

  onFormSubmit = () => {
    this.setState({ loading: true });
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = this.getRequestBody();
    body.addToken = true;
    new HttpService().sendRequest(`${host}/api/combinedCompany/heatmap`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      const { data } = result;
      if (!data || !data.hasOwnProperty('needsUpdate') || !data.needsUpdate) {
        this.setState({
          heatmapDatas: {},
          loading: false,
          hasRequestChanged: false,
          runningHeatMap: undefined,
          result,
        }, () => {
          this.getStatsData();
        });
      } else {
        this.setState({ loading: false, runningHeatMap: data, hasRequestChanged: false });
      }
    }).catch((err) => {
      this.setState({ loading: false });
      console.log(err);
    });
  }

  rerunReports = () => {
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    new HttpService().sendRequest(`${host}/api/rerun/` + this.state.result.report_id, {
      method: 'GET'
    }).then((result) => {
      const message = {
        title: 'Rerun reports',
        text: `Enki is working hard to prepare your report.`,
        iconName: 'cloud_done',
        iconColor: 'blue',
        duration: 8000,
      };
      this.props.pushToastMessage(message);
    }).catch((err) => {
      console.log(err);
    });
  }

  getStatsData = () => {
    const { heatmapInterval, heatmapDatas, result = {} } = this.state;
    const { report_id } = result;
    if (!report_id) { return; }

    if (heatmapInterval in heatmapDatas) {
      const {
        painPointsChartOptions,
        competitorsChartOptions,
        insightsChartOptions,
      } = heatmapDatas[heatmapInterval];
      this.setState({
        loadingChart: false,
        painPointsChartOptions,
        competitorsChartOptions,
        insightsChartOptions,
      });
      return;
    }

    this.setState({ loadingChart: true });
    const host = process.env.REACT_APP_SERVER || 'https://askenki-ppe.mybluemix.net';
    const body = {
      companies: [
        report_id,
      ],
      keyword: true,
      section: true,
      heatmap: 'company',
      interval: heatmapInterval !== 'all' ? heatmapInterval : null,
      parseChartData: true,
      minimumMatchCount: 0,
    };
    new HttpService().sendRequest(
      `${host}/api/company/nlpData/stats/`,
      {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((result) => {
      heatmapDatas[heatmapInterval] = result;
      this.setState({ loadingChart: false, heatmapDatas, ...result });
    });
  }

  handleSourceChange = (source) => {
    this.setState({ heatmapInterval: source }, () => {
      this.getStatsData();
    });
  }

  getBodyContent = () => {
    const { result, loadingChart } = this.state;
    const { currentUserData = {} } = this.props;
    const { userType = 'demo' } = currentUserData;
    const { hasScore } = result;

    if (!hasScore) {
      return (
        <NoResultContent textContent={(
          <div className="no-result-content__text">
            This report is deprecated, and is no longer supported. Please&nbsp;
            <a href="https://enkiai.com/contact" target="_blank" rel="noreferrer">contact us</a>
            &nbsp;to re-run this report.
          </div>
          )}
        />
      );
    }

    return (
      <div className="content">
        <RankedCompanyView userType={userType} result={result} />
        { !loadingChart ? this.getChartContent() : <CircleLoader isActive={true} size="small" /> }
      </div>
    );
  }

  getCompanyByName = (name) => {
    const { result } = this.state;
    const { company_ids: companyIds } = result;
    return companyIds.find(({ companyName }) => companyName === name);
  }

  removeNumberFromSeriesName = (seriesName) => {
    const names = seriesName.split(' ');
    if (names.length === 1) {
      return names[0];
    }

    const lastName = names[names.length - 1];
    const isLastPartNumber = /^\d+$/.test(lastName);
    let exactName = names.slice(0, names.length - 1).join(' ');

    if (!isLastPartNumber) {
      exactName += ` ${lastName}`;
    }

    return exactName;
  }

  onChartClick = (section = '', data) => {
    const { componentType, seriesName, name } = data;
    if (componentType !== 'series') {
      return;
    }

    const companyName = this.removeNumberFromSeriesName(seriesName);
    const { id, companyId } = this.getCompanyByName(companyName);
    const url = `/?report=${id}&keywords=${encodeURIComponent(name)}&companyId=${companyId}${section ? `&sections=${encodeURIComponent(section)}` : ''}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  getChartHeight = (chartData = {}) =>  {
    const { company = [], heatmapInterval } = this.state;
    const { yAxis = {} } = chartData;
    const { data = [] } = yAxis;
    const itemLength = heatmapInterval === 'all' ? 1 : company.length;
    return (70 + (data.length * (35 + (itemLength * 15)))) || 0;
  }

  getChartContent = () => {
    const {
      painPointsChartOptions,
      competitorsChartOptions,
      insightsChartOptions,
      heatmapInterval,
    } = this.state;
    return (
      <div className="content">
        { (insightsChartOptions || competitorsChartOptions || painPointsChartOptions) &&
          (
            <div className="company-reports__body__section">
              <div className="company-reports__sources">
                {
                  sourceList.map(({ sourceName, source }, index) => (
                    <div
                      key={index}
                      className={`company-reports__sources__item ${ source === heatmapInterval ? 'selected' : ''}`}
                      onClick={() => { this.handleSourceChange(source); }}
                    >
                      { sourceName }
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
        { insightsChartOptions ?
          <div className="company-reports__body__section">
            <h2> Insights </h2>
            <ReactECharts
              notMerge
              option={insightsChartOptions}
              style={{height: `${this.getChartHeight(insightsChartOptions)}px` }}
              onEvents= {{ click: this.onChartClick.bind(this, '') }}
            />
          </div>:''
        }
        { painPointsChartOptions ?
          <div className="company-reports__body__section">
            <h2> PainPoints </h2>
            <ReactECharts
              notMerge
              option={painPointsChartOptions}
              style={{height: `${this.getChartHeight(painPointsChartOptions)}px` }}
              onEvents= {{ click: this.onChartClick.bind(this, 'painPointV2') }}
            />
          </div>:''
        }
        { competitorsChartOptions ?
          <div className="company-reports__body__section">
            <h2> Competitors </h2>
            <ReactECharts
              notMerge
              option={competitorsChartOptions}
              style={{height: `${this.getChartHeight(competitorsChartOptions)}px` }}
              onEvents= {{ click: this.onChartClick.bind(this, 'competitors') }}
            />
          </div>:''
        }
      </div>
    )
  }

  renderDebugInputs = () => {
    return (
      <div>
        <div className="padding-l-10 margin-t-15">
          <Checkbox {...{checked: this.state.bucketizeEnabled, ...this.bucketizeCheckbox }} handleChange={(isChecked) => this.setState({ bucketizeEnabled: isChecked})} />
        </div>
      </div>
    );
  }

  render() {
    const { mobileNavigationStatus, currentUserData = {},  } = this.props;
    const { keywords, painPoints, competitors, loading, result, runningHeatMap, area, shareButtonLoader, hasRequestChanged, company = [], exportButtonLoader, debug } = this.state;
    const { userType = 'demo' } = currentUserData;
    const isDemoOrFremiumUser = (userType === 'demo' || userType === 'freemium');
    return (
      <div className={`margin-t-0 sidenav ${mobileNavigationStatus ? 'open' : ''}`}>
        <NavBar theme="dark" logo={{ small: true, desktop: true }} isDemo={(userType === 'demo')} withoutNavigation={ false } iconMenuItems={
          [
            <NavBarIconMenuItem key={1} title="Export the current report in csv format" isLoading={exportButtonLoader} onClick={() => this.exportReport()} value="export" path="/" color='primary-dark' icon='attachment' disabled={ loading || hasRequestChanged || runningHeatMap || isDemoOrFremiumUser} />,
            <NavBarIconMenuItem key={2} title="Generate a shareable report link"  isLoading={shareButtonLoader} onClick={() => this.saveReport()} value="share" path="/" color='primary-dark' icon='share' disabled={ loading || hasRequestChanged || isDemoOrFremiumUser} />
          ]
        } />
        <div className="company-reports">
          <h2 className="company-reports__title">
            Find insights about your target account
          </h2>
          <div className="company-reports__form">
            <div className="company-reports__form__item">
              <div className="company-input">
                <div className="company-input__title">
                  Companies
                  <div className="a-tooltip right d-none d-sm-inline-block margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Company / Industry
                      </h4>
                      <div className="a-tooltip__content__text">
                        Enki discovers Insights based on your "Target company or industry". When you enter a "Target company", such as "Chevron" or "AAA", either choose a suggestion from Enki or you can "Create" a new entry.
                      </div>
                    </span>
                  </div>
                  <div className="a-tooltip d-inline-block d-sm-none margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Company / Industry
                      </h4>
                      <div className="a-tooltip__content__text">
                        Enki discovers Insights based on your "Target company or industry". When you enter a "Target company", such as "Chevron" or "AAA", either choose a suggestion from Enki or you can "Create" a new entry.
                      </div>
                    </span>
                  </div>
                </div>
                <CompanySelector value={this.state.company} onChange={this.onCompanyChange} isMulti={true} disabled={loading || isDemoOrFremiumUser} />
              </div>
              <div className="company-input">
                <div className="company-input__title">
                  Target Vertical
                  <div className="a-tooltip right  d-none d-sm-inline-block margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Target Vertical
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can choose a predefined "Target Vertical" you are selling to such as "IoT Insights", or create a new one such as "Auto Manufacturers", "Insurance", etc.
                      </div>
                    </span>
                  </div>
                  <div className="a-tooltip d-inline-block d-sm-none margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Target Vertical
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can choose a predefined "Target Vertical" you are selling to such as "IoT Insights", or create a new one such as "Auto Manufacturers", "Insurance", etc.
                      </div>
                    </span>
                  </div>
                </div>
                <AreaSelector onChange={this.onAreaChange} value={ area && { label: area, value: area }} disabled={(loading || isDemoOrFremiumUser)} />
              </div>
              <div className="company-input">
                <AsyncSelector {...this.keywordSelector} value={keywords} disabled={loading || isDemoOrFremiumUser }  />
              </div>
              <div className="company-input">
                <AsyncSelector {...this.painPointSelector} value={painPoints} disabled={loading || isDemoOrFremiumUser }  />
              </div>
              <div className="company-input">
                <AsyncSelector {...this.competitorSelector} value={competitors} disabled={loading || isDemoOrFremiumUser } />
              </div>
              <div className="company-input">
                <div className="company-input__title">
                  Job Titles / Departments
                  <div className="a-tooltip right  d-none d-sm-inline-block margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Job Titles / Departments
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can enter the Job Titles / Departments such as "Director of IT", "Digital Transformation", "Human Resources" you target into Enki. Enki will crawl the web and combine the job titles with your solution attributes and discover the "Top Prospects". Enki can also find their contact information if requested.
                      </div>
                    </span>
                  </div>
                  <div className="a-tooltip d-inline-block d-sm-none margin-l-10">
                    <i className="material-icons">help</i>
                    <span className="a-tooltip__content">
                      <h4 className="a-tooltip__content__title">
                        Job Titles / Departments
                      </h4>
                      <div className="a-tooltip__content__text">
                        You can enter the Job Titles / Departments such as "Director of IT", "Digital Transformation", "Human Resources" you target into Enki. Enki will crawl the web and combine the job titles with your solution attributes and discover the "Top Prospects". Enki can also find their contact information if requested.
                      </div>
                    </span>
                  </div>
                </div>
                <TitleSelector onChange={this.onTitleChange} value={this.state.titles} disabled={loading || isDemoOrFremiumUser } />
                { debug && this.renderDebugInputs() }
              </div>
            </div>
            <div className="company-reports__form__footer">
              { loading ?
                <CircleLoader isActive={true} size="small"  />:
                <button className="company-reports__form__button" onClick={this.onFormSubmit} disabled={ loading || company.length < 1 } style={{ 'pointer-events': loading || company.length < 1? 'none': 'auto' }}>
                  Get Insights
                </button>
              }
               { debug && <button className="company-reports__form__button" onClick={this.rerunReports} disabled={ loading || company.length < 1 } style={{ 'pointer-events': loading || company.length < 1? 'none': 'auto',  marginLeft: '20px'}}>
                  Rerun Reports
                </button> }
            </div>
          </div>
          <div className="company-reports__body">
            { !loading && !hasRequestChanged  && runningHeatMap ?
              <ReceivedRequestCard message={userType === 'demo' ? 'Enki is working hard to prepare your report.' : undefined} /> : ''
            }
            { !loading && !hasRequestChanged && !runningHeatMap && result ?
              this.getBodyContent() : ''
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUserData: () => dispatch(fetchCurrentUserData()),
  pushToastMessage: (toastData) => dispatch(pushToastMessage(toastData)),
  receiveCurrentUserData: (params) => dispatch(receiveCurrentUserData(params)),
  updateMessagePopup: (data) => dispatch(updateMessagePopup(data)),
});

const mapStateToProps = (store) => {
  const { rootReducers, userReducers } = store;
  const { userDataReducers } = rootReducers;
  const { mobileNavigationReducers } = userReducers;
  return {
    currentUserData: userDataReducers.currentUserData,
    mobileNavigationStatus: mobileNavigationReducers.status,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyReports);
