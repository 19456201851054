import CookieService from 'services/CookieService';

const cookieService = new CookieService();

const DemoAllowedLocations = [
  { pathName: "/", isRequiredToken: true, requiredParams: ['report'] },
  { pathName: "/multiple", isRequiredToken: true, requiredParams: ['report', 'secretId'], alternativePath: 'login' },
  { pathName: "/demo", isRequiredToken: true, requiredParams: [] },
  { pathName: "/reports", isRequiredToken: true, requiredParams: [] },
];

const UnknowUserAllowedLocations = [
  { pathName: "/", isRequiredToken: false, requiredParams: ['report', 'secretId'], alternativePath: 'login' },
  { pathName: "/multiple", isRequiredToken: false, requiredParams: ['report', 'secretId'], alternativePath: 'login' },
  { pathName: "/demo", isRequiredToken: true, requiredParams: [] },
  { pathName: "/reports", isRequiredToken: true, requiredParams: [] },
];

const FreemiumAllowedLocations = [
  { pathName: "/", isRequiredToken: true, requiredParams: ['report'], alternativePath: 'research' },
  { pathName: "/multiple", isRequiredToken: true, requiredParams: ['report', 'secretId'], alternativePath: 'reports' },
  { pathName: "/demo", isRequiredToken: true, requiredParams: [] },
  { pathName: "/reports", isRequiredToken: true, requiredParams: [] },
  { pathName: "/research", isRequiredToken: true, requiredParams: [] },
];

export class RoutePermissonService {

  checkRoutePermmissiom(location) {
    const userType = cookieService.getCookie('user_type');
    const result = {
      'premium': () => this.checkPremiumUser(location),
      'freemium': () => this.checkFreemiumUser(location),
      'demo': () => this.checkDemoPermission(location),
      '': () => this.checkUnknownUser(location)
    }[userType]
    return result && result() || this.checkUnknownUser(location)
  }

  checkUnknownUser(location) {
    const { pathname, search } = location;
    const result = UnknowUserAllowedLocations.some(({ pathName, isRequiredToken, requiredParams }) => {
      return (pathName == pathname && (!isRequiredToken || this.checkDemoToken()) &&this.checkRequiredParams(search, requiredParams));
    });

    return { result: result, alternative: this.getAlternativePath(pathname, UnknowUserAllowedLocations) }
  }

  checkPremiumUser(location) {
    return this.checkToken();
  }

  checkFreemiumUser(location) {
    const { pathname, search } = location;
    const result = FreemiumAllowedLocations.some(({ pathName, isRequiredToken, requiredParams }) => {
      return (pathName == pathname && (!isRequiredToken || this.checkToken()) &&this.checkRequiredParams(search, requiredParams));
    });

    return { result: result, alternative: this.getAlternativePath(pathname, FreemiumAllowedLocations) };
  }

  checkDemoPermission(location) {
    const { pathname, search } = location;
    const result = DemoAllowedLocations.some(({ pathName, isRequiredToken, requiredParams }) => {
      return (pathName == pathname && (!isRequiredToken || this.checkDemoToken()) &&this.checkRequiredParams(search, requiredParams));
    });

    return { result: result, alternative: this.getAlternativePath(pathname, DemoAllowedLocations) };
  }

  checkDemoToken() {
    const token = this.getDemoToken();
    if(token.isNullOrEmpty()) {
      return false
    }

    return true;
  }

  checkToken() {
    const token = this.getToken();
    if(token.isNullOrEmpty()) {
      return { result: false }
    }

    return { result: true }
  }

  getAlternativePath(pathName, locations) {
    const { alternativePath } = locations.find((item) => item.pathName == pathName) || {};
    return alternativePath;
  }

  getToken() {
    return cookieService.getCookie('token');
  }

  getDemoToken() {
    return cookieService.getCookie('demo_token');
  }

  checkRequiredParams(search, requiredParams) {
    let result = true;

    if(requiredParams.length < 1) {
      return true;
    }

    requiredParams.forEach(element => {
      if(search.indexOf(`${element}=`) < 0) {
        result = false;
      }
    });

    return result;
  }

}
