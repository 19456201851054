import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CardView } from 'components/Views/CardView/CardView';
import TabBarView from 'components/Views/TabBarView/TabBarView';
import { searchTextInReport } from 'store/actions/dashboard/report';
import './ReportsHeaderTabView.scss';

const tabList = [
  {
    "name": "Single Company",
    "to": "/dashboard/reports",
    "icon": "search"
  },
  {
    "name": "Multiple Companies",
    "to": "/dashboard/reports/multiple",
    "icon": "insert_chart"
  }
];

export class ReportsHeaderTabView extends Component {

  onTabChanged = (changed) => {
    this.props.searchTextInReport('');
  }

  render() {
    return (
      <div className="reports-header-tab-view">
        <CardView classes="unhoverable cursor--default" childComponent={
          <TabBarView tabs={tabList} onTabChanged={this.onTabChanged} />
        } />
      </div>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    searchTextInReport: (text) => dispatch(searchTextInReport(text)),
  }
};

export default connect(null, mapDispatchToProps)(ReportsHeaderTabView);
